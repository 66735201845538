import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {PayorContactEntryDto} from '../../../models/payor-entry/payor-contact-entry-dto';
import {PayorListSearchRow} from '../../../models/common/payor-list-search-row';
import {CommonResult} from '../../../models/common/common-result';
import {BillingHttpClient} from '../../../common/billing-http-client.service';
import {ValidationResultsService} from '../../../services/validation-results.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {FacilityEntryPayorsDto} from '../../../models/facility-payor-entry/facility-entry-payors-dto';
import {FacilityEntryPayorDto} from '../../../models/facility-payor-entry/facility-entry-payor-dto';

@Component({
  selector: 'app-facility-entry',
  templateUrl: './facility-entry.component.html',
  styleUrls: ['./facility-entry.component.scss']
})
export class FacilityEntryComponent implements OnInit {

  id: number;
  private ngUnsubscribe = new Subject();
  model: FacilityEntryPayorsDto = new FacilityEntryPayorsDto();


  constructor(
    private billingHttpClient: BillingHttpClient,
    private router: Router,
    private validationResultsService: ValidationResultsService,
    private _service: AuthenticationService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
      });
    this.load();
  }

  load() {
    const params = new HttpParams().set('facilityId', this.id.toString());
    this.billingHttpClient.Get<FacilityEntryPayorsDto>('/facility-entry-payors', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  drop(event: CdkDragDrop<PayorContactEntryDto[]>) {
    moveItemInArray(this.model.facilityEntryPayorDtos, event.previousIndex, event.currentIndex);
  }

  editFacilityPayor(facilityPayorId: number) {
    this.router.navigate(['facility-payor-entry', facilityPayorId]);
  }

  selectPayor(payor: PayorListSearchRow) {
    let facilityPayorNew: FacilityEntryPayorDto = new FacilityEntryPayorDto;
    facilityPayorNew.facilityId = this.model.facilityId;
    facilityPayorNew.payorId = payor.payorId;
    facilityPayorNew.chargeCodePayorAssociationMode = 'All';
    facilityPayorNew.visitReasonPayorAssociationMode = 'All';
    facilityPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    facilityPayorNew.payorName = payor.name;
    facilityPayorNew.payorType = payor.payorType;
    facilityPayorNew.startDateInclusive = new Date(2000, 0, 1);
    facilityPayorNew.endDateInclusive = new Date(9999, 11, 31);
    facilityPayorNew.active = true;
    this.model.facilityEntryPayorDtos.push(facilityPayorNew);
  }

  createFacilityPayor(type: string) {
    let facilityPayorNew = new FacilityEntryPayorDto();
    facilityPayorNew.facilityId = this.model.facilityId;
    facilityPayorNew.chargeCodePayorAssociationMode = 'All';
    facilityPayorNew.visitReasonPayorAssociationMode = 'All';
    facilityPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    facilityPayorNew.payorName = this.model.name;
    facilityPayorNew.payorType = type;
    facilityPayorNew.ssnDisplayType = "Redact";
    facilityPayorNew.billingFrequencyType = "Monthly";
    facilityPayorNew.startDateInclusive = new Date(2000, 0, 1);
    facilityPayorNew.endDateInclusive = new Date(9999, 11, 31);
    facilityPayorNew.active = true;
    this.model.facilityEntryPayorDtos.push(facilityPayorNew);
  }

  cancel() {
    window.history.back();
  }

  save() {
    // Update rank
    this.model.facilityEntryPayorDtos.forEach((v, i) => {
      v.rank = i;
    });
    this.billingHttpClient.Post<CommonResult>('/facility-entry-payors', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeed']);
          this.load();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  get canSave(): boolean {
    return this._service.hasRole("ROLE_billing_employer_admin");
  }
}
