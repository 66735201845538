import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {PendingDebitsAndAdjustmentsSearchRow} from "../../../models/pending-debits-and-adjustments/pending-debits-and-adjustments-search-row";
import {PriceSearchHistoryDto} from "../../../models/pending-debits-and-adjustments/price-search-hisotry-popup/price-search-history-dto";
import {DataTableDirective} from "angular-datatables";
import {TaskHttpClient} from "../../../common/task-http-client";

declare var $: any;

@Component({
  selector: 'pending-debits-and-adjustments',
  templateUrl: './pending-debits-and-adjustments.component.html',
  styleUrls: ['./pending-debits-and-adjustments.component.scss']
})
export class PendingDebitsAndAdjustmentsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  model: PendingDebitsAndAdjustmentsSearchRow[] = [];
  priceHistory: PriceSearchHistoryDto;
  @ViewChild(DataTableDirective, {static: false})
  private datatableElement: DataTableDirective;
  searchStr: string = '';

  constructor(private billingHttpClient: BillingHttpClient,
              private taskHttpClient: TaskHttpClient,
              private  router: Router) {
  }

  ngOnInit() {
    const that = this;
    // let table = $('#tb-list');
    // Setup - add a text input to each footer cell
    $('#tb-pending-debits-and-adjustments thead tr').clone(true).appendTo('#tb-pending-debits-and-adjustments thead');
    $('#tb-pending-debits-and-adjustments thead tr:eq(1) th').each(function (i) {
      const title = $(this).text();
      if (i == 15) {
        $(this).html('<input type="text" placeholder="Search ' + title + '" />');
      } else {
        $(this).html('');
      }

      $('input', this).on('keyup change', function () {
        // datatableElement is null if it's put directly in the ngOnInit
        that.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            if (dtInstance.column(i).search() !== this.value) {
              dtInstance
                .column(i)
                .search(this.value)
                .draw();
            }
          }
        );

      });
    });
    $('#input-search').keypress(function (event) {
      const keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == '13') {
        that.search();
      }
    })

    this.dtOptions = {
      pagingType: "full_numbers",
      orderCellsTop: true,
      pageLength: 1000,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: '8%',
        },
      ],
      columns: [
        {data: ''},
        {data: 'financialTransactionPendingImportId'},
        {data: 'patient'},
        {data: 'employer'},
        {data: 'region'},
        {data: 'jobSite'},
        {data: 'jobNumber'},
        {data: 'facility'},
        {data: 'branch'},
        {data: 'provider'},
        {data: 'serviceDate'},
        {data: 'type'},
        {data: 'chargeCode'},
        {data: 'quantity'},
        {data: 'purchaseOrder'},
        {data: 'status'},
        {data: 'authorizationPatientId'},
        {data: 'groupingKey'},
      ],
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/pending-debits-and-adjustments", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      dom: 'ltipr',
    };
  }

  viewDebit = (debit: PendingDebitsAndAdjustmentsSearchRow) => {
    this.router.navigate(['pending-debit-edit', debit.financialTransactionPendingImportId]);
  }

  viewHistory = (debit: PendingDebitsAndAdjustmentsSearchRow) => {
    this.taskHttpClient.Get<PriceSearchHistoryDto>(`/price-search-history/${debit.financialTransactionPendingImportId}`)
      .subscribe(response => {
        this.priceHistory = response;
        $('#priceHistoryPopup').modal();
      });
  };

  navigateToChargeEntry = () => {
    this.router.navigate(['debit-entry', 0]);
  }

  navigateToEmployerChargeEntry = () => {
    this.router.navigate(['employer-debit-entry', 0]);
  }

  navigateToPayorChargeEntry = () => {
    this.router.navigate(['payor-debit-entry', 0]);
  }

  navigateToGroupingKeyDistribute = () => {
    this.router.navigate(['distribute-invoice-group']);
  }

  search() {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.searchStr)
          .draw();
      }
    );
  }
}

