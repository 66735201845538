import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PayorEntryDto} from "../../../../models/payor-entry/payor-entry-dto";
import {State, StateList} from "../../../../utilities/state-list";

@Component({
  selector: 'app-payor-entry-general',
  templateUrl: './payor-entry-general.component.html',
  styleUrls: ['./payor-entry-general.component.scss']
})
export class PayorEntryGeneralComponent implements OnInit {

  @Input() model: PayorEntryDto;
  @Output() saved = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Output() saveClosed = new EventEmitter();
  stateList: State[] = StateList.STATES;

  constructor() {
  }

  ngOnInit(): void {
  }

  save() {
    this.saved.emit();
  }

  cancel() {
    this.canceled.emit();
  }

  saveClose() {
    this.saveClosed.emit();
  }
}
