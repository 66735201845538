import {Component, Input, OnInit} from '@angular/core';
import {EmployerEntryRegionDto} from "../../../../models/employer-entry/employer-entry-region-dto";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-employer-entry-regions',
  templateUrl: './employer-entry-regions.component.html',
  styleUrls: ['./employer-entry-regions.component.scss']
})
export class EmployerEntryRegionsComponent implements OnInit {

  @Input() id: number;
  model: EmployerEntryRegionDto[] = [];

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private router: Router) {
  }

  ngOnInit(): void {
    const params = new HttpParams().set('employerId', this.id.toString());
    this.clinicHttpClient.Get<EmployerEntryRegionDto[]>('/employer-entry-regions', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  goToRegion(regionId: number) {
    this.router.navigate(['region-entry', regionId]);
  }
}
