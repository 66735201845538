import { Component, OnInit } from '@angular/core';
import {BillingHttpClient} from '../../../common/billing-http-client.service';
import {ValidationResultsService} from '../../../services/validation-results.service';
import {TaskHttpClient} from '../../../common/task-http-client';
import {HttpParams} from '@angular/common/http';
import {CommonResult} from '../../../models/common/common-result';
import {PayorChargeCodeDisplaySearchRow} from '../../../models/payor-entry/payor-charge-code-display-search-row';

@Component({
  selector: 'revert-checkout',
  templateUrl: './revert-checkout.component.html',
  styleUrls: ['./revert-checkout.component.scss']
})
export class RevertCheckoutComponent implements OnInit {

  authorizationPatientId: string;


  constructor(private appHttpclient: TaskHttpClient,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit(): void {
  }

  revertCheckout() {
    this.validationResultsService.reset()
    let payload = {
      authorizationPatientId: this.authorizationPatientId
    } ;
    this.appHttpclient.Post<CommonResult>('/revert-checkout', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          this.onRevertSucceeded();
        }else{
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      })
  }

  onRevertSucceeded() {
    this.validationResultsService.setSuccessMessages(["Revert checkout success"]);
  }

}
