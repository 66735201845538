import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {PayorListSearchRow} from "../../../models/common/payor-list-search-row";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

declare var $: any;

@Component({
  selector: 'payor-lookup',
  templateUrl: './payor-lookup.component.html',
  styleUrls: ['./payor-lookup.component.scss']
})
export class PayorLookupComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  model: PayorListSearchRow[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  @Input() params: Map<string, any>;

  constructor(private billingHttpClient: BillingHttpClient) {
  }

  @Output() payorChange: EventEmitter<PayorListSearchRow> = new EventEmitter<PayorListSearchRow>();

  ngOnInit() {
    const that = this;
    // Setup - add a text input to each footer cell
    $('#tb-list thead tr').clone(true).appendTo('#tb-list thead');
    $('#tb-list thead tr:eq(1) th').each(function (i) {
      const title = $(this).text();
      if (i == 4) {
        $(this).html('<input id="input-active" type="text" placeholder="Search ' + title + '" value="true"/>');
      } else {
        $(this).html('');
      }

      $('input', this).on('keyup change', function () {
        // datatableElement is null if it's put directly in the ngOnInit
        that.dtElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            if (dtInstance.column(i).search() !== this.value) {
              dtInstance
                .column(i)
                .search(this.value)
                .draw();
            }
          }
        );
      });
    });
    this.dtOptions = {
      pagingType: "full_numbers",
      orderCellsTop: true,
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: 'payorId'},
        {data: 'payorType'},
        {data: 'name'},
        {data: 'isGlobal'},
        {data: 'isActive'},
      ],
      ajax: (dataTablesParameters: any, callback) => {
        if (that.params != null && that.params.size > 0) {
          that.params.forEach((val, key) => {
            dataTablesParameters[key] = val;
          });
        }
        that.billingHttpClient.Post<DataTablesResponse>("/payor-list", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  selectPayor = (payor: PayorListSearchRow) => {
    this.payorChange.emit(payor);
    $('#payorLookupModal').modal('hide');
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    $('#input-active').keyup();
  }

  // used in statement.components
  // noinspection JSUnusedGlobalSymbols
  reloadTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}

