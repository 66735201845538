import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {ActivatedRoute} from "@angular/router";
import {ChargeCodeLookupSearchRow} from "../../../models/common/charge-code-lookup-search-row";
import {BranchDto} from "../../../models/common/branch-dto";
import {JobSiteDto} from "../../../models/common/job-site-dto";
import {RegionDto} from "../../../models/common/region-dto";
import {FacilityDto} from "../../../models/common/facility-dto";
import {PayorListSearchRow} from "../../../models/common/payor-list-search-row";
import {EmployerDto} from "../../../models/common/employer-dto";
import {BillingGroupListDto} from "../../../models/common/billing-group-list-dto";
import {NgForm} from "@angular/forms";
import {PendingFinancialTransactionDto} from "../../../models/common/pending-financial-transaction-dto";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../models/common/common-result";
import {TaskHttpClient} from "../../../common/task-http-client";

@Component({
  selector: 'employer-debit-entry',
  templateUrl: './employer-debit-entry.component.html',
  styleUrls: ['./employer-debit-entry.component.scss']
})
export class EmployerDebitEntryComponent implements OnInit {


  constructor(private billingHttpClient: BillingHttpClient,
              private clinicHttpClient: ClinicHttpClient,
              private taskHttpClient: TaskHttpClient,
              private validationResultsService: ValidationResultsService,
              private route: ActivatedRoute) {
  }

  transactionTypes: string[] = []
  employers: EmployerDto[] = [];
  regions: RegionDto[] = [];
  facilities: FacilityDto[] = [];
  branches: BranchDto[] = [];
  jobNumbers: string[] = [];
  jobSites: JobSiteDto[] = [];
  groupingKeys: BillingGroupListDto[] = [];

  transactionType = '';
  employer: EmployerDto = null;
  region: RegionDto = null;
  jobSite: JobSiteDto = null;
  branch: BranchDto = null;
  jobNumber: string = null;
  facility: FacilityDto = null;
  purchaseOrder: string;
  chargeCode: ChargeCodeLookupSearchRow = new ChargeCodeLookupSearchRow();
  displayChargeCode: string;
  serviceDate: Date;
  quantity: number = 1;
  notes: string;
  groupingKey: string;
  groupingKeyModel: BillingGroupListDto = null;
  providerNPI: string;

  overridingPayorPrice: boolean = false;
  amount: number;
  payor: PayorListSearchRow = new PayorListSearchRow();

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (+params['id'] > 0) {
        this.load(+params['id']);
      }
    });
    this.getNonVoidDebitTransactionTypes();
    this.getEmployers();
    this.getFacilities();
    this.getBranches();
    this.getBillingGroups();
  }

  load(id: number) {
    this.billingHttpClient.Get<PendingFinancialTransactionDto>(`/pending-financial-transaction/${id}`, {})
      .subscribe(response => {
        this.transactionType = response.testReasonType;
        if (response.employerId) {
          this.employer = new EmployerDto();
          this.employer.companyName = response.employer;
          this.employer.id = response.employerId;
          this.getEmployerBasedChargeEntryOptions(this.employer.id);
        }
        if (response.regionId) {
          this.region = new RegionDto();
          this.region.id = response.regionId;
          this.region.companyName = response.region;
          this.getRegionBasedChargeEntryOptions(this.region.id);
        }
        if (response.jobSiteId) {
          this.jobSite = new JobSiteDto();
          this.jobSite.id = response.jobSiteId;
          this.jobSite.companyName = response.jobSite;
        }
        if (response.branchId) {
          this.branch = new BranchDto();
          this.branch.id = response.branchId;
          this.branch.companyName = response.branch;
        }
        this.jobNumber = response.jobNumber;
        if (response.facilityId) {
          this.facility = new FacilityDto();
          this.facility.id = response.facilityId;
          this.facility.companyName = response.facility;
        }
        this.providerNPI = response.providerNPI;
        this.purchaseOrder = response.purchaseOrderNumber;
        if (response.chargeCode != null) {
          this.chargeCode.code = response.chargeCode;
          this.chargeCode.description = response.chargeCodeDescription;
        }
        this.quantity = response.quantity;
        this.serviceDate = response.serviceDate;
        this.groupingKey = response.groupingKey;
        this.notes = response.notes;
        this.amount = response.amount;
        if (response.payor != null) {
          this.payor.name = response.payor;
          this.payor.payorId = response.payorId;
        }
        this.displayChargeCode = response.displayChargeCode;
      });
  }

  getNonVoidDebitTransactionTypes() {
    if (this.transactionTypes.length == 0) {
      this.billingHttpClient.Get<string[]>('/non-void-debit-transaction-types')
        .subscribe(response => {
          this.transactionTypes = response;
        });
    }
  }

  getEmployers() {
    this.clinicHttpClient.Get<EmployerDto[]>('/debit-entry-employers', {})
      .subscribe(response => {
        this.employers = response;
      });
  }

  getFacilities() {
    if (this.facilities.length == 0) {
      this.clinicHttpClient.Get<FacilityDto[]>('/debit-entry-facilities')
        .subscribe(response => {
          this.facilities = response;
        });
    }
  }

  getBranches() {
    if (this.branches.length == 0) {
      this.clinicHttpClient.Get<BranchDto[]>('/debit-entry-branches')
        .subscribe(response => {
          this.branches = response;
        });
    }
  }

  getBillingGroups() {
    if (this.groupingKeys.length == 0) {
      this.billingHttpClient.Get<BillingGroupListDto[]>('/debit-entry-billing-groups')
        .subscribe(response => {
          this.groupingKeys = response;
        });
    }
  }

  getEmployerBasedChargeEntryOptions(employerId: number) {
    this.getJobNumbersByEmployer(employerId);
    this.getRegionsForEmployer(employerId);
  }

  getJobNumbersByEmployer(employerId: number) {
    const params = new HttpParams().set('employerId', employerId.toString());
    this.clinicHttpClient.Get<string[]>('/debit-entry-job-numbers', {params})
      .subscribe(response => {
        this.jobNumbers = response;
      });
  }

  getRegionsForEmployer(employerId: number) {
    const params = new HttpParams().set('employerId', employerId.toString());
    this.clinicHttpClient.Get<RegionDto[]>('/debit-entry-regions', {params})
      .subscribe(response => {
        this.regions = response;
      });
  }

  getRegionBasedChargeEntryOptions(regionId: number) {
    const params = new HttpParams().set('regionId', regionId.toString());
    this.clinicHttpClient.Get<JobSiteDto[]>('/debit-entry-job-sites', {params})
      .subscribe(response => {
        this.jobSites = response;
      });
  }

  chargeCodeChange(data: ChargeCodeLookupSearchRow) {
    this.chargeCode = data;
  }

  updateEmployer() {
    this.jobNumbers = [];
    this.jobNumber = null;
    this.regions = [];
    this.region = null;
    this.jobSites = [];
    this.jobSite = null;
    if (this.employer && this.employer.id) {
      this.getEmployerBasedChargeEntryOptions(this.employer.id);
    }
  }

  updateRegion() {
    this.jobSites = [];
    this.jobSite = null;
    if (this.region && this.region.id) {
      this.getRegionBasedChargeEntryOptions(this.region.id);
    }
  }

  get canSave(): boolean {
    return this.employer
      && (this.facility || this.branch)
      && this.chargeCode
      && this.chargeCode.code
      && this.serviceDate != null;
  }

  saveAndClose() {
    this.save().then(response => {
      if (response.result != 'failed') {
        this.onSaveAndCloseSucceeded();
        this.processSaved(response.result);
      } else {
        this.validationResultsService.setErrorMessages(response.validationResults);
      }
    });
  }

  onSaveAndCloseSucceeded() {
    this.validationResultsService.setSuccessMessages(["Save Succeeded"]);
    this.form.form.markAsPristine();
    window.history.back();
  }

  saveAndNew() {
    this.save().then(response => {
      if (response.result != 'failed') {
        this.onSaveAndNewSucceeded();
        this.processSaved(response.result);
      } else {
        this.validationResultsService.setErrorMessages(response.validationResults);
      }
    });
  }

  onSaveAndNewSucceeded() {
    this.validationResultsService.setSuccessMessages(["Save Succeeded"]);
    this.serviceDate = null;
    this.chargeCode = new ChargeCodeLookupSearchRow();
    this.quantity = 1;
    this.purchaseOrder = null;
    this.hideOverridePayorPrice();
  }

  save(): Promise<any> {
    const pendingFinancialTransaction = {
      chargeCode: this.chargeCode.code,
      chargeCodeDescription: this.chargeCode.description,
      employerId: this.employer.id,
      employer: this.employer.companyName,
      regionId: this.region == null ? null : this.region.id,
      region: this.region == null ? null : this.region.companyName,
      jobSiteId: this.jobSite == null ? null : this.jobSite.id,
      jobSite: this.jobSite == null ? null : this.jobSite.companyName,
      branchId: this.branch == null ? null : this.branch.id,
      branch: this.branch == null ? null : this.branch.companyName,
      facilityId: this.facility == null ? null : this.facility.id,
      facility: this.facility == null ? null : this.facility.companyName,
      jobNumber: this.jobNumber,
      id: 0,
      patientId: null,
      patientFirstName: null,
      patientLastName: null,
      patientIdentifier: null,
      serviceDate: this.serviceDate,
      purchaseOrderNumber: this.purchaseOrder,
      providerId: null,
      provider: null,
      providerNPI: this.providerNPI,
      authorizationPatientTestTypeId: null,
      authorizationPatientSupplyId: null,
      authorizationPatientInjuryClinicNoteId: null,
      financialTransactionPendingImportType: this.transactionType == null ? null : this.transactionType,
      quantity: this.quantity,
      testReasonType: null,
      amount: this.amount,
      payor: this.payor.payorId == null || this.payor.payorId == 0 ? null : this.payor.name,
      payorId: this.payor.payorId == null || this.payor.payorId == 0 ? null : this.payor.payorId,
      parentFinancialTransactionId: null,
      specimenId: null,
      authorizationPatientId: null,
      groupingKey: this.groupingKey,
      notes: this.notes,
      displayChargeCode: this.displayChargeCode
    };

    return this.billingHttpClient
      .Post<CommonResult>('/financial-transaction-pending-import', pendingFinancialTransaction)
      .toPromise();
  }

  setPayorOverride(value: PayorListSearchRow) {
    this.payor = value;
  }

  clearPayor() {
    this.payor = new PayorListSearchRow();
  }

  showOverridePayorPrice() {
    this.overridingPayorPrice = true;
  }

  hideOverridePayorPrice() {
    this.overridingPayorPrice = false;
    this.payor = new PayorListSearchRow();
    this.amount = null;
  }

  cancel() {
    window.history.back();
  }

  onSelectGroupingKey() {
    this.groupingKey = this.groupingKeyModel.billingGroupId.toString();
  }

  @ViewChild('form') form: NgForm;

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

  processSaved(id: string) {
    const params = new HttpParams().set('financialTransactionPendingImportId', id);
    this.taskHttpClient.Post('/process-pending-financial-transaction', params,
      {responseType: 'text' as 'json'})
      .subscribe(_ => {
      });
  }
}
