export class ChargeCodePriceRow {
  id: number;
  chargeCode: string;
  description: string;
  startDateInclusive: Date;
  endDateInclusive: Date;
  payorId: number;
  payor: string;
  billingEntityId: number;
  billingEntity: string;
  branchId: number;
  branch: string;
  visitReasonId: number;
  visitReason: string;
  price: number;
}
