import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {ErrorComponent} from "./components/framework/error/error.component";
import {HeaderComponent} from './components/framework/header/header.component';
import {ValidationResultsComponent} from './components/framework/validation-results/validation-results.component';
import {HttpClientModule} from "@angular/common/http";
import {jwtProvider} from "./helpers/jwt.interceptor";
import {FormsModule} from "@angular/forms";
// @ts-ignore
import {OAuthModule} from "angular-oauth2-oidc";
import {fakeBackendProvider} from "./helpers/fake-backend.interceptor";
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {billingHttpClientProvider} from "./common/billing-http-client.service";
import {InvoicesComponent} from './components/financials/invoices/invoices.component';
import {DataTablesModule} from "angular-datatables";
import {InvoiceDetailComponent} from './components/financials/invoice-detail/invoice-detail/invoice-detail.component';
import {ReallyClickModalComponent} from './components/framework/really-click-modal/really-click-modal.component';
import {ReallyClickDirective} from './directives/really-click.directive';
import {DisplayableMoneyComponent} from './components/framework/displayable-money/displayable-money.component';
import {MatTabsModule} from '@angular/material/tabs';
// Add below so that modal can be recognizeEnter or paste your release notes for en-US hered
// noinspection ES6UnusedImports
import * as bootstrap from "bootstrap";
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {PostalCodePipePipe} from './pipes/postal-code-pipe.pipe';
import {DatePipePipe} from './pipes/date-pipe.pipe';
import {PhonePipePipe} from './pipes/phone-pipe.pipe';
import {InvoiceDetailSummaryComponent} from './components/financials/invoice-detail/invoice-detail-summary/invoice-detail-summary.component';
import {InvoiceDetailDebitsComponent} from './components/financials/invoice-detail/invoice-detail-debits/invoice-detail-debits.component';
import {InvoiceDetailDebitsDirective} from './directives/invoice-detail-debits.directive';
import {InvoiceDetailLedgerComponent} from './components/financials/invoice-detail/invoice-detail-ledger/invoice-detail-ledger.component';
import {InvoiceDetailLedgerDirective} from './directives/invoice-detail-ledger.directive';
import {InvoiceDetailDebitDetailsComponent} from './components/financials/invoice-detail/invoice-detail-debit-details/invoice-detail-debit-details.component';
import {InvoiceDetailDebitDetailsDirective} from './directives/invoice-detail-debit-details.directive';
import {InvoiceDetailNotesDirective} from './directives/invoice-detail-notes.directive';
import {InvoiceDetailNotesComponent} from './components/financials/invoice-detail/invoice-detail-notes/invoice-detail-notes.component';
import {CanDeactivateGuard} from './services/can-deactivate-guard.service';
import {InvoiceDetailHistoryDirective} from './directives/invoice-detail-history.directive';
import {InvoiceDetailHistoryComponent} from './components/financials/invoice-detail/invoice-detail-history/invoice-detail-history.component';
import {AdjustmentBatchesComponent} from './components/financials/adjustment-batches/adjustment-batches.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AdjustmentBatchComponent} from './components/financials/adjustment-batch/adjustment-batch.component';
import {LinearStepperComponent} from './components/framework/linear-stepper/linear-stepper.component';
import {ControlTotalsComponent} from './components/financials/adjustment-batch/control-totals/control-totals.component';
import {SelectInvoicesComponent} from './components/financials/adjustment-batch/select-invoices/select-invoices.component';
import {ApplyAdjustmentsComponent} from './components/financials/adjustment-batch/apply-adjustments/apply-adjustments.component';
import {ReviewFinalizeComponent} from './components/financials/adjustment-batch/review-finalize/review-finalize.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatInputModule} from "@angular/material/input";
import {BillingEntitiesComponent} from './components/admin/billing-entities/billing-entities.component';
import {BillingEntityEntryComponent} from './components/admin/billing-entity-entry/billing-entity-entry.component';
import {EmployerListComponent} from './components/admin/employer-list/employer-list.component';
import {EmployerEntryComponent} from './components/admin/employer-entry/employer-entry.component';
import {ChargeCodePriceListComponent} from './components/admin/charge-code-price-list/charge-code-price-list.component';
import {PayorListComponent} from './components/admin/payor-list/payor-list.component';
import {ChargeCodeListComponent} from './components/admin/charge-code-list/charge-code-list.component';
import {ChargeCodeEntryComponent} from './components/admin/charge-code-entry/charge-code-entry.component';
import {PayorEntryComponent} from './components/admin/payor-entry/payor-entry.component';
import {ChargeCodePriceViewComponent} from './components/admin/charge-code-price-view/charge-code-price-view.component';
import {ChargeCodeEntryGeneralComponent} from './components/admin/charge-code-entry-content/charge-code-entry-general/charge-code-entry-general.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ChargeCodeEntryPricingComponent} from './components/admin/charge-code-entry-content/charge-code-entry-pricing/charge-code-entry-pricing.component';
import {EmployerEntryGeneralComponent} from './components/admin/employer-entry/employer-entry-general/employer-entry-general.component';
import {EmployerEntryRegionsComponent} from './components/admin/employer-entry/employer-entry-regions/employer-entry-regions.component';
import {MatSelectModule} from '@angular/material/select';
import {PayorEntryGeneralComponent} from './components/admin/payor-entry/payor-entry-general/payor-entry-general.component';
import {PayorEntrySubmitConfigComponent} from './components/admin/payor-entry/payor-entry-submit-config/payor-entry-submit-config.component';
import {MessageModalComponent} from './components/framework/message-modal/message-modal.component';
import {ApplyInvoicesAdjustmentsComponent} from './components/financials/adjustment-batch/apply-adjustments/apply-invoices-adjustments/apply-invoices-adjustments.component';
import {AdjustmentCreatorComponent} from './components/financials/adjustment-batch/apply-adjustments/apply-invoices-adjustments/adjustment-creator/adjustment-creator.component';
import {AdjustmentsComponent} from './components/financials/adjustment-batch/apply-adjustments/apply-invoices-adjustments/adjustments/adjustments.component';
import {ScrollDispatcher, ScrollingModule} from "@angular/cdk/scrolling";
import {BillingEntityEntryGeneralComponent} from './components/admin/billing-entity-entry-content/billing-entity-entry-general/billing-entity-entry-general.component';
import {BillingEntityEntryFacilitiesComponent} from './components/admin/billing-entity-entry-content/billing-entity-entry-facilities/billing-entity-entry-facilities.component';
import {BillingEntityEntryBranchesComponent} from './components/admin/billing-entity-entry-content/billing-entity-entry-branches/billing-entity-entry-branches.component';
import {PayorEntryInvoiceConfigComponent} from './components/admin/payor-entry/payor-entry-invoice-config/payor-entry-invoice-config.component';
import {PayorEntryStatementConfigComponent} from './components/admin/payor-entry/payor-entry-statement-config/payor-entry-statement-config.component';
import {AgGridModule} from 'ag-grid-angular';
import {EmployerEntryPayorsComponent} from './components/admin/employer-entry/employer-entry-payors/employer-entry-payors.component';
import {EmployerEntryResultsComponent} from './components/admin/employer-entry/employer-entry-results/employer-entry-results.component';
import {MatDialogModule} from '@angular/material/dialog';
import {PayorEntryContactsComponent} from './components/admin/payor-entry/payor-entry-contacts/payor-entry-contacts.component';
import {PayorEntryCallLogComponent} from './components/admin/payor-entry/payor-entry-call-log/payor-entry-call-log.component';
import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {EditButtonComponent} from './components/framework/edit-button/edit-button.component';
import {BillingDocumentCompositionComponent} from './components/financials/billing-document-composition/billing-document-composition.component';
import {DragulaModule} from "ng2-dragula";
import {ImageDisplayPipe} from './pipes/image-display.pipe';
import {PendingDebitEditComponent} from './components/financials/pending-debit-edit/pending-debit-edit.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PendingDebitsAndAdjustmentsComponent} from './components/financials/pending-debits-and-adjustments/pending-debits-and-adjustments.component';
import {PriceSearchHistoryPopupComponent} from './components/financials/pending-debits-and-adjustments/price-search-history-popup/price-search-history-popup.component';
import {DebitEntryComponent} from './components/financials/debit-entry/debit-entry.component';
import {PatientLookupComponent} from './components/common/patient-lookup/patient-lookup.component';
import {clinicHttpClientProvider} from "./common/clinic-http-client";
import {ChargeCodeLookupComponent} from './components/common/charge-code-lookup/charge-code-lookup.component';
import {PayorLookupComponent} from './components/common/payor-lookup/payor-lookup.component';
import {EmployerDebitEntryComponent} from './components/financials/employer-debit-entry/employer-debit-entry.component';
import {PayorDebitEntryComponent} from './components/financials/payor-debit-entry/payor-debit-entry.component';
import {GroupingKeyDistributeComponent} from './components/financials/grouping-key-distribute/grouping-key-distribute.component';
import {DebitsAndAdjustmentsComponent} from './components/financials/debits-and-adjustments/debits-and-adjustments.component';
import {EmployerLookupComponent} from './components/common/employer-lookup/employer-lookup.component';
import {StatementComponent} from "./components/financials/statements/statement/statement.component";
import {SystemStatementsComponent} from './components/financials/statements/system-statements/system-statements.component';
import {SystemStatementDetailComponent} from './components/financials/statements/system-statement-detail/system-statement-detail.component';
import {PendingTransactionErrorsComponent} from './components/dashboard/pending-transaction-errors/pending-transaction-errors.component';
import {InvoiceClosedErrorsComponent} from './components/dashboard/invoice-closed-errors/invoice-closed-errors.component';
import {InvoicesWithRequirementsComponent} from './components/dashboard/invoices-with-requirements/invoices-with-requirements.component';
import {PendingInvoicesComponent} from './components/dashboard/pending-invoices/pending-invoices.component';
import {PendingStatementsComponent} from './components/dashboard/pending-statements/pending-statements.component';
import {InvoicesPastDueComponent} from './components/dashboard/invoices-past-due/invoices-past-due.component';
import {CollectionCallLogComponent} from './components/dashboard/collection-call-log/collection-call-log.component';
import {CollectionCallComponent} from './components/dashboard/collection-call-log/collection-call/collection-call.component';
import {FailedEmailsComponent} from './components/dashboard/failed-emails/failed-emails.component';
import {documentHttpClientProvider} from "./common/document-http-client";
import {MatButtonModule} from "@angular/material/button";
import {SearchFacilitiesComponent} from "./components/common/search-facilities/search-facilities.component";
import {SearchBranchesComponent} from "./components/common/search-branches/search-branches.component";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {PayorContactEntryComponent} from "./components/admin/payor-entry/payor-entry-contacts/payor-contact-entry/payor-contact-entry.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {PayorEntryChargeCodeDisplayComponent} from './components/admin/payor-entry/payor-entry-charge-code-display/payor-entry-charge-code-display.component';
import {PayorEntryVendorIdsComponent} from './components/admin/payor-entry/payor-entry-vendor-ids/payor-entry-vendor-ids.component';
import {PayorEntryCustomPricingComponent} from './components/admin/payor-entry/payor-entry-custom-pricing/payor-entry-custom-pricing.component';
import {BranchListComponent} from './components/admin/branch-list/branch-list.component';
import {BranchEntryComponent} from './components/admin/branch-entry/branch-entry.component';
import {RegionEntryComponent} from './components/admin/region-entry/region-entry.component';
import {RegionEntryGeneralComponent} from './components/admin/region-entry/region-entry-general/region-entry-general.component';
import {RegionEntryPayorsComponent} from './components/admin/region-entry/region-entry-payors/region-entry-payors.component';
import {EmployerPayorEntryComponent} from './components/admin/employer-payor-entry/employer-payor-entry.component';
import {RegionPayorEntryComponent} from './components/admin/region-payor-entry/region-payor-entry.component';
import {EmployerPayorEntryGeneralComponent} from './components/admin/employer-payor-entry/employer-payor-entry-general/employer-payor-entry-general.component';
import {EmployerPayorEntryResponsibilitiesComponent} from './components/admin/employer-payor-entry/employer-payor-entry-responsibilities/employer-payor-entry-responsibilities.component';
import {VisitReasonLookupComponent} from './components/common/visit-reason-lookup/visit-reason-lookup.component';
import {RegionPayorEntryResponsibilitiesComponent} from './components/admin/region-payor-entry/region-payor-entry-responsibilities/region-payor-entry-responsibilities.component';
import {RegionPayorEntryGeneralComponent} from './components/admin/region-payor-entry/region-payor-entry-general/region-payor-entry-general.component';
import {InvoiceDetailClaimComponent} from './components/financials/invoice-detail/invoice-detail-claim/invoice-detail-claim.component';
import {InvoiceDetailClaimDirective} from './directives/invoice-detail-claim.directive';
import { ClinicalDocConfigComponent } from './components/admin/clinical-doc-config/clinical-doc-config.component';
import { UploadDocumentComponent } from './components/admin/clinical-doc-config/upload-document/upload-document.component';
import { ClinicalDocumentLookupComponent } from './components/admin/clinical-doc-config/clinical-document-lookup/clinical-document-lookup.component';
import {taskHttpClientProvider} from "./common/task-http-client";
import { CreditMemosComponent } from './components/dashboard/credit-memos/credit-memos.component';
import { RevertCheckoutComponent } from './components/admin/revert-checkout/revert-checkout.component';
import { ClaimAuditComponent } from './components/financials/invoice-detail/invoice-detail-claim/claim-audit/claim-audit.component';
import {reviewerApi9HttpClientProvider} from "./common/reviewer-api9-http-client";
import {reviewerApi8HttpClientProvider} from "./common/reviewer-api8-http-client";
import { BranchEntryGeneralComponent } from './components/admin/branch-entry/branch-entry-general/branch-entry-general.component';
import { BranchPayorEntryComponent } from './components/admin/branch-payor-entry/branch-payor-entry.component';
import { FacilityPayorEntryComponent } from './components/admin/facility-payor-entry/facility-payor-entry.component';
import { BranchPayorEntryGeneralComponent } from './components/admin/branch-payor-entry/branch-payor-entry-general/branch-payor-entry-general.component';
import { BranchPayorEntryResponsibilitiesComponent } from './components/admin/branch-payor-entry/branch-payor-entry-responsibilities/branch-payor-entry-responsibilities.component';
import { FacilityPayorEntryGeneralComponent } from './components/admin/facility-payor-entry/facility-payor-entry-general/facility-payor-entry-general.component';
import { FacilityPayorEntryResponsibilitiesComponent } from './components/admin/facility-payor-entry/facility-payor-entry-responsibilities/facility-payor-entry-responsibilities.component';
import {BranchEntryPayorsComponent} from "./components/admin/branch-entry/branch-entry-payors/branch-entry-payors.component";
import { FacilityEntryComponent } from './components/admin/facility-entry/facility-entry.component';
import { InvoiceEmailDeliverLogComponent } from './components/financials/invoice-detail/invoice-detail-history/invoice-email-deliver-log/invoice-email-deliver-log.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HeaderComponent,
    ValidationResultsComponent,
    DashboardComponent,
    InvoicesComponent,
    InvoiceDetailComponent,
    ReallyClickModalComponent,
    ReallyClickDirective,
    DisplayableMoneyComponent,
    PostalCodePipePipe,
    DatePipePipe,
    PhonePipePipe,
    InvoiceDetailSummaryComponent,
    InvoiceDetailDebitsComponent,
    InvoiceDetailDebitsDirective,
    InvoiceDetailLedgerComponent,
    InvoiceDetailLedgerDirective,
    InvoiceDetailDebitDetailsComponent,
    InvoiceDetailDebitDetailsDirective,
    InvoiceDetailNotesDirective,
    InvoiceDetailNotesComponent,
    InvoiceDetailHistoryDirective,
    InvoiceDetailHistoryComponent,
    AdjustmentBatchesComponent,
    AdjustmentBatchComponent,
    LinearStepperComponent,
    ControlTotalsComponent,
    SelectInvoicesComponent,
    ApplyAdjustmentsComponent,
    ReviewFinalizeComponent,
    BillingEntitiesComponent,
    BillingEntityEntryComponent,
    EmployerListComponent,
    EmployerEntryComponent,
    ChargeCodePriceListComponent,
    PayorListComponent,
    ChargeCodeListComponent,
    ChargeCodeEntryComponent,
    PayorEntryComponent,
    ChargeCodePriceViewComponent,
    ChargeCodeEntryGeneralComponent,
    ChargeCodeEntryPricingComponent,
    EmployerEntryGeneralComponent,
    EmployerEntryRegionsComponent,
    PayorEntryGeneralComponent,
    PayorEntrySubmitConfigComponent,
    MessageModalComponent,
    ApplyInvoicesAdjustmentsComponent,
    AdjustmentCreatorComponent,
    AdjustmentsComponent,
    BillingEntityEntryGeneralComponent,
    BillingEntityEntryFacilitiesComponent,
    BillingEntityEntryBranchesComponent,
    PayorEntryInvoiceConfigComponent,
    PayorEntryStatementConfigComponent,
    SearchFacilitiesComponent,
    EditButtonComponent,
    SearchBranchesComponent,
    BillingDocumentCompositionComponent,
    ImageDisplayPipe,
    PendingDebitEditComponent,
    PendingDebitsAndAdjustmentsComponent,
    PriceSearchHistoryPopupComponent,
    DebitEntryComponent,
    PatientLookupComponent,
    ChargeCodeLookupComponent,
    PayorLookupComponent,
    EmployerDebitEntryComponent,
    PayorDebitEntryComponent,
    GroupingKeyDistributeComponent,
    DebitsAndAdjustmentsComponent,
    StatementComponent,
    EmployerLookupComponent,
    SystemStatementsComponent,
    SystemStatementDetailComponent,
    PendingTransactionErrorsComponent,
    InvoiceClosedErrorsComponent,
    InvoicesWithRequirementsComponent,
    PendingInvoicesComponent,
    PendingStatementsComponent,
    InvoicesPastDueComponent,
    CollectionCallLogComponent,
    CollectionCallComponent,
    FailedEmailsComponent,
    EmployerEntryPayorsComponent,
    EmployerEntryResultsComponent,
    PayorEntryContactsComponent,
    PayorEntryCallLogComponent,
    PayorContactEntryComponent,
    PayorEntryChargeCodeDisplayComponent,
    PayorEntryVendorIdsComponent,
    PayorEntryCustomPricingComponent,
    BranchListComponent,
    BranchEntryComponent,
    RegionEntryComponent,
    RegionEntryGeneralComponent,
    RegionEntryPayorsComponent,
    EmployerPayorEntryComponent,
    RegionPayorEntryComponent,
    EmployerPayorEntryGeneralComponent,
    EmployerPayorEntryResponsibilitiesComponent,
    VisitReasonLookupComponent,
    RegionPayorEntryResponsibilitiesComponent,
    RegionPayorEntryGeneralComponent,
    InvoiceDetailClaimComponent,
    InvoiceDetailClaimDirective,
    ClinicalDocConfigComponent,
    UploadDocumentComponent,
    ClinicalDocumentLookupComponent,
    CreditMemosComponent,
    RevertCheckoutComponent,
    ClaimAuditComponent,
    BranchEntryGeneralComponent,
    BranchPayorEntryComponent,
    FacilityPayorEntryComponent,
    BranchPayorEntryGeneralComponent,
    BranchPayorEntryResponsibilitiesComponent,
    FacilityPayorEntryGeneralComponent,
    FacilityPayorEntryResponsibilitiesComponent,
    BranchEntryPayorsComponent,
    FacilityEntryComponent,
    InvoiceEmailDeliverLogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    DataTablesModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatNativeDateModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    ScrollingModule,
    AgGridModule,
    SatDatepickerModule,
    SatNativeDateModule,
    DragulaModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    DragDropModule,
  ],
  providers: [
    jwtProvider,
    // Providing the BillingHttpClient so it could be used as a service
    billingHttpClientProvider,
    clinicHttpClientProvider,
    documentHttpClientProvider,
    taskHttpClientProvider,
    reviewerApi8HttpClientProvider,
    reviewerApi9HttpClientProvider,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    CanDeactivateGuard,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
    ScrollDispatcher,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
