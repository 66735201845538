<div class="container-fluid">
  <h1>Clinical Document Configuration</h1>
  <hr>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field>
        <mat-label>Test Type</mat-label>
        <mat-select #testType [(ngModel)]="testTypeId" (selectionChange)="getEmployers()">
          <mat-option [value]="null">
            [Clear Selection]
          </mat-option>
          <mat-option *ngFor="let test of testTypes" [value]="test.id">
            {{test.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field>
        <mat-label>Employer</mat-label>
        <mat-select #employer [disabled]="!testType.value" [(ngModel)]="employerId" (selectionChange)="getJobNumbers()">
          <mat-option [value]="null">
            [Clear Selection]
          </mat-option>
          <mat-option *ngFor="let e of employers" [value]="e.id">
            {{e.companyName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field>
        <mat-label>Job Number</mat-label>
        <mat-select [disabled]="!employer.value" [(ngModel)]="jobNumberId" (selectionChange)="updateDocumentData()">
          <mat-option [value]="null">
            [Clear Selection]
          </mat-option>
          <mat-option *ngFor="let jobNum of jobNumbers" [value]="jobNum.id">
            {{jobNum.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group my-2">
    <button class="btn btn-primary mr-1" (click)="uploadDocument()"><i class="fas fa-plus"></i> Upload Document</button>
    <button class="btn btn-primary" (click)="lookupDocument()"><i class="fas fa-link"></i> Link Existing Document</button>
  </div>
  <div class="my-1">
    <h4>
      Current Level Documents
    </h4>
    <table class="table table-bordered table-striped table-hover table-condensed">
      <thead>
      <tr>
        <th class="button-col"></th>
        <th>Document Name</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let doc of curLevelDocs">
        <td>
          <button type="button" class="btn btn-sm btn-success" (click)="viewClinicalDocument(doc)">View</button>
          <button type="button" class="btn btn-sm btn-danger ml-2"
                  reallyClick="Are you sure you want to delete this current level document?"
                  reallyClickAction="deleteCurLevelDoc"
                  [reallyClickCaller]="this"
                  [reallyClickArguments]="[doc]">
            Delete
          </button>
        </td>
        <td>{{doc.documentName}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row my-1">
    <div class="col-md-6">
      <h4>Inherited Documents</h4>
      <table class="table table-bordered table-striped table-hover table-condensed">
        <thead>
        <tr>
          <th class="button-col"></th>
          <th>Document Name</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let doc of inheritedDocs">
          <td>
            <button type="button" class="btn btn-sm btn-success" (click)="viewClinicalDocument(doc)">View</button>
            <button type="button" class="btn btn-sm btn-warning ml-2" (click)="hideInheritedDoc(doc)">
              Hide
            </button>
          </td>
          <td>{{doc.documentName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <h4>Hidden Documents</h4>
      <table class="table table-bordered table-striped table-hover table-condensed">
        <thead>
        <tr>
          <th class="button-col"></th>
          <th>Document Name</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let doc of hiddenDocs">
          <td>
            <button type="button" class="btn btn-sm btn-success" (click)="viewClinicalDocument(doc)">View</button>
            <button type="button" class="btn btn-sm btn-primary ml-2" (click)="unhideHiddenDoc(doc)">
              Unhide
            </button>
          </td>
          <td>{{doc.documentName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

