<table class="table table-responsive table-condensed table-hover">
  <tbody>
  <tr *ngFor="let adjustment of model">
    <td>
      <button *ngIf="!disabled" class="btn btn-danger btn-sm mr-2" (click)="removeAdjustment(adjustment)"><i
        class="fa fa-close"></i></button>
      {{adjustment.financialTransactionType}}
    </td>
    <td style="vertical-align: middle">
      <displayable-money [model]="-adjustment.amount"></displayable-money>
    </td>
  </tr>
  </tbody>
</table>
