import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent implements OnInit, ICellRendererAngularComp {

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
  }

  refresh(params: any): boolean {
    return false;
  }

}
