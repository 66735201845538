import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ClinicalDocumentConfigurationDto} from '../../../../models/clinical-document-configuration/ClinicalDocumentConfigurationDto.model';
import {ClinicalDocConfigService} from '../clinical-doc-config.service/clinical-doc-config.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {

  fileName = '';
  fileToUpload: File = null;

  constructor(public dialogRef: MatDialogRef<UploadDocumentComponent>,
              private clinicalDocConfigService: ClinicalDocConfigService) { }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('fileKey', this.fileToUpload, this.fileToUpload.name);
    this.clinicalDocConfigService.uploadClinicalDocument(formData);
    this.dialogRef.close();
  }
}
