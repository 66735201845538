<div id="priceHistoryPopup" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Payor & Price Search History</h3>
      </div>
      <div class="modal-body">
        <div *ngIf="history?.employerExceptions?.length == 0">No Employer Exceptions for the charge code and the
          employer
        </div>
        <div *ngIf="history?.employerExceptions?.length > 0">
          <table class="table table-bordered table-condensed table-striped table-hover">
            <caption>Below charge code prices have been filtered out because of the Employer Exceptions</caption>
            <thead>
            <tr>
              <th>Charge Code</th>
              <th>Description</th>
              <th>Price</th>
              <th>Start Date Inclusive</th>
              <th>End Date Inclusive</th>
              <th>Payor</th>
              <th>Employer</th>
              <th>Region</th>
              <th>Branch</th>
              <th>BillingEntity</th>
            </tr>
            </thead>
            <tr *ngFor="let ee of history?.employerExceptions">
              <td>
                <span>{{ee.chargeCode}}</span>
              </td>
              <td>
                <span>{{ee.description}}</span>
              </td>
              <td>
                <div class="text-center">
                  <span class="pull-right">{{ee.price}}</span>
                </div>
              </td>
              <td>
                <span>{{ee.startDateInclusive}}</span>
              </td>
              <td>
                <span>{{ee.endDateInclusive}}</span>
              </td>
              <td>
                <span>{{ee.payor}}</span>
              </td>
              <td>
                <span>{{ee.employer}}</span>
              </td>
              <td>
                <span>{{ee.region}}</span>
              </td>
              <td>
                <span>{{ee.branch}}</span>
              </td>
              <td>
                <span>{{ee.billingEntity}}</span>
              </td>
            </tr>
          </table>
        </div>
        <div *ngFor="let history of history?.histories">
          <span>{{history}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
