import {InvoiceDetailClaimServiceDto} from "./invoice-detail-claim-service-dto";

export class InvoiceDetailClaimDto {
  invoiceNumberDisplay: string;
  insuranceAdjusterName: string;
  insuranceAdjusterAddress1: string;
  insuranceAdjusterAddress2: string;
  payorCity: string;
  payorState: string;
  payorPostalCode: string;
  insuredIdNumber: string;
  patientName: string;
  patientBirthday: Date;
  patientGender: string;
  insuredName: string;
  patientAddress: string;
  patientCity: string;
  patientState: string;
  patientZip: string;
  patientTel: string;
  insuredAddress: string;
  insuredCity: string;
  insuredState: string;
  insuredZip: string;
  insuredPolicyNumber: string;
  insuredDateOfBirth: Date;
  insuredGender: string;
  dateOfCurrentIllness: Date;
  qualOfCurrent: string;
  nameOfReferringProvider: string;
  item17A: string;
  item17BNpi: string;
  diagnosisOrNatureOfIllnessOrInjuryA: string;
  diagnosisOrNatureOfIllnessOrInjuryB: string;
  diagnosisOrNatureOfIllnessOrInjuryC: string;
  diagnosisOrNatureOfIllnessOrInjuryD: string;
  resubmissionCode: string;
  serviceDtos: InvoiceDetailClaimServiceDto[] = [];
  federalTaxIdNumber: string;
  patientAccountNo: string;
  totalCharge: string;
  serviceFacilityLocationInfoA: string;
  serviceFacilityLocationInfoB: string;
  serviceFacilityLocationInfoC: string;
  facilityAddress1: string;
  facilityAddress2: string;
  facilityCity: string;
  facilityState: string;
  facilityPostalCode: string;
  billingProviderTel: string;
  billingProviderName: string;
  billingProviderAddress: string;
  billingProviderCity: string;
  billingProviderState: string;
  billingProviderZip: string;
  billingProviderNpi: string;
}
