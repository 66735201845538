<h1>Adjustment Batch{{adjustmentBatchId > 0 ? ": " + adjustmentBatchId : ""}}</h1>
<div class="row">
  <linear-stepper style="width: 100%" [model]="linearStepperConfig"
                  (stepChange)="navigateToCurrentStep()">
  </linear-stepper>
  <br/>
  <div class="m-4 w-100">
    <router-outlet name="linearStep"></router-outlet>
  </div>
</div>
<div class="modal fade" id="navigate-back-to-control-totals" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Control Totals Redirect</h3>
        <p>{{invalidControlTotalMessage}} You are being navigated back to the control totals.</p>
      </div>
      <div class="modal-footer">
        <button id="navigateBackToControlTotalsDismiss" type="button" class="btn btn-default" data-dismiss="modal"
                (click)="navigateBackToControlTotals()">Ok
        </button>
      </div>
    </div>
  </div>
</div>
