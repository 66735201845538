import {ClinicHttpClient} from "../common/clinic-http-client";
import {ChargeCodePriceRow} from "../models/charge-code-price-list/charge-code-price-row";
import {HttpParams} from "@angular/common/http";
import {BranchDto} from "../models/common/branch-dto";

export class ChargeCodePriceUtility {
  public static getChargeCodePriceDetails(clinicHttpClient: ClinicHttpClient,
                                          chargeCodePrices: ChargeCodePriceRow[]) {
    if (chargeCodePrices && chargeCodePrices.length > 0) {
      const branchIds = chargeCodePrices.filter(r => r.branchId != null)
        .map(r => r.branchId.toString())
        .join(',');
      if (branchIds == "") return;
      let branches: BranchDto[] = [];
      const branchParams = new HttpParams().set("ids", branchIds);
      clinicHttpClient.Get<BranchDto[]>('/debit-entry-branches', {params: branchParams})
        .subscribe(response => {
          branches = response;
          chargeCodePrices.forEach(ccp => {
            if (ccp.branchId != null) {
              ccp.branch = branches.find(b => b.id == ccp.branchId)?.companyName;
            }
          });
        });
    }
  }
}
