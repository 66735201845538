import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChargeCodeEntryDto} from "../../../../models/charge-code-entry/charge-code-entry-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {CommonResult} from "../../../../models/common/common-result";

@Component({
  selector: 'app-charge-code-entry-general',
  templateUrl: './charge-code-entry-general.component.html',
  styleUrls: ['./charge-code-entry-general.component.scss']
})
export class ChargeCodeEntryGeneralComponent implements OnInit, OnDestroy {

  private id: number;
  private ngUnsubscribe = new Subject();
  @Input() model: ChargeCodeEntryDto;

  constructor(
    private billingHttpClient: BillingHttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private validationResultsService: ValidationResultsService
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
        if (this.id) {
          const params = new HttpParams().set('id', this.id.toString());
          this.billingHttpClient.Get<ChargeCodeEntryDto>('/charge-code', {params})
            .subscribe(
              response => {
                this.model = response;
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  cancel() {
    window.history.back();
  }

  delete() {
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Delete<CommonResult>('/charge-code', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(["Delete succeeded"]);
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      })
  }

  saveClose() {
    this.billingHttpClient.Post<CommonResult>('/charge-code', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(["Save succeeded"]);
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      })
  }
}
