export class PatientLookupSearchRow {
  patientId: number;
  authorizationId: number;
  authorizationPatientId: number;
  firstName: string;
  lastName: string;
  identifierValue: string;
  dateOfBirth: Date;
  contactPhone: string;
  companyName: string;
}
