import {Directive, HostListener, Input} from "@angular/core";
import {CustomReallyClickButton, ReallyClickService} from "../services/really-click.service";

@Directive({
  selector: '[reallyClick]'
})
export class ReallyClickDirective {

  constructor(private reallyClickService: ReallyClickService) {
  }

  @Input() reallyClickAction: string;
  @Input() reallyClickCaller: any;
  @Input('reallyClick') message: string;
  @Input() reallyClickArguments: any[] = [];
  @Input() reallyClickButtons: CustomReallyClickButton[] = [];

  @HostListener('click') onClick() {
    this.reallyClickService.show(this.message, this.reallyClickAction, this.reallyClickCaller, this.reallyClickArguments, this.reallyClickButtons);
  }

}
