import {Component, Input, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {InvoiceDetailSummaryDto} from "../../../../models/invoice-detail/summary/invoice-detail-summary-dto";
import {CommonResult} from "../../../../models/common/common-result";
import {ValidationResultsService} from "../../../../services/validation-results.service";

@Component({
  selector: 'invoice-detail-summary',
  templateUrl: './invoice-detail-summary.component.html',
  styleUrls: ['./invoice-detail-summary.component.scss']
})
export class InvoiceDetailSummaryComponent implements OnInit {


  @Input() invoiceId: number;
  private _showHistory: boolean = false;
  @Input() set showHistory(value: boolean) {
    if (this._showHistory !== value) {
      this._showHistory = value;
      this.load();
    }
  }

  model: InvoiceDetailSummaryDto = new InvoiceDetailSummaryDto();

  constructor(private billingHttpClient: BillingHttpClient,
              private validationResultsService: ValidationResultsService,
              private router: Router) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const params = new HttpParams().set('invoiceId', this.invoiceId.toString()).set('showHistory', String(this._showHistory));
    this.billingHttpClient.Get<InvoiceDetailSummaryDto>("/invoice-detail-summary", {params}).subscribe(
      response => {
        this.model = response;
      }
    );
  }

  navigateToInvoice(invoiceId: number) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['invoice', invoiceId]));
  }

  updateClaimNumber() {
    this.billingHttpClient.Post<CommonResult>(`/invoice/${this.model.invoiceId}/claim-number`, {'claimNumber' : this.model.claimNumber}).subscribe(
      response => {
        if (response.result === 'success') {
          this.validationResultsService.setSuccessMessages(['Update claim number success']);
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      }
    )
  }
}
