<div class="mt-3">
  <form #formGeneral="ngForm">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <mat-form-field class="w-100">
            <mat-label>Start Date<span class="text-danger">*</span></mat-label>
            <input matInput name="generalStartDate" [matDatepicker]="picker1" [(ngModel)]="model.startDateInclusive">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <mat-form-field class="w-100">
            <mat-label>End Date<span class="text-danger">*</span></mat-label>
            <input matInput name="generalEndDate" [matDatepicker]="picker2" [(ngModel)]="model.endDateInclusive">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
