import {Component, Input, OnInit} from '@angular/core';
import {PayorEntryVendorIdDto} from "../../../../models/payor-entry/payor-entry-vendor-id-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";

@Component({
  selector: 'app-payor-entry-vendor-ids',
  templateUrl: './payor-entry-vendor-ids.component.html',
  styleUrls: ['./payor-entry-vendor-ids.component.scss']
})
export class PayorEntryVendorIdsComponent implements OnInit {

  constructor(
    private billingHttpClient: BillingHttpClient,
    private validationResultsService: ValidationResultsService,
  ) {
  }

  @Input() payorId: number;
  model: PayorEntryVendorIdDto[] = [];

  ngOnInit() {
    const params = new HttpParams().set('payorId', this.payorId.toString());
    this.billingHttpClient.Get<PayorEntryVendorIdDto[]>('/payor-vendor-ids', {params})
      .subscribe(response => {
          this.model = response;
        }
      );
  }

  save() {
    this.billingHttpClient.Post<CommonResult>('/payor-vendor-ids', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save success']);
        } else {
          alert('Save failed');
        }
      })
  }
}
