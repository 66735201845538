<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <mat-form-field class="w-100">
        <mat-label>Batch Date<span class="text-danger">*</span></mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="batchDate" (dateChange)="onBatchDateChange($event)"
               [readonly]="!editable">
        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!editable"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <label for="batchTotal">Batch Total<span class="text-danger">*</span></label>
      <input id="batchTotal" type="number" class="form-control control-total-input" name="batchTotal"
             [(ngModel)]="controlTotalsDto.total" [attr.disabled]="!editable ? 'disabled' : null"
             (ngModelChange)="setSubTotalsValidationMessage()">
    </div>
    <div class="form-group">
      <label class="control-label">Payor</label>
      <select class="dropdown form-control" name="payor" [(ngModel)]="controlTotalsDto.selectedPayor.payorId"
              (ngModelChange)="onPayorChange()" [attr.disabled]="!editable ? 'disabled' : null">
        <option [ngValue]="null"></option>
        <option *ngFor="let option of payorOptions" [ngValue]="option.payorId">{{option.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="checkNumber">Check Number</label>
      <input id="checkNumber" type="text" class="form-control control-total-input" name="checkNumber"
             [(ngModel)]="controlTotalsDto.checkNumber" [attr.disabled]="!editable ? 'disabled' : null">
    </div>
  </div>
  <div class="col-md-9 subtotals-container">
    <h4>Subtotals <span class="text-danger">{{subTotalsValidationMessage}}</span>
      <displayable-money *ngIf="hasDifference()" [model]="subTotalDifference"></displayable-money>
    </h4>
    <div class="row">
      <div class="col-md-12">
        <hr class="divider"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row"
             *ngFor="let subtotal of controlTotalsDto.subtotals.slice(0, (controlTotalsDto.subtotals.length + 1) / 2); index as i">
          <div class="col-md-6">
            <label for="first-subtotal-{{i}}" class="float-right">{{subtotal.transactionType}}</label>
          </div>
          <div class="col-md-6">
            <input id="first-subtotal-{{i}}" type="number" class="form-control control-total-input"
                   [(ngModel)]="subtotal.amount"
                   [attr.disabled]="!editable ? 'disabled' : null" (ngModelChange)="setSubTotalsValidationMessage()">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row"
             *ngFor="let subtotal of controlTotalsDto.subtotals | slice: ((controlTotalsDto.subtotals.length + 1) / 2); index as i">
          <div class="col-md-6">
            <label for="second-subtotal-{{i}}" class="float-right">{{subtotal.transactionType}}</label>
          </div>
          <div class="col-md-6">
            <input id="second-subtotal-{{i}}" type="number" class="form-control control-total-input"
                   [(ngModel)]="subtotal.amount"
                   [attr.disabled]="!editable ? 'disabled' : null" (ngModelChange)="setSubTotalsValidationMessage()">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="changePayorModal" tabindex="-1" role="dialog">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Payor Change Notificaiton</h3>
        <p>You are changing the payor on this adjustment batch. This may cause some invoices to be unavailable. Do you
          want to change the selected payor?</p>
      </div>
      <div class="modal-footer">
        <button id="changePayorModalConfirm" type="button" class="btn btn-warning" data-dismiss="modal"
                (click)="changePayor()">Yes
        </button>
        <button id="changePayorModalDismiss" type="button" class="btn btn-default" data-dismiss="modal"
                (click)="revertPayor()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>
