<div class="row">
  <div class="col-md-12">
    <h1>Generate System Statements</h1>
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <mat-form-field class="w-100">
        <mat-label>Date<span class="text-danger">*</span></mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-8"></div>
  <div class="col-md-4 text-right">
    <button class="btn btn-success"
       reallyClick="Are you sure you want to generate statements? This will distribute statements to all payors that have a balance."
       reallyClickAction="generateStatements"
       [reallyClickCaller]="this"
       [reallyClickArguments]=""
       [attr.disabled]="!canSave ? 'disabled' : null">
      <i class="fa fa-check-square-o"></i>
      Generate Statements
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
    <h2>System Statements History</h2>
  </div>
</div>
<table id="tb-system-statements" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Date</th>
    <th>Statements Queued</th>
    <th>0 Day Balance</th>
    <th>30 Day Balance</th>
    <th>60 Day Balance</th>
    <th>90 Day Balance</th>
    <th>Total Balance</th>
    <th>Late Fees</th>
    <th>Time</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="viewDetails(row)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.date | date:"MM/dd/yyyy"}}</td>
    <td>{{row.statementsQueued}}</td>
    <td>{{row.zeroDayBalanceSum}}</td>
    <td>{{row.thirtyDayBalanceSum}}</td>
    <td>{{row.sixtyDayBalanceSum}}</td>
    <td>{{row.ninetyDayBalanceSum}}</td>
    <td>{{row.totalBalanceSum}}</td>
    <td>{{row.time | date: "M/d/yyyy h:mm a"}}</td>
  </tr>
  </tbody>
</table>
