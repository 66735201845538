import {Component, OnInit} from '@angular/core';
import {PendingDebitsAndAdjustmentsSearchRow} from "../../../models/pending-debits-and-adjustments/pending-debits-and-adjustments-search-row";
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {PriceSearchHistoryDto} from "../../../models/pending-debits-and-adjustments/price-search-hisotry-popup/price-search-history-dto";
import {TaskHttpClient} from "../../../common/task-http-client";

@Component({
  selector: 'app-pending-transaction-errors',
  templateUrl: './pending-transaction-errors.component.html',
  styleUrls: ['./pending-transaction-errors.component.scss']
})
export class PendingTransactionErrorsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: PendingDebitsAndAdjustmentsSearchRow[] = [];
  priceHistory: PriceSearchHistoryDto;

  constructor(private billingHttpClient: BillingHttpClient,
              private taskHttpClient: TaskHttpClient,
              private  router: Router) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      orderCellsTop: true,
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          width: '8%',
        },
      ],
      columns: [
        {data: 'financialTransactionPendingImportId'},
        {data: 'patient'},
        {data: 'employer'},
        {data: 'region'},
        {data: 'jobSite'},
        {data: 'jobNumber'},
        {data: 'facility'},
        {data: 'branch'},
        {data: 'provider'},
        {data: 'serviceDate'},
        {data: 'type'},
        {data: 'chargeCode'},
        {data: 'quantity'},
        {data: 'purchaseOrder'},
        {data: 'status'},
      ],
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.financialTransactionPendingImportStatus = 'Error';
        that.billingHttpClient.Post<DataTablesResponse>("/pending-debits-and-adjustments", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  viewDebit = (debit: PendingDebitsAndAdjustmentsSearchRow) => {
    this.router.navigate(['pending-debit-edit', debit.financialTransactionPendingImportId]);
  }

  viewHistory(debit: PendingDebitsAndAdjustmentsSearchRow) {
    this.taskHttpClient.Get<PriceSearchHistoryDto>(`/price-search-history/${debit.financialTransactionPendingImportId}`)
      .subscribe(response => {
        this.priceHistory = response;
        $('#priceHistoryPopup').modal();
      });
  }
}
