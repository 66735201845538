<div class="modal fade" id="payorLookupModal" tabindex="-1" role="dialog" aria-labelledby="payorLookupModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="payorLookupModalLabel">Search Payors</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table id="tb-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th></th>
            <th>Payor Type</th>
            <th>Name</th>
            <th>Is Global</th>
            <th>Is Active</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td>
              <div class="button-group" role="group">
                <button class="btn btn-success mr-1" (click)="selectPayor(row)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
            <td>{{row.payorType}}</td>
            <td>{{row.name}}</td>
            <td>{{row.global}}</td>
            <td>{{row.active}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

