import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-billing-entities',
  templateUrl: './billing-entities.component.html',
  styleUrls: ['./billing-entities.component.scss']
})
export class BillingEntitiesComponent implements OnInit {

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};

  constructor(private billingHttpClient: BillingHttpClient,
    private  router: Router) {
  }

  ngOnInit(): void {

    this.dtOptions = {
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false
        }
      ],
      columns: [
        {
          data: 'entityId',
          width: '1%',
          render: function (data, type, row, meta) {
            return '<button type="button" data-id="' + data + '" class="btn btn-success btn-edit">' +
              '<i class="fas fa-edit"></i>' +
              '</button>';
          }
        },
        {
          title: 'Name',
          data: 'entityName'
        }
      ],
      language: {
        emptyTable: 'Loading'
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-2 'B><'col-sm-3'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        {
          text: '<i class="fas fa-plus"></i><span style="margin-left: 5px;">Create</span>',
          className: "btn btn-success float-right",
          action: function (e, dt, node, config) {
            router.navigate(['billing-entity-entry']);
          }
        }
      ]
    };

    this.billingHttpClient.Get("/billing-entities").subscribe(
      val => {
        $('table').DataTable().rows.add(val as []).draw();
      }
    );

    const router = this.router;
    $('#tb-billing-entities').on('click', '.btn-edit', function () {
      router.navigate(['/billing-entity-entry', $(this).data('id')]);
    });
  } // ngOnInit
}
