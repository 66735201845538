<h1>Adjustment Batches</h1>
<table id="tb-adjustment-batches" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <td></td>
    <td>#</td>
    <td>Start Date</td>
    <td>Posted Date</td>
    <td>Payor</td>
    <td>Check Number</td>
    <td>Total</td>
    <td>Status</td>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <div class="button-group" role="group">
        <button class="btn btn-success mr-1" (click)="viewAdjustmentBatch(row)">
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn btn-success mr-1"
                reallyClick="Are you sure you want to copy this adjustment batch? This will make a new adjustment batch."
                reallyClickAction="copyAdjustmentBatch"
                [reallyClickCaller]="this"
                [reallyClickArguments]="[row]">
          <i class="fas fa-clipboard"></i><span style="margin-left: 5px">Copy</span>
        </button>
        <button class="btn btn-success mr-1"
                reallyClick="Are you sure you want to reverse this adjustment batch? This will make a new adjustment batch."
                reallyClickAction="reverseAdjustmentBatch"
                [reallyClickCaller]="this"
                [reallyClickArguments]="[row]">
          <i class="fas fa-fast-backward"></i><span style="margin-left: 5px">Reverse</span>
        </button>
      </div>
    </td>
    <td>{{row.adjustmentBatchId}}</td>
    <td>{{row.startDate | date: "MM/dd/yyyy"}}</td>
    <td>{{row.postedDate | date: "MM/dd/yyyy"}}</td>
    <td>{{row.payor}}</td>
    <td>{{row.checkNumber}}</td>
    <td>
      <displayable-money [model]="row.total"></displayable-money>
    </td>
    <td>{{row.status}}</td>
  </tr>
  </tbody>
</table>
<div class="modal fade" id="lockmodal" tabindex="-1" role="dialog">
  <div class="modal-dialog  modal-m" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-body">
          <p>
            This adjustment batch has been locked by user {{lockModel.userName}}. You may still edit this adjustment batch,
            but one of your work might lost.
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" type="button" data-dismiss="modal" (click)="onEdit(lockModel.adjustmentBatchId)">Edit
            anyway</button>
          <button class="btn btn-warning" type="button" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
