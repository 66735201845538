<h1>{{pageTitle}}<span *ngIf="model.name"> - {{model.name}}</span></h1>
<div class="row">
  <div class="col-md-12">
    <ul id="child-tabs" class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" href="#general" aria-controls="general" role="tab" data-toggle="tab"
           aria-selected="true">General</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#submission" aria-controls="submission" role="tab" data-toggle="tab"
           aria-selected="false">Submission Configuration</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#invoice" aria-controls="invoice" role="tab" data-toggle="tab"
           aria-selected="false">Invoicing Configuration</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#statement" aria-controls="statement" role="tab" data-toggle="tab"
           aria-selected="false">Statement Configuration</a>
      </li>
      <li class="nav-item" *ngIf="model.id && model.id != 0">
        <a class="nav-link" href="#contacts" aria-controls="contacts" role="tab" data-toggle="tab"
           aria-selected="false">Contacts</a>
      </li>
      <li class="nav-item" *ngIf="model.id && model.id != 0">
        <a class="nav-link" href="#call-log" aria-controls="call-log" role="tab" data-toggle="tab"
           aria-selected="false">Call Log</a>
      </li>
      <li class="nav-item" *ngIf="model.id && model.id != 0">
        <a class="nav-link" href="#charge-code-display" aria-controls="charge-code-display" role="tab" data-toggle="tab"
           aria-selected="false">Charge Code Display</a>
      </li>
      <li class="nav-item" *ngIf="model.id && model.id != 0">
        <a class="nav-link" href="#vendor-ids" aria-controls="vendor-ids" role="tab" data-toggle="tab"
           aria-selected="false">Vendor IDs</a>
      </li>
      <li class="nav-item" *ngIf="model.id && model.id != 0">
        <a class="nav-link" href="#custom-pricing" aria-controls="custome-pricing" role="tab" data-toggle="tab"
           aria-selected="false">Custom Pricing</a>
      </li>
    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane fade show active" id="general" aria-labelledby="general-tab">
        <app-payor-entry-general [model]="model" (saved)="onSave()" (canceled)="onCancel()" (saveClosed)="onSaveClose()">
        </app-payor-entry-general>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="submission" aria-labelledby="submission-tab">
        <app-payor-entry-submit-config [model]="model" (saved)="onSave()" (canceled)="onCancel()"
                                       (saveClosed)="onSaveClose()">
        </app-payor-entry-submit-config>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="invoice" aria-labelledby="invoice-tab">
        <app-payor-entry-invoice-config [model]="model" (saved)="onSave()" (canceled)="onCancel()"
                                        (saveClosed)="onSaveClose()">
        </app-payor-entry-invoice-config>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="statement" aria-labelledby="statement-tab">
        <app-payor-entry-statement-config [model]="model" (saved)="onSave()" (canceled)="onCancel()"
                                          (saveClosed)="onSaveClose()">
        </app-payor-entry-statement-config>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="contacts" aria-labelledby="contacts-tab"
           *ngIf="model.id && model.id != 0">
        <app-payor-entry-contacts [payorId]="model.id"></app-payor-entry-contacts>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="call-log" aria-labelledby="call-log-tab"
           *ngIf="model.id && model.id != 0">
        <payor-entry-call-log [payorId]="model.id"></payor-entry-call-log>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="charge-code-display" aria-labelledby="charge-code-display-tab"
           *ngIf="model.id && model.id != 0">
        <payor-entry-charge-code-display [payorId]="model.id"></payor-entry-charge-code-display>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="vendor-ids" aria-labelledby="vendor-ids-tab"
           *ngIf="model.id && model.id != 0">
        <app-payor-entry-vendor-ids [payorId]="model.id"></app-payor-entry-vendor-ids>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="custom-pricing" aria-labelledby="custom-pricing-tab"
           *ngIf="model.id && model.id != 0">
        <app-payor-entry-custom-pricing [payorId]="model.id" [payorType]="model.payorType" [payorName]="model.name">
        </app-payor-entry-custom-pricing>
      </div>
    </div>
  </div>
</div>
