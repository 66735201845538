<h1>Edit Payor Contacts</h1>
<form #form="ngForm">
  <div class="row">
    <div class="col-md-4">

      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput required placeholder="Name" name="name" [(ngModel)]="model.name">
        </mat-form-field>
      </div>

      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input matInput required placeholder="Title" name="title" [(ngModel)]="model.title">
        </mat-form-field>
      </div>

      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput required placeholder="Email" name="email" [(ngModel)]="model.email">
        </mat-form-field>
      </div>

      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Phone</mat-label>
          <input matInput required placeholder="Phone" name="phone" [(ngModel)]="model.phone">
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">

      <div class="form-group mt-2">
        <mat-form-field>
          <mat-label>Start Date<span class="text-danger">*</span></mat-label>
          <input matInput [matDatepicker]="picker" name="startDate" [(ngModel)]="model.startDateInclusive">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-group mt-2">
        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="picker1" name="endDate" [(ngModel)]="model.endDateInclusive">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <label class="control-label edit-label-offset">Description</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control" name="description" [(ngModel)]="model.description" rows="5" maxlength="1000"
                    spellcheck="true"></textarea>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <label class="control-label edit-label-offset">Payor Notes</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control" name="notes" [(ngModel)]="model.notes" rows="5" maxlength="1000"
                    spellcheck="true"></textarea>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="right-align mt-3">
  <button class="btn btn-warning has-spinner" (click)="cancel()">
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
    <i class="fa fa-times"></i>
    Cancel
  </button>
  <button class="btn btn-success has-spinner ml-2" (click)="save()">
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
</div>
