import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageModalService {
  constructor() {
  }

  public message: string = "";
  public icon: string = "";
  public title: string = "";

  public showModal(message: string, icon?: string, title?: string) {
    this.message = message;
    this.icon = icon;
    this.title = title;
    $('#messageModal').modal('show');
  }

  public resetModal() {
    this.message = "";
    this.icon = "";
    this.title = "";
  }
}
