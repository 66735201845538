<div class="modal fade" id="chargeCodeLookupModal" tabindex="-1" role="dialog"
     aria-labelledby="chargeCodeLookupModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="chargeCodeLookupModalLabel">Search Charge Codes</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th *ngIf="enableMultipleSelect"></th>
            <th></th>
            <th>Code</th>
            <th>Description</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td *ngIf="enableMultipleSelect">
              <div class="form-check">
                <mat-checkbox (change)="addOrRemoveChargeCode($event.checked,row)" [checked]="checkChargeCode(row)">
                </mat-checkbox>
              </div>
            </td>
            <td>
              <div class="button-group" role="group">
                <button class="btn btn-success mr-1" (click)="selectChargeCode(row)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
            <td>{{row.code}}</td>
            <td>{{row.description}}</td>
            <td>{{row.startDate | date:"M/d/yyyy"}}</td>
            <td>{{row.endDate | date: "M/d/yyyy"}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button *ngIf="enableMultipleSelect" id="chargeCodeLookupModalSave" type="button" class="btn btn-default"
                data-dismiss="modal" (click)="saveChargeCode()">
          Save
        </button>
        <button id="chargeCodeLookupModalDismiss" type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

