import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {EmployerSearchRow} from "../../../models/employer-list/employer-search-row";
import {ClinicHttpClient} from "../../../common/clinic-http-client";

@Component({
  selector: 'app-employer-list',
  templateUrl: './employer-list.component.html',
  styleUrls: ['./employer-list.component.scss']
})
export class EmployerListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: EmployerSearchRow[] = [];

  constructor(private clinicHttpClient: ClinicHttpClient,
              private router: Router) {
  }

  ngOnInit(): void {

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'employerName'},
        {data: 'employerAddress'},
      ],
      ajax: (dataTableParams: any, callback) => {
        this.clinicHttpClient.Post<DataTablesResponse>('/employer-search-rows', dataTableParams)
          .subscribe(a_resp => {
            this.model = a_resp.data;
            callback({
              recordsTotal: a_resp.recordsTotal,
              recordsFiltered: a_resp.recordsFiltered,
              data: []
            });
          });
      },
    };

  } // ngOnInit

  printId(employerId: number) {
    console.log(employerId);
  }

  goToEmployer(employerId: number) {
    this.router.navigate(['employer-entry', employerId]);
  }

}
