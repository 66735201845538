<h1>Statement Postal/Fax Queue</h1>
<table id="tb-pending-statements" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Name</th>
    <th>Payor Type</th>
    <th>Invoice Count</th>
    <th>Type</th>
    <th>Info</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success" (click)="printGroup(row)">
        <i class="fas fa-print"></i>
      </button>
    </td>
    <td>{{row.name}}</td>
    <td>{{row.payorType}}</td>
    <td>{{row.statementCount}}</td>
    <td>{{row.type}}</td>
    <td>{{row.info}}</td>
  </tr>
  </tbody>
</table>

<div class="modal fade" id="printAllGroupsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Print All Groups</h3>
        <p>Are you sure you want to print all pending invoices in Postal/Fax Queue?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="printAllGroups()">Print</button>
        <button class="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
