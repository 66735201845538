import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdjustmentDto} from "../../../../../../models/adjustment-batch/apply-adjustments/apply-invoices-adjustments/adjustments/adjustment-dto";
import {DecimalUtility} from "../../../../../../utilities/decimal-utility";

@Component({
  selector: 'adjustment-creator',
  templateUrl: './adjustment-creator.component.html',
  styleUrls: ['./adjustment-creator.component.scss']
})
export class AdjustmentCreatorComponent implements OnInit {

  @Input() financialTransactionTypeOptions: string[];
  @Input() showSmartApply: boolean = true;
  @Input() disabled: boolean;
  @Output() adjustmentAdded: EventEmitter<AdjustmentDto> = new EventEmitter<AdjustmentDto>();
  @Output() smartApplyAdjustment: EventEmitter<string> = new EventEmitter<string>();
  financialTransactionType: string = null;
  amount: number = 0;
  showError: boolean = false;
  ErrorMessage: string = '';

  constructor() {
  }

  ngOnInit() {
  }

  applyControlTotalAdjustments() {
    this.smartApplyAdjustment.emit(this.financialTransactionType);
    this.amount = 0;
    this.showError = false;
  }

  addAdjustment() {
    if (this.validateAdjustment()) {
      let adjustment: AdjustmentDto = {
        id: null,
        adjustmentBatchInvoiceId: null,
        amount: -this.amount,
        financialTransactionType: this.financialTransactionType,
      };
      this.adjustmentAdded.emit(adjustment);
      this.resetModel();
    }
  }

  validateAdjustment(): boolean {
    let result = true;
    if (this.financialTransactionType == null) {
      this.ErrorMessage = 'Error, type can\'t be null';
      result = false;
    } else if (isNaN(this.amount) || this.amount.toString() == "") {
      this.ErrorMessage = 'Error, amount can\'t be null';
      result = false;
    } else if (DecimalUtility.countDecimals(this.amount) > 2) {
      this.ErrorMessage = 'Error, precision can\'t be more than 2';
      result = false;
    } else if (parseFloat(this.amount.toString()) == 0) {
      this.ErrorMessage = 'Error, amount can\'t be 0';
      result = false;
    }
    if (result) {
      this.ErrorMessage = '';
    }
    this.showError = !result;
    return result;
  }

  resetModel() {
    this.financialTransactionType = null;
    this.amount = 0;
    this.showError = false;
  }

  setFinancialTransactionTypeOption(option: string) {
    this.financialTransactionType = option;
  }
}
