<h1>Debits (Pending)</h1>
<table id="tb-pending-transactions-errors" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Patient</th>
    <th>Employer</th>
    <th>Region</th>
    <th>Job Site</th>
    <th>Job #</th>
    <th>Facility</th>
    <th>Branch</th>
    <th>Provider</th>
    <th>Service Date</th>
    <th>Type</th>
    <th>Charge Code</th>
    <th>Qty.</th>
    <th>P.O.</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <div style="white-space: nowrap">
        <button class="btn btn-success mr-1" (click)="viewDebit(row)">
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn btn-success mr-1" (click)="viewHistory(row)">
          <i class="fas fa-info-circle"></i>
        </button>
      </div>
    </td>
    <td>{{row.patient}}</td>
    <td>{{row.employer}}</td>
    <td>{{row.region}}</td>
    <td>{{row.jobSite}}</td>
    <td>{{row.jobNumber}}</td>
    <td>{{row.facility}}</td>
    <td>{{row.branch}}</td>
    <td>{{row.provider}}</td>
    <td>{{row.serviceDate | date:"MM/dd/yyyy"}}</td>
    <td>{{row.type}}</td>
    <td>{{row.chargeCode}}</td>
    <td>{{row.quantity}}</td>
    <td>{{row.purchaseOrder}}</td>
    <td>{{row.status}}</td>
  </tr>
  </tbody>
</table>
<price-search-history-popup [history]="priceHistory"></price-search-history-popup>
