import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClinicalDocumentConfigurationDto} from '../../../models/clinical-document-configuration/ClinicalDocumentConfigurationDto.model';
import {ClinicHttpClient} from '../../../common/clinic-http-client';
import {ClinicalDocConfigService} from './clinical-doc-config.service/clinical-doc-config.service';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {UploadDocumentComponent} from './upload-document/upload-document.component';
import {EmployerDto} from '../../../models/common/employer-dto';
import {TestTypeLookupDto} from '../../../models/clinical-document-configuration/TestTypeLookupDto.model';
import {ClinicalDocumentLookupComponent} from './clinical-document-lookup/clinical-document-lookup.component';
import {JobNumberDto} from '../../../models/clinical-document-configuration/JobNumberDto.model';

@Component({
  selector: 'app-clinical-doc-config',
  templateUrl: './clinical-doc-config.component.html',
  styleUrls: ['./clinical-doc-config.component.scss']
})
export class ClinicalDocConfigComponent implements OnInit, OnDestroy {

  isLoading = true;

  curLevelDocs: ClinicalDocumentConfigurationDto[] = [];
  inheritedDocs: ClinicalDocumentConfigurationDto[] = [];
  hiddenDocs: ClinicalDocumentConfigurationDto[] = [];

  private curLevelDocsSub: Subscription;
  private inheritedDocsSub: Subscription;
  private hiddenDocsSub: Subscription;

  testTypeId: string = null;
  employerId: string = null;
  jobNumberId: string = null;

  testTypes: TestTypeLookupDto[] = [];
  employers: EmployerDto[] = [];
  jobNumbers: JobNumberDto[] = [];

  constructor(private clinicalDocConfigService: ClinicalDocConfigService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.curLevelDocsSub = this.clinicalDocConfigService.curLevelDocsUpdated
      .subscribe(curLevelDocs => {
        this.curLevelDocs = curLevelDocs;
      });
    this.inheritedDocsSub = this.clinicalDocConfigService.inheritedDocsUpdated
      .subscribe(inheritedDocs => {
        this.inheritedDocs = inheritedDocs;
      });
    this.hiddenDocsSub = this.clinicalDocConfigService.hiddenDocsUpdated
      .subscribe(hiddenDocs => {
        this.hiddenDocs = hiddenDocs;
      });
    this.updateDocumentData();
    this.clinicalDocConfigService.getTestTypes()
      .subscribe(response => {
        this.testTypes = response;
      });
  }

  ngOnDestroy(): void {
    this.curLevelDocsSub.unsubscribe();
    this.inheritedDocsSub.unsubscribe();
    this.hiddenDocsSub.unsubscribe();
  }

  updateDocumentData() {
    this.clinicalDocConfigService.getCurLevelDocuments(this.testTypeId, this.employerId, this.jobNumberId);
    this.clinicalDocConfigService.getInheritedDocuments(this.testTypeId, this.employerId, this.jobNumberId);
    this.clinicalDocConfigService.getHiddenDocuments(this.testTypeId, this.employerId, this.jobNumberId);
  }

  getEmployers() {
    this.updateDocumentData();
    if (!this.testTypeId) {
      this.employers = [];
      return;
    }
    this.clinicalDocConfigService.getEmployerForTestType(+this.testTypeId)
      .subscribe(employers => {
        this.employers = employers;
      });
  }

  getJobNumbers() {
    this.updateDocumentData();
    if (!this.employerId) {
      this.jobNumbers = [];
      return;
    }
    this.clinicalDocConfigService.getJobNumbers(this.employerId)
      .subscribe(jobNumbers => {
        this.jobNumbers = jobNumbers;
      });
  }

  deleteCurLevelDoc(document: ClinicalDocumentConfigurationDto) {
    this.clinicalDocConfigService.deleteCurLevelDocument(document);
  }

  hideInheritedDoc(document: ClinicalDocumentConfigurationDto) {
    this.clinicalDocConfigService.hideInheritedDoc(document, this.testTypeId, this.employerId, this.jobNumberId);
  }

  unhideHiddenDoc(document: ClinicalDocumentConfigurationDto) {
    this.clinicalDocConfigService.unhideHiddenDoc(document);
  }

  lookupDocument() {
    const dialogRef = this.dialog.open(ClinicalDocumentLookupComponent);
    dialogRef.afterClosed().subscribe((doc: ClinicalDocumentConfigurationDto) => {
      if (doc) {
        this.clinicalDocConfigService.addSelectedDocument(doc, this.testTypeId, this.employerId, this.jobNumberId);
      }
    });
  }

  uploadDocument() {
    const dialogRef = this.dialog.open(UploadDocumentComponent);
    dialogRef.afterClosed().subscribe((doc: ClinicalDocumentConfigurationDto) => {
      if (!doc) {
        return;
      }
      this.clinicalDocConfigService.addSelectedDocument(doc, this.testTypeId, this.employerId, this.jobNumberId);
    });
  }

  viewClinicalDocument(doc: ClinicalDocumentConfigurationDto) {
    this.clinicalDocConfigService.viewClinicalDocument(doc);
  }
}
