import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {InvoiceDetailDto} from "../../../../models/invoice-detail/invoice-detail-dto";
import {InvoiceStatus} from "../../../../models/common/invoice-status.enum";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";
import {InvoiceRenderingType} from "../../../../models/invoice-detail/invoice-rendering-type.enum";
import {InvoiceMetaData} from "../../../../models/invoice-detail/invoice-meta-data";
import {InvoiceDetailDebitsDirective} from "../../../../directives/invoice-detail-debits.directive";
import {InvoiceDetailDebitsComponent} from "../invoice-detail-debits/invoice-detail-debits.component";
import {InvoiceDetailLedgerComponent} from "../invoice-detail-ledger/invoice-detail-ledger.component";
import {InvoiceDetailLedgerDirective} from "../../../../directives/invoice-detail-ledger.directive";
import {InvoiceDetailDebitDetailsComponent} from "../invoice-detail-debit-details/invoice-detail-debit-details.component";
import {InvoiceDetailDebitDetailsDirective} from "../../../../directives/invoice-detail-debit-details.directive";
import {InvoiceDetailNotesComponent} from "../invoice-detail-notes/invoice-detail-notes.component";
import {InvoiceDetailNotesDirective} from "../../../../directives/invoice-detail-notes.directive";
import {InvoiceDetailHistoryComponent} from "../invoice-detail-history/invoice-detail-history.component";
import {InvoiceDetailHistoryDirective} from "../../../../directives/invoice-detail-history.directive";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {DocumentHttpClient} from "../../../../common/document-http-client";
import {InvoiceDetailClaimComponent} from "../invoice-detail-claim/invoice-detail-claim.component";
import {InvoiceDetailClaimDirective} from "../../../../directives/invoice-detail-claim.directive";
import {TaskHttpClient} from "../../../../common/task-http-client";
import {PayorListSearchRow} from "../../../../models/common/payor-list-search-row";
import {ResubmitInvoiceDto} from '../../../../models/invoice-detail/resubmit-invoice-dto';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit, OnDestroy {

  invoiceId: number;
  private ngUnsubscribe = new Subject();
  model: InvoiceDetailDto = new InvoiceDetailDto();
  emailModel: ResubmitInvoiceDto = new ResubmitInvoiceDto();
  showHistory: boolean = false;
  isNotesDirty: boolean = false;
  claimFormType:string = 'CMS';
  disableRequeue: boolean = false;

  invoiceStatus = InvoiceStatus;
  debitsComponent: InvoiceDetailDebitsComponent;
  private ledgerComponent: InvoiceDetailLedgerComponent;
  private debitDetailsComponent: InvoiceDetailDebitDetailsComponent;
  private notesComponent: InvoiceDetailNotesComponent;
  private historyComponent: InvoiceDetailHistoryComponent;
  private claimComponent: InvoiceDetailClaimComponent;

  private isDebitComponentLoaded = false;
  private isLedgerComponentLoaded = false;
  private isDebitDetailsComponentLoaded = false;
  private isNotesComponentLoaded = false;
  private isHistoryComponentLoaded = false;
  private isClaimComponentLoaded = false;
  @ViewChild(InvoiceDetailDebitsDirective, {static: true}) invoiceDetailDebitsHost: InvoiceDetailDebitsDirective;
  @ViewChild(InvoiceDetailLedgerDirective, {static: true}) invoiceDetailLedgerHost: InvoiceDetailLedgerDirective;
  @ViewChild(InvoiceDetailDebitDetailsDirective, {static: true}) invoiceDetailDebitDetailsHost: InvoiceDetailDebitDetailsDirective;
  @ViewChild(InvoiceDetailNotesDirective, {static: true}) invoiceDetailNotesHost: InvoiceDetailNotesDirective;
  @ViewChild(InvoiceDetailHistoryDirective, {static: true}) invoiceDetailHistoryHost: InvoiceDetailHistoryDirective;
  @ViewChild(InvoiceDetailClaimDirective, {static: true}) invoiceDetailClaimHost: InvoiceDetailClaimDirective;

  repriceDto: {
    financialTransactionIds: number[],
    closeIfZeroBalance: boolean
    priceDate: Date
  } = {
    financialTransactionIds: [],
    closeIfZeroBalance: false,
    priceDate: null
  };

  requeueServiceDate: Date;
  requeuePayor: PayorListSearchRow;
  requeueJobNumber: string;
  requeuePoNumber: string;

  constructor(private billingHttpClient: BillingHttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private documentHttpClient: DocumentHttpClient,
              private taskHttpClient: TaskHttpClient,
              private validationResultsService: ValidationResultsService,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.invoiceId = +params['id'];
      });

    const that = this;
    $('#child-tabs #a-debits').on('click', function (e) {
      e.preventDefault();
      that.loadDebit()
    });

    $('#requeueinvoicemodal').on('shown.bs.modal', function (e) {
      that.resetRequeue()
    });

    $('#child-tabs #a-ledger').on('click', function (e) {
      e.preventDefault();
      if (!that.isLedgerComponentLoaded) {
        const componentFactory = that.componentFactoryResolver.resolveComponentFactory(InvoiceDetailLedgerComponent);
        const viewContainerRef = that.invoiceDetailLedgerHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        that.ledgerComponent = (<InvoiceDetailLedgerComponent>componentRef.instance);
        that.ledgerComponent._showHistory = that.showHistory;
        that.ledgerComponent.invoiceId = that.invoiceId;
        that.isLedgerComponentLoaded = true;
      }
    });

    $('#child-tabs #a-debit-details').on('click', function (e) {
      e.preventDefault();
      if (!that.isDebitDetailsComponentLoaded) {
        const componentFactory = that.componentFactoryResolver.resolveComponentFactory(InvoiceDetailDebitDetailsComponent);
        const viewContainerRef = that.invoiceDetailDebitDetailsHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        that.debitDetailsComponent = (<InvoiceDetailDebitDetailsComponent>componentRef.instance);
        that.debitDetailsComponent._showHistory = that.showHistory;
        that.debitDetailsComponent.invoiceId = that.invoiceId;
        that.isDebitDetailsComponentLoaded = true;
      }
    });

    $('#child-tabs #a-notes').on('click', function (e) {
      e.preventDefault();
      if (!that.isNotesComponentLoaded) {
        const componentFactory = that.componentFactoryResolver.resolveComponentFactory(InvoiceDetailNotesComponent);
        const viewContainerRef = that.invoiceDetailNotesHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        that.notesComponent = (<InvoiceDetailNotesComponent>componentRef.instance);
        that.notesComponent.invoiceId = that.invoiceId;
        that.isNotesComponentLoaded = true;
      }
    });

    $('#child-tabs #a-history').on('click', function (e) {
      e.preventDefault();
      if (!that.isHistoryComponentLoaded) {
        const componentFactory = that.componentFactoryResolver.resolveComponentFactory(InvoiceDetailHistoryComponent);
        const viewContainerRef = that.invoiceDetailHistoryHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        that.historyComponent = (<InvoiceDetailHistoryComponent>componentRef.instance);
        that.historyComponent.invoiceId = that.invoiceId;
        that.historyComponent.invoiceNumberDisplay = that.model.invoiceNumberDisplay;
        that.isHistoryComponentLoaded = true;
      }
    });

    $('#repriceDebitsModal').on('hidden.bs.modal', this.resetReprice);

    this.load();
  }

  isInvoiceClosed() {
    return this.model.invoiceStatus == InvoiceStatus.closed;
  }

  showDebitIfVoidOrRequeue() {
    var hash = sessionStorage.getItem("isShowDebits");
    if (hash){
      $('#child-tabs li:eq(1) a').tab('show');
      this.loadDebit();
      sessionStorage.removeItem("isShowDebits")
    }
  }

  loadDebit() {
    if (!this.isDebitComponentLoaded) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InvoiceDetailDebitsComponent);
      const viewContainerRef = this.invoiceDetailDebitsHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      this.debitsComponent = (<InvoiceDetailDebitsComponent>componentRef.instance);
      this.debitsComponent.repriceDto = this.repriceDto;
      this.debitsComponent._showHistory = this.showHistory;
      this.debitsComponent.invoiceId = this.invoiceId;
      this.debitsComponent.invoiceStatus = this.model.invoiceStatus;
      this.isDebitComponentLoaded = true;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  load() {
    const params = new HttpParams().set('invoiceId', this.invoiceId.toString()).set('showHistory', String(this.showHistory));
    this.billingHttpClient.Get("/invoice-detail", {params}).subscribe(
      response => {
        this.onLoadSucceeded(response as InvoiceDetailDto);
        this.showDebitIfVoidOrRequeue()
      }
    );
    if (this.debitsComponent) {
      this.debitsComponent.showHistory = this.showHistory;
    }
    if (this.ledgerComponent) {
      this.ledgerComponent.showHistory = this.showHistory;
    }
    if (this.debitDetailsComponent) {
      this.debitDetailsComponent.showHistory = this.showHistory;
    }
  }

  onLoadSucceeded(response: InvoiceDetailDto) {
    this.model = response;
    let invoiceRenderingType = this.model.invoiceRenderingType;
    if (typeof invoiceRenderingType === 'string') {
      this.model.invoiceRenderingType = InvoiceRenderingType[invoiceRenderingType as keyof typeof InvoiceRenderingType];
    }
    if (this.isShowClaim){
      if (!this.isClaimComponentLoaded) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InvoiceDetailClaimComponent);
        const viewContainerRef = this.invoiceDetailClaimHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        this.claimComponent = (<InvoiceDetailClaimComponent>componentRef.instance);
        this.claimComponent.invoiceId = this.invoiceId;
        this.claimComponent.invoiceNumberDisplay = this.model.invoiceNumberDisplay;
        this.claimComponent.authorizationPatientId = this.model.authorizationPatientId;
        this.isClaimComponentLoaded = true;
      }
    }
  }

  closeInvoice() {
    this.updateInvoiceStatus(InvoiceStatus.closed);
  }

  openInvoice() {
    this.updateInvoiceStatus(InvoiceStatus.open);
  }

  showSubmitInvoice() {
    if (this.isShowClaim) {
      if (!this.claimComponent.canGenerateEDI) {
        $('#submitinvoicemodal').modal('show');
      } else {
        this.claimComponent.generateEDI();
        let payload = {
          isDownload837:true
        } as ResubmitInvoiceDto;
        this.submitInvoice(payload);
      }
    } else {
      this.submitInvoice();
    }
  }

  submitInvoice(payload?: ResubmitInvoiceDto) {
    if (this.showSplitByPatientAuthorizationMessage) {
      this.showUnableToPrintCMS1500Modal();
    } else {
      this.taskHttpClient.Post<CommonResult>(`/submit-invoice/${this.model.id}`, payload).subscribe(
        response => {
          if (response.result === 'success') {
            this.validationResultsService.setSuccessMessages(['Submit Invoice success']);
            this.validationResultsService.setErrorMessages(response.validationResults);
            this.reload();
          } else {
            this.validationResultsService.setErrorMessages(response.validationResults);
          }
        }
      )
    }
  }

  updateInvoiceStatus(status: InvoiceStatus) {
    this.billingHttpClient.Put<CommonResult>(`/invoice/${this.model.id}/status`, {'status': status}).subscribe(
      response => {
        if (response.result === 'success') {
          this.validationResultsService.setSuccessMessages(['Update Invoice status success']);
          this.reload();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      }
    );
  }

  resubmitInvoice() {
    this.submitInvoice(this.emailModel);
  }

  showResubmitInvoice() {
    if (!this.emailModel.extrasEmail) {
      this.emailModel.extrasEmail = this.model.billingEmail;
    }
    $('#resubmitinvoicemodal').modal('show');
  }

  get allowPrintingAsCMS1500() {
    return this.model.canPrintAsCMS1500ClaimForm;
  }

  checkIfAllowedToPrintInvoicePdf(event: Event) {
    if (this.model.invoiceRenderingType == InvoiceRenderingType.CMS1500ClaimForm) {
      this.checkIfAllowedToPrintInvoicePdfCMS1500(event);
    }
  }

  checkIfAllowedToPrintInvoicePdfCMS1500(event: Event) {
    if (!this.allowPrintingAsCMS1500) {
      event.preventDefault();
      event.stopPropagation();
      this.showUnableToPrintCMS1500Modal();
    } else {
      this.printCMS1500Pdf();
    }
  }

  showUnableToPrintCMS1500Modal(claimFormType?: string) {
    if(claimFormType){
      this.claimFormType = claimFormType;
    }else {
      this.claimFormType = 'CMS';
    }
    $('#unableToPrintCMS1500Modal').modal('show');
  }

  checkIfAllowedToPrintInvoicePdfCommercial1500(event: Event) {
    if (!this.allowPrintingAsCMS1500) {
      event.preventDefault();
      event.stopPropagation();
      this.showUnableToPrintCMS1500Modal('Commercial');
    } else {
      this.printCommercial1500Pdf();
    }
  }

  // Used by Update Metadata
  startUpdateInvoiceMetadata() {
    this.billingHttpClient.Put<CommonResult>(`/invoice/${this.model.id}/meta-data`, {}).subscribe(
      response => {
        if (response.result === 'success') {
          let metaData = this.billingHttpClient.Get<InvoiceMetaData>(`/invoice/${this.model.id}/meta-data`).subscribe(
            res => {
              this.model.payor = res.payor;
              this.model.vendorId = res.vendorId;
            }
          );
          this.validationResultsService.setSuccessMessages(['Updated Invoice Metadata.']);
        } else {
          alert('failed');
        }
      }
    );
  }

  getRequirementsMessage(): string {
    return this.getMissingRequirementsText();
  }

  get showSplitByPatientAuthorizationMessage() {
    return this.model.invoiceRenderingType == InvoiceRenderingType.CMS1500ClaimForm && !this.model.canPrintAsCMS1500ClaimForm;
  }

  get submitInvoiceText(): string {
    let result = "Are you sure you want to submit this invoice? This will send the invoice to the Payor per the payor's configuration. ";
    result += this.getMissingRequirementsText();
    return result;
  }

  private getMissingRequirementsText() {
    let result: string = "";
    let requirements: string[] = [];
    if (this.model.chainOfCustody) {
      requirements.push("Chain of Custody");
    }
    if (this.model.authorization) {
      requirements.push("Authorization");
    }
    if (this.model.medicalRecords) {
      requirements.push("Medical Records");
    }
    if (this.model.chainOfCustody || this.model.authorization || this.model.medicalRecords) {
      result += `This payor requires (${requirements.join(", ")}) to be attached. `;
    }
    return result;
  }

  isNotesDirtyChanged(value: boolean) {
    this.isNotesDirty = value;
  }

  showRequeueInvoice() {
    $('#requeueinvoicemodal').modal('show');
  }

  startRequeueInvoice() {
    this.disableRequeue = true;
    if (this.debitsComponent) {
      this.debitsComponent.parentDisableRequeue = true;
    }

    const that = this;
    const payload = {
      invoiceId: this.invoiceId,
      payorId: this.requeuePayor?.payorId,
      serviceDate: this.requeueServiceDate,
      jobNumber: this.requeueJobNumber,
      poNumber: this.requeuePoNumber
    };

    this.taskHttpClient.Post<CommonResult>('/requeue-invoice', payload)
      .subscribe(resp => {
        // Make sure modal is hidden before we refresh data and re-render the page
        $('.modal').each((i, elem) => {
          $(elem).modal('hide');
        });
        document.querySelector('.modal-backdrop')?.remove();

        if (resp.result === 'success') {
          alert('Requeue success! Proceed to refresh this page.');
          window.location.reload();
        } else {
          alert('failed');
        }
      })
      .add(() => {
        this.disableRequeue = false;
        if (this.debitsComponent) {
          this.debitsComponent.parentDisableRequeue = false;
        }
      });

    $('.modal').each((i, elem) => {
      $(elem).modal('hide');
    });
    document.querySelector('.modal-backdrop')?.remove();
  }

  repriceDebits() {
    this.disableRequeue = true;
    if (this.debitsComponent) {
      this.debitsComponent.parentDisableRequeue = true;
    }

    this.taskHttpClient.Post<CommonResult>('/reprice-debits', this.repriceDto).subscribe(resp => {
      $('.modal').each((i, elem) => {
        $(elem).modal('hide');
      });
      document.querySelector('.modal-backdrop')?.remove();

      if (resp.result === 'success') {
        alert('Reprice success! Proceed to refresh this page.');
        window.location.reload();
      } else {
        alert('Reprice debits failed');
        console.error(`Failed to reprice debits.`, resp.validationResults);
      }
    }).add(() => {
      this.disableRequeue = false;
      if (this.debitsComponent) {
        this.debitsComponent.parentDisableRequeue = false;
      }
    });

    $('.modal').each((i, elem) => {
      $(elem).modal('hide');
    });
    document.querySelector('.modal-backdrop')?.remove();
  }

  reload() {
    setTimeout(() => {
      this.router.navigateByUrl('/', {skipLocationChange: true})
        .then(() => this.router.navigate(['invoice', this.invoiceId]));
    }, 1500);
  }

  resetReprice() {
    this.repriceDto = {
      financialTransactionIds: [],
      closeIfZeroBalance: false,
      priceDate: null
    };

    // Update child component's reference if it is currently rendered on the page
    if (this.debitsComponent) {
      this.debitsComponent.repriceDto = this.repriceDto;
    }
  }

  resetRequeue() {
    this.requeuePayor = null;
    this.requeueServiceDate = null;
    this.requeueJobNumber = null;
    this.requeuePoNumber = null;
  }

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.isNotesDirty) {
      return window.confirm('Do you want to discard your notes?  To save your notes, click cancel in this dialog and click the Save Notes button on the Notes tab.');
    }
    return true;
  }

  printInvoice() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", String(this.showHistory));
    this.documentHttpClient.Get<Blob>(`/invoice-pdf/${this.model.id}`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'pdf');
      });
  }

  printCMS1500Pdf() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", String(this.showHistory)).set("invoiceRenderingType", `${InvoiceRenderingType.CMS1500ClaimForm}`);
    this.documentHttpClient.Get<Blob>(`/invoice-pdf/${this.model.id}`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'pdf');
      });
  }

  printCommercial1500Pdf() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", String(this.showHistory)).set("invoiceRenderingType", `${InvoiceRenderingType.Commercial1500ClaimForm}`);
    this.documentHttpClient.Get<Blob>(`/invoice-pdf/${this.model.id}`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'pdf');
      });
  }


  printConsolidated() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", 'true').set("invoiceRenderingType", `${InvoiceRenderingType.Standard}`);
    this.documentHttpClient.Get<Blob>(`/invoice-pdf/${this.model.id}`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'pdf');
      });
  }

  printNonDetailed() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", String(this.showHistory)).set("invoiceRenderingType", `${InvoiceRenderingType.NonDetailed}`);
    this.documentHttpClient.Get<Blob>(`/invoice-pdf/${this.model.id}`,
        {responseType: 'blob' as 'json', params: params})
        .subscribe(response => {
          this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'pdf');
        });
  }

  printChargeOnly() {
    if (this.model.invoiceRenderingType != InvoiceRenderingType.CMS1500ClaimForm) {
      let params = new HttpParams().set("includePreviousRevisionTransactions", String(this.showHistory)).set("invoiceRenderingType", `${InvoiceRenderingType.ChargeOnly}`);
      this.documentHttpClient.Get<Blob>(`/invoice-pdf/${this.model.id}`,
          {responseType: 'blob' as 'json', params: params})
          .subscribe(response => {
            this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'pdf');
          });
    } else {
      this.printInvoice();
    }
  }

  printDefault(event: Event) {
    console.log(this.model.invoiceRenderingType)
    console.log(this.model.payorType)
    if (this.model.invoiceRenderingType == InvoiceRenderingType.CMS1500ClaimForm) {
      if (this.model.payorType == 'TPA' || this.model.payorType == 'Commercial'){
        this.checkIfAllowedToPrintInvoicePdfCommercial1500(event)
      }else if(this.model.payorType == 'Employer' || this.model.payorType == 'WorkersComp'){
        this.checkIfAllowedToPrintInvoicePdfCMS1500(event)
      }else {
        this.printChargeOnly();
      }
    } else {
      this.printChargeOnly();
    }
  }

  get isShowChargeOnly(): boolean{
    return this.model.invoiceRenderingType == InvoiceRenderingType.CMS1500ClaimForm
      && (this.model.payorType == 'TPA' || this.model.payorType == 'Commercial' || this.model.payorType == 'Employer' || this.model.payorType == 'WorkersComp')
  }

  downloadDocument(blob: Blob, documentName: string, type: string) {
    let fileType;
    let fileExtension;
    if (type == 'pdf') {
      fileType = 'application/pdf';
      fileExtension = '.pdf';
    } else {
      fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      fileExtension = '.xlsx';
    }
    let newBlob = new Blob([blob], {type: fileType})
    let data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = documentName + fileExtension;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  printConsolidatedCsv() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", 'true');
    this.documentHttpClient.Get<Blob>(`/invoice-csv/${this.model.id}`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'csv');
      });
  }

  printCsv() {
    let params = new HttpParams().set("includePreviousRevisionTransactions", String(this.showHistory));
    this.documentHttpClient.Get<Blob>(`/invoice-csv/${this.model.id}`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadDocument(response, `invoice_${this.model.invoiceNumberDisplay}`, 'csv');
      });
  }

  setPayorOverride($event: PayorListSearchRow) {
    this.requeuePayor = $event;
    if (this.debitsComponent != null) {
      this.debitsComponent.requeuePayor = $event;
    }
  }

  applyPayment() {
    this.router.navigate(['adjustment-batch', "1", -1, this.model.payorID, this.invoiceId, {
      outlets: {
        linearStep: 'control-totals'
      }
    }])
  }

  gotoClaim() {
    $('#a-claim').click()
  }

  get isShowClaim(): boolean{
    return  this.model.payorType == 'Commercial' || this.model.payorType == 'WorkersComp';
  }

  addAllDebitsToRepriceDto() {
    this.repriceDto.financialTransactionIds.splice(0);

    if (this.isDebitComponentLoaded) {
      const debitIds = this.debitsComponent.debits.map(debit => debit.financialTransactionId);
      this.repriceDto.financialTransactionIds.push(...debitIds);
    } else {
      this.loadDebit();

      // No telling when debit child component is fully loaded and populated with data, so use a subscription here to catch that event.

      this.debitsComponent.debitsLoadedSubject.subscribe(next => {
        const debitIds = next.map(debit => debit.financialTransactionId);
        this.repriceDto.financialTransactionIds.push(...debitIds);
      });
    }

  }

  updatePoNumber() {
    const poNumber = prompt('Enter new PO number for all debits.');
    if (poNumber) {
      this.billingHttpClient.Put<CommonResult>('/debits/po-number', {
        invoiceId: this.invoiceId,
        poNumber
      }).subscribe(response => {
        if (response.result === 'success') {
          this.reload();
        } else {
          alert('Failed to update PO number.');
        }
      });
    }
  }

  updateJobNumber() {
    const jobNumber = prompt('Enter new job number for all debits.');
    if (jobNumber) {
      this.billingHttpClient.Put<CommonResult>('/debits/job-number', {
        invoiceId: this.invoiceId,
        jobNumber
      }).subscribe(response => {
        if (response.result === 'success') {
          this.reload();
        } else {
          alert('Failed to update job number.');
        }
      });
    }
  }
}
