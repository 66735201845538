<h1>Invoice Closed Charges</h1>
<hr class="divider"/>
<div class="row">
  <div class="col-md-3">
    <button class="btn btn-raised btn-success mr-1" id="createButton"
            reallyClick="Are you sure you want to start all select invoice adjustments?"
            reallyClickAction="createSelect"
            [reallyClickCaller]="this"
            [reallyClickArguments]="[]">
      <i class="fa fa-fw fa-check"></i>Apply Selected to Adjustment Invoice
    </button>
  </div>
  <div class="col-md-3">
    <button class="btn btn-raised btn-success mr-1" id="voidButton"
            reallyClick="Are you sure you want to void all select transactions?"
            reallyClickAction="voidSelect"
            [reallyClickCaller]="this"
            [reallyClickArguments]="[]">
      <i class="fa fa-fw fa-undo"></i>Void Selected
    </button>
  </div>
</div>
<hr class="divider"/>
<table id="tb-invoice-closed-errors" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
       class="table table-striped table-bordered mt-3"
       style="width: 100%">
  <thead>
  <tr>
    <th>
      <span id="select-all"><label for="checkall"></label><input type="checkbox" id="checkall"
                                                                 class="form-control" style="min-width: 15px;"
                                                                 (change)="switchChecks()"/></span>
    </th>
    <th></th>
    <th>Status</th>
    <th>Payor</th>
    <th>Charge Code</th>
    <th>Service Date</th>
    <th>Quantity</th>
    <th>Job Number</th>
    <th>P.O.</th>
    <th>Patient</th>
    <th>Employer</th>
    <th>Region</th>
    <th>Job Site</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <input id="{{row.financialTransactionId}}" type="checkbox" class="form-control checks"
       (change)="applySelect(false)"/>
    </td>
    <td>
        <button class="btn btn-sm btn-raised btn-success mr-1"
                reallyClick="Are you sure you want to start an invoice adjustment?"
                reallyClickAction="createInvoiceAdjustment"
                [reallyClickCaller]="this"
                [reallyClickArguments]="[row]">
          <i class="fa fa-fw fa-check"></i>Apply to Adj. Invoice
        </button>
        <button class="btn btn-sm btn-raised btn-success mt-3"
                reallyClick="Are you sure you want to void this transaction?"
                reallyClickAction="voidTransaction"
                [reallyClickCaller]="this"
                [reallyClickArguments]="[row]">
          <i class="fa fa-fw fa-undo"></i>Void
        </button>
    </td>
    <td>{{row.financialTransactionErrorStatus}}</td>
    <td>{{row.payor}}</td>
    <td>{{row.chargeCode}}</td>
    <td>{{row.serviceDate | date:"M/d/yyyy"}}</td>
    <td>{{row.quantity}}</td>
    <td>{{row.jobNumber}}</td>
    <td>{{row.purchaseOrderNumber}}</td>
    <td>{{row.patient}}</td>
    <td>{{row.employer}}</td>
    <td>{{row.region}}</td>
    <td>{{row.jobSite}}</td>
  </tr>
  </tbody>
</table>
