<h1>Failed Emails</h1>
<div class="row">
  <div class="col-md-3">
    <button class="btn btn-raised btn-success mr-1" id="createButton"
            reallyClick="Are you sure you want to do this?"
            reallyClickAction="dismissAllFailedEmail"
            [reallyClickCaller]="this"
            [reallyClickArguments]="">
      <i class="fa fa-fw fa-check"></i>Dismiss all
    </button>
  </div>
</div>
<hr class="divider"/>
<table id="tb-failed-emails" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Created Date</th>
    <th>Failure Type</th>
    <th>Email Address</th>
    <th>Failure Reason</th>
    <th>Failure Status</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button class="btn btn-sm btn-raised btn-success"
              reallyClick="Are you sure you want to dismiss this failed email?"
              reallyClickAction="dismissFailedEmail"
              [reallyClickCaller]="this"
              [reallyClickArguments]="[row]">
        <i class="fa fa-fw fa-trash"></i>Dismiss
      </button>
    </td>
    <td>{{row.createdDate | date:"M/d/yyyy"}}</td>
    <td>{{row.failureType}}</td>
    <td>{{row.emailAddress}}</td>
    <td>{{row.failureReason}}</td>
    <td>{{row.failureStatus}}</td>
  </tr>
  </tbody>
</table>
