<h4>Search Clinical Documents</h4>
<hr>
<div mat-dialog-content>
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
         style="width: 100%">
    <thead>
    <tr>
      <th></th>
      <th>Document Name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let clinicalDoc of model">
      <td>
        <button type="button" class="btn btn-sm btn-success" (click)="onSelect(clinicalDoc)">Select</button>
        <button type="button" class="btn btn-sm btn-success ml-2" (click)="onView(clinicalDoc)"><i class="far fa-file-pdf"></i> View</button>
      </td>
      <td>{{clinicalDoc.documentName}}</td>
    </tr>
    </tbody>
  </table>
</div>
<hr>
<button class="btn btn-warning float-right" matDialogClose="">Close</button>
