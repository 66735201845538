import {Component, Input, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {AuthenticationService} from "../../../../services/authentication.service";
import {HttpParams} from "@angular/common/http";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {PayorContactEntryDto} from "../../../../models/payor-entry/payor-contact-entry-dto";
import {PayorListSearchRow} from "../../../../models/common/payor-list-search-row";
import {CommonResult} from "../../../../models/common/common-result";
import {BranchEntryPayorDto} from "../../../../models/branch-payor-entry/branch-entry-payor-dto";
import {BranchEntryPayorsDto} from "../../../../models/branch-payor-entry/branch-entry-payors-dto";
import {BranchEmployerExceptionDto} from '../../../../models/branch-payor-entry/branch-employer-exception-dto';
import {DataTablesResponse} from '../../../../models/common/data-tables-response';
import {EmployerSearchRow} from '../../../../models/employer-list/employer-search-row';

@Component({
  selector: 'app-branch-entry-payors',
  templateUrl: './branch-entry-payors.component.html',
  styleUrls: ['./branch-entry-payors.component.scss']
})
export class BranchEntryPayorsComponent implements OnInit {

  @Input() id: number;
  model: BranchEntryPayorsDto = new BranchEntryPayorsDto();

  exceptionDtOptions: any = {};
  employerExceptions = [] as BranchEmployerExceptionDto[];


  constructor(
    private billingHttpClient: BillingHttpClient,
    private router: Router,
    private validationResultsService: ValidationResultsService,
    private _service: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const params = new HttpParams().set('branchId', this.id.toString());
    this.billingHttpClient.Get<BranchEntryPayorsDto>('/branch-entry-payors', {params})
      .subscribe(response => {
        this.model = response;
      });
    this.exceptionDtOptions = {
      paging:false,
      serverSide: true,
      info:false,
      processing: true,
      searching:false,
      columnDefs: [ {
        orderable: false,
        targets: [0,1]
      }],
      columns: [
        {data: ''},
        {data: 'employerName'},
      ],
      ajax: (dataTableParams: any, callback) => {
        dataTableParams.branchId = this.id.toString();
        this.billingHttpClient.Post<DataTablesResponse>('/branch-employer-exception-list', dataTableParams)
          .subscribe(a_resp => {
            this.employerExceptions = a_resp.data;
            callback({
              recordsTotal: a_resp.recordsTotal,
              recordsFiltered: a_resp.recordsFiltered,
              data: []
            });
          });
      }
    };
  }

  drop(event: CdkDragDrop<PayorContactEntryDto[]>) {
    moveItemInArray(this.model.branchEntryPayorDtos, event.previousIndex, event.currentIndex);
  }

  editBranchPayor(branchPayorId: number) {
    this.router.navigate(['branch-payor-entry', branchPayorId]);
  }

  selectPayor(payor: PayorListSearchRow) {
    let branchPayorNew: BranchEntryPayorDto = new BranchEntryPayorDto;
    branchPayorNew.branchId = this.model.branchId;
    branchPayorNew.payorId = payor.payorId;
    branchPayorNew.chargeCodePayorAssociationMode = 'All';
    branchPayorNew.visitReasonPayorAssociationMode = 'All';
    branchPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    branchPayorNew.payorName = payor.name;
    branchPayorNew.payorType = payor.payorType;
    branchPayorNew.startDateInclusive = new Date(2000, 0, 1);
    branchPayorNew.endDateInclusive = new Date(9999, 11, 31);
    branchPayorNew.active = true;
    this.model.branchEntryPayorDtos.push(branchPayorNew);
  }

  createBranchPayor(type: string) {
    let branchPayorNew = new BranchEntryPayorDto();
    branchPayorNew.branchId = this.model.branchId;
    branchPayorNew.chargeCodePayorAssociationMode = 'All';
    branchPayorNew.visitReasonPayorAssociationMode = 'All';
    branchPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    branchPayorNew.payorName = this.model.name;
    branchPayorNew.payorType = type;
    branchPayorNew.ssnDisplayType = "Redact";
    branchPayorNew.billingFrequencyType = "Weekly";
    branchPayorNew.startDateInclusive = new Date(2000, 0, 1);
    branchPayorNew.endDateInclusive = new Date(9999, 11, 31);
    branchPayorNew.active = true;
    this.model.branchEntryPayorDtos.push(branchPayorNew);
  }

  cancel() {
    window.history.back();
  }

  save() {
    // Update rank
    this.model.branchEntryPayorDtos.forEach((v, i) => {
      v.rank = i;
    });
    this.billingHttpClient.Post<CommonResult>('/branch-entry-payors', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeed']);
          this.load();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  get canSave(): boolean {
    return this._service.hasRole("ROLE_billing_branch_admin");
  }


  setEmployer(value: EmployerSearchRow) {
    let payload = {
      branchId: this.id.toString(),
      employerId: value.employerId,
    };
    this.billingHttpClient.Post<CommonResult>('/save-branch-employer-exception', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          var table = $('#tb-employer-exception-list').DataTable();
          table.draw(true);
        }
      });
  }

  deleteEmployerException(chargeCodePriceEmployerExceptionId: number) {
    const params = new HttpParams().set('id', chargeCodePriceEmployerExceptionId.toString());
    this.billingHttpClient.Delete<CommonResult>('/delete-branch-employer-exception', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          var table = $('#tb-employer-exception-list').DataTable();
          table.draw(true);
        } else {
          this.validationResultsService.setSuccessMessages(["delete failed"]);
        }
      });

  }

}
