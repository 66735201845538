<div class="modal fade" id="claimAudit" tabindex="-1" role="dialog" aria-labelledby="claimAuditLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="claimAuditLabel">Claim Audit</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table id="tb-list" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th>Description</th>
            <th>Time</th>
            <th>User</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td>{{row.description}}</td>
            <td>{{row.time | date:"MM/dd/yyyy HH:mm:ss"}}</td>
            <td>{{row.user}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

