<div class="flex-container">
  <div class="flex-column mt-5">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="model.name" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Tax ID</mat-label>
        <input matInput [(ngModel)]="model.taxId" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>NPI #</mat-label>
        <input matInput [(ngModel)]="model.npiNumber">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Phone Number</mat-label>
        <input matInput [(ngModel)]="model.phone">
      </mat-form-field>
    </div>
  </div>
  <div class="flex-column mt-1">
    <p>
      Remit To:
    </p>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Address Line 1</mat-label>
        <input matInput [(ngModel)]="model.address1" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Address Line 2</mat-label>
        <input matInput [(ngModel)]="model.address2">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input matInput [(ngModel)]="model.city" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select [(ngModel)]="model.state">
          <mat-option *ngFor="let state of stateList" [value]="state.value">
            {{state.display}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="flex-column mt-5">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Postal Code</mat-label>
        <input matInput [(ngModel)]="model.postalCode" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Email Address</mat-label>
        <input matInput [(ngModel)]="model.emailAddress">
      </mat-form-field>
    </div>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="enable-check" [(ngModel)]="model.billingEnabled">
      <label class="form-check-label" for="enable-check">Enable</label>
    </div>
  </div>
</div>
