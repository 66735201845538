import {Component, OnDestroy, OnInit} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {AuthorizationPatientDocumentDto} from "../../../models/billing-document-composition/authorization-patient-document-dto";
import {BillingComposedDocumentDto} from "../../../models/billing-document-composition/billing-composed-document-dto";
import {BillingComposedDocumentPageDto} from "../../../models/billing-document-composition/billing-composed-document-page-dto";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthorizationPatientDocumentPageDto} from "../../../models/billing-document-composition/authorization-patient-document-page-dto";
import {CommonResult} from "../../../models/common/common-result";
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {DocumentHttpClient} from "../../../common/document-http-client";

declare var $: any;

@Component({
  selector: 'billing-document-composition',
  templateUrl: './billing-document-composition.component.html',
  styleUrls: ['./billing-document-composition.component.scss'],
  providers: [DragulaService]
})

export class BillingDocumentCompositionComponent implements OnInit, OnDestroy {

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private documentHttpClient: DocumentHttpClient,
    private dragulaService: DragulaService,
  ) {

    const bag: any = this.dragulaService.find('DRAGULA');
    if (bag !== undefined) this.dragulaService.destroy('DRAGULA');

    dragulaService.createGroup('DRAGULA', {
      copy: (el, source) => {
        return source.id === 'left';
      },
      copyItem: (item: BillingComposedDocumentPageDto) => ({...item}),
      accepts: (el, target, source, sibling) => {
        // To avoid dragging from right to left container
        return target.id !== 'left';
      }
    });
  }

  financialTransactionId: number;
  authorizationPatientId: number;
  authorizationPatientDocuments: AuthorizationPatientDocumentDto[] = [];
  billingComposedDocument: BillingComposedDocumentDto = new BillingComposedDocumentDto();

  authorizationPatientDocument: AuthorizationPatientDocumentDto;

  //Left pages
  leftPages: BillingComposedDocumentPageDto[] = [];
  rightPages: BillingComposedDocumentPageDto[] = [];

  pageImagePreview: string = "";

  ngOnInit() {
  }

  load() {
    const that = this;
    this.authorizationPatientDocument = null;
    this.authorizationPatientDocuments = [];
    this.billingComposedDocument = new BillingComposedDocumentDto();
    this.rightPages = [];
    this.leftPages = [];
    let params = new HttpParams().set('authorizationPatientId', this.authorizationPatientId.toString());
    this.clinicHttpClient.Get<AuthorizationPatientDocumentDto[]>('/authorization-patient-documents', {params})
      .subscribe(response => {
        this.authorizationPatientDocuments = response;
      });
    params = new HttpParams().set('financialTransactionId', this.financialTransactionId.toString());
    this.documentHttpClient.Get<BillingComposedDocumentDto>('/billing-composed-document', {params})
      .subscribe(response => {
        if (response != null) {
          this.billingComposedDocument = response;
          if (this.billingComposedDocument?.pages) {
            that.rightPages = this.billingComposedDocument?.pages;
          }
        }
      });
  }

  onAuthorizationPatientDocumentChanged() {
    if (this.authorizationPatientDocument) {
      //fetch new selected document pages
      let params = new HttpParams().set('storageLocation', this.authorizationPatientDocument.storageLocation);
      this.documentHttpClient.Get<AuthorizationPatientDocumentPageDto[]>('/authorization-patient-document-pages', {params})
        .subscribe(response => {
          //set billingComposedDocumentPages
          this.leftPages = response.map((d) => {
            let dto: BillingComposedDocumentPageDto = {
              id: null,
              authorizationPatientDocumentId: this.authorizationPatientDocument.authorizationPatientDocumentId,
              storageLocation: this.authorizationPatientDocument.storageLocation,
              documentName: this.authorizationPatientDocument.documentName,
              sourcePageNumber: d.pageNumber,
              pageThumbnail: d.pageThumbnail,
            };
            return dto;
          });
        });
    } else {
      this.authorizationPatientDocument = null;
      this.leftPages = [];
    }
  }

  getRemoveDocumentCompositionPageMessage() {
    return "Are you sure you want to remove this page?";
  }

  // Used by remove button reallyClick
  deleteComposedDocumentPage(i) {
    this.rightPages.splice(i, 1);
  }

  saveBillingDocumentComposition() {
    this.updateBillingComposedDocument();

    const payload = this.cloneWithoutThumbnail(this.billingComposedDocument);

    this.documentHttpClient.Post<CommonResult>('/billing-composed-document', payload)
      .subscribe(response => {
        if (response.result === 'success') {
          this.authorizationPatientDocument = null;
          this.leftPages = [];
          $('#invoiceDebitBillingDocumentComposition').modal('hide');
        } else {
          alert("failed");
        }
      });
  }

  updateBillingComposedDocument() {
    this.billingComposedDocument.financialTransactionId = this.financialTransactionId;
    this.billingComposedDocument.authorizationPatientId = this.authorizationPatientId;
    this.billingComposedDocument.pages = this.rightPages;
  }

  cloneWithoutThumbnail(dto: BillingComposedDocumentDto ) {
    let documentDto = new BillingComposedDocumentDto();
    documentDto.billingComposedDocumentId = dto.billingComposedDocumentId;
    documentDto.financialTransactionId = dto.financialTransactionId;
    documentDto.storageLocation = dto.storageLocation;
    documentDto.authorizationPatientId = dto.authorizationPatientId;
    documentDto.pages = dto.pages.map((p) => {
      let pageDto = {...p};
      pageDto.pageThumbnail = null;
      return pageDto;
    });
    return documentDto;
  }

  previewRenderedComposition() {
    this.updateBillingComposedDocument();

    const payload = this.cloneWithoutThumbnail(this.billingComposedDocument);

    this.documentHttpClient.Post<Blob>('/render-billing-document-composition', payload,
      {responseType: 'blob' as 'json'})
      .subscribe(response => {
        this.downloadPdf(response, 'preview');
      });
  }

  downloadRawDocument() {
    let params = new HttpParams().set('storageLocation', this.authorizationPatientDocument.storageLocation)
      .set('documentName', this.authorizationPatientDocument.documentName);
    this.documentHttpClient.Get<Blob>('/authorization-document-encoded-pdf',
      {responseType: 'blob' as 'json', params: params})
      .subscribe(response => {
        this.downloadPdf(response, this.authorizationPatientDocument.documentName);
      });
  }

  noDocumentSelected() {
    return this.authorizationPatientDocument == null
      || this.authorizationPatientDocument.authorizationPatientDocumentId == null
      || this.authorizationPatientDocument.authorizationPatientDocumentId == 0;
  }

  downloadPdf(blob: Blob, documentName: string) {
    let newBlob = new Blob([blob], {type: "application/pdf"})
    let data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = documentName + ".pdf";
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  previewAuthorizationDocumentPage(documentPage: BillingComposedDocumentPageDto) {
    this.pageImagePreview = documentPage.pageThumbnail;
  }

  previewBillingComposedPage(composedDocumentPage: BillingComposedDocumentPageDto) {
    this.pageImagePreview = composedDocumentPage.pageThumbnail;
  }

  ngOnDestroy() {
    this.dragulaService.destroy('DRAGULA');
    this.authorizationPatientDocument = null;
    this.leftPages = [];
  }
}
