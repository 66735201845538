<h1>Invoices with Requirements</h1>

<div>
  <h6>Filter by Billing Entity</h6>
  <label>
    <select class="dropdown form-control" name="billing-entity" [(ngModel)]="billingEntity" (ngModelChange)="applyBillingEntityFilter()">
      <option [ngValue]="null"></option>
      <option *ngFor="let option of billingEntities" [ngValue]="option">{{option.entityName}}</option>
    </select>
  </label>
</div>
<table id="tb-invoices-with-requirements" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th></th>
    <th>#</th>
    <th>Amount</th>
    <th>Employer</th>
    <th>Payor</th>
    <th>Payor Type</th>
    <th>Region</th>
    <th>Job #</th>
    <th>Purchase Order</th>
    <th>Reason For Visit</th>
    <th>Patient</th>
    <th>Status</th>
    <th>Requirements</th>
    <th>Invoice Period</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <div class="form-check">
        <mat-checkbox (change)="addOrRemoveInvoice($event.checked,row)" [checked]="checkInvoice(row)">
        </mat-checkbox>
      </div>
    </td>
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="goToInvoice(row.invoiceId)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.invoiceNumberDisplay}}</td>
    <td><displayable-money [model]="row.invoiceAmount"></displayable-money></td>
    <td>{{row.employer}}</td>
    <td>{{row.payor}}</td>
    <td>{{row.payorType}}</td>
    <td>{{row.region}}</td>
    <td>{{row.jobNumber}}</td>
    <td>{{row.purchaseOrder}}</td>
    <td>{{row.reasonForVisit}}</td>
    <td>{{row.patientName}}</td>
    <td>{{row.status}}</td>
    <td>{{row.requirements}}</td>
    <td>{{row.invoicePeriod}}</td>
  </tr>
  </tbody>
</table>
