<div class="modal fade" id="patientLookupModal" tabindex="-1" role="dialog" aria-labelledby="patientLookupModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="patientLookupModalLabel">Search Patients</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th></th>
            <th>AuthorizationID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>ID (SSN)</th>
            <th>DOB</th>
            <th>Phone Number</th>
            <th>Company Name</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td>
              <div class="button-group" role="group">
                <button class="btn btn-success mr-1" (click)="selectPatient(row)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
            <td>{{row.authorizationId}}</td>
            <td>{{row.firstName}}</td>
            <td>{{row.lastName}}</td>
            <td>{{row.identifierValue}}</td>
            <td>{{row.dateOfBirth | date:"MM/dd/yyyy"}}</td>
            <td>{{row.contactPhone}}</td>
            <td>{{row.companyName}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button id="patientLookupModalDismiss" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

