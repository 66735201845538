import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'displayable-money',
  templateUrl: './displayable-money.component.html',
  styleUrls: ['./displayable-money.component.scss']
})
export class DisplayableMoneyComponent implements OnInit {

  @Input() model: number;

  constructor() {
  }

  ngOnInit() {
  }

  get shouldDisplayRed(): boolean {
    let result = false;
    if (this.model !== undefined && this.model !== null) {
      if (this.model < 0) {
        result = true;
      }
    }
    return result;
  }

  get display(): string {
    let result = "";
    if (this.model !== undefined && this.model !== null) {
      const numberWithTwoDecimals = parseFloat(this.model.toFixed(2));
      const formattedNumber = this.formatNumber(numberWithTwoDecimals);
      if (this.model < 0) {
        result = `($${formattedNumber})`.replace('-', '');
      } else {
        result = `$${formattedNumber}`;
      }
    }
    return result;
  }

  formatNumber(value: number): string {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts.length < 2) {
      parts.push("00");
    }
    parts[1] = parts[1].padEnd(2, "0");
    return parts.join(".");
  }


}
