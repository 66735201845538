import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmployerPayorEntryGeneralDto} from "../../../../models/employer-payor-entry/employer-payor-entry-general-dto";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'employer-payor-entry-general',
  templateUrl: './employer-payor-entry-general.component.html',
  styleUrls: ['./employer-payor-entry-general.component.scss']
})
export class EmployerPayorEntryGeneralComponent implements OnInit {

  constructor() {
  }

  @Input() model: EmployerPayorEntryGeneralDto = new EmployerPayorEntryGeneralDto();
  @ViewChild('formGeneral') formGeneral: NgForm;

  ngOnInit() {
  }

}
