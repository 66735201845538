import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { DataTablesResponse } from 'src/app/models/common/data-tables-response';
import {ChargeCodeLookupSearchRow} from '../../../models/common/charge-code-lookup-search-row';
import { BillingHttpClient } from 'src/app/common/billing-http-client.service';

@Component({
  selector: 'app-charge-code-list',
  templateUrl: './charge-code-list.component.html',
  styleUrls: ['./charge-code-list.component.scss']
})
export class ChargeCodeListComponent implements OnInit {

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};
  model: ChargeCodeLookupSearchRow[] = [];

  @Output() messageEvent = new EventEmitter<string>();

  constructor(private billingHttpClient: BillingHttpClient,
              private  router: Router) {
  }

  ngOnInit(): void {

    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10000,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'code'},
        {data: 'description'},
        {data: 'startDate'},
        {data: 'endDate'},
        {data: 'price'},
      ],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>('/charge-code-list', dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: '<\'row\'<\'col-sm-7\'l><\'col-sm-2 \'><\'col-sm-3\'f>>' +
        '<\'row\'<\'col-sm-12\'tr>>' +
        '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
      // Configure the buttons
    };


  } // ngOnInit

  goToChargeCode(chargeCodeId: number) {
    this.router.navigate(['charge-code-entry', chargeCodeId]);
  }

}
