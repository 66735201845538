import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {RegionPayorEntryGeneralDto} from "../../../../models/region-payor-entry/region-payor-entry-general-dto";

@Component({
  selector: 'region-payor-entry-general',
  templateUrl: './region-payor-entry-general.component.html',
  styleUrls: ['./region-payor-entry-general.component.scss']
})
export class RegionPayorEntryGeneralComponent implements OnInit {

  constructor() {
  }

  @Input() model: RegionPayorEntryGeneralDto = new RegionPayorEntryGeneralDto();
  @ViewChild('formGeneral') formGeneral: NgForm;

  ngOnInit(): void {
  }

}
