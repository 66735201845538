<h1>
  Facilities Assigned to this Billing Entity
</h1>
<hr>
<div>
  <button class="btn btn-primary" (click)="openDialog()">
    Add Facility
  </button>
</div>
<div>
  <table class="table table-striped table-bordered mt-2" style="width: 100%">
    <thead>
    <tr>
      <th></th>
      <th>Facility Name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of model; index as i">
      <td>
        <button type="button" class="btn btn-danger mr-1" (click)="removeFacility(i)">
          <i class="fas fa-trash-alt"></i>
        </button>
        <button type="button" class="btn btn-success" (click)="goToFacilityPayor(row.facilityId)">
          <i class="fas fa-edit"></i>
        </button>
      </td>
      <td>{{row.facilityName}}</td>
    </tr>
    </tbody>
  </table>
</div>
