<div class="row">
  <div class="col-md-12">
    <h2>Contacts Assigned to this Payor</h2>
    <hr class="divider"/>
    <div class="col-md-12">
      <span class="input-group-btn fix-span">
        <button type="button" class="btn btn-success btn-raised" (click)="addPayorContact()">
          Add Contact
        </button>
      </span>
    </div>
    <table class="table table-bordered table-striped table-hover mt-2">
      <thead>
      <tr>
        <th class="fit"></th>
        <th>Name</th>
        <th>Title</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Start Date</th>
        <th>End Date</th>
      </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let row of model" cdkDrag>
        <td class="fit button-container">
          <div class="row">
            <div class="col-md-3">
              <div class="row">
                <i class="fa fa-sort-up fa-stack-2x"></i>
              </div>
              <div class="row">
                <i class="fa fa-sort-down fa-stack-2x"></i>
              </div>
            </div>
            <div class="col-md-9">
              <button type="button" [title]="'Edit'"
                      (click)="editPayorContact(row.id);"
                      class="btn btn-success">
                <i class="fas fa-edit"></i>
              </button>
              <button type="button" class="btn btn-danger ml-2" (click)="deletePayorContact(row.id)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </td>
        <td>
          <span>{{row.name}}</span>
        </td>
        <td>
          <span>{{row.title}}</span>
        </td>
        <td>
          <span>{{row.email}}</span>
        </td>
        <td>
          <span>{{row.phone | phonePipe}}</span>
        </td>
        <td>
          <span>{{row.startDateInclusive | date:"MM/dd/yyyy"}}</span>
        </td>
        <td>
          <span>{{row.endDateInclusive | date:"MM/dd/yyyy"}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="right-align">
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
</div>
