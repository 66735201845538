<div class="row">
  <div class="col-md-6">
    <h2>Pending Debit ID: {{model.id}}</h2>
  </div>
  <div class="col-md-6">
    <h2 class="pull-right">Status: {{model.financialTransactionPendingImportStatus}}</h2>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row invoice-button-row">
  <div class="col-md-12 text-center">
    <button *ngIf="canCopy" class="btn btn-warning mr-1"
            reallyClick="Are you sure you want to copy this debit?"
            reallyClickAction="copyDebit"
            [reallyClickCaller]="this"
            [reallyClickArguments]="">
      Copy Debit
    </button>
    <button *ngIf="canCopy && canCancel" class="btn btn-warning mr-1"
            reallyClick="Are you sure you want to cancel and copy this debit?"
            reallyClickAction="cancelDebit"
            [reallyClickCaller]="this"
            [reallyClickArguments]="[true]">
      Cancel And Copy Debit
    </button>
    <button *ngIf="canCancel" class="btn btn-warning mr-1"
            reallyClick="Are you sure you want to cancel this debit?"
            reallyClickAction="cancelDebit"
            [reallyClickCaller]="this"
            [reallyClickArguments]="[false]">
      Cancel Debit
    </button>
    <button class="btn btn-primary" (click)="navigateBack()" type="button">Back</button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="row">
      <h3>General Info</h3>
    </div>
    <div class="row">
      <label class="control-label label-offset">Debit Type:</label>
      <span>{{model.financialTransactionPendingImportType}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Service Date</label>
      <span>{{model.serviceDate | date:"MM/dd/yyyy"}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Quantity</label>
      <span>{{model.quantity}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Grouping Key</label>
      <span>{{model.groupingKey}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Charge Code</label>
      <span>{{model.chargeCode}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Charge Code Description</label>
      <span>{{model.chargeCodeDescription}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Specimen ID</label>
      <span>{{model.specimenId}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Amount</label>
      <displayable-money [model]="model.amount"></displayable-money>
    </div>
    <div class="row">
      <label class="control-label label-offset">Notes</label>
      <span>{{model.notes}}</span>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <h3>Payor Info</h3>
    </div>
    <div class="row">
      <label class="control-label label-offset">Name</label>
      <span>{{model.payor}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Facility</label>
      <span>{{model.facility}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Branch</label>
      <span>{{model.branch}}</span>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <h3>Patient Info</h3>
    </div>
    <div class="row">
      <label class="control-label label-offset">First Name</label>
      <span>{{model.patientFirstName}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Last Name</label>
      <span>{{model.patientLastName}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Identifier</label>
      <span>{{model.patientIdentifier}}</span>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <h3>Split By Info</h3>
    </div>
    <div class="row">
      <label class="control-label label-offset">Auth Patient ID</label>
      <span>{{model.authorizationPatientId}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Employer</label>
      <span>{{model.employer}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Region</label>
      <span>{{model.region}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Job Site</label>
      <span>{{model.jobSite}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Job Number</label>
      <span>{{model.jobNumber}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Reason For Visit</label>
      <span>{{model.testReasonType}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Purchase Order</label>
      <span>{{model.purchaseOrderNumber}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Provider NPI</label>
      <span>{{model.providerNPI}}</span>
    </div>
  </div>
</div>
