import {EmployerPayorEntryGeneralDto} from "./employer-payor-entry-general-dto";
import {EmployerPayorEntryResponsibilitiesDto} from "./employer-payor-entry-responsibilities-dto";

export class EmployerPayorEntryDto {
  employerPayorId: number;
  employerId: number;
  employerName: string;
  payorId: number;
  payorName: string;
  employerPayorEntryGeneralDto: EmployerPayorEntryGeneralDto = new EmployerPayorEntryGeneralDto();
  employerPayorEntryResponsibilitiesDto: EmployerPayorEntryResponsibilitiesDto = new EmployerPayorEntryResponsibilitiesDto();
}
