import {BillingComposedDocumentPageDto} from "./billing-composed-document-page-dto";

export class BillingComposedDocumentDto {
  billingComposedDocumentId: number;
  financialTransactionId: number;
  authorizationPatientId: number;
  storageLocation: string;

  pages: BillingComposedDocumentPageDto[] = [];
}
