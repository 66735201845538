import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceDetailHistoryDto} from "../../../../models/invoice-detail/invoice-detail-history-dto";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";


@Component({
  selector: 'invoice-detail-history',
  templateUrl: './invoice-detail-history.component.html',
  styleUrls: ['./invoice-detail-history.component.scss']
})
export class InvoiceDetailHistoryComponent implements OnInit {

  model: InvoiceDetailHistoryDto[] = [];
  invoiceId: number;
  invoiceNumberDisplay: string;
  mailParams: Map<string, any> = new Map<string, any>();

  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router) {
  }

  ngOnInit() {
    const params = new HttpParams().set('invoiceId', this.invoiceId.toString());
    this.billingHttpClient.Get<InvoiceDetailHistoryDto[]>("/invoice-detail-history", {params}).subscribe(response => {
      this.model = response;
    });
    this.mailParams.set('invoiceNumberDisplay', this.invoiceNumberDisplay);
  }


  onSelectHistoryItem(adjustmentBatchId: number): void {
    this.router.navigate(['adjustment-batch', "1", adjustmentBatchId,-1,-1]);
  }
}
