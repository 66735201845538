<h1>Invoice Search - {{getSearchType()}}</h1>
<table id="tb-invoices" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>#</th>
    <th>Amount</th>
    <th>Employer</th>
    <th>Payor</th>
    <th>Region</th>
<!--    <th>Branch</th>-->
    <th>Job #</th>
    <th>Purchase Order</th>
    <th *ngIf="!isPending">Reason For Visit</th>
    <th *ngIf="!isPending">Patient</th>
    <th *ngIf="!isPending">Status</th>
    <th *ngIf="!isPending">AP #</th>
    <th *ngIf="isPending">Claim Number</th>
    <th *ngIf="isPending">Fax</th>
    <th *ngIf="isPending">Post</th>
    <th *ngIf="isPending">Failed Email</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="goToInvoice(row.invoiceId)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.invoiceNumberDisplay}}</td>
    <td><displayable-money [model]="row.invoiceAmount"></displayable-money></td>
    <td>{{row.employer}}</td>
    <td>{{row.payor}}</td>
    <td>{{row.region}}</td>
<!--    <td>{{row.branch}}</td>-->
    <td>{{row.jobNumber}}</td>
    <td>{{row.purchaseOrder}}</td>
    <td *ngIf="isPending">{{row.claimNumber}}</td>
    <td>{{row.reasonForVisit}}</td>
    <td>{{row.patientName}}</td>
    <td>{{row.status}}</td>
    <td>{{row.authorizationPatientId}}</td>
  </tr>
  </tbody>
</table>
