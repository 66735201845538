import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChargeCodePriceRow} from "../../../models/charge-code-price-list/charge-code-price-row";
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {ChargeCodePriceUtility} from "../../../utilities/charge-code-price-utility";
import {ChargeCodeLookupSearchRow} from "../../../models/common/charge-code-lookup-search-row";
import {PayorListSearchRow} from "../../../models/common/payor-list-search-row";
import {SearchBranchesComponent} from "../../common/search-branches/search-branches.component";
import {BillingEntityBranchDto} from "../../../models/billing-entity-entry/billing-entity-branch-dto";
import {MatDialog} from "@angular/material/dialog";
import {BillingEntityDto} from "../../../models/billing-entities/billing-entity-dto";
import {CommonResult} from "../../../models/common/common-result";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {AuthenticationService} from "../../../services/authentication.service";
import {DataTablesResponse} from '../../../models/common/data-tables-response';
import {ChargeCodePriceHistoryDto} from '../../../models/charge-code-price/charge-code-price-history-dto';
import {EmployerSearchRow} from '../../../models/employer-list/employer-search-row';
import {EmployerExceptionDto} from '../../../models/charge-code-price/employer-exception-dto';
import {TestReasonLookupSearchRow} from "../../../models/common/test-reason-lookup-search-row";

declare var $: any;

@Component({
  selector: 'app-charge-code-price-view',
  templateUrl: './charge-code-price-view.component.html',
  styleUrls: ['./charge-code-price-view.component.scss']
})
export class ChargeCodePriceViewComponent implements OnInit, OnDestroy {

  private id: number;
  model: ChargeCodePriceRow = new ChargeCodePriceRow();
  private ngUnsubscribe = new Subject();
  billingEntities = [] as BillingEntityDto[];
  originalPrice: number;
  historyDtOptions: any = {};
  exceptionDtOptions: any = {};
  historyModel = [] as ChargeCodePriceHistoryDto[];
  employerExceptions = [] as EmployerExceptionDto[];

  constructor(
    private billingHttpClient: BillingHttpClient,
    private clinicHttpClient: ClinicHttpClient,
    private validationResultsService: ValidationResultsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _service: AuthenticationService,) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
        if (this.id) {
          const params = new HttpParams().set('id', this.id.toString());
          this.billingHttpClient.Get<ChargeCodePriceRow>('/charge-code-price', {params})
            .subscribe(
              response => {
                this.model = response;
                this.originalPrice = this.model.price;
                let chargeCodePrices = [this.model];
                ChargeCodePriceUtility.getChargeCodePriceDetails(this.clinicHttpClient, chargeCodePrices);
              }
            );
        }
      });
    this.route.queryParams
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        let payorId = +params['payorId'];
        if (payorId) {
          this.model.payorId = payorId;
          this.model.payor = params['payorName'];
        }
      });
    this.billingHttpClient.Get<BillingEntityDto[]>("/billing-entities").subscribe(
      val => {
        this.billingEntities = val;
      }
    );
    this.historyDtOptions = {
      paging:false,
      serverSide: true,
      info:false,
      processing: true,
      searching:false,
      columnDefs: [ {
        orderable: false,
        targets: [0,1,2,3,4,5,6,7,8,9,10]
      }],
      columns: [
        {data: 'chargeCode'},
        {data: 'price'},
        {data: 'employer'},
        {data: 'region'},
        {data: 'payor'},
        {data: 'billingEntity'},
        {data: 'branch'},
        {data: 'startDateInclusive'},
        {data: 'endDateInclusive'},
        {data: 'editDate'},
        {data: 'userName'},
      ],
      ajax: (dataTableParams: any, callback) => {
        dataTableParams.chargeCodePriceId = this.id.toString();
        this.billingHttpClient.Post<DataTablesResponse>('/charge-code-price-history-list', dataTableParams)
          .subscribe(a_resp => {
            this.historyModel = a_resp.data;
            callback({
              recordsTotal: a_resp.recordsTotal,
              recordsFiltered: a_resp.recordsFiltered,
              data: []
            });
          });
      }
    };

    this.exceptionDtOptions = {
      paging:false,
      serverSide: true,
      info:false,
      processing: true,
      searching:false,
      columnDefs: [ {
        orderable: false,
        targets: [0,1]
      }],
      columns: [
        {data: ''},
        {data: 'employerName'},
      ],
      ajax: (dataTableParams: any, callback) => {
        dataTableParams.chargeCodePriceId = this.id.toString();
        this.billingHttpClient.Post<DataTablesResponse>('/charge-code-price-employer-exception-list', dataTableParams)
          .subscribe(a_resp => {
            this.employerExceptions = a_resp.data;
            callback({
              recordsTotal: a_resp.recordsTotal,
              recordsFiltered: a_resp.recordsFiltered,
              data: []
            });
          });
      }
    };
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  chargeCodeChange(chargeCodeLookupSearchRow: ChargeCodeLookupSearchRow) {
    this.model.chargeCode = chargeCodeLookupSearchRow.code;
    this.model.description = chargeCodeLookupSearchRow.description;
  }

  clearPayor() {
    this.model.payorId = null;
    this.model.payor = null;
  }

  selectPayor($event: PayorListSearchRow) {
    this.model.payorId = $event.payorId;
    this.model.payor = $event.name;
  }

  openBranchDialog() {
    const dialogRef = this.dialog.open(SearchBranchesComponent, {
      data: new BillingEntityBranchDto()
    });
    dialogRef.afterClosed()
      .subscribe(value => {
        if (value && value.id) {
          this.model.branchId = value.id;
          this.model.branch = value.companyName;
        }
      });
  }

  setEmployer(value: EmployerSearchRow) {
    let payload = {
      chargeCodePriceId: this.id.toString(),
      employerId: value.employerId,
    };
    this.billingHttpClient.Post<CommonResult>('/save-employer-exception', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          var table = $('#tb-employer-exception-list').DataTable();
          table.draw(true);
        }
      });
  }

  deleteEmployerException(chargeCodePriceEmployerExceptionId: number) {
    const params = new HttpParams().set('id', chargeCodePriceEmployerExceptionId.toString());
    this.billingHttpClient.Delete<CommonResult>('/delete-employer-exception', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          var table = $('#tb-employer-exception-list').DataTable();
          table.draw(true);
        } else {
          this.validationResultsService.setSuccessMessages(["delete failed"]);
        }
      });

  }

  clearBranch() {
    this.model.branchId = null;
    this.model.branch = null;
  }

  cancel() {
    window.history.back();
  }

  saveClose() {
    if (this._service.hasRole("ROLE_billing_pricing_admin")) {
      if (this.originalPrice) {
        let diff = this.model.price - this.originalPrice;
        let perc = Math.abs(diff) / this.originalPrice * 100;
        if (perc >= 10) {
          let drasticChangeModal = $('#drasticChangeModal');
          drasticChangeModal.modal('show');
          $('#p-message').text(`You are changing price from ${this.originalPrice} to ${this.model.price}. It has ${diff > 0 ? "increased" : "decreased"} by ${perc}%. Are you sure?`);
        } else {
          this.realSaveAndClose();
        }
      } else {
        this.realSaveAndClose();
      }
    } else {
      alert('You do not have enough permissions to do that')
    }
  }

  realSaveAndClose() {
    this.billingHttpClient.Post<CommonResult>('/charge-code-price', this.model)
      .subscribe(response => {
        if (response.result != 'success') {
          this.validationResultsService.setErrorMessages(response.validationResults);
        } else {
          this.validationResultsService.setSuccessMessages(["Save succeeded"]);
          window.history.back();
        }
      });
  }

  visitReasonChange($event: TestReasonLookupSearchRow) {
    this.model.visitReasonId = $event.testReasonId;
    this.model.visitReason = $event.testReasonCode;
  }

  clearVisitReason() {
    this.model.visitReasonId = null;
    this.model.visitReason = null;
  }
}
