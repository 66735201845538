import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PayorEntryDto} from "../../../../models/payor-entry/payor-entry-dto";

@Component({
  selector: 'app-payor-entry-statement-config',
  templateUrl: './payor-entry-statement-config.component.html',
  styleUrls: ['./payor-entry-statement-config.component.scss']
})
export class PayorEntryStatementConfigComponent implements OnInit {

  @Input() model: PayorEntryDto = new PayorEntryDto();
  @Output() saved = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Output() saveClosed = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.saved.emit();
  }

  cancel() {
    this.canceled.emit();
  }

  saveClose() {
    this.saveClosed.emit();
  }

}
