<h1>Pricing Search</h1>
<table id="tb-charge-code-price-list" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered" style="width: 100%;">
  <thead>
    <tr>
      <th></th>
      <th>Charge Code</th>
      <th>Description</th>
      <th>Price</th>
      <th>Start Date Inclusive</th>
      <th>End Date Inclusive</th>
      <th>Payor</th>
      <th>Branch</th>
      <th>Billing Entity</th>
      <th>Visit Reason</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of model">
      <td width="1%">
        <button type="button" class="btn btn-success btn-edit" (click)="goToPrice(row.id)">
          <i class="fas fa-edit"></i>
        </button>
      </td>
      <td>{{row.chargeCode}}</td>
      <td>{{row.description}}</td>
      <td><displayable-money [model]="row.price"></displayable-money></td>
      <td>{{row.startDateInclusive | date:"MM/dd/yyyy"}}</td>
      <td>{{row.endDateInclusive | date:"MM/dd/yyyy"}}</td>
      <td>{{row.payor}}</td>
      <td>{{row.branch}}</td>
      <td>{{row.billingEntity}}</td>
      <td>{{row.visitReason}}</td>
    </tr>
  </tbody>
</table>
