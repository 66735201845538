import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {PayorEntryDto} from "../../../models/payor-entry/payor-entry-dto";
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {CommonResult} from "../../../models/common/common-result";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'app-payor-entry',
  templateUrl: './payor-entry.component.html',
  styleUrls: ['./payor-entry.component.scss']
})
export class PayorEntryComponent implements OnInit, OnDestroy {

  private id: number;
  private ngUnsubscribe = new Subject();

  pageTitle: string;
  model: PayorEntryDto = new PayorEntryDto();

  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router,
              private validationResultsService: ValidationResultsService,
              private route: ActivatedRoute,
              private _service: AuthenticationService) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
        if (this.id) {
          const params = new HttpParams().set('id', this.id.toString());
          this.billingHttpClient.Get<PayorEntryDto>('/payor', {params})
            .subscribe(
              response => {
                this.model = response;
                this.pageTitle = 'Edit Payor';
              }
            );
        } else {
          this.pageTitle = 'Add Payor';
        }
      });
  }

  onSaveClose() {
    this.save().then(response => {
      if (response.result != 'success') {
        this.validationResultsService.setErrorMessages(response.validationResults);
      } else {
        this.validationResultsService.setSuccessMessages(["Save succeeded"]);
        if (response.validationResults != null && response.validationResults.length > 0) {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
        this.onCancel();
      }
    });
  }

  onSave() {
    this.save().then(response => {
      if (response.result != 'success') {
          this.validationResultsService.setErrorMessages(response.validationResults);
      } else {
        this.validationResultsService.setSuccessMessages(["Save succeeded"]);
        if (response.validationResults != null && response.validationResults.length > 0) {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
        if (this.model.id == null) {
          this.getByNameAndType().then(payor => {
            this.model = payor;
            this.router.navigate(['./', payor.id], {relativeTo: this.route});
          });
        }
      }
    });
  }

  save(): Promise<CommonResult> {
    return this.billingHttpClient.Post<CommonResult>('/payor', this.model).toPromise();
  }

  getByNameAndType(): Promise<PayorEntryDto> {
    return this.billingHttpClient.Get<PayorEntryDto>(`/payor-by-name-and-type?name=${this.model.name}&type=${this.model.payorType}`)
      .toPromise();
  }


  onCancel() {
    this.router.navigate(['payor-list']);
  }

}
