import {Component, Input, OnInit} from '@angular/core';
import {EmployerEntryPayorDto} from "../../../../models/employer-entry/employer-entry-payor-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {PayorContactEntryDto} from "../../../../models/payor-entry/payor-contact-entry-dto";
import {PayorListSearchRow} from "../../../../models/common/payor-list-search-row";
import {EmployerEntryPayorsDto} from "../../../../models/employer-entry/employer-entry-payors-dto";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {AuthenticationService} from "../../../../services/authentication.service";

@Component({
  selector: 'app-employer-entry-payors',
  templateUrl: './employer-entry-payors.component.html',
  styleUrls: ['./employer-entry-payors.component.scss']
})
export class EmployerEntryPayorsComponent implements OnInit {

  @Input() id: number;
  model: EmployerEntryPayorsDto = new EmployerEntryPayorsDto();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private router: Router,
    private validationResultsService: ValidationResultsService,
    private _service: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const params = new HttpParams().set('employerId', this.id.toString());
    this.billingHttpClient.Get<EmployerEntryPayorsDto>('/employer-entry-payors', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  drop(event: CdkDragDrop<PayorContactEntryDto[]>) {
    moveItemInArray(this.model.employerEntryPayorDtos, event.previousIndex, event.currentIndex);
  }

  editEmployerPayor(employerPayorId: number) {
    this.router.navigate(['employer-payor-entry', employerPayorId]);
  }

  selectPayor(payor: PayorListSearchRow) {
    let employerPayorNew: EmployerEntryPayorDto = new EmployerEntryPayorDto;
    employerPayorNew.employerId = this.model.employerId;
    employerPayorNew.payorId = payor.payorId;
    employerPayorNew.chargeCodePayorAssociationMode = 'All';
    employerPayorNew.visitReasonPayorAssociationMode = 'All';
    employerPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    employerPayorNew.payorName = payor.name;
    employerPayorNew.payorType = payor.payorType;
    employerPayorNew.startDateInclusive = new Date(2000, 0, 1);
    employerPayorNew.endDateInclusive = new Date(9999, 11, 31);
    employerPayorNew.active = true;
    this.model.employerEntryPayorDtos.push(employerPayorNew);
  }

  createEmployerPayor(type: string) {
    let employerPayorNew = new EmployerEntryPayorDto();
    employerPayorNew.employerId = this.model.employerId;
    employerPayorNew.chargeCodePayorAssociationMode = 'All';
    employerPayorNew.visitReasonPayorAssociationMode = 'All';
    employerPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    employerPayorNew.payorName = this.model.name;
    employerPayorNew.payorType = type;
    employerPayorNew.ssnDisplayType = "Redact";
    employerPayorNew.billingFrequencyType = "Weekly";
    employerPayorNew.startDateInclusive = new Date(2000, 0, 1);
    employerPayorNew.endDateInclusive = new Date(9999, 11, 31);
    employerPayorNew.active = true;
    this.model.employerEntryPayorDtos.push(employerPayorNew);
  }

  cancel() {
    window.history.back();
  }

  save() {
    // Update rank
    this.model.employerEntryPayorDtos.forEach((v, i) => {
      v.rank = i;
    });
    this.billingHttpClient.Post<CommonResult>('/employer-entry-payors', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeed']);
          this.load();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  get canSave(): boolean {
    return this._service.hasRole("ROLE_billing_employer_admin");
  }
}
