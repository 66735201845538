<div class="row">
  <div class="col-md-6">
    <h3>Notes Printed on Invoice</h3>
    <form>
      <div class="form-group">
        <textarea name="publicTextInput" rows="5" #publicTextInput class="form-control" [ngModel]="publicTextInput.value"></textarea>
        <button type="submit" name="publicClick" class="btn btn-success btn-sm" [disabled]="!publicTextInput.value" (click)="save(publicTextInput.value, $event.target); publicDisplayValue=publicTextInput.value">
          <i class="fa fa-save"></i>
          <span style="margin-left: 5px">Save Note</span>
        </button>
        <span *ngIf="showSaveError" class="small text-danger"><strong style="margin-left: 20px;">Error saving...</strong></span>
      </div>
    </form>
  </div>
  <div class="col-md-6">
    <h3>Internal Notes</h3>
    <form>
      <div class="form-group">
        <textarea name="privateTextInput" rows="5" #privateTextInput class="form-control mb-2" [ngModel]="privateTextInput.value"></textarea>
        <button type="submit" name="privateClick" class="btn btn-success btn-sm" [disabled]="!privateTextInput.value" (click)="save(privateTextInput.value, $event.target); privateDisplayValue=privateTextInput.value">
          <i class="fa fa-save"></i>
          <span style="margin-left: 5px">Save Note</span>
        </button>
      </div>
    </form>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <h5>Public Notes</h5>
    <hr>
    <div>{{publicDisplayValue ? publicDisplayValue : model.notesPrintedOnInvoice}}</div>
  </div>
  <div class="col-md-6">
    <h5>Private Notes</h5>
    <hr>
    <div>{{privateDisplayValue ? privateDisplayValue : model.notesInternal}}</div>
  </div>
</div>
