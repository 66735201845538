<div>
  <ag-grid-angular
    class="ag-theme-alpine grid-size"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaults"
    [floatingFilter]="true"
    (gridReady)="onGridReady($event)"
    (animationQueueEmpty)="gridApi?.sizeColumnsToFit()">
  </ag-grid-angular>
</div>
