<h2>Employer-Payor Charge Code Configurations</h2>
<hr class="divider"/>
<form #form="ngForm">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <h4>Employer:</h4>
        </div>
        <div class="col-md-10">
          <h4>{{model.employerName}}</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <h4>Payor:</h4>
        </div>
        <div class="col-md-10">
          <h4>{{model.payorName}}</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs" role="tablist">

        <li class="nav-item">
          <a class="nav-link active" href="#summary" aria-controls="summary" role="tab" data-toggle="tab"
             aria-selected="true">General</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#responsibilities" aria-controls="responsibilities" role="tab" data-toggle="tab"
             aria-selected="false">Responsibilities</a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" class="tab-pane fade show active" id="summary" aria-labelledby="summary">
          <div>
            <employer-payor-entry-general #generalComponent
                                          [model]="model.employerPayorEntryGeneralDto"></employer-payor-entry-general>
          </div>
        </div>
        <div role="tabpanel" class="tab-pane fade" id="responsibilities" aria-labelledby="responsibilities">
          <div>
            <employer-payor-entry-responsibilities #responsibilitiesComponent
                                                   [model]="model.employerPayorEntryResponsibilitiesDto"
                                                   [employerPayorId]="model.employerPayorId"></employer-payor-entry-responsibilities>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row text-right mt-3">
    <div class="col-md-8"></div>
    <div class="col-md-4">
      <button class="btn btn-warning has-spinner mr-2" (click)="cancel()">
        <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
        <i class="fa fa-times"></i>
        Cancel
      </button>
      <button class="btn btn-danger has-spinner mr-2"
              reallyClick="Are you sure you want to do this?"
              reallyClickAction="delete"
              [reallyClickCaller]="this"
              *ngIf="model.employerPayorId && model.employerPayorId != 0">
        <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
        <i class="fa fa-trash"></i>
        Delete
      </button>
      <button class="btn btn-success has-spinner mr-2" (click)="save()">
        <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
        <i class="fa fa-check-square-o"></i>
        Save
      </button>
      <button class="btn btn-success has-spinner" (click)="saveClose()">
        <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
        <i class="fa fa-check-square-o"></i>
        Save and Close
      </button>
    </div>
  </div>
</form>
