<h1>Employer Search</h1>
<table id="tb-employer-list" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered" style="width: 100%;">
  <thead>
    <tr>
      <th></th>
      <th>Name</th>
      <th>Address</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of model">
      <td class="minWidth">
        <button type="button" class="btn btn-success btn-edit" (click)="goToEmployer(row.employerId)">
          <i class="fas fa-edit"></i>
        </button>
      </td>
      <td>{{row.employerName}}</td>
      <td>{{row.employerAddress}}</td>
    </tr>
  </tbody>
</table>
