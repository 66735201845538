import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BranchPayorEntryGeneralDto} from '../../../../models/branch-payor-entry/branch-payor-entry-general-dto';

@Component({
  selector: 'branch-payor-entry-general',
  templateUrl: './branch-payor-entry-general.component.html',
  styleUrls: ['./branch-payor-entry-general.component.scss']
})
export class BranchPayorEntryGeneralComponent implements OnInit {

  constructor() {
  }

  @Input() model: BranchPayorEntryGeneralDto = new BranchPayorEntryGeneralDto();
  @ViewChild('formGeneral') formGeneral: NgForm;

  ngOnInit() {
  }

}
