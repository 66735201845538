<h1>Search Branches</h1>
<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Name</th>
    <th>Facility Name</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="goToBranch(row)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.name}}</td>
    <td>{{row.facilityName}}</td>
  </tr>
  </tbody>
</table>
