import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {ValidationResultsService} from "./services/validation-results.service";
import {BusyService} from "./services/busy.service";
import {RouterService} from "./services/router.service";
import {environment} from "../environments/environment";
import {ReallyClickService} from "./services/really-click.service";
import {MessageModalService} from "./services/message-modal.service";
import {AuthenticationService} from "./services/authentication.service";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    ValidationResultsService,
    BusyService,
    RouterService,
    ReallyClickService,
    MessageModalService
  ],
})
export class AppComponent implements OnInit {

  title: string;

  constructor(
    private router: Router,
    private busyService: BusyService,
    private routerService: RouterService,
    private _service: AuthenticationService
  ) {
    this.title = 'Prime';
  }

  get isBusy() {
    return this.busyService.isBusy;
  }

  ngOnInit() {
    this.addPolyFills();

    setInterval(() => {
      this.triggerBusyAnimation()
    }, 2000);


    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e) => {
        let eAny = e as any;
        this.routerService.previousUrl = eAny.url;
      });

    $("body").addClass(environment.env);

    if (!this._service.isLoggedIn()) {
      this.login();
    }
  }

  login() {
    this._service.obtainAccessToken();
  }

  at100: boolean = false;

  triggerBusyAnimation() {
    if (this.busyService.isBusy) {
      if (!this.at100) {
        $('.chart').attr("data-percent", "0");
        setTimeout(() => {
          $('.chart').attr("data-percent", "100");
        }, 5);
        this.at100 = true;
      } else {
        $('.chart').attr("data-percent", "100");
        setTimeout(() => {
          $('.chart').attr("data-percent", "0");
        }, 5);
        this.at100 = false;
      }
    }
  }

  addPolyFills() {
    this.addPadEnd();
    this.addPadStart();
  }

  /*String padStart support in IE*/
  addPadStart() {
    if (!String.prototype.padStart) {
      String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
          return String(this);
        } else {
          targetLength = targetLength - this.length;
          if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
          }
          return padString.slice(0, targetLength) + String(this);
        }
      };
    }
  }

  /*String padEnd support in IE*/
  addPadEnd() {
    if (!String.prototype.padEnd) {
      String.prototype.padEnd = function padEnd(targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
          return String(this);
        } else {
          targetLength = targetLength - this.length;
          if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
          }
          return String(this) + padString.slice(0, targetLength);
        }
      };
    }
  }
}
