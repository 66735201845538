import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {CollectionCallDto} from "../../../../models/collection-call-log/collection-call-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {CommonResult} from "../../../../models/common/common-result";
import {ValidationResultsService} from "../../../../services/validation-results.service";

@Component({
  selector: 'collection-call',
  templateUrl: './collection-call.component.html',
  styleUrls: ['./collection-call.component.scss']
})
export class CollectionCallComponent implements OnInit {

  model: CollectionCallDto = new CollectionCallDto();

  constructor(private billingHttpClient: BillingHttpClient,
              private validationResultsService: ValidationResultsService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.load(+params['id']);
    });
  }

  load(payorId: number) {
    const that = this;
    this.billingHttpClient.Get<CollectionCallDto>(`/collection-call/${payorId}`)
      .subscribe(response => {
        that.model = response;
        that.setCallBackDate(5);
      });
  }

  setCallOutcome(outcome: string) {
    this.model.callOutcome = outcome;
  }

  getCallOutcome(): string {
    var result = "";
    if (this.model.callOutcome) {
      if (this.model.callOutcome == 'Answer') {
        result = this.model.callOutcome;
      } else {
        result = "No Answer";
      }
    }
    return result;
  }

  setCallBackDate(days: number) {
    const date = moment().add(days, 'days');
    this.model.dateOccurred = moment().toDate();
    this.model.callbackDate = date.toDate();
  }

  cancel() {
    window.history.back();
  }

  saveAndClose() {
    this.model.offsetInMinutes = moment().utcOffset();

    let payload = {
      payorId: this.model.payorId,
      callOutcome: this.model.callOutcome,
      currentNotes: this.model.currentNotes,
      callbackDate: this.model.callbackDate,
      dateOccurred: this.model.dateOccurred,
      offsetInMinutes: this.model.offsetInMinutes,
    } as CollectionCallDto;


    this.billingHttpClient.Put<CommonResult>('/collection-call', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeed'])
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      })
  }
}
