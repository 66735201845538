import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash'
import { SelectInvoiceDto } from "../../../../models/adjustment-batch/select-invoices/select-invoice-dto";
import { DataTablesResponse } from "../../../../models/common/data-tables-response";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BillingHttpClient } from "../../../../common/billing-http-client.service";
import { AdjustmentBatchDataService } from "../../../../services/adjustment-batch.data.service";
import { ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";

declare var $: any;

@Component({
  selector: 'select-invoices',
  templateUrl: './select-invoices.component.html',
  styleUrls: ['./select-invoices.component.scss']
})
export class SelectInvoicesComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};
  dtDetailOptions: DataTables.Settings = {};
  model = [] as SelectInvoiceDto[];
  detailModel = [] as SelectInvoiceDto[];
  private ngUnsubscribe = new Subject();
  private adjustmentBatchId: number;
  private invoiceId: number;
  editable: boolean = true;
  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;
  searchStr: string = '';
  inputDetailPayor: string = '';
  inputDetailSSN: string = '';
  inputDetailName: string = '';

  constructor(private billingHttpClient: BillingHttpClient,
    public route: ActivatedRoute,
    private dataService: AdjustmentBatchDataService) {
  }

  ngOnInit() {
    if (!this.dataService.initializedSteps.includes(1)) {
      return;
    }
    const that = this;
    $('#input-search').keypress(function (event) {
      const keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == '13') {
        that.search();
      }
    })
    this.editable = this.dataService.editable;
    if (this.editable) {
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 25,
        serverSide: true,
        processing: true,
        stateSave: true,
        searchDelay: 2000,
        columnDefs: [
          {
            targets: 0,
            orderable: true,
            data: 'invoiceId'
          },
          {
            targets: -1,
            orderable: false,
          },
          {
            targets: 9,
            searchable: false,
          }
        ],
        columns: [
          { data: '' },
          { data: 'invoiceNumberDisplay' },
          { data: 'payor' },
          { data: 'startDate' },
          { data: 'endDate' },
          { data: 'amount' },
          { data: 'balance' },
          { data: 'billingEntity' },
          { data: 'patientNames', visible: false },
          { data: 'ssn', visible: false },
        ],
        order: [[0, 'desc']],
        ajax: (dataTablesParameters: any, callback) => {
          if (that.dataService.controlTotalsDto.selectedPayor.payorId) {
            dataTablesParameters.payorId = that.dataService.controlTotalsDto.selectedPayor.payorId
          }
          that.billingHttpClient.Post<DataTablesResponse>("/select-invoices", dataTablesParameters)
            .subscribe(resp => {
              that.model = resp.data;
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: []
              });
            });
        },
        dom: 'ltipr',
      };
    }
    if (this.editable) {
      this.dtDetailOptions = {
        pagingType: "full_numbers",
        pageLength: 25,
        serverSide: false,
        processing: true,
        stateSave: true,
        searchDelay: 2000,
        columnDefs: [
          {
            targets: 0,
            orderable: true,
            data: 'invoiceId'
          },
          {
            targets: -1,
            orderable: false,
          },
          {
            targets: 9,
            searchable: false,
          }
        ],
        columns: [
          { data: '' },
          { data: 'invoiceNumberDisplay' },
          { data: 'payor' },
          { data: 'startDate' },
          { data: 'endDate' },
          { data: 'amount' },
          { data: 'balance' },
          { data: 'billingEntity' },
          { data: 'patientNames', visible: false },
          { data: 'ssn', visible: false },
        ],
        order: [[0, 'desc']],
        dom: 'ltipr',
      };
    }
    this.route.parent.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.adjustmentBatchId = +params['id'];
        this.invoiceId = +params['invoiceId'];
      });
    if (!this.dataService.initializedSteps.includes(2)) {
      this.dataService.selectedInvoices = [] as SelectInvoiceDto[];
      if (this.adjustmentBatchId > 0) {
        this.billingHttpClient.Get<SelectInvoiceDto[]>(`/adjustment-batch-select-invoices/${this.adjustmentBatchId}`, {})
          .subscribe(response => {
            that.dataService.selectedInvoices = response || [];
          });
      }
      if (this.invoiceId > 0) {
        this.billingHttpClient.Get<SelectInvoiceDto>(`/adjustment-batch-select-invoice/${this.invoiceId}`, {})
          .subscribe(response => {
            that.dataService.selectedInvoices.push(response);
          });
      }

      this.dataService.initializedSteps.push(2);
    }
    $('#tb-select-invoices tbody, #tb-sd tbody, #tb-detail-invoices').on('click', 'td.details-control', function () {
      let tr = $(this).closest('tr');
      let invoiceId = parseInt(tr.attr('id'));
      let tdi = tr.find(".details-control i.fa");
      if (tr.hasClass('shown')) {
        tr.next().remove();
        tr.removeClass('shown');
        tdi.first().removeClass('fa-minus-square');
        tdi.first().addClass('fa-plus-square');
      } else {
        // Display details
        let data = that.model.find((i) => i.invoiceId == invoiceId);
        if (data == undefined) {
          data = that.detailModel.find((i) => i.invoiceId == invoiceId);
        }
        if (data == undefined) {
          data = that.selectedInvoices.find(i => i.invoiceId == invoiceId);
        }
        tr.after(that.format(data));
        tr.addClass('shown');
        tdi.first().removeClass('fa-plus-square');
        tdi.first().addClass('fa-minus-square');
      }
    });
  }

  rowCss(invoiceId: number) {
    let selectedInvoices = this.dataService.selectedInvoices;
    let selectedInvoice = selectedInvoices.find((i) => i.invoiceId == invoiceId);
    if (selectedInvoice) {
      return 'row-highlight';
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  selectInvoice = (invoiceId: number) => {
    let selectedInvoices = this.dataService.selectedInvoices;
    let selectedInvoice = selectedInvoices.find((i) => i.invoiceId == invoiceId);
    if (selectedInvoice === undefined) {
      selectedInvoice = this.model.find((i) => i.invoiceId == invoiceId);
      if (selectedInvoice == undefined) {
        selectedInvoice = this.detailModel.find((i) => i.invoiceId == invoiceId);
      }
      selectedInvoices.push(selectedInvoice);
      $(`#${selectedInvoice.invoiceId}`).addClass("row-highlight");
    } else {
      selectedInvoices.splice(selectedInvoices.indexOf(selectedInvoice), 1);
      $(`#${selectedInvoice.invoiceId}`).removeClass("row-highlight");
    }
  };

  get selectedInvoices(): SelectInvoiceDto[] {
    let result = this.dataService.selectedInvoices;
    result = _.orderBy(result, ["invoiceId"], ['desc']);
    return result;
  }

  /* Formatting function for row details - modify as you need */
  format(i: SelectInvoiceDto) {
    // `d` is the original data object for the row
    return '<tr>' +
      '<td colspan="10">' +
      '<table class="w-100">' +
      '<tr>' +
      '<td>Employer:</td>' +
      '<td colspan="9">' + i.employer + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td>Region:</td>' +
      '<td colspan="9">' + i.region + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td>Patient:</td>' +
      '<td colspan="9">' + i.patientNames + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td>Job Number:</td>' +
      '<td colspan="9">' + i.jobNumber + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td>Purchase Order:</td>' +
      '<td colspan="9">' + i.purchaseOrder + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td>Reason For Visit:</td>' +
      '<td colspan="9">' + i.testReasonType + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td>Facility:</td>' +
      '<td colspan="9">' + i.facility + '</td>' +
      '</tr>' +
      '</table>' +
      '</td>' +
      '</tr>';
  }

  search() {
    this.datatableElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          if (dtElement.dtOptions.serverSide) {
            dtInstance.search(this.searchStr)
              .draw();
          }
        }
      );
    })
  }

  invoiceDetailSearch() {
    const that = this;
    this.datatableElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          if (!dtElement.dtOptions.serverSide) {
            if ((this.inputDetailName && this.inputDetailName.length > 3) || 
            (this.inputDetailPayor && this.inputDetailPayor.length > 3) || 
            (this.inputDetailSSN && this.inputDetailSSN.length > 3)) {
              const invoiceDetailLoader = document.getElementById("invoiceDetailLoader");
              invoiceDetailLoader.classList.remove('d-none');
              this.billingHttpClient.Post<SelectInvoiceDto[]>(`/select-invoices-search?payorName=${this.inputDetailPayor}&name=${this.inputDetailName}&ssn=${this.inputDetailSSN}`,{})
                .subscribe(resp => {
                  invoiceDetailLoader.classList.add('d-none');
                  that.detailModel = resp;
                });
            }
          }
        }
      );
    })
  }
}
