import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[invoice-detail-debits-host]'
})
export class InvoiceDetailDebitsDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
