import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {DashboardInvoiceClosedErrorSearchRow} from "../../../models/invoice-closed-errors/dashboard-invoice-closed-error-search-row";
import {CommonResult} from "../../../models/common/common-result";
import {HttpParams} from "@angular/common/http";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {TaskHttpClient} from "../../../common/task-http-client";
import {ValidationResultsService} from "../../../services/validation-results.service";
declare var $: any;

@Component({
  selector: 'app-invoice-closed-errors',
  templateUrl: './invoice-closed-errors.component.html',
  styleUrls: ['./invoice-closed-errors.component.scss']
})
export class InvoiceClosedErrorsComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  model: DashboardInvoiceClosedErrorSearchRow[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  select: number[] = [];


  constructor(private billingHttpClient: BillingHttpClient,
              private taskHttpClient: TaskHttpClient,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      orderCellsTop: true,
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: '12%',
        },
        {
          targets: 1,
          orderable: false,
          width: '12%',
        },
      ],
      columns: [
        {data: ''},
        {data: ''},
        {data: 'financialTransactionErrorStatus'},
        {data: 'payor'},
        {data: 'chargeCode'},
        {data: 'serviceDate'},
        {data: 'quantity'},
        {data: 'jobNumber'},
        {data: 'purchaseOrderNumber'},
        {data: 'patient'},
        {data: 'employer'},
        {data: 'region'},
        {data: 'jobSite'},
      ],
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/invoice-closed-errors", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
    $('#createButton').prop('disabled', true);
    $('#voidButton').prop('disabled', true);
  }

  switchChecks() {
    let checks = $('.checks');
    if (checks.length === 0) {
      return;
    }
    let checkall = $('#checkall');
    for (var i = 0; i < checks.length; i++) {
      checks[i].checked = checkall[0].checked;
    }
    $('#createButton').prop('disabled', !checkall[0].checked);
    $('#voidButton').prop('disabled', !checkall[0].checked);
  }


  // Used by really click action
  createInvoiceAdjustment = async (debit: DashboardInvoiceClosedErrorSearchRow) => {
    const payload = {
      financialTransactionId: debit.financialTransactionId,
    };
    this.taskHttpClient.Post<CommonResult>('/create-invoice-adjustment', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          this.reloadTable();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  // Used by really click action
  voidTransaction = async (debit: DashboardInvoiceClosedErrorSearchRow) => {
    const payload = {
      financialTransactionId: debit.financialTransactionId,
    };
    this.taskHttpClient.Post<CommonResult>('/void-debit', payload)
      .subscribe(resp => {
        if (resp.result === 'success') {
          this.reloadTable();
        } else {
          alert('failed');
        }
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  reloadTable() {
    let checkall = $('#checkall');
      checkall[0].checked = false;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  createSelect(){
    this.select = [];
    let checks = $('.checks');
    for (var j = 0; j < checks.length; j++) {
      for (var i = 0; i < this.model.length; i++) {
        if (checks[j].checked === true && (checks[j].id === this.model[i].financialTransactionId.toString())) {
          this.select.push(this.model[i].financialTransactionId);
          break;
        }
      }
    }
    const payload = {
      financialTransactionIds: this.select,
    };
    this.taskHttpClient.Post<CommonResult>('/create-invoice-adjustments', payload)
      .subscribe(response => {
        //Maybe some financial transactions succeed and some fail
        if (response.result == 'success') {
          this.reloadTable();
        }
        if (response.validationResults.length > 0){
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  voidSelect(){
    this.select = [];
    let checks = $('.checks');
    for (var j = 0; j < checks.length; j++) {
      for (var i = 0; i < this.model.length; i++) {
        if (checks[j].checked === true && (checks[j].id === this.model[i].financialTransactionId.toString())) {
          this.select.push(this.model[i].financialTransactionId);
          break;
        }
      }
    }
    const payload = {
      financialTransactionIds: this.select,
    };
    this.taskHttpClient.Post<CommonResult>('/void-error-debits', payload)
      .subscribe(resp => {
        if (resp.result === 'success') {
          this.reloadTable();
        } else {
          alert('failed');
        }
      });
  }

  applySelect(check:boolean) {
    let checks = $('.checks');
    if (checks.length === 0) {
      return;
    }
    let createButton = $('#createButton');
    let voidButton = $('#voidButton');
    for (var i = 0; i < checks.length; i++) {
      if (checks[i].checked){
        createButton.prop('disabled', false);
        voidButton.prop('disabled', false);
        return;
      }
    }
    createButton.prop('disabled', true);
    voidButton.prop('disabled', true);

  }
}
