<h1>Branch</h1>
<div class="row">
  <div class="col-md-12">
    <ul id="child-tabs" class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" href="#general" aria-controls="general" role="tab" data-toggle="tab"
           aria-selected="true">General</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#branch" aria-controls="branch" role="tab" data-toggle="tab"
           aria-selected="false">Payor</a>
      </li>
    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane fade show active" id="general" aria-labelledby="general-tab">
        <app-branch-entry-general [id]="id"></app-branch-entry-general>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="branch" aria-labelledby="payors-tab">
        <app-branch-entry-payors [id]="id"></app-branch-entry-payors>
      </div>
    </div>
  </div>
</div>
