import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {BranchEntryDto} from "../../../../models/branch-entry/branch-entry-dto";
import {State, StateList} from "../../../../utilities/state-list";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {ActivatedRoute} from "@angular/router";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-branch-entry-general',
  templateUrl: './branch-entry-general.component.html',
  styleUrls: ['./branch-entry-general.component.scss']
})
export class BranchEntryGeneralComponent implements OnInit, OnDestroy {

  @Input() id: number;
  private ngUnsubscribe = new Subject();
  model: BranchEntryDto = new BranchEntryDto();
  stateList: State[] = StateList.STATES;

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private route: ActivatedRoute,
    private validationResultsService: ValidationResultsService) {
  }

  ngOnInit() {
    const params = new HttpParams().set('id', this.id.toString());
    this.clinicHttpClient.Get<BranchEntryDto>('/branch-entry', {params})
      .subscribe(response => {
        this.model = response;
      })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    this.clinicHttpClient.Post<CommonResult>('/branch-entry', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save success']);
        } else {
          alert('Save failed');
        }
      });
  }

  saveClose() {
    this.save();
    this.cancel();
  }

  cancel() {
    window.history.back();
  }

  @ViewChild('form') form: NgForm;

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

}
