import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {AdjustmentDto} from "../../../../../../models/adjustment-batch/apply-adjustments/apply-invoices-adjustments/adjustments/adjustment-dto";

@Component({
  selector: 'adjustments',
  templateUrl: './adjustments.component.html',
  styleUrls: ['./adjustments.component.scss']
})
export class AdjustmentsComponent implements OnInit {

  @Input() model: AdjustmentDto[];
  @Input() disabled: boolean;
  @Output() adjustmentRemoved: EventEmitter<AdjustmentDto> = new EventEmitter<AdjustmentDto>();

  constructor() { }

  ngOnInit() {
  }

  removeAdjustment(adjustment: AdjustmentDto) {
    this.adjustmentRemoved.emit(adjustment);
  }
}
