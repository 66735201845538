import {AdjustmentBatchDataService} from "../../../../services/adjustment-batch.data.service";
import {Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {ActivatedRoute} from "@angular/router";
import {ControlTotalsDto} from "../../../../models/adjustment-batch/control-totals/control-totals-dto";
import {PayorOption} from "../../../../models/common/payor-option";
import {DecimalUtility} from "../../../../utilities/decimal-utility";
import {HttpParams} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'control-totals',
  templateUrl: './control-totals.component.html',
  styleUrls: ['./control-totals.component.scss']
})
export class ControlTotalsComponent implements OnInit, OnDestroy {

  private adjustmentBatchId: number;
  private ngUnsubscribe = new Subject();
  controlTotalsDto: ControlTotalsDto = new ControlTotalsDto();
  payorId: number;

  subTotalsValidationMessage: string = null;
  subTotalDifference: number = 0;
  currentSelectedPayor: PayorOption;
  payorOptions: PayorOption[] = [];
  payorChangeWarningShown = false;
  editable: boolean = true;

  batchDate: Date;

  constructor(private billingHttpClient: BillingHttpClient,
              private route: ActivatedRoute,
              private dataService: AdjustmentBatchDataService) {
  }

  ngOnInit(): void {
    const that = this;
    this.route.parent.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.adjustmentBatchId = +params['id'];
        this.payorId = +params['payorId'];
      });
    if (!this.dataService.initializedSteps.includes(1)) {
      let params = new HttpParams().set('adjustmentBatchId', this.adjustmentBatchId.toString());
      this.billingHttpClient.Get<ControlTotalsDto>(`/adjustment-batch-control-totals`, {params})
        .subscribe(response => {
          that.dataService.controlTotalsDto = response;
          that.batchDate = response.date;
          if (that.batchDate == null) {
            that.batchDate = new Date();
            that.dataService.controlTotalsDto.date = that.batchDate;
          }
          that.controlTotalsDto = that.dataService.controlTotalsDto;
          if (that.controlTotalsDto.selectedPayor) {
            that.currentSelectedPayor = _.cloneDeep(that.controlTotalsDto.selectedPayor);
          }
          that.editable = that.dataService.editable;
          that.setSubTotalsValidationMessage();
        });
      this.billingHttpClient.Get<PayorOption[]>("/payor-options")
        .subscribe(response => {
          that.dataService.payorOptions = response;
          that.payorOptions = that.dataService.payorOptions;
          if (that.payorId > 0 && that.payorOptions.filter(a => a.payorId == that.payorId).length > 0){
            that.currentSelectedPayor = _.cloneDeep(that.payorOptions.filter(a => a.payorId == that.payorId)[0]);
            that.controlTotalsDto.selectedPayor = that.currentSelectedPayor
          }
        });
      this.dataService.adjustmentBatchId = this.adjustmentBatchId;
      this.dataService.initializedSteps.push(1);
    } else {
      this.editable = that.dataService.editable;
      this.controlTotalsDto = this.dataService.controlTotalsDto;
      this.batchDate = this.dataService.controlTotalsDto.date;
      if (this.controlTotalsDto.selectedPayor) {
        this.currentSelectedPayor = _.cloneDeep(that.controlTotalsDto.selectedPayor);
      }
      this.payorOptions = this.dataService.payorOptions;
      this.setSubTotalsValidationMessage();
    }
    this.lockAdjustmentBatch()
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onBatchDateChange(event) {
    this.dataService.controlTotalsDto.setDate(event.value);
  }

  setSubTotalsValidationMessage() {
    let result = "";
    this.subTotalDifference = null;
    if (DecimalUtility.countDecimals(this.controlTotalsDto.total) > 2) {
      result = "- *Total have too many decimals";
    } else if (this.anySubtotalsHaveMoreThanTwoDecimals()) {
      result = "- *One or more subtotals have too many decimals";
    } else if (this.subtotalsDontEqualBatchTotal() != 0) {
      this.subTotalDifference = this.subtotalsDontEqualBatchTotal();
      result = `- *Subtotals do not equal the batch total. Difference:`;
    }
    this.subTotalsValidationMessage = result;
  }

  anySubtotalsHaveMoreThanTwoDecimals(): boolean {
    return _.some(this.controlTotalsDto.subtotals, (value) => {
      return DecimalUtility.countDecimals(value.amount) > 2;
    });
  }

  hasDifference() {
    return !isNaN(this.subTotalDifference) && this.subTotalDifference != 0;
  }

  subtotalsDontEqualBatchTotal(): number {
    let result: number;
    const sum =
      _.sumBy(this.controlTotalsDto.subtotals, (value) => {
        return this.getTotalValue(value.amount);
      });
    result = this.getTotalValue(this.controlTotalsDto.total) - sum;
    result = Math.round(result * 100) / 100;
    return result;
  }

  getTotalValue(value: number): number {
    let result = 0;
    if (value != null && value.toString() != "") {
      result = parseFloat(value.toString());
    }
    return result;
  }

  onPayorChange() {
    if (this.payorChangeWarningShown || (isNaN(this.adjustmentBatchId) || this.adjustmentBatchId <= 0) || this.currentSelectedPayor == null) {
      this.changePayor();
    } else {
      this.showChangePayorModal();
      this.payorChangeWarningShown = true;
    }
  }

  showChangePayorModal() {
    $('#changePayorModal').modal('show');
  }

  changePayor() {
    this.currentSelectedPayor = _.cloneDeep(this.controlTotalsDto.selectedPayor);
  }

  revertPayor() {
    this.controlTotalsDto.selectedPayor = this.currentSelectedPayor;
  }

  lockAdjustmentBatch(){
    if (!(isNaN(this.adjustmentBatchId) || this.adjustmentBatchId <= 0)){
      let params = new HttpParams().set('adjustmentBatchId', this.adjustmentBatchId.toString());
      this.billingHttpClient.Get('/lock-adjustment-batch', {params})
        .subscribe(_ => {});
    }

  }
}
