import { Component, OnInit } from '@angular/core';
import { MessageModalService } from '../../../services/message-modal.service';

@Component({
  selector: 'message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {

  constructor(public messageModalService: MessageModalService) { }

  ngOnInit() {
  }

  get message(): string {
    return this.messageModalService.message;
  }

  get title(): string {
    return this.messageModalService.title;
  }

  get icon(): string {
    return this.messageModalService.icon;
  }

  closeMessageModal() {
    this.messageModalService.resetModal();
  }
}
