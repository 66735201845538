import {Component, Input, OnInit} from '@angular/core';
import {State, StateList} from "../../../../utilities/state-list";
import {CommonResult} from "../../../../models/common/common-result";
import {EmployerEntryResultsDto} from "../../../../models/employer-entry/employer-entry-results-dto";
import {EmployerEntryResultEmailDto} from "../../../../models/employer-entry/employer-entry-result-email-dto";
import {HttpParams} from "@angular/common/http";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {EmployerEntryResultFaxDto} from "../../../../models/employer-entry/employer-entry-result-fax-dto";
import {EmployerEntryResultAddressDto} from "../../../../models/employer-entry/employer-entry-result-address-dto";

declare var $: any;

@Component({
  selector: 'app-employer-entry-results',
  templateUrl: './employer-entry-results.component.html',
  styleUrls: ['./employer-entry-results.component.scss']
})
export class EmployerEntryResultsComponent implements OnInit {

  @Input() id: number;
  model: EmployerEntryResultsDto = new EmployerEntryResultsDto();
  stateList: State[] = StateList.STATES;

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private validationResultsService: ValidationResultsService,
  ) {
  }

  ngOnInit() {
    const params = new HttpParams().set('employerId', this.id.toString());
    this.clinicHttpClient.Get<EmployerEntryResultsDto>('/employer-entry-results', {params})
      .subscribe(response => {
        this.model = response;
      });
    $('#editEmail').on('shown.bs.modal', function () {
      $('#editEmail_Description').focus();
    });
    $('#editFaxNumber').on('shown.bs.modal', function () {
      $('#editFaxNumber_Description').focus();
    });
    $('#editAddress').on('shown.bs.modal', function () {
      $('#editAddress_Description').focus();
    });
  }

  emailBeingEdited: EmployerEntryResultEmailDto = new EmployerEntryResultEmailDto();

  editEmail(dto?: EmployerEntryResultEmailDto) {
    $('#editEmail').modal('show');
    if (dto) {
      this.emailBeingEdited = {
        employerDetailResultEmailId: dto.employerDetailResultEmailId,
        employerDetailId: this.id,
        description: dto.description,
        emailAddress: dto.emailAddress,
      };
    } else {
      this.emailBeingEdited = {
        employerDetailResultEmailId: null,
        employerDetailId: this.id,
        description: "",
        emailAddress: "",
      };
    }
  }

  saveEmailModal() {
    let errorMessages = [];
    if (this.emailBeingEdited.emailAddress.length > 100) {
      errorMessages.push('Email Address cannot exceed 100 characters');
    }
    if (this.emailBeingEdited.description.length > 100) {
      errorMessages.push('Description cannot exceed 100 characters');
    }
    if (errorMessages.length > 0) {
      this.validationResultsService.setErrorMessages(errorMessages);
      return;
    }

    if (this.emailBeingEdited.employerDetailResultEmailId != null) {
      let index = this.model.employerEntryResultEmailDtos.findIndex(e => e.employerDetailResultEmailId == this.emailBeingEdited.employerDetailResultEmailId);
      this.model.employerEntryResultEmailDtos[index] = this.emailBeingEdited;
    } else {
      this.model.employerEntryResultEmailDtos.push(this.emailBeingEdited);
    }
    this.closeEmailModal();
  }

  closeEmailModal() {
    $('#editEmail').modal('hide');
  }

  // Used by really click envent
  deleteEmail(itemToDelete: EmployerEntryResultEmailDto) {
    this.model.employerEntryResultEmailDtos
      .splice(this.model.employerEntryResultEmailDtos
        .findIndex(e => e.employerDetailResultEmailId ==
          itemToDelete.employerDetailResultEmailId), 1)
  }

  faxNumberBeingEdited: EmployerEntryResultFaxDto = new EmployerEntryResultFaxDto();

  editFaxNumber(dto?: EmployerEntryResultFaxDto) {
    $('#editFaxNumber').modal('show');
    if (dto) {
      this.faxNumberBeingEdited = {
        employerDetailResultFaxId: dto.employerDetailResultFaxId,
        employerDetailId: this.id,
        description: dto.description,
        faxNumber: dto.faxNumber,
      };
    } else {
      this.faxNumberBeingEdited = {
        employerDetailResultFaxId: null,
        employerDetailId: this.id,
        description: "",
        faxNumber: "",
      }
    }
  }

  saveFaxNumberModal() {
    let errorMessages = [];
    if (this.faxNumberBeingEdited.faxNumber.length > 12) {
      errorMessages.push('Fax number cannot exceed 12 characters');
    }
    if (this.faxNumberBeingEdited.description.length > 100) {
      errorMessages.push('Description cannot exceed 100 characters');
    }
    if (errorMessages.length > 0) {
      this.validationResultsService.setErrorMessages(errorMessages);
      return;
    }

    if (this.faxNumberBeingEdited.employerDetailResultFaxId != null) {
      let index = this.model.employerEntryResultFaxDtos.findIndex(f => f.employerDetailResultFaxId == this.faxNumberBeingEdited.employerDetailResultFaxId);
      this.model.employerEntryResultFaxDtos[index] = this.faxNumberBeingEdited;
    } else {
      this.model.employerEntryResultFaxDtos.push(this.faxNumberBeingEdited);
    }
    this.closeFaxNumberModal();
  }

  closeFaxNumberModal() {
    $('#editFaxNumber').modal('hide');
  }

  //Used by really click
  deleteFaxNumber(itemToDelete: EmployerEntryResultFaxDto) {
    this.model.employerEntryResultFaxDtos
      .splice(this.model.employerEntryResultFaxDtos
        .findIndex(e => e.employerDetailResultFaxId == itemToDelete.employerDetailResultFaxId), 1)
  }

  addressBeingEdited: EmployerEntryResultAddressDto = new EmployerEntryResultAddressDto();

  editAddress(dto?: EmployerEntryResultAddressDto) {
    $('#editAddress').modal('show');
    if (dto) {
      this.addressBeingEdited = {
        employerDetailResultAddressId: dto.employerDetailResultAddressId,
        employerDetailId: this.id,
        description: dto.description,
        address1: dto.address1,
        address2: dto.address2,
        city: dto.city,
        state: dto.state,
        postalCode: dto.postalCode,
      };
    } else {
      this.addressBeingEdited = {
        employerDetailResultAddressId: null,
        employerDetailId: this.id,
        description: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
      };
    }
  }

  saveAddressModal() {
    let errorMessages = [];
    if (this.addressBeingEdited.address1.length > 100) {
      errorMessages.push('Address 1 cannot exceed 100 characters');
    }
    if (this.addressBeingEdited.address2?.length > 100 ?? false) {
      errorMessages.push('Address 2 cannot exceed 100 characters');
    }
    if (this.addressBeingEdited.city.length > 100) {
      errorMessages.push('City cannot exceed 100 characters');
    }
    if (this.addressBeingEdited.postalCode.length > 9) {
      errorMessages.push('Postal code cannot exceed 9 characters');
    }
    if (isNaN(Number(this.addressBeingEdited.postalCode))) {
      errorMessages.push('Invalid postal code');
    }
    if (this.addressBeingEdited.state.length > 2) {
      errorMessages.push('State cannot exceed 2 characters');
    }
    if (this.addressBeingEdited.description.length > 100) {
      errorMessages.push('Description cannot exceed 100 characters');
    }
    if (errorMessages.length > 0) {
      this.validationResultsService.setErrorMessages(errorMessages);
      return;
    }

    if (this.addressBeingEdited.employerDetailResultAddressId != null) {
      let index = this.model.employerEntryResultAddressDtos
        .findIndex(f => f.employerDetailResultAddressId ==
          this.addressBeingEdited.employerDetailResultAddressId);
      this.model.employerEntryResultAddressDtos[index] = this.addressBeingEdited;
    } else {
      this.model.employerEntryResultAddressDtos.push(this.addressBeingEdited);
    }
    this.closeAddressModal();
  }

  closeAddressModal() {
    $('#editAddress').modal('hide');
  }

  //User by really click
  deleteAddress(itemToDelete: EmployerEntryResultAddressDto) {
    this.model.employerEntryResultAddressDtos
      .splice(this.model.employerEntryResultAddressDtos
        .findIndex(e => e.employerDetailResultAddressId == itemToDelete.employerDetailResultAddressId), 1)
  }

  saveClose() {
    this.save();
    this.cancel();
  }

  save() {
    this.clinicHttpClient.Post<CommonResult>('/employer-entry-results', this.model)
      .subscribe(response => {
        if (response.result != 'success') {
          this.validationResultsService.setErrorMessages(response.validationResults);
        } else {
          this.validationResultsService.setSuccessMessages(["Save succeeded"]);
        }
      })
  }

  cancel() {
    window.history.back();
  }
}
