import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {AdjustmentBatchDataService} from "../../../../services/adjustment-batch.data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ApplyAdjustmentInvoiceDto} from "../../../../models/adjustment-batch/apply-adjustments/apply-adjustment-invoice-dto";
import {ActivatedRoute} from "@angular/router";
import {MessageModalService} from "../../../../services/message-modal.service";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {ApplyAdjustmentDebitDto} from "../../../../models/adjustment-batch/apply-adjustments/apply-adjustment-debit-dto";
import {AdjustmentDto} from "../../../../models/adjustment-batch/apply-adjustments/apply-invoices-adjustments/adjustments/adjustment-dto";
import {ApplyAdjustmentControlTotal} from "../../../../models/adjustment-batch/apply-adjustments/apply-adjustment-control-total";

@Component({
  selector: 'apply-adjustments',
  templateUrl: './apply-adjustments.component.html',
  styleUrls: ['./apply-adjustments.component.scss']
})
export class ApplyAdjustmentsComponent implements OnInit, OnDestroy {

  private adjustmentBatchId: number;
  private ngUnsubscribe = new Subject();
  financialTransactionTypeOptions: string[] = [];
  controlTotalType: string = null;
  model: ApplyAdjustmentInvoiceDto[] = [];
  editable: boolean = true;
  @ViewChild('invoices') invoices;
  controlTotals: ApplyAdjustmentControlTotal[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private route: ActivatedRoute,
              private messageModalService: MessageModalService,
              private dataService: AdjustmentBatchDataService) {
  }

  ngOnInit() {
    if (!this.dataService.initializedSteps.includes(2)) {
      return;
    }
    const that = this;
    this.editable = this.dataService.editable;
    this.route.parent.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.adjustmentBatchId = +params['id'];
      });
    this.model = this.dataService.applyAdjustmentInvoices;
    let selectedInvoiceIds = that.dataService.selectedInvoices.map(i => i.invoiceId);
    if (!this.dataService.initializedSteps.includes(3)) {
      // First time to apply adjustments tab
      if (this.adjustmentBatchId > 0) {
        this.billingHttpClient.Get<ApplyAdjustmentInvoiceDto[]>(`/adjustment-batch-apply-adjustments/adjustment-batch-id=${this.adjustmentBatchId}`, {})
          .subscribe(response => {
            that.addApplyAdjustmentInvoices(response);
            that.syncSelectedInvoices(selectedInvoiceIds);
          });
      } else {
        this.syncSelectedInvoices(selectedInvoiceIds);
      }
      this.dataService.initializedSteps.push(3);
    } else {
      this.syncSelectedInvoices(selectedInvoiceIds);
    }
  }

  syncSelectedInvoices(selectedInvoiceIds: number[]) {
    let existingApplyAdjustmentInvoiceIds = this.dataService.applyAdjustmentInvoices.map(i => i.invoiceId);
    let noLongerSelectedIds = existingApplyAdjustmentInvoiceIds.filter(i => !selectedInvoiceIds.includes(i)) || [];
    this.removeNonSelectedInvoices(noLongerSelectedIds);
    this.removeNonExistTransactionTypes();
    let newInvoiceIds = selectedInvoiceIds.filter(i => !existingApplyAdjustmentInvoiceIds.includes(i)) || [];
    if (newInvoiceIds.length > 0) {
      let idStr = newInvoiceIds.join(",");
      this.billingHttpClient.Get<ApplyAdjustmentInvoiceDto[]>(`/adjustment-batch-apply-adjustments/invoice-ids=${idStr}`, {})
        .subscribe(response => {
          this.addApplyAdjustmentInvoices(response);
          this.model = this.dataService.applyAdjustmentInvoices;
          this.invoices.modelUpdatedCallback();
          this.updateControlTotalsAndInvoices();
        });
    } else {
      if (this.invoices !== undefined) {
        this.invoices.modelUpdatedCallback();
      }
      this.updateControlTotalsAndInvoices();
    }
    this.financialTransactionTypeOptions = this.dataService.financialTransactionTypeOptions();
  }


  updateControlTotalsAndInvoices() {
    const that = this;
    this.controlTotals = this.dataService.applyAdjustmentControlTotals();
    let update;
    _.forEach(this.model, i => {
      _.forEach(i.adjustments, adjustment => {
        update = new ControlTotalUpdate();
        update.financialTransactionType = adjustment.financialTransactionType;
        update.value = adjustment.amount;
        that.updateControlTotal(update);
      });
      _.forEach(i.debits, debit => {
        _.forEach(debit.adjustments, adjustment => {
          update = new ControlTotalUpdate();
          update.financialTransactionType = adjustment.financialTransactionType;
          update.value = adjustment.amount;
          that.updateControlTotal(update);
        });
      })
    });
  }

  addApplyAdjustmentInvoices(applyAdjustmentInvoices: ApplyAdjustmentInvoiceDto[]) {
    let curApplyAdjustmentInvoices = this.dataService.applyAdjustmentInvoices;
    _.forEach(applyAdjustmentInvoices, invoice => {
      if (!curApplyAdjustmentInvoices.find(i => i.invoiceId == invoice.invoiceId)) {
        curApplyAdjustmentInvoices.push(invoice);
      }
    });
    this.sortApplyAdjustmentInvoices();
  }

  sortApplyAdjustmentInvoices() {
    let curApplyAdjustmentInvoices = this.dataService.applyAdjustmentInvoices;
    if (curApplyAdjustmentInvoices.length > 0) {
      curApplyAdjustmentInvoices.sort(function (a, b) {
        try {
          if (Date.parse(a.startDate) === Date.parse(b.startDate)) {
            return Date.parse(a.endDate) - Date.parse(b.endDate);
          }
          return Date.parse(a.startDate) - Date.parse(b.startDate);
        } catch (err) {
          console.log(err);
          return -1;
        }
      });
    }
  }

  removeNonExistTransactionTypes() {
    let applyAdjustmentInvoices = this.dataService.applyAdjustmentInvoices;
    let controlTotals = this.dataService.applyAdjustmentControlTotals();
    const types = controlTotals.map(c => c.financialTransactionType);
    _.forEach(applyAdjustmentInvoices, i => {
      i.adjustments = i.adjustments.filter(a => types.includes(a.financialTransactionType));
      _.forEach(i.debits, debit => {
        if (debit.adjustments != null) {
          debit.adjustments = debit.adjustments.filter(a => types.includes(a.financialTransactionType));
        }
      })
    });
  }

  removeNonSelectedInvoices(invoiceIds) {
    let applyAdjustmentInvoices = this.dataService.applyAdjustmentInvoices;
    _.forEach(invoiceIds, (id) => {
      applyAdjustmentInvoices
        .splice(applyAdjustmentInvoices
          .indexOf(applyAdjustmentInvoices
            .find(a => a.invoiceId === id)), 1);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateControlTotal(update: ControlTotalUpdate) {
    let controlTotal = _.find(this.controlTotals, (value) => {
      return value.financialTransactionType == update.financialTransactionType;
    });
    let modifiedExistingValue = parseFloat((controlTotal.remaining * 100).toFixed(0));
    let modifiedValue = parseFloat((update.value * 100).toFixed(0));
    controlTotal.remaining = (modifiedExistingValue + modifiedValue) / 100;
  }

  applyControlTotalToAllInvoices() {
    let controlTotal = this.getControlTotal(this.controlTotalType);
    const [nMap, pMap] = this.model.reduce((result, invoice) => {
      const [negative, positive] = invoice.debits
        .reduce((res, element) => {
          res[element.updatedBalance < 0 ? 0 : 1].push(element);
          return res
        }, [[], []]);
      result[0].set(invoice, negative);
      result[1].set(invoice, positive);
      return result;
    }, [new Map(), new Map()]);
    nMap.forEach((debits, invoice, _) => {
      debits.forEach((debit) => {
        this.applyControlTotalToDebit(controlTotal, invoice, debit);
      });
    });
    pMap.forEach((debits, invoice, _) => {
      debits.forEach((debit) => {
        this.applyControlTotalToDebit(controlTotal, invoice, debit);
      });
    });
  }

  getControlTotal(controlTotalTypeOption: string): ApplyAdjustmentControlTotal {
    return _.find(this.controlTotals, (controlTotal) => {
      return controlTotal.financialTransactionType == controlTotalTypeOption;
    });
  }

  handleSmartApplyUpdate(update: SmartApplyAdjustmentUpdate) {
    let controlTotal = this.getControlTotal(update.option);
    switch (update.type) {
      case SmartApplyType.debit:
        this.applyControlTotalToDebit(controlTotal, update.invoice, update.debit);
        break;
      case SmartApplyType.invoice:
        this.applyControlTotalToInvoiceAdjustments(controlTotal, update.invoice);
        break;
      case SmartApplyType.invoiceDebits:
        this.applyControlTotalToAllInvoiceDebits(controlTotal, update.invoice);
        break;
      default:
    }
  }

  applyControlTotalToDebit(controlTotal: ApplyAdjustmentControlTotal, invoice: ApplyAdjustmentInvoiceDto, debit: ApplyAdjustmentDebitDto) {
    let valueToApply = 0;
    if (debit.updatedBalance < 0) {
      valueToApply = -debit.updatedBalance;
    } else {
      if (debit.updatedBalance > 0) {
        if (controlTotal.remaining <= 0) {
          this.messageModalService.showModal(`Control total ${controlTotal.financialTransactionType} has ${controlTotal.remaining} dollars remaining`, null, "Control Total Balance");
          return;
        } else {
          if (controlTotal.remaining >= debit.updatedBalance) {
            valueToApply = -debit.updatedBalance;
          } else {
            valueToApply = -controlTotal.remaining;
          }
        }
      }
    }
    if (valueToApply != 0) {
      let adjustment: AdjustmentDto = {
        id: null,
        adjustmentBatchInvoiceId: null,
        amount: valueToApply,
        financialTransactionType: controlTotal.financialTransactionType
      };
      this.invoices.addDebitAdjustment(adjustment, invoice, debit);
    }
  }

  applyControlTotalToInvoiceAdjustments(controlTotal: ApplyAdjustmentControlTotal, invoice: ApplyAdjustmentInvoiceDto) {
    let valueToApply = 0;
    if (invoice.updatedBalance < 0) {
      valueToApply = -invoice.updatedBalance;
    } else {
      if (invoice.updatedBalance > 0) {
        if (controlTotal.remaining <= 0) {
          this.messageModalService.showModal(`Control total ${controlTotal.financialTransactionType} has ${controlTotal.remaining} dollars remaining`, null, "Control Total Balance");
          return;
        } else {
          if (controlTotal.remaining >= invoice.updatedBalance) {
            valueToApply = -invoice.updatedBalance;
          } else {
            valueToApply = -controlTotal.remaining;
          }
        }
      }
    }
    if (valueToApply != 0) {
      let adjustment: AdjustmentDto = {
        id: null,
        adjustmentBatchInvoiceId: null,
        amount: valueToApply,
        financialTransactionType: controlTotal.financialTransactionType
      };
      this.invoices.addInvoiceAdjustment(adjustment, invoice);
    }
  }

  applyControlTotalToAllInvoiceDebits(controlTotal: ApplyAdjustmentControlTotal, invoice: ApplyAdjustmentInvoiceDto) {
    if (invoice && invoice.debits) {
      const [negative, positive] = invoice.debits
        .reduce((result, element) => {
          result[element.updatedBalance < 0 ? 0 : 1].push(element);
          return result;
        }, [[], []]);
      _.forEach(negative, (debit) => {
        this.applyControlTotalToDebit(controlTotal, invoice, debit);
      });
      _.forEach(positive, (debit) => {
        this.applyControlTotalToDebit(controlTotal, invoice, debit);
      });
    }
  }

  controlTotalTypeChange() {
    this.invoices.updateAdjustmentCreatorSelections(this.controlTotalType);
  }
}

export class ControlTotalUpdate {
  financialTransactionType: string;
  value: number;
}

export class SmartApplyAdjustmentUpdate {
  type: SmartApplyType;
  option: string;
  invoice: ApplyAdjustmentInvoiceDto;
  debit: ApplyAdjustmentDebitDto;
}

export enum SmartApplyType {
  invoiceDebits = 0,
  invoice = 1,
  debit = 2
}
