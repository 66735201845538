<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
       class="table borderless">
  <thead></thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td [hidden]="true">{{getDebitHeaderValue(row)}}</td>
    <td>
      <div class="row">
        <div class="col-md-10">
          <h4>{{getDebitHeaderValue(row)}}</h4>
        </div>
        <div class="col-md-2">
          <h4>Balance:
            <displayable-money [model]="row.balance"></displayable-money>
          </h4>
        </div>
      </div>
      <table class="table table-bordered table-striped table-hover">
        <thead>
        <tr>
          <th>Service Date</th>
          <th>Posted Date</th>
          <th>Description</th>
          <th>Type</th>
          <th>Quantity</th>
          <th>Debits</th>
          <th>Credits</th>
          <th>Balance</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of row.ledgerItems">
          <td>
            <span>{{item.serviceDate | date:"MM/dd/yyyy"}}</span>
          </td>
          <td>
            <span>{{item.postedDate | date:"MM/dd/yyyy"}}</span>
          </td>
          <td>
            <span>{{item.description}}</span>
          </td>
          <td>
            <span>{{item.transactionType}}</span>
          </td>
          <td>
            <span>{{item.quantity}}</span>
          </td>
          <td>
            <span><displayable-money [model]="item.debit"></displayable-money></span>
          </td>
          <td>
            <span><displayable-money [model]="item.credit"></displayable-money></span>
          </td>
          <td>
            <span><displayable-money [model]="item.balance"></displayable-money></span>
          </td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
  </tbody>
</table>
