import {Component, Input, OnInit} from '@angular/core';
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";
import {RegionEntryGeneralDto} from "../../../../models/region-entry/region-entry-general-dto";
import {State, StateList} from "../../../../utilities/state-list";
import {AuthenticationService} from "../../../../services/authentication.service";

@Component({
  selector: 'app-region-entry-general',
  templateUrl: './region-entry-general.component.html',
  styleUrls: ['./region-entry-general.component.scss']
})
export class RegionEntryGeneralComponent implements OnInit {

  @Input() id: number;
  model: RegionEntryGeneralDto = new RegionEntryGeneralDto();
  stateList: State[] = StateList.STATES;

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private validationResultsService: ValidationResultsService,
    private _service: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    const params = new HttpParams().set('id', this.id.toString());
    this.clinicHttpClient.Get<RegionEntryGeneralDto>('/region-entry', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  saveClose() {
    this.save();
    this.cancel();
  }

  save() {
    this.clinicHttpClient.Post<CommonResult>('/region-entry', this.model)
      .subscribe(response => {
        if (response.result != 'success') {
          this.validationResultsService.setErrorMessages(response.validationResults);
        } else {
          this.validationResultsService.setSuccessMessages(["Save succeeded"]);
        }
      })
  }

  cancel() {
    window.history.back();
  }

  get canSave(): boolean {
    return this._service.hasRole("ROLE_billing_employer_admin");
  }
}
