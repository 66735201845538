<div class="row mt-5">
  <div class="col-md-12">
    <h4>
      Error
    </h4>
    <hr class="divider" />
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <p>
      We're sorry, but we are currently unable to show you this page.  This could be due to one of several reasons:
    </p>
    <ul>
      <li>
        There was an error while attempting to render this page
      </li>
      <li>
        Your user does not have access to this patient's record
      </li>
      <li>
        Your user does not have access to this page
      </li>
    </ul>
    <p>
      If you were not expecting to see this page, please contact eme.support@primeoccmed.com.
  </div>
</div>
