<div class="flex-container">
  <div class="column" id="col1">
    <p class="colHeader">
      Basic Details
    </p>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Employer Name</mat-label>
        <input matInput placeholder="Employer Name" [(ngModel)]="model.name">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Address Line 1</mat-label>
        <input matInput required placeholder="address 1" [(ngModel)]="model.address1">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Address Line 2</mat-label>
        <input matInput placeholder="address 2" [(ngModel)]="model.address2">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input matInput required [(ngModel)]="model.city">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select [(ngModel)]="model.state">
          <mat-option *ngFor="let state of stateList" [value]="state.value">
            {{state.display}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Postal Code</mat-label>
        <input matInput required [(ngModel)]="model.postalCode">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Tax ID</mat-label>
        <input matInput [(ngModel)]="model.taxId">
      </mat-form-field>
    </div>
  </div>

  <div class="column" id="col2">
    <p class="colHeader">
      Accounts Payable Contact
    </p>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput required [(ngModel)]="model.accountsPayableName">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput required [(ngModel)]="model.accountsPayablePhone">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Fax</mat-label>
        <input matInput required [(ngModel)]="model.accountsPayableFax">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="model.accountsPayableEmail">
      </mat-form-field>
    </div>
  </div>

  <div class="column" id="col3">
    <p class="colHeader">
      Manager Responsible for Services Related Questions
    </p>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="model.responsibleManagerName">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput [(ngModel)]="model.responsibleManagerTitle">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="model.responsibleManagerEmailAddress">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput [(ngModel)]="model.responsibleManagerPhone">
      </mat-form-field>
    </div>
  </div>
</div>

<hr>

<div class="flex-container">
  <div class="column">
    HR Main Contact
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="model.hrMainName">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput [(ngModel)]="model.hrMainPhone">
      </mat-form-field>
    </div>
  </div>

  <div class="column">
    Injury Management Contact
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="model.injuryManagementName">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput [(ngModel)]="model.injuryManagementPhone">
      </mat-form-field>
    </div>
  </div>

  <div class="column">
    Safety Manager
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="model.safetyManagerName">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput [(ngModel)]="model.safetyManagerPhone">
      </mat-form-field>
    </div>
  </div>
</div>

<hr>

<div class="salesRepresent">
  <mat-form-field>
    <mat-label>Sales Representative</mat-label>
    <mat-select [(ngModel)]="model.salesRepresentativeId">
      <mat-option value="">(select)</mat-option>
      <mat-option *ngFor="let sr of salesRepresentativeDtos" [value]="sr.id">
        {{sr.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="right-align">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
