import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {BillingHttpClient} from '../../../common/billing-http-client.service';
import {ValidationResultsService} from '../../../services/validation-results.service';
import {FacilityPayorEntryDto} from '../../../models/facility-payor-entry/facility-payor-entry-dto';
import {NgForm} from '@angular/forms';
import {FacilityPayorEntryResponsibilitiesComponent} from './facility-payor-entry-responsibilities/facility-payor-entry-responsibilities.component';
import {FacilityPayorEntryGeneralComponent} from './facility-payor-entry-general/facility-payor-entry-general.component';
import {HttpParams} from '@angular/common/http';
import {CommonResult} from '../../../models/common/common-result';
import {CanComponentDeactivate} from '../../../services/can-deactivate-guard.service';

@Component({
  selector: 'app-facility-payor-entry',
  templateUrl: './facility-payor-entry.component.html',
  styleUrls: ['./facility-payor-entry.component.scss']
})
export class FacilityPayorEntryComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  id: number;
  private ngUnsubscribe = new Subject();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private route: ActivatedRoute,
    private validationResultsService: ValidationResultsService) {
  }

  model: FacilityPayorEntryDto = new FacilityPayorEntryDto();
  @ViewChild('form') form: NgForm;
  @ViewChild('responsibilitiesComponent') responsibilitiesComponent: FacilityPayorEntryResponsibilitiesComponent;
  @ViewChild('generalComponent') generalComponent: FacilityPayorEntryGeneralComponent;

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
      });
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Get<FacilityPayorEntryDto>('/facility-payor-entry', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    this.billingHttpClient.Post<CommonResult>('/facility-payor-entry', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded']);
          this.markAsPristine();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  saveClose() {
    this.save();
    this.cancel();
  }

  private markAsPristine() {
    this.form.form.markAsPristine();
    this.generalComponent.formGeneral.form.markAsPristine();
    this.responsibilitiesComponent.formResponsibilities.form.markAsPristine();
  }

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty || this.responsibilitiesComponent.formResponsibilities.dirty || this.generalComponent.formGeneral.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

  delete() {
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Delete<CommonResult>('/facility-payor-entry', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded'])
          this.markAsPristine();
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  cancel() {
    window.history.back();
  }

}
