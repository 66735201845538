<h1>Collection Call Queue</h1>
<table id="tb-collection-call-log" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Payor</th>
    <th>Payor Type</th>
    <th>Callback Date</th>
    <th>Invoice Count</th>
    <th>Due</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="navigateToCollectionCall(row.payorId)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.payor}}</td>
    <td>{{row.payorType}}</td>
    <td>{{row.callbackDate | date:"M/d/yyyy"}}</td>
    <td>{{row.invoiceCount}}</td>
    <td>{{row.due}}</td>
  </tr>
  </tbody>
</table>
