<div class="row">
  <div class="col-md-12">
    <h2>Vendor IDs assigned to this Payor</h2>
    <hr class="divider" />
    <div class="panel panel-default-dark">
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th>Billing Entity Name</th>
            <th>Vendor ID</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model; index as i;">
            <td>
              <span>{{row.billingEntityName}}</span>
            </td>
            <td>
              <input [name]="'vendorID_'+i" class="form-control" type="text" [(ngModel)]="row.vendorId" />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="right-align">
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
</div>
