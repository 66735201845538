import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AuthenticationService} from "./authentication.service";
import {environment} from "../../environments/environment";
import {User} from "../models/authentication/user";
import {finalize} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivateChild {

  constructor(
    private _service: AuthenticationService,
    private router: Router,
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._service.isLoggedIn()) {
      if (this._service.isTokenExpired()) {
        this._service.logout();
      } else if (!this._service.getUser()) {
        this._service.getResource(environment.authUrl + '/me')
          .subscribe(
            val => {
              sessionStorage.setItem("user", JSON.stringify(val));
              this._service.getUserSubject().next(val as User);
              if (this.checkPermission()) {
                this.router.navigate(['dashboard']);
                return true;
              }
            }, _ => {
              this.router.navigate(['error']);
              return false;
            }
          );
      } else {
        return this.checkPermission();
      }
    }
    return false;
  }

  checkPermission() {
    if (this._service.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['error']);
      return false;
    }
  }
}
