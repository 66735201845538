import {Component, Input, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {HttpParams} from "@angular/common/http";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {PayorContactEntryDto} from "../../../../models/payor-entry/payor-contact-entry-dto";
import {PayorListSearchRow} from "../../../../models/common/payor-list-search-row";
import {CommonResult} from "../../../../models/common/common-result";
import {RegionEntryPayorsDto} from "../../../../models/region-entry/region-entry-payors-dto";
import {RegionEntryPayorDto} from "../../../../models/region-entry/region-entry-payor-dto";
import {AuthenticationService} from "../../../../services/authentication.service";

@Component({
  selector: 'app-region-entry-payors',
  templateUrl: './region-entry-payors.component.html',
  styleUrls: ['./region-entry-payors.component.scss']
})
export class RegionEntryPayorsComponent implements OnInit {

  @Input() id: number;
  model: RegionEntryPayorsDto = new RegionEntryPayorsDto();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private router: Router,
    private validationResultsService: ValidationResultsService,
    private _service: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const params = new HttpParams().set('regionId', this.id.toString());
    this.billingHttpClient.Get<RegionEntryPayorsDto>('/region-entry-payors', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  drop(event: CdkDragDrop<PayorContactEntryDto[]>) {
    moveItemInArray(this.model.regionEntryPayorDtos, event.previousIndex, event.currentIndex);
  }

  editRegionPayor(regionPayorId: number) {
    this.router.navigate(['region-payor-entry', regionPayorId]);
  }

  selectPayor(payor: PayorListSearchRow) {
    let regionPayorNew: RegionEntryPayorDto = new RegionEntryPayorDto;
    regionPayorNew.regionId = this.model.regionId;
    regionPayorNew.payorId = payor.payorId;
    regionPayorNew.chargeCodePayorAssociationMode = 'All';
    regionPayorNew.visitReasonPayorAssociationMode = 'All';
    regionPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    regionPayorNew.payorName = payor.name;
    regionPayorNew.payorType = payor.payorType;
    regionPayorNew.startDateInclusive = new Date(2000, 0, 1);
    regionPayorNew.endDateInclusive = new Date(9999, 11, 31);
    regionPayorNew.active = true;
    this.model.regionEntryPayorDtos.push(regionPayorNew);
  }

  createRegionPayor(type: string) {
    let regionPayorNew = new RegionEntryPayorDto();
    regionPayorNew.regionId = this.model.regionId;
    regionPayorNew.chargeCodePayorAssociationMode = 'All';
    regionPayorNew.visitReasonPayorAssociationMode = 'All';
    regionPayorNew.chargeCodeAndVisitReasonBinaryMode = 'And';
    regionPayorNew.payorName = this.model.name;
    regionPayorNew.payorType = type;
    regionPayorNew.ssnDisplayType = "Redact";
    regionPayorNew.billingFrequencyType = "Monthly";
    regionPayorNew.startDateInclusive = new Date(2000, 0, 1);
    regionPayorNew.endDateInclusive = new Date(9999, 11, 31);
    regionPayorNew.active = true;
    this.model.regionEntryPayorDtos.push(regionPayorNew);
  }

  cancel() {
    window.history.back();
  }

  save() {
    // Update rank
    this.model.regionEntryPayorDtos.forEach((v, i) => {
      v.rank = i;
    });
    this.billingHttpClient.Post<CommonResult>('/region-entry-payors', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeed']);
          this.load();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  get canSave(): boolean {
    return this._service.hasRole("ROLE_billing_employer_admin");
  }
}
