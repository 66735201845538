import {Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from "@angular/router";
import {LinearStepperConfig, LinearStepperStep} from "../../framework/linear-stepper/linear-stepper.component";
import {AdjustmentBatchDataService} from "../../../services/adjustment-batch.data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

declare var $: any;

@Component({
  selector: 'adjustment-batch',
  templateUrl: './adjustment-batch.component.html',
  styleUrls: ['./adjustment-batch.component.scss'],
  providers: [AdjustmentBatchDataService]
})
export class AdjustmentBatchComponent implements OnInit, OnDestroy {

  adjustmentBatchId: number;
  payorId: number;
  invoiceId: number;
  linearStepperConfig: LinearStepperConfig = {
    currentStep: 1,
    previousStep: 0,
    enforceLinearProgression: true,
    enforceLinearRegression: false,
    steps: [{
      label: "Enter Control Totals",
      hasBeenVisited: false,
      route: "control-totals",
      step: 1
    }, {
      label: "Select Invoices",
      hasBeenVisited: false,
      route: "select-invoices",
      step: 2
    }, {
      label: "Apply Adjustments",
      hasBeenVisited: false,
      route: "apply-adjustments",
      step: 3
    }, {
      label: "Review & Finalize",
      hasBeenVisited: false,
      route: "review-finalize",
      step: 4
    }]
  };
  private ngUnsubscribe = new Subject();

  invalidControlTotalMessage: string = "";

  constructor(private router: Router,
              public route: ActivatedRoute,
              private adjustmentBatchDataService: AdjustmentBatchDataService) {
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.payorId = +params.get("payorId");
        this.invoiceId = +params.get("invoiceId");
        this.adjustmentBatchId = +params.get('id');
        let stepToGo = +params.get('step');
        if (stepToGo > 1 && !this.adjustmentBatchDataService.initializedSteps.includes(stepToGo - 1)) {
          stepToGo = 1;
          this.linearStepperConfig.currentStep = stepToGo;
        }
        this.navigateToCurrentStep();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  navigateToCurrentStep() {
    const currentStep = this.getCurrentStep();
    let stepToGo = currentStep.step;
    // Leave from control total to select invoice
    if (this.linearStepperConfig.previousStep == 1 && stepToGo == 2) {
      let invalid = false;
      if (this.isBatchDateMission()) {
        invalid = true;
        this.invalidControlTotalMessage = 'Batch Date is required.'
      } else if (this.isTotalMissing()) {
        invalid = true;
        this.invalidControlTotalMessage = 'Batch Total is required.'
      } else if (this.isBatchTotalEqualsToSubTotals()) {
        invalid = true;
        this.invalidControlTotalMessage = 'Subtotals do not equal to batch total.'
      }
      if (invalid) {
        $('#navigate-back-to-control-totals').modal('show');
        return;
      }
    }
    setTimeout(() => {
      this.router.navigate(['/adjustment-batch', currentStep.step, this.adjustmentBatchId,this.payorId,this.invoiceId, {
        outlets: {
          linearStep: [currentStep.route]
        }
      }]);
    });
  }

  isBatchDateMission() {
    return this.adjustmentBatchDataService.controlTotalsDto.date === null
      || this.adjustmentBatchDataService.controlTotalsDto.date === undefined;
  }

  isTotalMissing() {
    return this.adjustmentBatchDataService.controlTotalsDto.total === null
      || this.adjustmentBatchDataService.controlTotalsDto.total === undefined;
  }

  isBatchTotalEqualsToSubTotals() {
    let result: number;
    const sum =
      _.sumBy(this.adjustmentBatchDataService.controlTotalsDto.subtotals, (value) => {
        return this.getTotalValue(value.amount);
      });
    result = this.getTotalValue(this.adjustmentBatchDataService.controlTotalsDto.total) - sum;
    result = Math.round(result * 100) / 100;
    return result;
  }

  getTotalValue(value: number): number {
    let result = 0;
    if (value != null && !isNaN(value)) {
      result = value;
    }
    return result;
  }

  navigateBackToControlTotals() {
    this.linearStepperConfig.previousStep = 0;
    this.linearStepperConfig.currentStep = 1;
  }


  getCurrentStep(): LinearStepperStep {
    return this.getStep(this.linearStepperConfig.currentStep);
  }

  getStep(stepNumber: number): LinearStepperStep {
    return _.find(this.linearStepperConfig.steps, (value) => {
      return value.step == stepNumber;
    });
  }
}
