import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {PendingStatementsSearchRow} from "../../../models/pending-statements/pending-statements-search-row";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../models/common/common-result";
import {DocumentHttpClient} from "../../../common/document-http-client";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {ValidationResultsService} from "../../../services/validation-results.service";

declare var $: any;

@Component({
  selector: 'pending-statements',
  templateUrl: './pending-statements.component.html',
  styleUrls: ['./pending-statements.component.scss']
})
export class PendingStatementsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};
  model: PendingStatementsSearchRow[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private documentHttpClient: DocumentHttpClient,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'name'},
        {data: 'payorType'},
        {data: 'statementCount'},
        {data: 'type'},
        {data: 'info'},
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/pending-statements", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-2 'B><'col-sm-3'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        // {
        //   text: '<i class="fas fa-print"></i><span style="margin-left: 5px;">Print All Groups</span>',
        //   className: "btn btn-success float-right",
        //   action: function (e, dt, node, config) {
        //     that.printAllGroupsAreYouSure();
        //   }
        // }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  printGroup = (payorGroup: PendingStatementsSearchRow) => {
    let type = payorGroup.type;
    let payorId = payorGroup.payorId;

    let params = new HttpParams().set('type', type).set('payorId', payorId.toString());
    this.documentHttpClient.Get<Blob>(`/group-statements-pdf`,
      {responseType: 'blob' as 'json', params: params})
      .subscribe(resp => {
        this.downloadPdf(resp, 'statement');
        params = new HttpParams()
          .set('type', type)
          .set('payorId', payorId.toString())
          .set('status', 'PendingPayment');
        this.billingHttpClient.Put<CommonResult>('/statements-status', params)
          .subscribe(res => {
            if (res.result == 'success') {
              this.reloadDebitsTable();
            } else {
              this.validationResultsService.setErrorMessages(res.validationResults);
            }
          });
      });
  }

  downloadPdf(blob: Blob, documentName: string) {
    let newBlob = new Blob([blob], {type: "application/pdf"})
    let data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = documentName + ".pdf";
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  reloadDebitsTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  printAllGroupsAreYouSure = () => {
    $('#printAllGroupsModal').modal('show');
  }

  printAllGroups = () => {
    // if (this.grid.pagedResult.TotalCount > 0) {
    //   let url = `/billing/GetSubmitAllStatementGroups/0`;
    //   let newWindow = window.open(url, '_blank');
    //   newWindow.onunload = () => {
    //     this.grid.load();
    //   };
    // }
  }

}
