import {FacilityPayorEntryGeneralDto} from './facility-payor-entry-general-dto';
import {FacilityPayorEntryResponsibilitiesDto} from './facility-payor-entry-responsibilities-dto';

export class FacilityPayorEntryDto {
  facilityPayorId: number;
  facilityId: number;
  facilityName: string;
  payorId: number;
  payorName: string;
  facilityPayorEntryGeneralDto: FacilityPayorEntryGeneralDto = new FacilityPayorEntryGeneralDto();
  facilityPayorEntryResponsibilitiesDto: FacilityPayorEntryResponsibilitiesDto = new FacilityPayorEntryResponsibilitiesDto();
}
