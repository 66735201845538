<h1>Payor Search</h1>
<table id="tb-payor-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Payor Type</th>
    <th>Name</th>
    <th>Is Global</th>
    <th>Is Active</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="goToPayor(row.payorId)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.payorType}}</td>
    <td>{{row.name}}</td>
    <td>
      <input type="checkbox" [checked]="row.global" disabled>
    </td>
    <td>
      <input type="checkbox" [checked]="row.active" disabled>
    </td>
  </tr>
  </tbody>
</table>
