<form class="form-inline">
  <div class="form-group mb-2">
    <div class="input-group">
      <select class="dropdown form-control input-sm" name="transactionType" [(ngModel)]="financialTransactionType"
              [attr.disabled]="disabled ? 'disabled' : null">
        <option [ngValue]="null">-Select Type-</option>
        <option *ngFor="let option of financialTransactionTypeOptions" [ngValue]="option">{{option}}</option>
      </select>
      <div class="input-group-append">
        <button [attr.disabled]="disabled ? 'disabled' : null"
                *ngIf="financialTransactionType != null && showSmartApply" type="button" class="btn btn-success btn-sm"
                (click)="applyControlTotalAdjustments()"><i class="fa fa-angle-double-right"
                                                            aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  <div class="form-group mb-2 ml-2">
    <div class="input-group amount-input">
      <input name="amount" type="number" [(ngModel)]="amount" placeholder="Amount" class="form-control input-sm"
             [attr.disabled]="disabled ? 'disabled' : null"/>
      <div class="input-group-append">
        <button [attr.disabled]="disabled ? 'disabled' : null" type="button" class="btn btn-success btn-sm"
                (click)="addAdjustment()"><i class="fa fa-plus" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  <div class="mb-2 ml-3">
    <span *ngIf="showError" class="error">{{ErrorMessage}}</span>
  </div>
</form>
