import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BranchListSearchRow} from "../../../models/branch-list/branch-list-search-row";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {ClinicHttpClient} from "../../../common/clinic-http-client";

@Component({
  selector: 'branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss']
})
export class BranchListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: BranchListSearchRow[] = [];

  constructor(private clinicHttpClient: ClinicHttpClient,
              private router: Router) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'name'},
        {data: 'facilityName'},
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.clinicHttpClient.Post<DataTablesResponse>("/branches", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  goToBranch(branch: BranchListSearchRow) {
    this.router.navigate(['branch-entry', branch.branchId]);
  }
}
