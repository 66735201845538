import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {DashboardCreditMemosSearchRow} from "../../../models/credit-memos/dashboard-credit-memos-search-row";

@Component({
  selector: 'credit-memos',
  templateUrl: './credit-memos.component.html',
  styleUrls: ['./credit-memos.component.scss']
})
export class CreditMemosComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: DashboardCreditMemosSearchRow[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private  router: Router) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: 'invoiceId'},
        {data: 'invoiceNumberDisplay'},
        {data: 'invoiceAmount'},
        {data: 'balance'},
        {data: 'employer'},
        {data: 'payor'},
        {data: 'region'},
        {data: 'jobNumber'},
        {data: 'purchaseOrder'},
        {data: 'reasonForVisit'},
        {data: 'patientName'},
        {data: 'status'},
      ],
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/credit-memos", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  goToInvoice = (invoiceId) => {
    this.router.navigate(['invoice', invoiceId]);
  }
}
