import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {BaseHttpClient} from "./base-http-client";

export function clinicHttpClientCreator(http: HttpClient) {
  return new ClinicHttpClient(http);
}

@Injectable()
export class ClinicHttpClient extends BaseHttpClient {
  constructor(protected http: HttpClient) {
    super(http, environment.clinicUrl);
  }
}

export const clinicHttpClientProvider = {
  provide: ClinicHttpClient,
  useFactory: clinicHttpClientCreator,
  deps: [HttpClient]
};
