<h1>
  Billing Entity
</h1>
<hr>
<div>
  <div class="name-header">
    Name: {{model.name}}
  </div>
  <hr>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="General">
      <app-billing-entity-entry-general [model]="model"></app-billing-entity-entry-general>
    </mat-tab>
    <mat-tab label="Facilities">
      <app-billing-entity-entry-facilities
        [model]="model.billingEntityFacilities" [billingEntityId]="model.id"></app-billing-entity-entry-facilities>
    </mat-tab>
    <mat-tab label="Branches">
      <app-billing-entity-entry-branches [model]="model.billingEntityBranches" [billingEntityId]="model.id">
      </app-billing-entity-entry-branches>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="right-align">
  <button class="btn btn-warning" (click)="cancel()">
    Cancel
  </button>
  <button class="btn btn-success ml-2" (click)="save()">
    Save
  </button>
  <button class="btn btn-success ml-2" (click)="saveClose()">
    Save and Close
  </button>
</div>
