import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {BaseHttpClient} from "./base-http-client";

export function reviewerHttpClientCreator(http: HttpClient) {
  return new ReviewerApi9HttpClient(http);
}

@Injectable()
export class ReviewerApi9HttpClient extends BaseHttpClient {
  constructor(protected http: HttpClient) {
    super(http, environment.reviewerApi9Url);
  }
}

export const reviewerApi9HttpClientProvider = {
  provide: ReviewerApi9HttpClient,
  useFactory: reviewerHttpClientCreator,
  deps: [HttpClient]
};
