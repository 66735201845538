export class EmployerEntryResultAddressDto {
  employerDetailResultAddressId: number;
  employerDetailId: number;
  description: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
}
