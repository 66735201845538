import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DashboardTileDto} from "../../models/dashboard/dashboard-tile-dto";
import * as moment from 'moment';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  model: DashboardTileDto[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router) {
  }

  ngOnInit() {
    this.billingHttpClient.Get("/dashboard").subscribe(
      val => {
        this.model = val as DashboardTileDto[];
      }
    )
  }

  navigateToTile(tile: DashboardTileDto) {
    this.router.navigate([tile.route]);
  }

  formatNumberWithMileStones(count: number): string {
    if (count != 0 && !count)
      return "";

    let result = count.toString();
    if (count > 999999) {
      result = this.millionFormatter(count);
    } else if (count > 999) {
      result = this.thousandFormatter(count);
    }

    return result;
  }

  millionFormatter(num: number): string {
    return (num / 1000000).toFixed(1) + 'm';
  }

  thousandFormatter(num: number): string {
    return (num / 1000).toFixed(1) + 'k';
  }

  getUpdatedMinutes(tiles: DashboardTileDto[]): string {
    const millis = tiles?.[0]?.updated;
    return millis == null
      ? 'Updating...'
      : `Updated ${moment(millis).fromNow()}`;
  }

  showTile(title) {
    if (title === 'Invoices Past Due' ||
    title === 'Collection Call Queue') {
      return 'd-none';
    }
    return '';
  }

}
