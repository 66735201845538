import {AdjustmentBatchStatus} from "../adjustment-batch-status.enum";
import {PayorOption} from "../../common/payor-option";
import {AdjustmentBatchSubtotalDto} from "./adjustment-batch-subtotal-dto";

export class ControlTotalsDto {
  adjustmentBatchId: number;
  adjustmentBatchStatus: AdjustmentBatchStatus = AdjustmentBatchStatus.open;
  balanceSheetType: string;
  date: Date;
  postedDate: Date;
  selectedPayor: PayorOption = new PayorOption();
  total: number;
  checkNumber: string;
  subtotals = [] as AdjustmentBatchSubtotalDto[];

  constructor() {
    this.setDate(new Date());
  }

  setDate(batchDate: Date) {
    this.date = batchDate;
  }
}
