import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";
import {InvoiceDetailDebitDetailsDto} from "../../../../models/invoice-detail/debit-detail/invoice-detail-debit-details-dto";

@Component({
  selector: 'invoice-detail-debit-details',
  templateUrl: './invoice-detail-debit-details.component.html',
  styleUrls: ['./invoice-detail-debit-details.component.scss']
})
export class InvoiceDetailDebitDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  model: InvoiceDetailDebitDetailsDto[];
  invoiceId: number;
  _showHistory: boolean = false;
  set showHistory(value: boolean) {
    if (this._showHistory !== value) {
      this._showHistory = value;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }

  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private billingHttpClient: BillingHttpClient) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searching: false,
      autoWidth: false,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          searchable: false,
          visible: false,
          width: '0%',
        },
        {
          targets: 1,
          orderable: false,
          searchable: false,
          width: '100%',
        }
      ],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.invoiceId = this.invoiceId.toString();
        dataTablesParameters.showHistory = String(this._showHistory);
        that.billingHttpClient.Post<DataTablesResponse>("/invoice-detail-debit-details", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data as InvoiceDetailDebitDetailsDto[];
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getDebitHeaderValue(row: InvoiceDetailDebitDetailsDto): string {
    let result = "";
    if (row.patientName) {
      result = row.patientName;
    } else if (row.employer) {
      result = row.employer;
    }
    if (row.description && result) {
      result = `${result} - ${row.description}`;
    } else {
      result = `${row.description}`;
    }
    return result;
  }
}
