<div class="parent-div">
  <div  class="child-div cursor-pointer" [ngClass]="{'transparent-text':model.currentStep == 1}" (click)="previous()">
    <span>&nbsp;&nbsp;&nbsp;Previous</span>
  </div>
  <div class="child-div cursor-pointer"
       *ngFor="let step of model.steps"
       [ngClass]="{'selected-step':step.step == model.currentStep, 'previous-step':step.step < model.currentStep, 'next-step':step.step > model.currentStep}"
       (click)="setStep(step)">
    <div *ngIf="step.step > 1" class="arrow-right" [ngClass]="{'arrow-right-selected':step.step == model.currentStep+1, 'arrow-right-previous':step.step < model.currentStep+1, 'arrow-right-next':step.step > model.currentStep+1}"></div>
    <span>&nbsp;&nbsp;&nbsp;{{step.label}}</span>
  </div>
  <div class="child-div cursor-pointer" [ngClass]="{'transparent-text':model.currentStep == model.steps.length, 'arrow-right-next': model.currentStep == model.steps.length}" (click)="next()">
    <div class="arrow-right"></div>
    <span>&nbsp;&nbsp;&nbsp;Next</span>
  </div>
</div>
