import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PatientLookupSearchRow} from "../../../models/common/patient-lookup-search-row";
import {EmployerDto} from "../../../models/common/employer-dto";
import {RegionDto} from "../../../models/common/region-dto";
import {FacilityDto} from "../../../models/common/facility-dto";
import {BillingGroupListDto} from "../../../models/common/billing-group-list-dto";
import {BranchDto} from "../../../models/common/branch-dto";
import {JobSiteDto} from "../../../models/common/job-site-dto";
import {ProviderDto} from "../../../models/common/provider-dto";
import {ChargeCodeLookupSearchRow} from "../../../models/common/charge-code-lookup-search-row";
import {PayorListSearchRow} from "../../../models/common/payor-list-search-row";
import {NgForm} from "@angular/forms";
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {PendingFinancialTransactionDto} from "../../../models/common/pending-financial-transaction-dto";
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {HttpParams} from "@angular/common/http";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {CommonResult} from "../../../models/common/common-result";
import {TaskHttpClient} from "../../../common/task-http-client";

@Component({
  selector: 'debit-entry',
  templateUrl: './debit-entry.component.html',
  styleUrls: ['./debit-entry.component.scss']
})
export class DebitEntryComponent implements OnInit {

  constructor(private billingHttpClient: BillingHttpClient,
              private clinicHttpClient: ClinicHttpClient,
              private taskHttpClient: TaskHttpClient,
              private validationResultsService: ValidationResultsService,
              private route: ActivatedRoute) {
  }

  transactionTypes: string[] = []
  employers: EmployerDto[] = [];
  regions: RegionDto[] = [];
  facilities: FacilityDto[] = [];
  jobNumbers: string[] = [];
  providers: ProviderDto[] = [];
  jobSites: JobSiteDto[] = [];
  branches: BranchDto[] = [];
  groupingKeys: BillingGroupListDto[] = [];

  transactionType = '';
  patient: PatientLookupSearchRow = new PatientLookupSearchRow();
  employer: EmployerDto = null;
  region: RegionDto = null;
  jobSite: JobSiteDto = null;
  branch: BranchDto = null;
  jobNumber: string = null;
  facility: FacilityDto = null;
  provider: ProviderDto = null;
  purchaseOrder: string;
  chargeCode: ChargeCodeLookupSearchRow = new ChargeCodeLookupSearchRow();
  displayChargeCode: string;
  quantity: number = 1;
  serviceDate: Date;
  testReasonType: string;
  specimenId: string;
  groupingKey: string;
  notes: string;
  groupingKeyModel: BillingGroupListDto = null;

  overridingPayorPrice: boolean = false;
  amount: number;
  payor: PayorListSearchRow = new PayorListSearchRow();

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (+params['id'] > 0) {
        this.load(+params['id']);
      }
    });
  }

  load(id: number) {
    this.billingHttpClient.Get<PendingFinancialTransactionDto>(`/pending-financial-transaction/${id}`, {})
      .subscribe(response => {
        this.transactionType = response.financialTransactionPendingImportType;
        let patientId = response.patientId;
        if (patientId) {
          this.getNonVoidDebitTransactionTypes();
          this.getEmployersForPatient(patientId);
          this.getFacilities();
          this.getBranches();
          this.getBillingGroups();
          const params = new HttpParams().set('authorizationPatientId', response.authorizationPatientId.toString());
          this.clinicHttpClient.Get<PatientLookupSearchRow>(`/patient-lookup-search-row`, {params})
            .subscribe(res => {
              this.patient = res;
            });
        }
        if (response.employerId) {
          this.employer = new EmployerDto();
          this.employer.companyName = response.employer;
          this.employer.id = response.employerId;
          this.getEmployerBasedChargeEntryOptions(this.employer.id);
        }
        if (response.regionId) {
          this.region = new RegionDto();
          this.region.id = response.regionId;
          this.region.companyName = response.region;
          this.getRegionBasedChargeEntryOptions(this.region.id);
        }
        if (response.jobSiteId) {
          this.jobSite = new JobSiteDto();
          this.jobSite.id = response.jobSiteId;
          this.jobSite.companyName = response.jobSite;
        }
        if (response.branchId) {
          this.branch = new BranchDto();
          this.branch.id = response.branchId;
          this.branch.companyName = response.branch;
        }
        this.jobNumber = response.jobNumber;
        if (response.facilityId) {
          this.facility = new FacilityDto();
          this.facility.id = response.facilityId;
          this.facility.companyName = response.facility;
          this.getFacilityBasedChargeEntryOptions(this.facility.id);
        }
        if (response.providerId) {
          this.provider = new ProviderDto();
          this.provider.id = response.providerId;
          this.provider.name = response.provider;
          this.provider.providerNPI = response.providerNPI;
        }
        this.purchaseOrder = response.purchaseOrderNumber;
        if (response.chargeCode != null) {
          this.chargeCode.code = response.chargeCode;
          this.chargeCode.description = response.chargeCodeDescription;
        }
        this.quantity = response.quantity;
        this.serviceDate = response.serviceDate;
        this.testReasonType = response.testReasonType;
        this.specimenId = response.specimenId;
        this.groupingKey = response.groupingKey;
        this.notes = response.notes;
        this.amount = response.amount;
        if (response.payor != null) {
          this.payor.name = response.payor;
          this.payor.payorId = response.payorId;
        }
        this.displayChargeCode = response.displayChargeCode;
      });
  }

  getNonVoidDebitTransactionTypes() {
    if (this.transactionTypes.length == 0) {
      this.billingHttpClient.Get<string[]>('/non-void-debit-transaction-types')
        .subscribe(response => {
          this.transactionTypes = response;
        });
    }
  }

  getEmployersForPatient(patientId: number) {
    const params = new HttpParams().set('patientId', patientId.toString());
    this.clinicHttpClient.Get<EmployerDto[]>('/debit-entry-employers', {params})
      .subscribe(response => {
        this.employers = response;
      });
  }

  getFacilities() {
    if (this.facilities.length == 0) {
      this.clinicHttpClient.Get<FacilityDto[]>('/debit-entry-facilities')
        .subscribe(response => {
          this.facilities = response;
        });
    }
  }

  getBranches() {
    if (this.branches.length == 0) {
      this.clinicHttpClient.Get<BranchDto[]>('/debit-entry-branches')
        .subscribe(response => {
          this.branches = response;
        });
    }
  }

  getBillingGroups() {
    if (this.groupingKeys.length == 0) {
      this.billingHttpClient.Get<BillingGroupListDto[]>('/debit-entry-billing-groups')
        .subscribe(response => {
          this.groupingKeys = response;
        });
    }
  }

  patientChange(patient: PatientLookupSearchRow) {
    this.patient = patient;
    this.transactionType = null;
    this.employer = null;
    this.updateEmployer();
    this.facility = null;
    this.updateFacility();

    this.getNonVoidDebitTransactionTypes();
    this.getEmployersForPatient(patient.patientId);
    this.getFacilities();
    this.getBranches();
    this.getBillingGroups();
  }

  updateEmployer() {
    this.jobNumbers = [];
    this.jobNumber = null;
    this.regions = [];
    this.region = null;
    this.jobSites = [];
    this.jobSite = null;
    if (this.employer && this.employer.id) {
      this.getEmployerBasedChargeEntryOptions(this.employer.id);
    }
  }

  updateRegion() {
    this.jobSites = [];
    this.jobSite = null;
    if (this.region && this.region.id) {
      this.getRegionBasedChargeEntryOptions(this.region.id);
    }
  }

  updateFacility() {
    this.providers = [];
    this.provider = null;
    if (this.facility && this.facility.id) {
      this.getFacilityBasedChargeEntryOptions(this.facility.id);
    }
  }

  getEmployerBasedChargeEntryOptions(employerId: number) {
    this.getJobNumbersByEmployer(employerId);
    this.getRegionsForEmployer(employerId);
  }

  getJobNumbersByEmployer(employerId: number) {
    const params = new HttpParams().set('employerId', employerId.toString());
    this.clinicHttpClient.Get<string[]>('/debit-entry-job-numbers', {params})
      .subscribe(response => {
        this.jobNumbers = response;
      });
  }

  getRegionsForEmployer(employerId: number) {
    const params = new HttpParams().set('employerId', employerId.toString());
    this.clinicHttpClient.Get<RegionDto[]>('/debit-entry-regions', {params})
      .subscribe(response => {
        this.regions = response;
      });
  }

  getRegionBasedChargeEntryOptions(regionId: number) {
    const params = new HttpParams().set('regionId', regionId.toString());
    this.clinicHttpClient.Get<JobSiteDto[]>('/debit-entry-job-sites', {params})
      .subscribe(response => {
        this.jobSites = response;
      });
  }

  getFacilityBasedChargeEntryOptions(facilityId: number) {
    const params = new HttpParams().set('facilityId', facilityId.toString());
    this.clinicHttpClient.Get<ProviderDto[]>('/debit-entry-providers', {params})
      .subscribe(response => {
        this.providers = response;
      });
  }

  chargeCodeChange(data: ChargeCodeLookupSearchRow) {
    this.chargeCode = data;
  }

  get canSave(): boolean {
    return this.employer && (this.facility || this.branch)
      && this.chargeCode && this.chargeCode.code && this.patient && this.serviceDate != null;
  }

  saveAndClose() {
    this.save().then(response => {
      if (response.result != 'failed') {
        this.onSaveAndCloseSucceeded();
        this.processSaved(response.result);
      }
    });
  }

  processSaved(id: string) {
    const params = new HttpParams().set('financialTransactionPendingImportId', id);
    this.taskHttpClient.Post('/process-pending-financial-transaction', params,
      {responseType: 'text' as 'json'})
      .subscribe(_ => {
      });
  }

  onSaveAndCloseSucceeded() {
    this.form.form.markAsPristine();
    window.history.back();
  }

  saveAndNew() {
    this.save().then(response => {
      if (response.result != 'failed') {
        this.onSaveAndNewSucceeded();
        this.processSaved(response.result);
      }
    });
  }

  onSaveAndNewSucceeded() {
    this.validationResultsService.setSuccessMessages(["Save Succeeded"]);
    this.serviceDate = null;
    this.chargeCode = new ChargeCodeLookupSearchRow();
    this.quantity = 1;
    this.purchaseOrder = null;
    this.hideOverridePayorPrice();
  }

  save(): Promise<any> {
    const pendingFinancialTransaction = {
      chargeCode: this.chargeCode.code,
      chargeCodeDescription: this.chargeCode.description,
      employerId: this.employer.id,
      employer: this.employer.companyName,
      regionId: this.region == null ? null : this.region.id,
      region: this.region == null ? null : this.region.companyName,
      jobSiteId: this.jobSite == null ? null : this.jobSite.id,
      jobSite: this.jobSite == null ? null : this.jobSite.companyName,
      branchId: this.branch == null ? null : this.branch.id,
      branch: this.branch == null ? null : this.branch.companyName,
      facilityId: this.facility == null ? null : this.facility.id,
      facility: this.facility == null ? null : this.facility.companyName,
      jobNumber: this.jobNumber,
      id: 0,
      patientId: this.patient.patientId,
      patientFirstName: this.patient.firstName,
      patientLastName: this.patient.lastName,
      patientIdentifier: this.patient.identifierValue,
      serviceDate: this.serviceDate,
      purchaseOrderNumber: this.purchaseOrder,
      providerId: this.provider == null ? null : this.provider.id,
      provider: this.provider == null ? null : this.provider.name,
      providerNPI: this.provider == null ? null : this.provider.providerNPI,
      authorizationPatientTestTypeId: null,
      authorizationPatientSupplyId: null,
      authorizationPatientInjuryClinicNoteId: null,
      financialTransactionPendingImportType: this.transactionType == null ? null : this.transactionType,
      quantity: this.quantity,
      testReasonType: this.testReasonType,
      amount: this.amount,
      payor: this.payor.payorId == null || this.payor.payorId == 0 ? null : this.payor.name,
      payorId: this.payor.payorId == null || this.payor.payorId == 0 ? null : this.payor.payorId,
      parentFinancialTransactionId: null,
      specimenId: this.specimenId,
      authorizationPatientId: this.patient.authorizationPatientId,
      groupingKey: this.groupingKey,
      notes: this.notes,
      displayChargeCode: this.displayChargeCode
    };

    return this.billingHttpClient
      .Post<CommonResult>('/financial-transaction-pending-import', pendingFinancialTransaction)
      .toPromise();
  }

  setPayorOverride(value: PayorListSearchRow) {
    this.payor = value;
  }

  clearPayor() {
    this.payor = new PayorListSearchRow();
  }

  showOverridePayorPrice() {
    this.overridingPayorPrice = true;
  }

  hideOverridePayorPrice() {
    this.overridingPayorPrice = false;
    this.payor = new PayorListSearchRow();
    this.amount = null;
  }

  cancel() {
    window.history.back();
  }

  onSelectGroupingKey(groupingKey: BillingGroupListDto) {
    if (groupingKey != null) {
      this.groupingKey = groupingKey.billingGroupId.toString();
    } else {
      this.groupingKey = null;
    }
  }

  @ViewChild('form') form: NgForm;

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }
}

