import {Component, Input, OnInit} from '@angular/core';
import {PayorEntryCallDto} from "../../../../models/payor-entry/payor-entry-call-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";

@Component({
  selector: 'payor-entry-call-log',
  templateUrl: './payor-entry-call-log.component.html',
  styleUrls: ['./payor-entry-call-log.component.scss']
})
export class PayorEntryCallLogComponent implements OnInit {

  @Input() payorId: number;
  model: PayorEntryCallDto[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(
    private billingHttpClient: BillingHttpClient,
    private  router: Router
  ) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columns: [
        {data: 'userName'},
        {data: 'time'},
        {data: 'notes'},
      ],
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.payorId = this.payorId;
        that.billingHttpClient.Post<DataTablesResponse>("/payor-entry-calls", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  performCall() {
    this.router.navigate(['collection-call', this.payorId.toString()]);
  }
}
