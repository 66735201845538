import {Pipe, PipeTransform} from '@angular/core';
import {DateDto} from "../models/common/date-dto";

@Pipe({
  name: 'datePipe'
})
export class DatePipePipe implements PipeTransform {
  transform(dateDto: DateDto) {
    let result = "";
    if (dateDto == null) {
      return "";
    } else if (dateDto.month && dateDto.day && dateDto.year) {
      result = `${dateDto.month.toString()}/${dateDto.day.toString()}/${dateDto.year.toString()}`;
    }
    return result
  }
}
