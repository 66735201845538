import {PayorVendorIdDto} from "./payor-vendor-id-dto";

export class PayorEntryDto {
  id: number;
  payorType: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  defaultPo: string;
  billingEmail: string;
  billingFax: string;
  ssnDisplayType: string;
  billingFrequencyType: string;
  deliverByEmail: boolean;
  deliverByFax: boolean;
  deliverByPostal: boolean;
  doNotSendInvoice: boolean;
  allowGroupingKeyInvoicesToSplitOnBillingFrequency: boolean;
  attachToInvoiceAuthorization: boolean;
  attachToInvoiceChainOfCustody: boolean;
  attachToInvoiceMedicalTestingResults: boolean;
  attachToInvoiceMedicalTestingRecords: boolean;
  splitByPatient: boolean;
  splitByJobNumber: boolean;
  splitByPo: boolean;
  splitByEmployerRegion: boolean;
  splitByReasonForVisit: boolean;
  splitByFacility: boolean;
  includeTaxIdNumber: boolean;
  includeSpecimenIdNumber: boolean;
  includeJobNumber: boolean;
  includePoNumber: boolean;
  includeVendorIdNumber: boolean;
  includeEmployerName: boolean;
  invoiceRenderingType: string;
  receivesStatements: boolean;
  includeStatementEmployerName: boolean;
  active: boolean = true;
  global: boolean;
  nonDetailed: boolean;
  payorVendorIds: PayorVendorIdDto[];
}
