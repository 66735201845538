<h4>Upload Clinical Document</h4>
<hr>
<!--<div class="row">-->
<!--  <mat-form-field class="mr-5">-->
<!--    <mat-label #fileLabel>File</mat-label>-->
<!--    <input matInput placeholder="Upload PDF">-->
<!--  </mat-form-field>-->
<!--  <button class="btn btn-primary"><i class="far fa-file-pdf"></i> Browse</button>-->
<!--</div>-->


<div class="form-group">

<!--  <label #fileLabel for="input-file-id" class="control-label col-md-1">File: </label>-->
<!--  <div class="col-md-6">-->
<!--    <input type="text" disabled="disabled" class="form-control filename-name" [(ngModel)]="fileName" />-->
<!--    <input #fileInput class="hide" type="file" name="input-file-id" accept="application/pdf,image/gif,image/png,image/tiff,image/jpeg,image/bmp" id="input-file-id" value="" (change)="fileChange($event)">-->
<!--  </div>-->
<!--  <div class="col-md-2">-->
<!--    <button for="input-file-id" class="btn btn-primary"-->
<!--            (click)="fileLabel.click()">-->
<!--      <i class="fa fa-file-pdf-o"></i> Browse-->
<!--    </button>-->
<!--  </div>-->

<!--  -->

  <label for="file">Choose File</label>
  <input type="text" disabled class="form-control" [(ngModel)]="fileName"/>
  <input type="file" id="file" accept="application/pdf,image/gif,image/png,image/tiff,image/jpeg,image/bmp"
         (change)="handleFileInput($event.target.files)">
</div>


<hr>
<button class="btn btn-warning float-right" matDialogClose="">Close</button>
