import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PatientLookupSearchRow} from "../../../models/common/patient-lookup-search-row";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {ClinicHttpClient} from "../../../common/clinic-http-client";

declare var $: any;

@Component({
  selector: 'patient-lookup',
  templateUrl: './patient-lookup.component.html',
  styleUrls: ['./patient-lookup.component.scss']
})
export class PatientLookupComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: PatientLookupSearchRow[] = [];

  constructor(private clinicHttpClient: ClinicHttpClient) {
  }

  @Output() patientChange: EventEmitter<PatientLookupSearchRow> = new EventEmitter<PatientLookupSearchRow>();

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'authorizationId'},
        {data: 'firstName'},
        {data: 'lastName'},
        {data: 'identifierValue'},
        {data: 'dateOfBirth'},
        {data: 'contactPhone'},
        {data: 'companyName'},
      ],
      // order: [[3, 'asc'], [2, 'asc'], [5, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.clinicHttpClient.Post<DataTablesResponse>("/patient-lookup-search-rows", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };

  }

  selectPatient = (patient: PatientLookupSearchRow) => {
    this.patientChange.emit(patient);
    $('#patientLookupModal').modal('hide');
  }
}
