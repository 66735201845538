import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SearchBranchesComponent} from "../../../common/search-branches/search-branches.component";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {HttpParams} from "@angular/common/http";
import {BillingEntityBranchDto} from "../../../../models/billing-entity-entry/billing-entity-branch-dto";
import {BranchDto} from "../../../../models/common/branch-dto";

@Component({
  selector: 'app-billing-entity-entry-branches',
  templateUrl: './billing-entity-entry-branches.component.html',
  styleUrls: ['./billing-entity-entry-branches.component.scss']
})
export class BillingEntityEntryBranchesComponent implements OnInit {

  @Input() billingEntityId: number;
  private _model: BillingEntityBranchDto[];
  get model(): BillingEntityBranchDto[] {
    return this._model;
  }
  @Input() set model(value: BillingEntityBranchDto[]) {
    this._model = value;
    if (value && value.length > 0) {
      let ids = value.map(b => b.branchId).join(',');
      const params = new HttpParams().set('ids', ids);
      this.clinicHttpClient.Get<BranchDto[]>('/branch-list', {params})
        .subscribe(response => {
          this._model.map(b => {
            let obj = response.find(r => r.id == b.branchId);
            if (obj && obj.companyName) {
              b.branchName = obj.companyName;
            }
          });
        });

    }
  }
  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(SearchBranchesComponent, {
      data: new BillingEntityBranchDto()
    });
    dialogRef.afterClosed()
      .subscribe(value => {
        if (value && value.id && this._model.filter(m => m.branchId == value.id).length == 0) {
          this._model.push({
            billingEntityId: this.billingEntityId,
            branchId: value.id,
            branchName: value.companyName,
          } as BillingEntityBranchDto);
        }
      });
  }

  removeBranch(i: number) {
    this.model.splice(i, 1);
  }
}
