import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  constructor() { }

  private Count: number = 0;
  isBusy: boolean = false;

  increment(promise: Promise<any>): void {
    this.Count += 1;
    this.updateIsBusy(promise);
  }

  decrement(): void {
    this.Count -= 1;
    if (this.Count < 0) {
      this.Count = 0;
    }
    this.updateIsBusy(null);
  }

  resetCounter(): void {
    this.Count = 0;
  }

  private updateIsBusy(promise: Promise<any>) {
    var originalBusy = this.isBusy;
    if (this.Count > 0) {
      setTimeout(() => { this.isBusy = true; });

    }
    else {
      setTimeout(() => { this.isBusy = false; });
    }
    if (originalBusy !== this.isBusy) {
      this.triggerBusyChanged(this.isBusy);
    }
    if (promise !== undefined && promise !== null) {
      this.triggerBusyStarted(promise);
    }
  }

  private busyChangedHandlers: { (data: boolean): void; }[] = [];
  private busyStartedHandlers: { (promise: Promise<any>): void; }[] = [];

  public onBusyStarted(handler: { (promise: Promise<any>): void }) {
    this.busyStartedHandlers.push(handler);
  }

  public offBusyStarted(handler: { (promise: Promise<any>): void }) {
    this.busyStartedHandlers = this.busyStartedHandlers.filter(h => h !== handler);
  }

  private triggerBusyStarted(promise: Promise<any>) {
    this.busyStartedHandlers.slice(0).forEach(h => h(promise));
  }

  public onBusyChanged(handler: { (data: boolean): void }) {
    this.busyChangedHandlers.push(handler);
  }

  public offBusyChanged(handler: { (data: boolean): void }) {
    this.busyChangedHandlers = this.busyChangedHandlers.filter(h => h !== handler);
  }

  private triggerBusyChanged(data: boolean) {
    this.busyChangedHandlers.slice(0).forEach(h => h(data));
  }
}
