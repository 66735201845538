export class RegionEntryPayorDto {
  regionPayorId: number;
  regionId: number;
  payorId: number;
  rank: number;
  chargeCodePayorAssociationMode: string;
  visitReasonPayorAssociationMode: string;
  chargeCodeAndVisitReasonBinaryMode: string;
  payorName: string;
  payorType: string;
  billingFrequencyType: string;
  ssnDisplayType: string;
  startDateInclusive: Date;
  endDateInclusive: Date;
  active: boolean;
}
