import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from '../../../../../common/billing-http-client.service';
import {Router} from '@angular/router';
import {DataTablesResponse} from '../../../../../models/common/data-tables-response';
import {ClaimAuditDto} from '../../../../../models/invoice-detail/claim/claim-audit-dto';
import {DragulaService} from 'ng2-dragula';
import {DataTableDirective} from 'angular-datatables';

@Component({
  selector: 'app-claim-audit',
  templateUrl: './claim-audit.component.html',
  styleUrls: ['./claim-audit.component.scss'],
})
export class ClaimAuditComponent implements OnInit {


  dtOptions: DataTables.Settings = {};
  model: ClaimAuditDto[] = [];
  invoiceId: number;

  @ViewChild(DataTableDirective, {static: false})
  private datatableElement: DataTableDirective;

  constructor(private billingHttpClient: BillingHttpClient) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columns: [
        {data: 'description'},
        {data: 'time'},
        {data: 'user'},
      ],
      order: [[1, 'desc']],
      ajax: (dataTableParams:any, callback) => {
        dataTableParams.invoiceId = this.invoiceId;

        this.billingHttpClient.Post<DataTablesResponse>('/claim-audit', dataTableParams)
          .subscribe(a_resp => {
            this.model = a_resp.data;
            callback({
              recordsTotal: a_resp.recordsTotal,
              recordsFiltered: a_resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }


  reload(){
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.draw();
      }
    );
  }
}
