import {InvoiceDetailTransactionTotalsDto} from "./invoice-detail-transaction-totals-dto";
import {InvoiceDetailAdjustedInvoicesDto} from "./invoice-detail-adjusted-invoices-dto";
import {DateDto} from "../../common/date-dto";

export class InvoiceDetailSummaryDto {
  invoiceId: number;
  invoiceNumberDisplay: string;
  employerId: number;
  employer: string;
  facilityId: number;
  facility: string;
  billingEntityId: number;
  billingEntity: string;
  regionId: number;
  region: string;
  jobNumber: string;
  purchaseOrder: string;
  testReasonType: string;
  patientId: number;
  patientFirstName: string;
  patientLastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  contact: string;
  contactPhone: string;
  groupingKey: string;
  startDate: string;
  endDate: string;
  dateDistributed: string;
  lastAdjustmentDate: string;
  invoiceAmount: number;
  transactionTotalsDto: InvoiceDetailTransactionTotalsDto = new InvoiceDetailTransactionTotalsDto();
  invoiceAdjustmentsDto: InvoiceDetailAdjustedInvoicesDto = new InvoiceDetailAdjustedInvoicesDto();
  status: string;
  claimNumber: string;
  branch: string;
}
