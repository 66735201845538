export class ProviderDto {
  id: number;
  credentials: string;
  contactID: number;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  gender: string;
  dateOfBirth: Date;
  providerNPI: string;
}
