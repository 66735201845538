<h1>Dashboard</h1>
<p>{{getUpdatedMinutes(model)}}</p>
<div class="row">
  <div class="col-md-3 {{showTile(tile.title)}}" *ngFor="let tile of model">
    <div class="tile {{tile.color}}" (click)="navigateToTile(tile)">
      <div *ngIf="tile.type ==1">
        <div class="row">
          <div class="col-md-12 tile-title">
            <h4>{{tile.title}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr class="divider black-divider"/>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <span class="tile-count">{{formatNumberWithMileStones(tile.count)}}</span>
          <i class="fa fa-5x fa-{{tile.icon}} pull-right tile-icon"></i>
        </div>
      </div>
      <div *ngIf="tile.type == 2">
        <div class="d-flex justify-content-between">
          <div class="tile-title">
            <h4>{{tile.title}}</h4>
          </div>
          <span class="tile-count">{{formatNumberWithMileStones(tile.count)}}</span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr class="divider black-divider"/>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="tile-title">
            <h4>{{tile.subTitle}}</h4>
          </div>
          <span class="tile-subcount">{{formatNumberWithMileStones(tile.subCount)}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
