<h1>Charge Code</h1>
<hr>
<div>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="General">
      <app-charge-code-entry-general [model]="model"></app-charge-code-entry-general>
    </mat-tab>
    <mat-tab label="Pricing">
      <app-charge-code-entry-pricing [model]="model.chargeCodePrices"></app-charge-code-entry-pricing>
    </mat-tab>
  </mat-tab-group>
</div>
