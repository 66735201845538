import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  name: string = "";

  constructor(
    private router: Router,
    // private oauthService: AuthenticationService,
    private _service: AuthenticationService) {
  }

  ngOnInit(): void {
    this._service.getUserSubject().subscribe(user => {
      this.name = this.getDisplayName(user?.name ?? "");
    })
  }

  getDisplayName(name: string) {
    if (name) {
      return name.substring(0, name.indexOf('@'));
    }
    return "";
  }

  get isAuthenticated() {
    return this._service.isAuthenticated();
  }

  logout() {
    this._service.logout();
  }

  // refresh() {
  //   this.oauthService.silentRefresh();
  // }

  get isBillingMaster() {
    return this._service.hasRole('ROLE_billing_master_admin');
  }

}
