import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {PayorListSearchRow} from '../../../../models/common/payor-list-search-row';
import {ClinicalDocConfigService} from '../clinical-doc-config.service/clinical-doc-config.service';
import {DataTablesResponse} from '../../../../models/common/data-tables-response';
import {MatDialogRef} from '@angular/material/dialog';
import {ClinicalDocumentConfigurationDto} from '../../../../models/clinical-document-configuration/ClinicalDocumentConfigurationDto.model';

@Component({
  selector: 'app-clinical-document-lookup',
  templateUrl: './clinical-document-lookup.component.html',
  styleUrls: ['./clinical-document-lookup.component.scss']
})
export class ClinicalDocumentLookupComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  model: ClinicalDocumentConfigurationDto[] = [];

  constructor(private clinicalDocConfigService: ClinicalDocConfigService,
              private dialogRef: MatDialogRef<ClinicalDocumentLookupComponent>) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'documentName'},
      ],
      ajax: (dataTableParams: any, callback) => {
        this.clinicalDocConfigService.getClinicalDocuments(dataTableParams)
          .subscribe(resp => {
            this.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  onSelect(document: ClinicalDocumentConfigurationDto) {
    this.dialogRef.close(document);
  }

  onView(clinicalDoc: ClinicalDocumentConfigurationDto) {
    this.clinicalDocConfigService.viewClinicalDocument(clinicalDoc);
  }
}
