<h3>{{model.facilityName}} -- {{model.branchName}}</h3>
<form #form="ngForm">
  <div class="row">
    <div class="col-md-12">
      <hr class="divider"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="control-label">Address Line 1:</label><span class="required">*</span>
        <input type="text" class="form-control" name="Address1" [(ngModel)]="model.address1"/>
      </div>
      <div class="form-group">
        <label class="control-label">Address Line 2:</label>
        <input type="text" class="form-control" name="Address2" [(ngModel)]="model.address2"/>
      </div>
      <div class="form-group">
        <label class="control-label">City:</label><span class="required">*</span>
        <input type="text" class="form-control" name="City" [(ngModel)]="model.city"/>
      </div>
      <div class="form-group">
        <label class="control-label">State</label><span class="required">*</span>
        <select class="form-control" name="State" [(ngModel)]="model.state">
          <option value="">(select)</option>
          <option *ngFor="let state of stateList" [value]="state.value">{{state.display}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="control-label">Postal Code:</label><span class="required">*</span>
        <input type="text" class="form-control" name="PostalCode" [(ngModel)]="model.postalCode"/>
      </div>
    </div>
  </div>
  <div class="right-align">
    <button class="btn btn-warning has-spinner mr-2" (click)="cancel()">
      <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
      <i class="fa fa-times"></i>
      Cancel
    </button>
    <button class="btn btn-success has-spinner mr-2" (click)="save()">
      <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
      <i class="fa fa-check-square-o"></i>
      Save
    </button>
    <button class="btn btn-success has-spinner mr-2" (click)="saveClose()">
      <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
      <i class="fa fa-check-square-o"></i>
      Save and Close
    </button>
  </div>
</form>

