<button type="button" class="btn btn-success mt-3" (click)="performCall()"> Perform Call</button>
<div class="row mt-3">
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
           style="width: 100%">
      <thead>
      <tr>
        <th>Caller</th>
        <th>Date</th>
        <th>Notes</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let call of model">
        <td>
          {{call.userName}}
        </td>
        <td>
          {{call.time | date: "M/d/yyyy hh:mm:ss a"}}
        </td>
        <td>
          {{call.notes}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
