import {Component, Input, OnInit} from '@angular/core';
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {EmployerEntryGeneralDto} from "../../../../models/employer-entry/employer-entry-general-dto";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";
import {SalesRepresentativeDto} from "../../../../models/employer-entry/sales-representative-dto";
import {State, StateList} from "../../../../utilities/state-list";

@Component({
  selector: 'app-employer-entry-general',
  templateUrl: './employer-entry-general.component.html',
  styleUrls: ['./employer-entry-general.component.scss']
})
export class EmployerEntryGeneralComponent implements OnInit {

  @Input() id: number;
  model: EmployerEntryGeneralDto = new EmployerEntryGeneralDto();
  stateList: State[] = StateList.STATES;
  salesRepresentativeDtos: SalesRepresentativeDto[] = [];

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private validationResultsService: ValidationResultsService
  ) {
  }

  ngOnInit(): void {
    const params = new HttpParams().set('id', this.id.toString());
    this.clinicHttpClient.Get<EmployerEntryGeneralDto>('/employer-entry', {params})
      .subscribe(response => {
        this.model = response;
      });
    this.clinicHttpClient.Get<SalesRepresentativeDto[]>('/sales-representative' )
      .subscribe(response => {
        this.salesRepresentativeDtos = response;
      });
  }

  saveClose() {
    this.commit().then(response => {
      if (response.result != 'success') {
        this.validationResultsService.setErrorMessages(response.validationResults);
      } else {
        this.validationResultsService.setSuccessMessages(["Save succeeded"]);
        this.cancel();
      }
    });
  }

  save() {
    this.commit().then(response => {
      if (response.result != 'success') {
        this.validationResultsService.setErrorMessages(response.validationResults);
      } else {
        this.validationResultsService.setSuccessMessages(["Save succeeded"]);
      }
    });
  }

  commit(): Promise<any> {
    return this.clinicHttpClient.Post<CommonResult>('/employer-entry', this.model).toPromise()
  }

  cancel() {
    window.history.back();
  }
}
