import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {
  AdjustmentBatchDataService,
  BranchPayment,
  EntityInvoiceAdjustment
} from "../../../../services/adjustment-batch.data.service";
import {ApplyAdjustmentControlTotal} from "../../../../models/adjustment-batch/apply-adjustments/apply-adjustment-control-total";
import {Router} from "@angular/router";

@Component({
  selector: 'review-finalize',
  templateUrl: './review-finalize.component.html',
  styleUrls: ['./review-finalize.component.scss']
})
export class ReviewFinalizeComponent implements OnInit {

  model: ApplyAdjustmentControlTotal[] = [];
  deleteStatus: string = 'ready'; // is either 'waiting' or 'ready'
  entityInvoiceAdjustment: EntityInvoiceAdjustment;
  branchPayments: BranchPayment[] = [];
  editable: boolean = true;
  postedDate: Date;

  constructor(private router: Router,
              private dataService: AdjustmentBatchDataService) {
    this.entityInvoiceAdjustment = dataService.getEntityInvoiceAdjustment();
    this.branchPayments = dataService.branchPayments;
  }

  ngOnInit() {
    if (!this.dataService.initializedSteps.includes(3)) {
      return;
    }
    this.postedDate = this.dataService.controlTotalsDto.postedDate;
    this.editable = this.dataService.editable;
    this.model = this.dataService._applyAdjustmentControlTotals;
  }

  deleteAdjustmentBatch() {
    this.deleteStatus = 'waiting';
    this.dataService.deleteAdjustmentBatch(
      () => this.router.navigate(['adjustment-batches']),
      () => {
        alert('Failed to delete adjustment batch.');
        this.deleteStatus = 'ready';
      }
    );
  }

  getKeys(map) {
    return Array.from(map.keys());
  }

  finalizeAndPostBatch() {
    if (!this.postedDate) {
      alert("Post Date is required");
      return;
    }
    this.dataService.finalize(this.postedDate);
    this.router.navigate(['adjustment-batches']);
  }

  hasAnyRemaining() {
    return this.model.some((total) => total.remaining != 0);
  }

  showDeleteSection() {
    return this.dataService.adjustmentBatchId > 0;
  }
}
