import { Injectable } from '@angular/core';
import {ClinicHttpClient} from '../../../../common/clinic-http-client';
import {ClinicalDocumentConfigurationDto} from '../../../../models/clinical-document-configuration/ClinicalDocumentConfigurationDto.model';
import {Subject} from 'rxjs';
import {EmployerDto} from '../../../../models/common/employer-dto';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {TestTypeLookupDto} from '../../../../models/clinical-document-configuration/TestTypeLookupDto.model';
import {DataTablesResponse} from '../../../../models/common/data-tables-response';
import {map} from 'rxjs/operators';
import {JobNumberDto} from '../../../../models/clinical-document-configuration/JobNumberDto.model';
import {DocumentHttpClient} from '../../../../common/document-http-client';
import {BaseHttpClient} from '../../../../common/base-http-client';
import {BillingHttpClient} from '../../../../common/billing-http-client.service';
import {CommonResult} from '../../../../models/common/common-result';

@Injectable({
  providedIn: 'root'
})
export class ClinicalDocConfigService {

  curLevelDocs: ClinicalDocumentConfigurationDto[] = [];
  curLevelDocsUpdated = new Subject<ClinicalDocumentConfigurationDto[]>();

  inheritedDocs: ClinicalDocumentConfigurationDto[] = [];
  inheritedDocsUpdated = new Subject<ClinicalDocumentConfigurationDto[]>();

  hiddenDocs: ClinicalDocumentConfigurationDto[] = [];
  hiddenDocsUpdated = new Subject<ClinicalDocumentConfigurationDto[]>();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private clinicHttpClient: ClinicHttpClient,
    private documentHttpClient: DocumentHttpClient) { }

  getClinicalDocuments(dataTablesParameters: any) {
    return this.clinicHttpClient
      .Post<DataTablesResponse>('/clinical-documents', dataTablesParameters)
      .pipe(map(clinicalDocs => {
        clinicalDocs.data = clinicalDocs.data.map(doc => {
          return {
            clinicalDocumentId: doc.clinicalDocumentId,
            documentName: doc.documentName,
          } as ClinicalDocumentConfigurationDto;
        });
        return clinicalDocs;
      }));
  }

  getCurLevelDocuments(testTypeId: string, employerId: string, jobNumberId: string) {
    const params = this.initParams(testTypeId, employerId, jobNumberId);
    this.clinicHttpClient.Get<ClinicalDocumentConfigurationDto[]>('/clinical-document-configuration/current-level-documents', {params})
      .subscribe(docs => {
        this.curLevelDocs = docs;
        this.curLevelDocsUpdated.next(this.curLevelDocs.slice());
      });
  }

  getInheritedDocuments(testTypeId: string, employerId: string, jobNumberId: string) {
    const params = this.initParams(testTypeId, employerId, jobNumberId);
    this.clinicHttpClient.Get<ClinicalDocumentConfigurationDto[]>('/clinical-document-configuration/inherited-documents', {params})
      .subscribe(docs => {
        this.inheritedDocs = docs;
        this.inheritedDocsUpdated.next(this.inheritedDocs.slice());
      });
  }

  getHiddenDocuments(testTypeId: string, employerId: string, jobNumberId: string) {
    const params = this.initParams(testTypeId, employerId, jobNumberId);
    this.clinicHttpClient.Get<ClinicalDocumentConfigurationDto[]>('/clinical-document-configuration/hidden-documents', {params})
      .subscribe(docs => {
        this.hiddenDocs = docs;
        this.hiddenDocsUpdated.next(this.hiddenDocs);
      });
  }

  hideInheritedDoc(inheritedDoc: ClinicalDocumentConfigurationDto, testTypeId: string, employerId: string, jobNumberId: string) {
    const params = this.initParams(testTypeId, employerId, jobNumberId);
    this.clinicHttpClient.Get<CommonResult>('/hide-inherited-document/' + inheritedDoc.clinicalDocumentId, {params})
      .subscribe(response => {
        if (response.result !== 'failed') {
          this.deleteInheritedDocument(inheritedDoc);
          inheritedDoc.clinicalDocumentConfigurationId = +response.result;
          this.hiddenDocs.push(inheritedDoc);
          this.hiddenDocsUpdated.next(this.hiddenDocs.slice());
        }
      });
  }

  unhideHiddenDoc(hiddenDoc: ClinicalDocumentConfigurationDto) {
    this.clinicHttpClient.Get<CommonResult>('/unhide-hidden-document/' + hiddenDoc.clinicalDocumentConfigurationId)
      .subscribe(response => {
        if (response.result === 'success') {
          this.deleteHiddenDocument(hiddenDoc);
          this.inheritedDocs.push(hiddenDoc);
          this.inheritedDocsUpdated.next(this.inheritedDocs.slice());
        }
      });
  }

  deleteCurLevelDocument(deletedDoc: ClinicalDocumentConfigurationDto) {
    this.clinicHttpClient.Delete('/delete-current-level-document/' + deletedDoc.clinicalDocumentConfigurationId)
      .subscribe((response: CommonResult) => {
        if (response.result === 'success') {
          this.curLevelDocs = this.curLevelDocs.filter(doc => doc !== deletedDoc);
          this.curLevelDocsUpdated.next(this.curLevelDocs.slice());
        }
      });
  }

  deleteInheritedDocument(deletedDoc: ClinicalDocumentConfigurationDto) {
    this.inheritedDocs = this.inheritedDocs.filter(doc => doc !== deletedDoc);
    this.inheritedDocsUpdated.next(this.inheritedDocs.slice());
  }

  deleteHiddenDocument(deletedDoc: ClinicalDocumentConfigurationDto) {
    this.hiddenDocs = this.hiddenDocs.filter(doc => doc !== deletedDoc);
    this.hiddenDocsUpdated.next(this.hiddenDocs.slice());
  }

  addSelectedDocument(document: ClinicalDocumentConfigurationDto, testTypeId: string, employerId: string, jobNumberId: string) {
    const params = this.initParams(testTypeId, employerId, jobNumberId);
    this.clinicHttpClient.Get<CommonResult>('/add-current-level-document/' + document.clinicalDocumentId, {params})
      .subscribe(response => {
        if (response.result !== 'failed') {
          document.clinicalDocumentConfigurationId = +response.result;
          this.curLevelDocs.push(document);
          this.curLevelDocsUpdated.next(this.curLevelDocs.slice());
        }
      });
  }

  getTestTypes() {
    return this.clinicHttpClient.Get<TestTypeLookupDto[]>('/test-types');
  }

  getJobNumbers(employerId: string) {
    const params = new HttpParams()
      .set('employerId', employerId);
    return this.billingHttpClient
      .Get<JobNumberDto[]>('/job-numbers', {params});
  }

  getEmployerSearchRows() {
    return this.clinicHttpClient
      .Get<any>('/get-all-employers')
      .pipe(map(employers => {
        return employers.map(employer => {
          return {
            id: employer.employerId,
            companyName: employer.employerName,
          } as EmployerDto;
        });
      }));
  }

  getEmployerForTestType(testTypeId: number) {
    return this.clinicHttpClient
      .Get<any>('/get-employers-for-test-type/' + testTypeId)
      .pipe(map(companies => {
        return companies.map(company => {
          return {
            id: company.employerId,
            companyName: company.employerName,
          } as EmployerDto;
        });
      }));
  }

  uploadClinicalDocument(formData: FormData) {
    this.documentHttpClient
      .Post<any>('/clinical-documents', formData)
      .subscribe(response => {
        // response.result === 'success'
      });
  }

  viewClinicalDocument(doc: ClinicalDocumentConfigurationDto) {
    this.documentHttpClient
      .Get<Blob>('/clinical-document-pdf/' + doc.clinicalDocumentId, {responseType: 'blob' as 'json'})
      .subscribe(response => {
        this.downloadPdf(response, doc.documentName);
      });
  }

  downloadPdf(blob: Blob, documentName: string) {
    const newBlob = new Blob([blob], {type: 'application/pdf'})
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = documentName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  initParams(testTypeId, employerId, jobNumberId): HttpParams {
    let params = new HttpParams();
    if (testTypeId) {
      params = params.set('testTypeId', testTypeId);
    }
    if (employerId) {
      params = params.set('employerId', employerId);
    }
    if (jobNumberId) {
      params = params.set('jobNumberId', jobNumberId);
    }
    return params;
  }

}
