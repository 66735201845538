<h4 class="mt-3">Regions Assigned to this Employer</h4>
<table datatable class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Region Name</th>
    <th>Address</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success" (click)="goToRegion(row.regionId)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.name}}</td>
    <td>{{row.formattedAddress}}</td>
  </tr>
  </tbody>
</table>
