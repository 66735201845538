export class DecimalUtility {
  public static countDecimals(value: number): number {
    if (value != null && value.toString() != "") {
      const intValue = parseFloat(value.toString());
      if (Math.floor(intValue.valueOf()) === intValue.valueOf()) return 0;
      return intValue.toString().split(".")[1].length || 0;
    } else {
      return 0;
    }
  }
}
