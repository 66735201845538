import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import { DataTablesResponse } from 'src/app/models/common/data-tables-response';
import {PayorListSearchRow} from "../../../models/common/payor-list-search-row";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

declare var $: any;

@Component({
  selector: 'app-payor-list',
  templateUrl: './payor-list.component.html',
  styleUrls: ['./payor-list.component.scss']
})
export class PayorListComponent implements OnInit, AfterViewInit { // supposed to have implements oninit

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};
  model: PayorListSearchRow[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private billingHttpClient: BillingHttpClient,
    private router: Router) {
  }

  ngOnInit(): void {
    const that = this;
    // Setup - add a text input to each footer cell
    $('#tb-payor-list thead tr').clone(true).appendTo('#tb-payor-list thead');
    $('#tb-payor-list thead tr:eq(1) th').each(function (i) {
      const title = $(this).text();
      if (i == 4) {
        $(this).html('<input id="input-active" type="text" placeholder="Search ' + title + '" value="true"/>');
      } else {
        $(this).html('');
      }

      $('input', this).on('keyup change', function () {
        // datatableElement is null if it's put directly in the ngOnInit
        that.dtElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            if (dtInstance.column(i).search() !== this.value) {
              dtInstance
                .column(i)
                .search(this.value)
                .draw();
            }
          }
        );
      });
    });
    this.dtOptions = {
      pagingType: "full_numbers",
      orderCellsTop: true,
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: 'payorId'},
        {data: 'payorType'},
        {data: 'name'},
        {data: 'isGlobal'},
        {data: 'isActive'},
      ],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/payor-list", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-2 'B><'col-sm-3'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        {
          text: '<i class="fas fa-plus"></i><span style="margin-left: 5px;">Create</span>',
          className: "btn btn-success float-right",
          action: function (e, dt, node, config) {
            that.router.navigate(['payor-entry'])
          }
        }
      ]
    };

  } // ngOnInit

  goToPayor(payorId: number) {
    this.router.navigate(['payor-entry', payorId]);
    console.log(payorId);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    $('#input-active').keyup();
  }
}
