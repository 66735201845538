import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from '../../../../../common/billing-http-client.service';
import {Router} from '@angular/router';
import {DataTablesResponse} from '../../../../../models/common/data-tables-response';
import {InvoiceEmailDeliverLogDto} from '../../../../../models/invoice-email-deliver-log/invoice-email-deliver-log-dto';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-invoice-email-deliver-log',
  templateUrl: './invoice-email-deliver-log.component.html',
  styleUrls: ['./invoice-email-deliver-log.component.scss']
})
export class InvoiceEmailDeliverLogComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  model: InvoiceEmailDeliverLogDto[] = [];
  @Input() params: Map<string, any>;

  constructor(private billingHttpClient: BillingHttpClient,
              private  router: Router) {
  }

  ngOnInit(): void {
    const that = this;
    // Setup - add a text input to each footer cell
    $('#tb-mail thead tr').clone(true).appendTo('#tb-mail thead');
    $('#tb-mail thead tr:eq(1) th').each(function (i) {
      const title = $(this).text();
      $(this).html('<input id="input-'+ i +'" type="text" placeholder="Search" value="" style="width: 100%"/>');

      $('input', this).on('keyup change', function () {
        // datatableElement is null if it's put directly in the ngOnInit
        that.dtElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            if (dtInstance.column(i).search() !== this.value) {
              dtInstance
                .column(i)
                .search(this.value)
                .draw();
            }
          }
        );
      });
    });
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columns: [
        {data: 'sentAt'},
        {data: 'payor'},
        {data: 'invoiceNumberDisplay'},
        {data: 'status'},
        {data: 'sendTo'},
        {data: 'sendFrom'},
        {data: 'mailSubject'},
      ],
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        if (that.params != null && that.params.size > 0) {
          that.params.forEach((val, key) => {
            dataTablesParameters[key] = val;
          });
        }
        that.billingHttpClient.Post<DataTablesResponse>("/invoice-submission-email", dataTablesParameters)
          .subscribe(resp => {
              that.model = resp.data;
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: []
              });
          });
      },
    };
  }

}
