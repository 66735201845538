import {Component, Input} from '@angular/core';
import {BillingEntityEntryDto} from "../../../../models/billing-entity-entry/billing-entity-entry-dto";
import {State, StateList} from "../../../../utilities/state-list";

@Component({
  selector: 'app-billing-entity-entry-general',
  templateUrl: './billing-entity-entry-general.component.html',
  styleUrls: ['./billing-entity-entry-general.component.scss']
})
export class BillingEntityEntryGeneralComponent {

  @Input() model: BillingEntityEntryDto;
  stateList: State[] = StateList.STATES;

  constructor() {
  }
}
