<h1>Invoices Past Due</h1>
<table id="tb-invoices-past-due" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>#</th>
    <th>Amount</th>
    <th>Employer</th>
    <th>Payor</th>
    <th>Payor Type</th>
    <th>Region</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success btn-edit" (click)="goToInvoice(row.invoiceId)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.invoiceNumberDisplay}}</td>
    <td><displayable-money [model]="row.invoiceAmount"></displayable-money></td>
    <td>{{row.employer}}</td>
    <td>{{row.payor}}</td>
    <td>{{row.payorType}}</td>
    <td>{{row.region}}</td>
  </tr>
  </tbody>
</table>
