import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FacilityPayorEntryGeneralDto} from '../../../../models/facility-payor-entry/facility-payor-entry-general-dto';

@Component({
  selector: 'app-facility-payor-entry-general',
  templateUrl: './facility-payor-entry-general.component.html',
  styleUrls: ['./facility-payor-entry-general.component.scss']
})
export class FacilityPayorEntryGeneralComponent implements OnInit {

  constructor() {
  }

  @Input() model: FacilityPayorEntryGeneralDto = new FacilityPayorEntryGeneralDto();
  @ViewChild('formGeneral') formGeneral: NgForm;

  ngOnInit(): void {
  }

}

