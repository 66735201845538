import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {ActivatedRoute} from "@angular/router";
import {BranchDto} from "../../../models/common/branch-dto";
import {BillingGroupListDto} from "../../../models/common/billing-group-list-dto";
import {FacilityDto} from "../../../models/common/facility-dto";
import {PayorListSearchRow} from "../../../models/common/payor-list-search-row";
import {NgForm} from "@angular/forms";
import {PendingFinancialTransactionDto} from "../../../models/common/pending-financial-transaction-dto";
import {CommonResult} from "../../../models/common/common-result";
import {HttpParams} from "@angular/common/http";
import {TaskHttpClient} from "../../../common/task-http-client";
import {ChargeCodeLookupSearchRow} from "../../../models/common/charge-code-lookup-search-row";

@Component({
  selector: 'payor-debit-entry',
  templateUrl: './payor-debit-entry.component.html',
  styleUrls: ['./payor-debit-entry.component.scss']
})
export class PayorDebitEntryComponent implements OnInit {

  constructor(private billingHttpClient: BillingHttpClient,
              private clinicHttpClient: ClinicHttpClient,
              private taskHttpClient: TaskHttpClient,
              private validationResultsService: ValidationResultsService,
              private route: ActivatedRoute) {
  }

  transactionTypes: string[] = [];
  facilities: FacilityDto[] = [];
  branches: BranchDto[] = [];
  groupingKeys: BillingGroupListDto[] = [];

  transactionType = '';
  branch: BranchDto = null;
  facility: FacilityDto = null;
  serviceDate: Date;
  amount: number;
  payor: PayorListSearchRow = new PayorListSearchRow();
  notes: string;
  groupingKey: string;
  groupingKeyModel: BillingGroupListDto = null;
  chargeCode: ChargeCodeLookupSearchRow = new ChargeCodeLookupSearchRow();
  displayChargeCode: string;
  purchaseOrder: string;
  quantity: number = 1;

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (+params['id'] > 0) {
        this.load(+params['id']);
      }
    });
    this.getNonVoidDebitTransactionTypes();
    this.getFacilities();
    this.getBranches();
    this.getBillingGroups();
  }

  load(id: number) {
    this.billingHttpClient.Get<PendingFinancialTransactionDto>(`/pending-financial-transaction/${id}`, {})
      .subscribe(response => {
        this.transactionType = response.testReasonType;
        if (response.branchId) {
          this.branch = new BranchDto();
          this.branch.id = response.branchId;
          this.branch.companyName = response.branch;
        }
        if (response.facilityId) {
          this.facility = new FacilityDto();
          this.facility.id = response.facilityId;
          this.facility.companyName = response.facility;
        }
        this.serviceDate = response.serviceDate;
        this.groupingKey = response.groupingKey;
        this.notes = response.notes;
        this.amount = response.amount;
        if (response.payor != null) {
          this.payor.name = response.payor;
          this.payor.payorId = response.payorId;
        }
      });
  }

  getNonVoidDebitTransactionTypes() {
    if (this.transactionTypes.length == 0) {
      this.billingHttpClient.Get<string[]>('/non-void-debit-transaction-types')
        .subscribe(response => {
          this.transactionTypes = response;
        });
    }
  }

  getFacilities() {
    if (this.facilities.length == 0) {
      this.clinicHttpClient.Get<FacilityDto[]>('/debit-entry-facilities')
        .subscribe(response => {
          this.facilities = response;
        });
    }
  }

  getBranches() {
    if (this.branches.length == 0) {
      this.clinicHttpClient.Get<BranchDto[]>('/debit-entry-branches')
        .subscribe(response => {
          this.branches = response;
        });
    }
  }

  getBillingGroups() {
    if (this.groupingKeys.length == 0) {
      this.billingHttpClient.Get<BillingGroupListDto[]>('/debit-entry-billing-groups')
        .subscribe(response => {
          this.groupingKeys = response;
        });
    }
  }

  get canSave(): boolean {
    return this.transactionType
      && (this.facility || this.branch)
      && this.payor
      && this.amount
      && this.serviceDate != null
      && this.chargeCode.code
      && this.quantity != 0;
  }

  saveAndClose() {
    this.save().then(response => {
      if (response.result != 'failed') {
        this.onSaveAndCloseSucceeded();
        this.processSaved(response.result);
      }
    });
  }

  onSaveAndCloseSucceeded() {
    this.form.form.markAsPristine();
    window.history.back();
  }

  saveAndNew() {
    this.save().then(response => {
      if (response.result != 'failed') {
        this.onSaveAndNewSucceeded();
        this.processSaved(response.result);
      }
    });
  }

  onSaveAndNewSucceeded() {
    this.validationResultsService.setSuccessMessages(["Save Succeeded"]);
    this.serviceDate = null;
  }

  save(): Promise<any> {
    const pendingFinancialTransaction = {
      chargeCode: this.chargeCode.code,
      displayChargeCode: this.displayChargeCode,
      chargeCodeDescription: this.chargeCode.description,
      employerId: null,
      employer: null,
      regionId: null,
      region: null,
      jobSiteId: null,
      jobSite: null,
      branchId: this.branch == null ? null : this.branch.id,
      branch: this.branch == null ? null : this.branch.companyName,
      facilityId: this.facility == null ? null : this.facility.id,
      facility: this.facility == null ? null : this.facility.companyName,
      jobNumber: null,
      id: 0,
      patientId: null,
      patientFirstName: null,
      patientLastName: null,
      patientIdentifier: null,
      serviceDate: this.serviceDate,
      purchaseOrderNumber: this.purchaseOrder,
      providerId: null,
      provider: null,
      providerNPI: null,
      authorizationPatientTestTypeId: null,
      authorizationPatientSupplyId: null,
      authorizationPatientInjuryClinicNoteId: null,
      financialTransactionPendingImportType: this.transactionType,
      quantity: this.quantity,
      testReasonType: null,
      amount: this.amount,
      payor: this.payor.payorId == null || this.payor.payorId == 0 ? null : this.payor.name,
      payorId: this.payor.payorId == null || this.payor.payorId == 0 ? null : this.payor.payorId,
      parentFinancialTransactionId: null,
      specimenId: null,
      authorizationPatientId: null,
      groupingKey: this.groupingKey,
      notes: this.notes
    };

    return this.billingHttpClient
      .Post<CommonResult>('/financial-transaction-pending-import', pendingFinancialTransaction)
      .toPromise();
  }

  chargeCodeChange(data: ChargeCodeLookupSearchRow) {
    this.chargeCode = data;
  }

  setPayorOverride(value: PayorListSearchRow) {
    this.payor = value;
  }

  clearPayor() {
    this.payor = new PayorListSearchRow();
  }

  cancel() {
    window.history.back();
  }

  onSelectGroupingKey() {
    this.groupingKey = this.groupingKeyModel.billingGroupId.toString();
  }

  @ViewChild('form') form: NgForm;

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

  processSaved(id: string) {
    const params = new HttpParams().set('financialTransactionPendingImportId', id);
    this.taskHttpClient.Post('/process-pending-financial-transaction', params,
      {responseType: 'text' as 'json'})
      .subscribe(_ => {
      });
  }
}
