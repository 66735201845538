import {BranchPayorEntryGeneralDto} from './branch-payor-entry-general-dto';
import {BranchPayorEntryResponsibilitiesDto} from './branch-payor-entry-responsibilities-dto';

export class BranchPayorEntryDto {
  branchPayorId: number;
  branchId: number;
  branchName: string;
  payorId: number;
  payorName: string;
  branchPayorEntryGeneralDto: BranchPayorEntryGeneralDto = new BranchPayorEntryGeneralDto();
  branchPayorEntryResponsibilitiesDto: BranchPayorEntryResponsibilitiesDto = new BranchPayorEntryResponsibilitiesDto();
}
