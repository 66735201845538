import {FacilityPayorChargeCodeListItemDto} from './facility-payor-charge-code-list-item-dto';
import {FacilityPayorVisitReasonListItemDto} from './facility-payor-visit-reason-list-item-dto';

export class FacilityPayorEntryResponsibilitiesDto {
  chargeCodePayorAssociationMode: string;
  visitReasonPayorAssociationMode: string;
  chargeCodeAndVisitReasonBinaryMode: string;
  facilityPayorChargeCodes: FacilityPayorChargeCodeListItemDto[] = [];
  facilityPayorVisitReasons: FacilityPayorVisitReasonListItemDto[] = [];
}
