<h1>
  Charge Code Price Entry
</h1>
<div class="row mt-5">
  <div class="col-md-3">
    <div class="form-group">
      <label for="chargeCode">Charge Code</label><span class="text-danger">*</span>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#chargeCodeLookupModal">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <input type="text" class="form-control" id="chargeCode" name="chargeCode" [(ngModel)]="model.chargeCode"
               disabled/>
      </div>
    </div>
    <charge-code-lookup (chargeCodeChange)="chargeCodeChange($event)"></charge-code-lookup>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="payor">Payor</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <input type="text" class="form-control" id="payor" name="payor" [(ngModel)]="model.payor" disabled/>
        <div class="input-group-append">
          <button class="btn btn-warning" type="button" (click)="clearPayor()">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <payor-lookup (payorChange)="selectPayor($event)"></payor-lookup>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="payor">Branch</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-primary" type="button" (click)="openBranchDialog()"
                  [attr.disabled]="model.billingEntityId">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <input type="text" class="form-control" id="branch" name="branch" [(ngModel)]="model.branch" disabled/>
        <div class="input-group-append">
          <button class="btn btn-warning" type="button" (click)="clearBranch()">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <label class="control-label">Billing Entity</label>
    <br/>
    <select class="dropdown form-control" name="billing-entity" [(ngModel)]="model.billingEntityId"
            [attr.disabled]="model.branchId">
      <option [ngValue]="null"></option>
      <option *ngFor="let option of billingEntities" [ngValue]="option.entityId">{{option.entityName}}</option>
    </select>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <label for="chargeCode">Visit Reason</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#testReasonLookupModal">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <input type="text" class="form-control" id="visitReason" name="visitReason" [(ngModel)]="model.visitReason"
               disabled/>
        <div class="input-group-append">
          <button class="btn btn-warning" type="button" (click)="clearVisitReason()">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <visit-reason-lookup (testReasonChange)="visitReasonChange($event)"></visit-reason-lookup>
  </div>
  <div class="col-md-3">
    <div class="form-group mt-2">
      <mat-form-field class="w-100">
        <mat-label>Start Date<span class="text-danger">*</span></mat-label>
        <input matInput [matDatepicker]="picker" name="startDate" [(ngModel)]="model.startDateInclusive">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group mt-2">
      <mat-form-field class="w-100">
        <mat-label>End Date<span class="text-danger">*</span></mat-label>
        <input matInput [matDatepicker]="picker1" name="endDate" [(ngModel)]="model.endDateInclusive">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="price">Price</label><span class="text-danger">*</span>
      <input id="price" type="text" class="form-control" name="text" [(ngModel)]="model.price">
    </div>
  </div>
</div>
<div [hidden] ="!(historyModel.length > 0)">
  <div class="row">
    <div class="col-md-12">
      <hr class="divider"/>
    </div>
  </div>
  <h3>Price History</h3>
  <table id="tb-charge-code-price-history-list" datatable [dtOptions]="historyDtOptions"
         class="table table-striped table-bordered"
         style="width: 100%;">
    <thead>
    <tr>
      <th>Code</th>
      <th>Price</th>
      <th>Employer</th>
      <th>Region</th>
      <th>Payor</th>
      <th>Billing Entity</th>
      <th>Branch</th>
      <th>Start</th>
      <th>End</th>
      <th>Edit Date</th>
      <th>User</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of historyModel">
      <td>{{row.chargeCode}}</td>
      <td>
        <displayable-money [model]="row.price"></displayable-money>
      </td>
      <td>{{row.employer}}</td>
      <td>{{row.region}}</td>
      <td>{{row.payor}}</td>
      <td>{{row.billingEntity}}</td>
      <td>{{row.branch}}</td>
      <td>{{row.startDateInclusive | date:"MM/dd/yyyy"}}</td>
      <td>{{row.endDateInclusive | date:"MM/dd/yyyy"}}</td>
      <td>{{row.editDate | date:"MM/dd/yyyy HH:mm:ss"}}</td>
      <td>{{row.userName}}</td>
    </tr>
    </tbody>
  </table>
</div>
<div [hidden]="!(employerExceptions.length > 0)">
  <div class="row">
    <div class="col-md-12">
      <hr class="divider"/>
    </div>
  </div>
  <h3>Employer Exceptions</h3>
  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#employerLookupModal"><i class="fa fa-search"></i></button>
  <table id="tb-employer-exception-list" datatable [dtOptions]="exceptionDtOptions" class="table table-striped table-bordered"
         style="width: 100%;">
    <thead>
    <tr>
      <th></th>
      <th>Employer Name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of employerExceptions">
      <td><button type="button" class="btn btn-danger" (click)="deleteEmployerException(row.id)"><i
        class="fa fa-trash"></i></button></td>
      <td>{{row.employerName}}</td>
    </tr>
    </tbody>
  </table>
  <employer-lookup #employerLookup (employerChange)="setEmployer($event)"></employer-lookup>
</div>
<div class="right-align mt-3">
  <button class="btn btn-warning" (click)="cancel()">
    Cancel
  </button>
  <button class="btn btn-success ml-2" (click)="saveClose()">
    Save and Close
  </button>
</div>
<div class="modal fade" id="drasticChangeModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Price is updated by a drastic amount</h3>
        <p id="p-message"></p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="realSaveAndClose()">Confirm</button>
        <button class="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
