<div class="modal fade" id="messageModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="modal-title"><i *ngIf="icon" class="fa fa-{{icon}}"></i> {{title}}</h1>
        <p>{{message}}</p>
      </div>
      <div class="modal-footer">
        <button id="messageModalDismiss" type="button" class="btn btn-default" data-dismiss="modal" (click)="closeMessageModal()">Ok</button>
      </div>
    </div>
  </div>
</div>
