import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {InvoicesPastDueSearchRow} from "../../../models/invoices-past-due/invoices-past-due-search-row";

@Component({
  selector: 'invoices-past-due',
  templateUrl: './invoices-past-due.component.html',
  styleUrls: ['./invoices-past-due.component.scss']
})
export class InvoicesPastDueComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: InvoicesPastDueSearchRow[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private  router: Router) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: 'invoiceId'},
        {data: 'invoiceNumberDisplay'},
        {data: 'invoiceAmount'},
        {data: 'employer'},
        {data: 'payor'},
        {data: 'payorType'},
        {data: 'region'},
      ],
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/invoices-past-due", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  goToInvoice = (invoiceId) => {
    this.router.navigate(['invoice', invoiceId]);
  }
}
