import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChargeCodeLookupSearchRow} from "../../../../models/common/charge-code-lookup-search-row";
import {PayorChargeCodeDisplaySearchRow} from "../../../../models/payor-entry/payor-charge-code-display-search-row";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../../models/common/common-result";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

declare var $: any;

@Component({
  selector: 'payor-entry-charge-code-display',
  templateUrl: './payor-entry-charge-code-display.component.html',
  styleUrls: ['./payor-entry-charge-code-display.component.scss']
})
export class PayorEntryChargeCodeDisplayComponent implements OnInit, AfterViewInit {

  @Input() payorId: number;
  model: PayorChargeCodeDisplaySearchRow[] = [];
  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private billingHttpClient: BillingHttpClient,) {
  }

  editChargeCodeDisplay: PayorChargeCodeDisplaySearchRow = new PayorChargeCodeDisplaySearchRow();
  editChargeCode: ChargeCodeLookupSearchRow = new ChargeCodeLookupSearchRow();

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columns: [
        {data: 'payorChargeCodeDisplayId'},
        {data: 'description'},
        {data: 'chargeCode'},
        {data: 'chargeCodeDisplay'},
        {data: ''},
      ],
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.payorId = this.payorId;
        that.billingHttpClient.Post<DataTablesResponse>("/payor-charge-code-displays", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data as PayorChargeCodeDisplaySearchRow[];
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-2 'B><'col-sm-3'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        {
          text: '<i class="fas fa-plus"></i><span style="margin-left: 5px;">Create</span>',
          className: "btn btn-success float-right",
          action: function (e, dt, node, config) {
            that.createDisplay();
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  chargeCodeChange(row: ChargeCodeLookupSearchRow) {
    this.editChargeCodeDisplay.chargeCode = row.code;
  }

  editDisplay = (row: PayorChargeCodeDisplaySearchRow) => {
    this.editChargeCodeDisplay = row;
    this.editChargeCode = new ChargeCodeLookupSearchRow();
    $('#chargeCodeDisplayModal').modal('show');
  }

  // User by reallyClick
  // noinspection JSUnusedGlobalSymbols
  deleteDisplay = (row: PayorChargeCodeDisplaySearchRow) => {
    const params = new HttpParams().set('id', row.payorChargeCodeDisplayId.toString());
    this.billingHttpClient.Delete<CommonResult>('/payor-charge-code-display', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          this.reloadTable();
        } else {
          alert('Delete failed');
        }
      });
  }

  createDisplay = () => {
    this.editChargeCodeDisplay = new PayorChargeCodeDisplaySearchRow();
    this.editChargeCode = new ChargeCodeLookupSearchRow();
    $('#chargeCodeDisplayModal').modal('show');
  }

  saveChargeCodeDisplay() {
    let payload = {
      chargeCode: this.editChargeCodeDisplay.chargeCode,
      chargeCodeDescription: this.editChargeCodeDisplay.chargeCodeDescription,
      chargeCodeDisplay: this.editChargeCodeDisplay.chargeCodeDisplay,
      payorChargeCodeDisplayId: this.editChargeCodeDisplay.payorChargeCodeDisplayId,
      payorId: this.payorId
    } as PayorChargeCodeDisplaySearchRow;
    this.billingHttpClient.Post<CommonResult>('/payor-charge-code-display', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          this.reloadTable();
        } else {
          alert('Save failed');
        }
      })
  }

  reloadTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}
