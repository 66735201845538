<br/>
<button *ngIf="!allCollapsed" (click)="toggleAllCollapse()" class="btn btn-success btn-sm" type="button">Collapse All
</button>
<button *ngIf="allCollapsed" (click)="toggleAllCollapse()" class="btn btn-success btn-sm" type="button">Expand All
</button>
<br/>
<div class="invoice-container" *ngFor="let invoice of model">
  <div class="row invoice-header">
    <div class="col-md-1 cursor-pointer" (click)="toggleCollapse(invoice.invoiceId)">
      #{{invoice.invoiceNumberDisplay}}</div>
    <div class="col-md-2">{{invoice.payor}}</div>
    <div class="col-md-2">{{invoice.invoicePeriod}}</div>
    <div class="col-md-3"><a *ngIf="!disabled && controlTotalType != null" class=""
                             (click)="smartApplyAdjustmentToInvoiceDebits(controlTotalType, invoice)">Smart
      Apply {{controlTotalType}} <i class="fa fa-angle-double-right" aria-hidden="true"></i></a></div>
    <div class="col-md-2">Amount:
      <displayable-money [model]="invoice.amount"></displayable-money>
    </div>
    <div class="col-md-2">Balance:
      <displayable-money [model]="invoice.updatedBalance"></displayable-money>
    </div>
  </div>
  <table
    *ngIf="(!collapsedMap.has(invoice.invoiceId) || !collapsedMap.get(invoice.invoiceId)) && invoice.debits.length <= 10"
    class="table table-bordered table-striped table-responsive table-condensed table-hover">
    <thead>
    <tr>
      <th class="w-5">Date</th>
      <th class="w-5">Patient</th>
      <th class="w-5">Type</th>
      <th class="w-20">Description</th>
      <th class="w-5">Quantity</th>
      <th class="w-5">Amount</th>
      <th class="w-5">Balance</th>
      <th class="w-30"></th>
      <th class="w-20">Adjustments</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="invoice.adjustments.length > 0">
      <tr>
        <td class="w-5"></td>
        <td class="w-5"></td>
        <td class="w-5"></td>
        <td class="w-20">Invoice Level Adjustments</td>
        <td class="w-5"></td>
        <td class="w-5">
          <displayable-money class="float-right" [model]="invoice.amount"></displayable-money>
        </td>
        <td class="w-5">
          <displayable-money class="float-right" [model]="invoice.updatedBalance"></displayable-money>
        </td>
        <td class="w-30">
          <adjustment-creator #adjustmentCreator
                              [disabled]="disabled"
                              [financialTransactionTypeOptions]="financialTransactionTypeOptions"
                              (adjustmentAdded)="addInvoiceAdjustment($event, invoice)"
                              (smartApplyAdjustment)="smartApplyAdjustmentToInvoice($event, invoice)"></adjustment-creator>
        </td>
        <td class="w-20">
          <adjustments [model]="invoice.adjustments" (adjustmentRemoved)="removeInvoiceAdjustment($event, invoice)"
                       [disabled]="disabled"></adjustments>
        </td>
      </tr>
    </ng-container>
    <tr *ngFor="let debit of invoice.debits">
      <td class="w-5">{{debit.serviceDate | date:"MM/dd/yyyy"}}<p class="red void-text" *ngIf="debit.hasBeenVoided">(Voided)</p>
      </td>
      <td class="w-5">{{debit.patientName}}</td>
      <td class="w-5">{{debit.financialTransactionType}}</td>
      <td class="w-20">{{debit.description}}</td>
      <td class="w-5">{{debit.quantity}}</td>
      <td class="w-5">
        <displayable-money class="float-right" [model]="debit.amount"></displayable-money>
      </td>
      <td class="w-5">
        <displayable-money class="float-right" [model]="debit.updatedBalance"></displayable-money>
      </td>
      <td class="w-30">
        <adjustment-creator #adjustmentCreator
                            [disabled]="disabled"
                            [financialTransactionTypeOptions]="financialTransactionTypeOptions"
                            (adjustmentAdded)="addDebitAdjustment($event, invoice, debit)"
                            (smartApplyAdjustment)="smartApplyAdjustmentToDebit($event, invoice, debit)"></adjustment-creator>
      </td>
      <td class="w-20">
        <adjustments [model]="debit.adjustments" (adjustmentRemoved)="removeDebitAdjustment($event, invoice, debit)"
                     [disabled]="disabled"></adjustments>
      </td>
    </tr>
    </tbody>
  </table>
  <cdk-virtual-scroll-viewport itemSize="10" style="height: 900px"
                               *ngIf="(!collapsedMap.has(invoice.invoiceId) || !collapsedMap.get(invoice.invoiceId)) && invoice.debits.length > 10">
    <table class="table table-bordered table-striped table-responsive table-condensed table-hover">
      <thead>
      <tr>
        <th class="w-5">Date</th>
        <th class="w-5">Patient</th>
        <th class="w-5">Type</th>
        <th class="w-20">Description</th>
        <th class="w-5">Quantity</th>
        <th class="w-5">Amount</th>
        <th class="w-5">Balance</th>
        <th class="w-30"></th>
        <th class="w-20">Adjustments</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="invoice.adjustments.length > 0">
        <tr>
          <td class="w-5"></td>
          <td class="w-5"></td>
          <td class="w-5"></td>
          <td class="w-20">Invoice Level Adjustments</td>
          <td class="w-5"></td>
          <td class="w-5">
            <displayable-money class="float-right" [model]="invoice.amount"></displayable-money>
          </td>
          <td class="w-5">
            <displayable-money class="float-right" [model]="invoice.updatedBalance"></displayable-money>
          </td>
          <td class="w-30">
            <adjustment-creator #adjustmentCreator
                                [disabled]="disabled"
                                [financialTransactionTypeOptions]="financialTransactionTypeOptions"
                                (adjustmentAdded)="addInvoiceAdjustment($event, invoice)"
                                (smartApplyAdjustment)="smartApplyAdjustmentToInvoice($event, invoice)"></adjustment-creator>
          </td>
          <td class="w-20">
            <adjustments [model]="invoice.adjustments" (adjustmentRemoved)="removeInvoiceAdjustment($event, invoice)"
                         [disabled]="disabled"></adjustments>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let debit of invoice.debits">
        <tr>
          <td class="w-5">{{debit.serviceDate | date:"MM/dd/yyyy"}}<p class="red void-text" *ngIf="debit.hasBeenVoided">
            (Voided)</p></td>
          <td class="w-5">{{debit.patientName}}</td>
          <td class="w-5">{{debit.financialTransactionType}}</td>
          <td class="w-20">{{debit.description}}</td>
          <td class="w-5">{{debit.quantity}}</td>
          <td class="w-5">
            <displayable-money class="float-right" [model]="debit.amount"></displayable-money>
          </td>
          <td class="w-5">
            <displayable-money class="float-right" [model]="debit.updatedBalance"></displayable-money>
          </td>
          <td class="w-30">
            <adjustment-creator #adjustmentCreator
                                [disabled]="disabled"
                                [financialTransactionTypeOptions]="financialTransactionTypeOptions"
                                (adjustmentAdded)="addDebitAdjustment($event, invoice, debit)"
                                (smartApplyAdjustment)="smartApplyAdjustmentToDebit($event, invoice, debit)"></adjustment-creator>
          </td>
          <td class="w-20">
            <adjustments [model]="debit.adjustments" (adjustmentRemoved)="removeDebitAdjustment($event, invoice, debit)"
                         [disabled]="disabled"></adjustments>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
