<div id="inner-validation-results" class="message-containers">
  <div class="tab-pane" id="alert-2">
    <div class="message-container alert alert-success"
         [hidden]="!validationResultsService || !validationResultsService.successMessages || validationResultsService.successMessages.length == 0">
      <div class="container">
        <button type="button" class="close" (click)="hideSuccessMessages()">&times;</button>
        <div *ngFor="let message of validationResultsService.successMessages">
          <strong>Succeeded: </strong>{{ message }}
        </div>
      </div>
    </div>
    <div class="message-container alert alert-danger"
         [hidden]="!validationResultsService || !validationResultsService.errorMessages || validationResultsService.errorMessages.length == 0">
      <div class="container">
        <button type="button" class="close" (click)="hideErrorMessages()">&times;</button>
        <div *ngFor="let message of validationResultsService.errorMessages">
          <strong>Error: </strong>{{ message }}
        </div>
      </div>
    </div>
    <div class="message-container alert alert-warning"
         [hidden]="!validationResultsService || !validationResultsService.warningMessages || validationResultsService.warningMessages.length == 0">
      <div class="container">
        <button type="button" class="close" (click)="hideWarningMessages()">&times;</button>
        <div *ngFor="let message of validationResultsService.warningMessages">
          <strong>Warning: </strong>{{ message }}
        </div>
      </div>
    </div>
    <div class="message-container alert alert-info"
         [hidden]="!validationResultsService || !validationResultsService.infoMessages || validationResultsService.infoMessages.length == 0">
      <div class="container">
        <button type="button" class="close" (click)="hideInfoMessages()">&times;</button>
        <div *ngFor="let message of validationResultsService.infoMessages">
          <strong>Note: </strong>{{ message }}
        </div>
      </div>
    </div>
  </div>
</div>
