import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[invoice-detail-history-host]'
})
export class InvoiceDetailHistoryDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
