<h1>Charge Code Search</h1>
<table id="tb-charge-code-list" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Code</th>
    <th>Description</th>
    <th>Start Date</th>
    <th>End Date</th>
    <th>Price</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td></td>
    <td>{{row.code}}</td>
    <td>{{row.description}}</td>
    <td>{{row.startDate | date:"M/d/yyyy"}}</td>
    <td>{{row.endDate | date: "M/d/yyyy"}}</td>
    <td>{{row.price | currency: 'USD':'$'}}</td>
  </tr>
  </tbody>
</table>
