import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PayorContactEntryDto} from "../../../../../models/payor-entry/payor-contact-entry-dto";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {BillingHttpClient} from "../../../../../common/billing-http-client.service";
import {CommonResult} from "../../../../../models/common/common-result";
import {ValidationResultsService} from "../../../../../services/validation-results.service";

@Component({
  selector: 'payor-contact-entry',
  templateUrl: './payor-contact-entry.component.html',
  styleUrls: ['./payor-contact-entry.component.scss']
})
export class PayorContactEntryComponent implements OnInit, OnDestroy {

  private id: number;
  model: PayorContactEntryDto = new PayorContactEntryDto();
  private ngUnsubscribe = new Subject();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private route: ActivatedRoute,
    private validationResultsService: ValidationResultsService) {
  }

  @ViewChild('form') form: NgForm;

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
        if (this.id) {
          const params = new HttpParams().set('id', this.id.toString());
          this.billingHttpClient.Get<PayorContactEntryDto>('/payor-contact', {params})
            .subscribe(response => {
                this.model = response;
              }
            );
        }
        this.model.payorId = +params['payorId'];
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    this.billingHttpClient.Post<CommonResult>('/payor-contact', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.form.form.markAsPristine();
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

  cancel() {
    window.history.back();
  }
}
