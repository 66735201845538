import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PayorEntryDto} from "../../../../models/payor-entry/payor-entry-dto";

@Component({
  selector: 'app-payor-entry-invoice-config',
  templateUrl: './payor-entry-invoice-config.component.html',
  styleUrls: ['./payor-entry-invoice-config.component.scss']
})
export class PayorEntryInvoiceConfigComponent implements OnInit {

  @Input() model: PayorEntryDto = new PayorEntryDto();
  @Output() saved = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Output() saveClosed = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.model && this.model.billingFrequencyType == null) {
      this.model.billingFrequencyType = 'Weekly';
    }
  }

  save() {
    this.checkManualSubmission();
    this.saved.emit();
  }

  cancel() {
    this.canceled.emit();
  }

  saveClose() {
    this.checkManualSubmission();
    this.saveClosed.emit();
  }

  checkManualSubmission() {
    if (this.model.attachToInvoiceAuthorization || 
      this.model.attachToInvoiceChainOfCustody || 
      this.model.attachToInvoiceMedicalTestingRecords || 
      this.model.includeSpecimenIdNumber ||
      this.model.includeJobNumber ||
      this.model.includeVendorIdNumber ||
      this.model.includeEmployerName) {
        this.model.doNotSendInvoice = true;
      }
  };

}
