<div class="modal fade" id="mailLookupModal" tabindex="-1" role="dialog" aria-labelledby="mailLookupModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="mailLookupModalLabel">Email Deliver Log</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table id="tb-mail" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th>Sent At</th>
            <th>Payor</th>
            <th>Invoice Number Display</th>
            <th>Status</th>
            <th>Send To</th>
            <th>Send From</th>
            <th>Mail Subject</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td>{{row.sentAt | date:'MM/dd/yyyy hh:mm:ss'}}</td>
            <td>{{row.payor}}</td>
            <td>{{row.invoiceNumberDisplay}}</td>
            <td>{{row.status}}</td>
            <td>{{row.sendTo}}</td>
            <td>{{row.sendFrom}}</td>
            <td>{{row.mailSubject}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

