<h1>Revert Checkout</h1>
<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <label for="authorizationPatientId">Authorization Patient Id</label>
      <input id="authorizationPatientId" type="text" class="form-control" name="groupingKey" [(ngModel)]="authorizationPatientId">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-10"></div>
  <div class="col-md-2 text-right">
    <button class="btn btn-success" (click)="revertCheckout()">
      <i class="fa fa-check-square-o"></i>
      Revert
    </button>
  </div>
</div>
