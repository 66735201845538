import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'linear-stepper',
  templateUrl: './linear-stepper.component.html',
  styleUrls: ['./linear-stepper.component.scss']
})
export class LinearStepperComponent implements OnInit {

  @Input() model: LinearStepperConfig;
  @Output() stepChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
  }

  getCurrentStep(): LinearStepperStep {
    return this.getStep(this.model.currentStep);
  }

  getStep(stepNumber: number): LinearStepperStep {
    return _.find(this.model.steps, (value) => {
      return value.step == stepNumber;
    });
  }

  previous() {
    if (this.model.currentStep > 1) {
      this.model.currentStep--;
      this.stepChange.emit(this.model.currentStep);
    }
  }

  next() {
    if (this.model.currentStep < this.model.steps.length) {
      this.model.currentStep++;
      this.stepChange.emit(this.model.currentStep);
    }
  }

  setStep(step: LinearStepperStep) {
    this.model.previousStep = this.model.currentStep;
    if (this.model.enforceLinearProgression && step.step > this.model.currentStep) {
      this.model.currentStep++;
    } else if (this.model.enforceLinearRegression && step.step < this.model.currentStep) {
      this.model.currentStep--;
    } else {
      this.model.currentStep = step.step;
    }
    this.stepChange.emit(this.model.currentStep);
  }
}


export class LinearStepperConfig {
  steps: LinearStepperStep[] = [];
  enforceLinearProgression: boolean = true;
  enforceLinearRegression: boolean = false;
  currentStep: number = 0;
  previousStep: number = -1;
}

export class LinearStepperStep {
  step: number;
  route: string;
  label: string;
  hasBeenVisited: boolean;
}
