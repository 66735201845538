<div class="row">
  <div class="col-md-12">
    &nbsp;
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="row">
      <label class="control-label label-offset">Invoice:</label>
      <span>{{model.invoiceNumberDisplay}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Status:</label>
      <span>{{model.status}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Claim Number</label>
    </div>
    <div class="row">
      <div class="input-group">
        <input type="text" class="form-control" name="ClaimNumber" [(ngModel)]="model.claimNumber" />
        <div class="input-group-append">
          <button class="btn btn-success" (click)="updateClaimNumber()">
            <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
            <i class="fa fa-check-square-o"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <label class="control-label label-offset">Address:</label>
      <span>
            <span>{{model.address1}}<br/></span>
            <span *ngIf="model.address2">{{model.address2}}<br/></span>
            <span>{{model.city}}<span *ngIf="model.city && model.state">,</span> {{model.state}}</span>
            <span *ngIf="model.postalCode"> {{model.postalCode | postalCodePipe}}</span>
      </span>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <label class="control-label label-offset">Contact:</label>
      <span>{{model.contact}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Phone:</label>
      <span>{{model.contactPhone | phonePipe}}</span>
    </div>
  </div>
  <div class="col">
    <div class="row" *ngIf="model.billingEntityId">
      <label class="control-label label-offset">Billing Entity:</label>
      <span>{{model.billingEntity}}</span>
    </div>
    <div class="row" *ngIf="model.groupingKey">
      <label class="control-label label-offset">Grouping Key:</label>
      <span>{{model.groupingKey}}</span>
    </div>
    <div class="row" *ngIf="model.employerId">
      <label class="control-label label-offset">Employer:</label>
      <span>{{model.employer}}</span>
    </div>
    <div class="row" *ngIf="model.regionId ">
      <label class="control-label label-offset">Region:</label>
      <span>{{model.region}}</span>
    </div>
    <div class="row" *ngIf="model.jobNumber ">
      <label class="control-label label-offset">Job Number:</label>
      <span>{{model.jobNumber}}</span>
    </div>
    <div class="row" *ngIf="model.purchaseOrder">
      <label class="control-label label-offset">Purchase Order:</label>
      <span>{{model.purchaseOrder}}</span>
    </div>
    <div class="row" *ngIf="model.patientId">
      <label class="control-label label-offset">Patient:</label>
      <span>{{model.patientFirstName}} {{model.patientLastName}}</span>
    </div>
    <div class="row" *ngIf="model.branch">
      <label class="control-label label-offset">Branch:</label>
      <span>{{model.branch}}</span>
    </div>
    <div class="row" *ngIf="model.testReasonType">
      <label class="control-label label-offset">Reason For Visit:</label>
      <span>{{model.testReasonType}}</span>
    </div>
    <div class="row" *ngIf="model.facilityId">
      <label class="control-label label-offset">Facility:</label>
      <span>{{model.facility}}</span>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <label class="control-label label-offset">Invoice Period:</label>
      <span>{{model.startDate}} - {{model.endDate}} </span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Invoice Date:</label>
      <span>{{model.dateDistributed}}</span>
    </div>
    <div class="row">
      <label class="control-label label-offset">Last Adjustment Date:</label>
      <span>{{model.lastAdjustmentDate}}</span>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-6">
    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th>Transaction Type</th>
        <th>Amount</th>
        <th>Balance</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of model.transactionTotalsDto.transactionTotals">
        <td>
          <span>{{row.transactionType}}</span>
        </td>
        <td>
          <span><displayable-money [model]="row.amount"></displayable-money></span>
        </td>
        <td>
          <span><displayable-money [model]="row.due"></displayable-money></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>** BALANCE **</span>
        </td>
        <td [colSpan]="2" style="text-align: right">
          <span><displayable-money [model]="model.transactionTotalsDto.balance"></displayable-money></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-6">
    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th>#</th>
        <th>Amount</th>
        <th>Balance</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of model.invoiceAdjustmentsDto.adjustedInvoices">
        <td>
          <span *ngIf="row.invoiceId == model.invoiceId">{{row.invoiceNumberDisplay}} (current)</span>
          <a *ngIf="row.invoiceId != model.invoiceId"
             [routerLink]="" (click)="navigateToInvoice(row.invoiceId)">{{row.invoiceNumberDisplay}}</a>
        </td>
        <td>
          <span><displayable-money [model]="row.amount"></displayable-money></span>
        </td>
        <td>
          <span><displayable-money [model]="row.balance"></displayable-money></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>** TOTALS **</span>
        </td>
        <td>
          <span><displayable-money [model]="model.invoiceAdjustmentsDto.amountTotal"></displayable-money></span>
        </td>
        <td>
          <span><displayable-money [model]="model.invoiceAdjustmentsDto.balanceTotal"></displayable-money></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
