import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {InvoicesComponent} from "./components/financials/invoices/invoices.component";
import {InvoiceDetailComponent} from "./components/financials/invoice-detail/invoice-detail/invoice-detail.component";
import {CanDeactivateGuard} from "./services/can-deactivate-guard.service";
import {AdjustmentBatchesComponent} from "./components/financials/adjustment-batches/adjustment-batches.component";
import {AdjustmentBatchComponent} from "./components/financials/adjustment-batch/adjustment-batch.component";
import {ControlTotalsComponent} from "./components/financials/adjustment-batch/control-totals/control-totals.component";
import {SelectInvoicesComponent} from "./components/financials/adjustment-batch/select-invoices/select-invoices.component";
import {ApplyAdjustmentsComponent} from "./components/financials/adjustment-batch/apply-adjustments/apply-adjustments.component";
import {ReviewFinalizeComponent} from "./components/financials/adjustment-batch/review-finalize/review-finalize.component";
import {BillingEntitiesComponent} from "./components/admin/billing-entities/billing-entities.component";
import {BillingEntityEntryComponent} from './components/admin/billing-entity-entry/billing-entity-entry.component';
import {EmployerListComponent} from './components/admin/employer-list/employer-list.component';
import {EmployerEntryComponent} from './components/admin/employer-entry/employer-entry.component';
import {ChargeCodePriceListComponent} from './components/admin/charge-code-price-list/charge-code-price-list.component';
import {PayorListComponent} from './components/admin/payor-list/payor-list.component';
import {ChargeCodeListComponent} from './components/admin/charge-code-list/charge-code-list.component';
import {ChargeCodeEntryComponent} from './components/admin/charge-code-entry/charge-code-entry.component';
import {ChargeCodePriceViewComponent} from './components/admin/charge-code-price-view/charge-code-price-view.component';
import {PayorEntryComponent} from './components/admin/payor-entry/payor-entry.component';
import {PendingDebitEditComponent} from "./components/financials/pending-debit-edit/pending-debit-edit.component";
import {PendingDebitsAndAdjustmentsComponent} from "./components/financials/pending-debits-and-adjustments/pending-debits-and-adjustments.component";
import {DebitEntryComponent} from "./components/financials/debit-entry/debit-entry.component";
import {EmployerDebitEntryComponent} from "./components/financials/employer-debit-entry/employer-debit-entry.component";
import {PayorDebitEntryComponent} from "./components/financials/payor-debit-entry/payor-debit-entry.component";
import {GroupingKeyDistributeComponent} from "./components/financials/grouping-key-distribute/grouping-key-distribute.component";
import {DebitsAndAdjustmentsComponent} from "./components/financials/debits-and-adjustments/debits-and-adjustments.component";
import {StatementComponent} from "./components/financials/statements/statement/statement.component";
import {SystemStatementsComponent} from "./components/financials/statements/system-statements/system-statements.component";
import {SystemStatementDetailComponent} from "./components/financials/statements/system-statement-detail/system-statement-detail.component";
import {PendingTransactionErrorsComponent} from "./components/dashboard/pending-transaction-errors/pending-transaction-errors.component";
import {InvoiceClosedErrorsComponent} from "./components/dashboard/invoice-closed-errors/invoice-closed-errors.component";
import {InvoicesWithRequirementsComponent} from "./components/dashboard/invoices-with-requirements/invoices-with-requirements.component";
import {PendingInvoicesComponent} from "./components/dashboard/pending-invoices/pending-invoices.component";
import {PendingStatementsComponent} from "./components/dashboard/pending-statements/pending-statements.component";
import {InvoicesPastDueComponent} from "./components/dashboard/invoices-past-due/invoices-past-due.component";
import {CollectionCallLogComponent} from "./components/dashboard/collection-call-log/collection-call-log.component";
import {CollectionCallComponent} from "./components/dashboard/collection-call-log/collection-call/collection-call.component";
import {FailedEmailsComponent} from "./components/dashboard/failed-emails/failed-emails.component";
import {AuthGuardService} from "./services/auth-guard.service";
import {ErrorComponent} from "./components/framework/error/error.component";
import {PayorContactEntryComponent} from "./components/admin/payor-entry/payor-entry-contacts/payor-contact-entry/payor-contact-entry.component";
import {BranchListComponent} from "./components/admin/branch-list/branch-list.component";
import {BranchEntryComponent} from "./components/admin/branch-entry/branch-entry.component";
import {RegionEntryComponent} from "./components/admin/region-entry/region-entry.component";
import {EmployerPayorEntryComponent} from "./components/admin/employer-payor-entry/employer-payor-entry.component";
import {RegionPayorEntryComponent} from "./components/admin/region-payor-entry/region-payor-entry.component";
import {ClinicalDocConfigComponent} from './components/admin/clinical-doc-config/clinical-doc-config.component';
import {CreditMemosComponent} from './components/dashboard/credit-memos/credit-memos.component';
import {RevertCheckoutComponent} from './components/admin/revert-checkout/revert-checkout.component';
import {BranchPayorEntryComponent} from './components/admin/branch-payor-entry/branch-payor-entry.component';
import {FacilityPayorEntryComponent} from './components/admin/facility-payor-entry/facility-payor-entry.component';
import {FacilityEntryComponent} from './components/admin/facility-entry/facility-entry.component';

const routes: Routes = [
  {
    path: '', canActivateChild: [AuthGuardService], children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'invoices/:search', component: InvoicesComponent},
      {path: 'invoices', component: InvoicesComponent},
      {path: 'invoice/:id', component: InvoiceDetailComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'adjustment-batches', component: AdjustmentBatchesComponent},
      {
        path: 'adjustment-batch/:step/:id/:payorId/:invoiceId', component: AdjustmentBatchComponent, children: [
          {
            path: 'control-totals',
            component: ControlTotalsComponent,
            outlet: "linearStep",
          },
          {
            path: 'select-invoices',
            component: SelectInvoicesComponent,
            outlet: "linearStep",
          },
          {
            path: 'apply-adjustments',
            component: ApplyAdjustmentsComponent,
            outlet: "linearStep",
          },
          {
            path: 'review-finalize',
            component: ReviewFinalizeComponent,
            outlet: "linearStep"
          },
        ]
      },
      {path: 'billing-entities', component: BillingEntitiesComponent},
      {path: 'billing-entity-entry', component: BillingEntityEntryComponent},
      {path: 'billing-entity-entry/:id', component: BillingEntityEntryComponent},
      {path: 'clinical-document-configuration', component: ClinicalDocConfigComponent},
      {path: 'employer-list', component: EmployerListComponent},
      {path: 'employer-entry/:id', component: EmployerEntryComponent},
      {path: 'pricing-list', component: ChargeCodePriceListComponent},
      {path: 'price', component: ChargeCodePriceViewComponent},
      {path: 'price/:id', component: ChargeCodePriceViewComponent},
      {path: 'payor-list', component: PayorListComponent},
      {path: 'payor-entry', component: PayorEntryComponent},
      {path: 'payor-entry/:id', component: PayorEntryComponent},
      {path: 'charge-code-list', component: ChargeCodeListComponent},
      {path: 'charge-code-entry', component: ChargeCodeEntryComponent},
      {path: 'charge-code-entry/:id', component: ChargeCodeEntryComponent},
      {path: 'charge-code-price-view', component: ChargeCodePriceViewComponent},
      {path: 'pending-debit-edit/:id', component: PendingDebitEditComponent},
      {path: 'pending-debits-and-adjustments', component: PendingDebitsAndAdjustmentsComponent},
      {path: 'debit-entry/:id', component: DebitEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'employer-debit-entry/:id', component: EmployerDebitEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'payor-debit-entry/:id', component: PayorDebitEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'distribute-invoice-group', component: GroupingKeyDistributeComponent},
      {path: 'debits-and-adjustments', component: DebitsAndAdjustmentsComponent},
      {path: 'statement', component: StatementComponent},
      {path: 'system-statements', component: SystemStatementsComponent},
      {path: 'system-statement-detail/:id', component: SystemStatementDetailComponent},
      {path: 'pending-transaction-errors', component: PendingTransactionErrorsComponent},
      {path: 'invoice-closed-errors', component: InvoiceClosedErrorsComponent},
      {path: 'invoices-with-requirements', component: InvoicesWithRequirementsComponent},
      {path: 'pending-invoices', component: PendingInvoicesComponent},
      {path: 'pending-statements', component: PendingStatementsComponent},
      {path: 'invoices-past-due', component: InvoicesPastDueComponent},
      {path: 'collection-call-log', component: CollectionCallLogComponent},
      {path: 'collection-call/:id', component: CollectionCallComponent},
      {path: 'failed-emails', component: FailedEmailsComponent},
      {
        path: 'payor-contact-entry/:id/:payorId',
        component: PayorContactEntryComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {path: 'branch-list', component: BranchListComponent},
      {path: 'branch-entry/:id', component: BranchEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'region-entry/:id', component: RegionEntryComponent},
      {path: 'employer-payor-entry/:id', component: EmployerPayorEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'region-payor-entry/:id', component: RegionPayorEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'credit-memos', component: CreditMemosComponent},
      {path: 'revert-checkout', component: RevertCheckoutComponent},
      {path: 'branch-payor-entry/:id', component: BranchPayorEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'facility-entry/:id', component: FacilityEntryComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'facility-payor-entry/:id', component: FacilityPayorEntryComponent, canDeactivate: [CanDeactivateGuard]},
    ],
  },
  {path: 'error', component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
