<div class="row">
  <div class="col-md-12">
    <h2>Custom Pricing</h2>
    <hr class="divider" />
    <div *ngIf="payorType != 'Employer'">
      <div class="btn-group">
        <button type="button" class="btn btn-success" (click)="addNewPrice()">
          Add Custom Price
        </button>
      </div>
      <div class="panel panel-default-dark mt-3">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th class="fit"></th>
              <th>Charge Code</th>
              <th>Description</th>
              <th>Price</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of model">
              <td class="fit button-container">
                <div class="row">
                  <div class="col-md-12">
                    <button type="button" [title]="'Edit'"
                            (click)="editPrice(row);"
                            class="grid-btn btn btn-sm btn-raised btn-success">
                      <i class="fa fa-edit"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td>
                <span>{{row.chargeCode}}</span>
              </td>
              <td>
                <span>{{row.chargeCodeDescription}}</span>
              </td>
              <td>
                <span><displayable-money [model]="row.price"></displayable-money></span>
              </td>
              <td>
                <span>{{row.startDate | date:"MM/dd/yyyy"}}</span>
              </td>
              <td>
                <span>{{row.endDate | date:"MM/dd/yyyy"}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="payorType == 'Employer'">
      <p>
        You cannot add a custom Payor price for Employer Payors because payor-specific pricing is global
        and not configurable on payors that are specific to employers.  If you want to configure a price
        for this employer, set a price for the employer itself rather than for the employer's payor.
      </p>
    </div>
  </div>
</div>
