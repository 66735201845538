import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {EmployerPayorEntryDto} from "../../../models/employer-payor-entry/employer-payor-entry-dto";
import {EmployerPayorEntryResponsibilitiesComponent} from "./employer-payor-entry-responsibilities/employer-payor-entry-responsibilities.component";
import {EmployerPayorEntryGeneralComponent} from "./employer-payor-entry-general/employer-payor-entry-general.component";
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../models/common/common-result";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {CanComponentDeactivate} from "../../../services/can-deactivate-guard.service";

@Component({
  selector: 'employer-payor-entry',
  templateUrl: './employer-payor-entry.component.html',
  styleUrls: ['./employer-payor-entry.component.scss']
})
export class EmployerPayorEntryComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  id: number;
  private ngUnsubscribe = new Subject();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private route: ActivatedRoute,
    private validationResultsService: ValidationResultsService) {
  }

  model: EmployerPayorEntryDto = new EmployerPayorEntryDto();
  @ViewChild('form') form: NgForm;
  @ViewChild('responsibilitiesComponent') responsibilitiesComponent: EmployerPayorEntryResponsibilitiesComponent;
  @ViewChild('generalComponent') generalComponent: EmployerPayorEntryGeneralComponent;

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
      });
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Get<EmployerPayorEntryDto>('/employer-payor-entry', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    this.billingHttpClient.Post<CommonResult>('/employer-payor-entry', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded'])
          this.markAsPristine();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  saveClose() {
    this.save();
    this.cancel();
  }

  private markAsPristine() {
    this.form.form.markAsPristine();
    this.generalComponent.formGeneral.form.markAsPristine();
    this.responsibilitiesComponent.formResponsibilities.form.markAsPristine();
  }

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty || this.responsibilitiesComponent.formResponsibilities.dirty || this.generalComponent.formGeneral.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

  delete() {
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Delete<CommonResult>('/employer-payor-entry', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded'])
          this.markAsPristine();
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  cancel() {
    window.history.back();
  }
}
