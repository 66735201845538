import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataTablesResponse} from '../../../models/common/data-tables-response';
import {BillingHttpClient} from '../../../common/billing-http-client.service';
import {DashboardInvoicesWithRequirementsSearchRow} from '../../../models/invoices-with-requirements/dashboard-invoices-with-requirements-search-row';
import {BillingEntityDto} from '../../../models/billing-entities/billing-entity-dto';
import {DataTableDirective} from 'angular-datatables';
import {CommonResult} from '../../../models/common/common-result';
import {InvoiceBodyDto} from '../../../models/invoices-with-requirements/invoice-body-dto';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'invoices-with-requirements',
  templateUrl: './invoices-with-requirements.component.html',
  styleUrls: ['./invoices-with-requirements.component.scss']
})
export class InvoicesWithRequirementsComponent implements OnInit {

  dtOptions: any = {};
  model: DashboardInvoicesWithRequirementsSearchRow[] = [];
  select: DashboardInvoicesWithRequirementsSearchRow[] = [];
  billingEntities = [] as BillingEntityDto[];
  billingEntity: BillingEntityDto;
  @ViewChild(DataTableDirective, {static: false})
  private datatableElement: DataTableDirective;

  constructor(private billingHttpClient: BillingHttpClient,
              public datePipe: DatePipe,
              private  router: Router) {
  }

  ngOnInit(): void {
    this.billingHttpClient.Get<BillingEntityDto[]>('/billing-entities').subscribe(
      val => {
        this.billingEntities = val;
      }
    );
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columns: [
        {
          data: null,
          orderable: false,
        },
        {
          data: 'invoiceId',
        },
        {data: 'invoiceNumberDisplay'},
        {data: 'invoiceAmount'},
        {data: 'employer'},
        {data: 'payor'},
        {data: 'payorType'},
        {data: 'region'},
        {data: 'jobNumber'},
        {data: 'purchaseOrder'},
        {data: 'reasonForVisit'},
        {data: 'patientName'},
        {data: 'status'},
        {data: 'requirements'},
        {data: 'invoicePeriod'}
      ],
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        if (that.billingEntity){
          dataTablesParameters.billingEntityId = that.billingEntity.entityId;
        }
        that.billingHttpClient.Post<DataTablesResponse>('/invoices-with-requirements', dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: '<\'row\'<\'col-sm-7\'l><\'col-sm-2 \'B><\'col-sm-3\'f>>' +
        '<\'row\'<\'col-sm-12\'tr>>' +
        '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
      // Configure the buttons
      buttons: [
        {
          text: '<i class="fas fa-plus"></i><span style="margin-left: 5px;">Generate EDI</span>',
          className: 'btn btn-success float-right',
          action: function(e, dt, node, config) {
            if (that.billingEntity){
              let model = {
                invoiceIds: that.select.map(value => value.invoiceId)
              } as InvoiceBodyDto;
              that.billingHttpClient.Post<Blob>('/invoice-837Ps', model,
                {responseType: 'blob' as 'json'}).toPromise()
                .then(function (result) {
                  var blob = new Blob([result]);
                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  var dateAsString = that.datePipe.transform(Date.now(), 'yyyyMMdd_HHmmss');
                  var dic = {"Louisiana Occupational Medicine and Safety Institute LLC":198337, "Prime Occupational Medicine":198077,
                    "Prime Occupational Physicians - Texas":198339};
                  var zirAccNo = '';
                  if (dic.hasOwnProperty(that.billingEntity.entityName)){
                    zirAccNo = dic[that.billingEntity.entityName]
                  }
                  link.download = `ZIRMED_${zirAccNo}_IMAGES_${dateAsString}.zip`;
                  link.click();
                })
            }else {
              alert("Billing Entity is required to generate group EDI")
            }
          }
        }
      ]
    };
  }

  goToInvoice = (invoiceId) => {
    this.router.navigate(['invoice', invoiceId]);
  };

  addOrRemoveInvoice = (checked: boolean, invoice: DashboardInvoicesWithRequirementsSearchRow) => {
    if (checked) {
      this.select.push(invoice);
    } else {
      for (var i = 0; i < this.select.length; i++) {
        if (this.select[i] == invoice) {
          this.select.splice(i, 1);
        }
      }
    }
  };

  checkInvoice = (invoice: DashboardInvoicesWithRequirementsSearchRow) => {
    return this.select.indexOf(invoice) != -1;
  };

  applyBillingEntityFilter() {
    this.select.splice(0,this.select.length);
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.draw();
      }
    );
  }

}
