<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-md-4">
      <p>
        Payor Information
      </p>
      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput required placeholder="Name" [(ngModel)]="model.name">
        </mat-form-field>
      </div>
      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Payor Type</mat-label>
          <mat-select [(ngModel)]="model.payorType" required>
            <mat-option value="">(select)</mat-option>
            <mat-option value="Employer">Employer</mat-option>
            <mat-option value="WorkersComp">Worker's Comp</mat-option>
            <mat-option value="TPA">TPA</mat-option>
            <mat-option value="Commercial">Commercial</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Invoice Rendering Type</mat-label>
          <mat-select [(ngModel)]="model.invoiceRenderingType">
            <mat-option value="">(select)</mat-option>
            <mat-option value="Standard">Standard Invoice</mat-option>
            <mat-option value="CMS1500ClaimForm">CMS 1500 Claim Form</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Address Line 1</mat-label>
          <input matInput required [(ngModel)]="model.address1">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Address Line 2</mat-label>
          <input matInput [(ngModel)]="model.address2">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>City</mat-label>
          <input matInput required [(ngModel)]="model.city">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select required [(ngModel)]="model.state">
            <mat-option *ngFor="let state of stateList" [value]="state.value">
              {{state.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Postal Code</mat-label>
          <input matInput required [(ngModel)]="model.postalCode">
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <p>
        Billing Information
      </p>
      <div class="pad-top bold">
        <mat-form-field appearance="fill">
          <mat-label>Default PO</mat-label>
          <input matInput placeholder="Default PO" [(ngModel)]="model.defaultPo">
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <p>
        Payor Status
      </p>
      <mat-checkbox class="bold" [(ngModel)]="model.active">Active</mat-checkbox>
      <br>
      <mat-checkbox class="bold" [(ngModel)]="model.global">Global</mat-checkbox>
    </div>
  </div>
</div>

<div class="right-align">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-danger mr-2">
    <i class="fa fa-trash"></i>
    Delete Payor
  </button>
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
