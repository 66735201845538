import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DebitsAndAdjustmentsSearchRow} from "../../../models/debits-and-adjustments/debits-and-adjustments-search-row";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {HttpParams} from "@angular/common/http";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {DataTableDirective} from "angular-datatables";

declare var $: any;

@Component({
  selector: 'debits-and-adjustments',
  templateUrl: './debits-and-adjustments.component.html',
  styleUrls: ['./debits-and-adjustments.component.scss']
})
export class DebitsAndAdjustmentsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  model: DebitsAndAdjustmentsSearchRow[] = [];
  @ViewChild(DataTableDirective, {static: false})
  private datatableElement: DataTableDirective;
  searchStr: string = '';

  constructor(private billingHttpClient: BillingHttpClient,
              private  router: Router,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit() {
    const that = this;

    $('#input-search').keypress(function (event) {
      const keycode = (event.keyCode ? event.keyCode : event.which);

      if (keycode == '13') {
        that.search();
      }
    })

    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 1000,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: '8%',
        },
      ],
      columns: [
        {data: ''},
        {data: 'financialTransactionId'},
        {data: 'chargeCode'},
        {data: 'quantity'},
        {data: 'serviceDate'},
        {data: 'payor'},
        {data: 'amount'},
        {data: 'patient'},
        {data: 'employer'},
        {data: 'region'},
        {data: 'jobSite'},
        {data: 'jobNumber'},
        {data: 'facility'},
        {data: 'branch'},
        {data: 'provider'},
        {data: 'purchaseOrder'},
        {data: 'financialTransactionStatus'},
        {data: 'authorizationPatientId'},
        {data: 'groupingKey'},
      ],
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/debits-and-adjustments", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      dom: 'ltipr',
    };
  }

  viewDebit = (charge: DebitsAndAdjustmentsSearchRow) => {
    let params = new HttpParams().set('financialTransactionId', charge.financialTransactionId.toString());
    this.billingHttpClient.Get<number>('/invoice-id', {params})
      .subscribe(response => {
        if (response) {
          this.router.navigate(['invoice', response]);
        } else {
          this.validationResultsService.setErrorMessages(['No associated invoice']);
        }
      });
  }

  search() {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.searchStr)
          .draw();
      }
    );
  }
}

