<div class="row mt-3">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        Email Addresses
        <button type="button" class="btn btn-sm btn-link" style="margin-left:10px" (click)="editEmail()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          Add
        </button>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-striped table-hover table-condensed">
          <thead>
          <tr>
            <th></th>
            <th>Description</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model.employerEntryResultEmailDtos">
            <td>
              <button type="button" class="btn btn-sm btn-success" (click)="editEmail(row)">Edit</button>
              <button type="button" class="btn btn-sm btn-danger ml-2"
                      reallyClick="Are you sure you want to delete this result email address?"
                      reallyClickAction="deleteEmail"
                      [reallyClickCaller]="this"
                      [reallyClickArguments]="[row]">
                Delete
              </button>
            </td>
            <td>{{row.description}}</td>
            <td>{{row.emailAddress}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        Fax Numbers
        <button type="button" class="btn btn-sm btn-link" style="margin-left:10px" (click)="editFaxNumber()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          Add
        </button>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-striped table-hover table-condensed">
          <thead>
          <tr>
            <th></th>
            <th>Description</th>
            <th>Fax Number</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model.employerEntryResultFaxDtos">
            <td>
              <button type="button" class="btn btn-sm btn-success" (click)="editFaxNumber(row)">Edit</button>
              <button type="button" class="btn btn-sm btn-danger ml-2"
                      reallyClick="Are you sure you want to delete this result fax number?"
                      reallyClickAction="deleteFaxNumber"
                      [reallyClickCaller]="this"
                      [reallyClickArguments]="[row]">
                Delete
              </button>
            </td>
            <td>{{row.description}}</td>
            <td>{{row.faxNumber}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        Addresses
        <button type="button" class="btn btn-sm btn-link" style="margin-left:10px" (click)="editAddress()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          Add
        </button>
      </div>
      <table class="table table-bordered table-striped table-hover table-condensed">
        <thead>
        <tr>
          <th></th>
          <th>Description</th>
          <th>Address Line 1</th>
          <th>Address Line 2</th>
          <th>City</th>
          <th>State</th>
          <th>Postal Code</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of model.employerEntryResultAddressDtos">
          <td>
            <button type="button" class="btn btn-sm btn-success" (click)="editAddress(row)">Edit</button>
            <button type="button" class="btn btn-sm btn-danger ml-2"
                    reallyClick="Are you sure you want to delete this result address?"
                    reallyClickAction="deleteAddress"
                    [reallyClickCaller]="this"
                    [reallyClickArguments]="[row]">
              Delete
            </button>
          </td>
          <td>{{row.description}}</td>
          <td>{{row.address1}}</td>
          <td>{{row.address2}}</td>
          <td>{{row.city}}</td>
          <td>{{row.state}}</td>
          <td>{{row.postalCode}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="editEmail" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Edit Email Address
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="editEmail_Description">Description</label>
          <input type="text" class="form-control" id="editEmail_Description" name="editEmail_Description"
                 [(ngModel)]="emailBeingEdited.description"/>
        </div>

        <div class="form-group">
          <label for="editEmail_EmailAddress">Email Address</label>
          <input type="text" class="form-control" id="editEmail_EmailAddress" name="editEmail_EmailAddress"
                 [(ngModel)]="emailBeingEdited.emailAddress"/>
        </div>
      </div>
      <div class="modal-footer">
        <button id="saveEmailModal" type="button" class="btn btn-success" (click)="saveEmailModal()"
                [disabled]="!emailBeingEdited.emailAddress || !emailBeingEdited.description">Save
        </button>
        <button id="editEmailModalDismiss" type="button" class="btn btn-warning" data-dismiss="modal"
                (click)="closeEmailModal()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="editFaxNumber" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Edit Fax Number
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="editFaxNumber_Description">Description</label>
          <input type="text" class="form-control" id="editFaxNumber_Description" name="editFaxNumber_Description"
                 [(ngModel)]="faxNumberBeingEdited.description"/>
        </div>

        <div class="form-group">
          <label for="editFaxNumber_FaxNumber">Fax Number</label>
          <input type="text" class="form-control" id="editFaxNumber_FaxNumber" name="editFaxNumber_FaxNumber"
                 [(ngModel)]="faxNumberBeingEdited.faxNumber"/>
        </div>
      </div>
      <div class="modal-footer">
        <button id="saveFaxNumberModal" type="button" class="btn btn-success" (click)="saveFaxNumberModal()"
                [disabled]="!faxNumberBeingEdited.faxNumber || !faxNumberBeingEdited.description">Save
        </button>
        <button id="editFaxNumberModalDismiss" type="button" class="btn btn-warning" data-dismiss="modal"
                (click)="closeFaxNumberModal()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="editAddress" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Edit Address
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="editAddress_Description">Description</label>
          <input type="text" class="form-control" id="editAddress_Description" name="editAddress_Description"
                 [(ngModel)]="addressBeingEdited.description"/>
        </div>

        <div class="form-group">
          <label for="editAddress_Address1">Address Line 1</label>
          <input type="text" class="form-control" id="editAddress_Address1" name="editEmail_EmailAddress"
                 [(ngModel)]="addressBeingEdited.address1"/>
        </div>
        <div class="form-group">
          <label for="editAddress_Address2">Address Line 2</label>
          <input type="text" class="form-control" id="editAddress_Address2" name="editAddress_Address2"
                 [(ngModel)]="addressBeingEdited.address2"/>
        </div>
        <div class="form-group">
          <label for="editAddress_City">City</label>
          <input type="text" class="form-control" id="editAddress_City" name="editAddress_City"
                 [(ngModel)]="addressBeingEdited.city"/>
        </div>
        <div class="form-group">
          <label for="editAddress_State">State</label>
          <select class="form-control" id="editAddress_State" name="editAddress_State"
                  [(ngModel)]="addressBeingEdited.state">
            <option value="">(select)</option>
            <option *ngFor="let state of stateList" [value]="state.value">{{state.display}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="editAddress_PostalCode">Postal Code</label>
          <input type="text" class="form-control" id="editAddress_PostalCode" name="editAddress_PostalCode"
                 [(ngModel)]="addressBeingEdited.postalCode"/>
        </div>
      </div>
      <div class="modal-footer">
        <button id="saveAddressModal" type="button" class="btn btn-success" (click)="saveAddressModal()"
                [disabled]="!addressBeingEdited.address1 || !addressBeingEdited.description
                || !addressBeingEdited.city || !addressBeingEdited.state
                || !addressBeingEdited.postalCode">Save</button>
        <button id="editAddressModalDismiss" type="button" class="btn btn-warning" data-dismiss="modal"
                (click)="closeAddressModal()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="right-align mt-3">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
