import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BaseHttpClient} from "./base-http-client";
import {environment} from "../../environments/environment";

export function taskHttpClientCreator(http: HttpClient) {
  return new TaskHttpClient(http);
}

@Injectable()
export class TaskHttpClient extends BaseHttpClient {
  constructor(protected http: HttpClient) {
    super(http, environment.taskUrl);
  }
}

export const taskHttpClientProvider = {
  provide: TaskHttpClient,
  useFactory: taskHttpClientCreator,
  deps: [HttpClient]
};
