<div class="row">
  <div class="col-md-8">
    <ul id="child-tabs" class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" href="#allInvoice" aria-controls="summary" role="tab" data-toggle="tab"
          aria-selected="true">All Invoices</a>
      </li>
      <li class="nav-item">
        <a id="a-debits" class="nav-link" href="#invoiceDetail" aria-controls="debits" role="tab" data-toggle="tab"
          aria-selected="false">Invoice Detail Search</a>
      </li>

    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane fade show active" id="allInvoice" aria-labelledby="summary-tab">
        <h3 class="text-center">Available Invoices</h3>

        <!-- All Invoices Search -->

        <div class="row">
          <div class="input-group col-md-2 ml-auto mr-3">
            <input id="input-search" type="text" class="form-control" placeholder="search" [(ngModel)]="searchStr"
              aria-label="Search" aria-describedby="Search">
            <div class="input-group-append">
              <button class="btn btn-success" type="button" (click)="search()"><i class="fas fa-search"></i></button>
            </div>
          </div>
        </div>
        <table id="tb-select-invoices" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
          style="width: 100%">
          <thead>
            <tr>
              <th></th>
              <th>#</th>
              <th>Payor</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Amount</th>
              <th>Balance</th>
              <th>Billing Entity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of model" id="{{row.invoiceId}}" class="{{rowCss(row.invoiceId)}}">
              <td>
                <button type="button" class="btn btn-success" (click)="selectInvoice(row.invoiceId)">
                  <i class="fa fa-sm fa-arrows-h"></i>
                </button>
              </td>
              <td>{{row.invoiceNumberDisplay}}</td>
              <td>{{row.payor}}</td>
              <td>{{row.startDate | date:"MM/dd/yyyy"}}</td>
              <td>{{row.endDate | date:"MM/dd/yyyy"}}</td>
              <td>
                <displayable-money [model]="row.amount"></displayable-money>
              </td>
              <td>
                <displayable-money [model]="row.balance"></displayable-money>
              </td>
              <td>{{row.billingEntity}}</td>
              <td class="details-control" style="width: 15px">
                <i class="fa fa-plus-square" aria-hidden="true"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- /All Invoice Search -->

      </div>
      <div role="tabpanel" class="tab-pane fade detail-invoices" id="invoiceDetail" aria-labelledby="debits-tab"
        style="margin-top: 15px">
        <h3>Invoice Detail Search</h3>

        <!-- INVOICE DETAIL SEARCH -->

        <div class="row mb-3 pl-3 pr-3">
          <input id="inputDetailPayor" type="text" class="form-control col-2" placeholder="Payor Name"
            [(ngModel)]="inputDetailPayor" aria-label="Search" aria-describedby="Search">
          <input id="inputDetailSSN" type="text" class="form-control col-3" placeholder="SSN (Partial allowed)"
            [(ngModel)]="inputDetailSSN" aria-label="Search" aria-describedby="Search">
          <input id="inputDetailName" type="text" class="form-control col-6" placeholder="Patient Name (Partial allowed)"
            [(ngModel)]="inputDetailName" aria-label="Search" aria-describedby="Search">

            <button class="btn btn-success col-1" type="button" (click)="invoiceDetailSearch()"><i
                class="fas fa-search"></i></button>
        </div>
        <table id="tb-detail-invoices" datatable [dtOptions]="dtDetailOptions" class="table table-striped table-bordered"
          style="width: 100%">
          <thead>
            <tr>
              <th></th>
              <th>#</th>
              <th>Payor</th>
              <th>Date Range</th>
              <th>Amount</th>
              <th>Billing Entity</th>
              <th>Invoice Contains Patient Name</th>
              <th>Invoice Contains Patient Social</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of detailModel" id="{{row.invoiceId}}" class="{{rowCss(row.invoiceId)}}">
              <td>
                <button type="button" class="btn btn-success" (click)="selectInvoice(row.invoiceId)">
                  <i class="fa fa-sm fa-arrows-h"></i>
                </button>
              </td>
              <td>{{row.invoiceNumberDisplay}}</td>
              <td>{{row.payor}}</td>
              <td>{{row.startDate | date:"MM/dd/yyyy"}} 
                <br>-<br>
                {{row.endDate | date:"MM/dd/yyyy"}}</td>
              <td>
                <displayable-money [model]="row.amount"></displayable-money>
              </td>
              <td>{{row.billingEntity}}</td>
              <td>{{row.patientNames}}</td>
              <td>{{row.ssn}}</td>
              <td class="details-control" style="width: 15px">
                <i class="fa fa-plus-square" aria-hidden="true"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <div id="invoiceDetailLoader" class="loading-screen d-none">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

        <!-- /INVOICE DETAIL SEARCH -->

      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3 class="text-center">Selected Invoices</h3>
    <table id="tb-sd" class="table table-bordered table-striped table-condensed table-hover">
      <thead>
        <tr>
          <th class="fit"></th>
          <th>#</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of selectedInvoices" id="{{invoice.invoiceId}}">
          <td class="fit">
            <button class="grid-btn btn btn-sm btn-raised btn-danger" type="button" title=""
              (click)="selectInvoice(invoice.invoiceId)" [disabled]="!editable">
              <i class="fa fa-fw fa-arrows-h"></i>
            </button>
          </td>
          <td>{{invoice.invoiceNumberDisplay}}</td>
          <td>{{invoice.startDate | date:"MM/dd/yyyy"}}</td>
          <td>{{invoice.endDate | date:"MM/dd/yyyy"}}</td>
          <td>
            <displayable-money class="pull-right" [model]="invoice.amount"></displayable-money>
          </td>
          <td class="details-control" style="width: 15px">
            <i class="fa fa-plus-square" aria-hidden="true"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>