import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InvoiceStatus} from "../../../../models/common/invoice-status.enum";
import {InvoiceDetailDebitDto} from "../../../../models/invoice-detail/debits/invoice-detail-debit-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";
import {Observable, Subject} from "rxjs";
import {DataTableDirective} from "angular-datatables";
import * as _ from 'lodash';
import {CommonResult} from "../../../../models/common/common-result";
import {DecimalUtility} from "../../../../utilities/decimal-utility";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {BillingDocumentCompositionComponent} from "../../billing-document-composition/billing-document-composition.component";
import {TaskHttpClient} from "../../../../common/task-http-client";
import {PayorListSearchRow} from "../../../../models/common/payor-list-search-row";
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'invoice-detail-debits',
  templateUrl: './invoice-detail-debits.component.html',
  styleUrls: ['./invoice-detail-debits.component.scss']
})
export class InvoiceDetailDebitsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @ViewChild('billingDocumentModal') billingDocumentModal: BillingDocumentCompositionComponent;

  invoiceId: number;
  repriceDto: {
    financialTransactionIds: number[],
    closeIfZeroBalance: boolean
    priceDate: Date
  };
  _showHistory: boolean = false;
  set showHistory(value: boolean) {
    if (this._showHistory !== value) {
      this._showHistory = value;
      this.reloadDebitsTable();
    }
  }

  dtTrigger: Subject<any> = new Subject();

  invoiceStatus: InvoiceStatus;

  debits: InvoiceDetailDebitDto[];
  debitsLoadedSubject: Subject<InvoiceDetailDebitDto[]> = new Subject<InvoiceDetailDebitDto[]>();

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};

  editDebit: InvoiceDetailDebitDto = new InvoiceDetailDebitDto();

  parentDisableRequeue = false;
  disableRequeue = false;
  requeueDebit: InvoiceDetailDebitDto = new InvoiceDetailDebitDto();
  requeueAmount: number;
  requeueQuantity: number;
  requeueServiceDate: Date;
  requeuePayor: PayorListSearchRow;
  requeueGroupingKey: string;
  requeueJobNumber: string;
  requeuePoNumber: string;

  selectedButtonIds: string[] = ['#repriceSelectedButton', '#requeueSelectedButton', '#voidSelectedButton', '#updateSelectedMenuButton'];

  constructor(private billingHttpClient: BillingHttpClient,
              private taskHttpClient: TaskHttpClient,
              private router: Router,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit() {
    this.load();
    const that = this;
    $('#requeuedebitmodal').on('shown.bs.modal', function (e) {
      that.requeuePayor = null;
    });
    $('#invoiceDebitBillingDocumentComposition').on('shown.bs.modal', function() {
      that.billingDocumentModal.load();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  load() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searching: false,
      autoWidth: false,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          searchable: false,
          width: '4%',
          data: 'id',
        },
        {
          targets: 1,
          width: '8%',
          data: 'transactionDate',
        },
        {
          targets: 2,
          width: '10%',
          data: 'patientName',
        },
        {
          targets: 3,
          width: '17%',
          data: 'description',
        },
        {
          targets: 4,
          width: '10%',
          data: 'branch',
        },
        {
          targets: 5,
          width: '6%',
          data: 'transactionType',
        },
        {
          targets: 6,
          width: '3%',
          data: 'quantity',
        },
        {
          targets: 7,
          width: '4%',
          data: 'amount',
        },
        {
          targets: 8,
          width: '6%',
          data: 'due',
        },
        {
          targets: 9,
          orderable: false,
          searchable: false,
          visible: false,
        },
      ],
      order: [[2, 'asc'], [1, 'asc'], [3, 'asc'], [8, 'desc'], [7, 'desc'], [6, 'desc'], [0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.invoiceId = this.invoiceId.toString();
        dataTablesParameters.showHistory = String(this._showHistory);
        that.billingHttpClient.Post<DataTablesResponse>("/invoice-detail-debits", dataTablesParameters)
          .subscribe(resp => {
            that.debits = resp.data as InvoiceDetailDebitDto[];
            that.debitsLoadedSubject.next(that.debits);
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-5 'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        {
          attr: {
            id: 'requeueSelectedButton',
            disabled: true,
          },
          text: '<span style="margin-left: 5px;">Void Selected</span>',
          className: "btn btn-danger float-right",
          action: function (e, dt, node, config) {
            $('#voidSelectedModal').modal('show');
          }
        },
        {
          attr: {
            id: 'voidSelectedButton',
            disabled: true,
          },
          text: '<span style="margin-left: 5px;">Requeue Selected</span>',
          className: "btn btn-warning float-right mr-2",
          action: function (e, dt, node, config) {
            $('#requeueSelectedModal').modal('show');
          }
        },
        {
          attr: {
            id: 'repriceSelectedButton',
            disabled: true,
            'data-toggle': 'modal',
            'data-target': '#repriceDebitsModal'
          },
          text: '<span style="margin-left: 5px;">Reprice Selected</span>',
          className: "btn btn-warning float-right mr-2",
          action: () => {
            // Clear array and push only checked row's financial transaction IDs
            this.repriceDto.financialTransactionIds.splice(0);
            const checkedBoxes = $('#tb-debits input.checks:checked');
            for (let i = 0; i < checkedBoxes.length; i++) {
              const id = parseInt(checkedBoxes[i].id, 10);
              this.repriceDto.financialTransactionIds.push(id);
            }
          }
        }
      ]
    };
  }

  // Used by void button
  voidDebit(debit: InvoiceDetailDebitDto) {
    const that = this;
    const payload = {
      financialTransactionId: debit.financialTransactionId,
      invoiceId: this.invoiceId
    };
    this.taskHttpClient.Post<CommonResult>('/void-debit', payload)
      .subscribe(resp => {
        if (resp.result === 'success') {
          that.onVoidDebitSucceeded();
        } else {
          alert('failed');
        }
      });
  }

  repriceDebit(debit: InvoiceDetailDebitDto) {
    this.repriceDto.financialTransactionIds = [debit.financialTransactionId];
  }

  showRequeueDebit(debit: InvoiceDetailDebitDto) {
    this.requeueDebit = _.cloneDeep(debit);
    this.requeueJobNumber = this.requeueDebit.jobNumber;
    this.requeuePoNumber = this.requeueDebit.poNumber;
    this.requeueQuantity = this.requeueDebit.quantity;

    $('#requeuedebitmodal').modal('show');
  }

  resetRequeue() {
    this.requeueDebit = new InvoiceDetailDebitDto();
    this.requeueQuantity = null;
    this.requeueAmount = null;
    this.requeuePayor = null;
    this.requeueServiceDate = null;
    this.requeueGroupingKey = null;
    this.requeueJobNumber = null;
    this.requeuePoNumber = null;
  }

  startRequeueDebit() {
    this.disableRequeue = true;

    const that = this;
    if (this.requeueAmount != null && (isNaN(this.requeueAmount) || DecimalUtility.countDecimals(this.requeueAmount) > 2)) {
      this.validationResultsService.setErrorMessages(['Price is invalid']);
    } else {
      const payload = {
        financialTransactionId: this.requeueDebit.financialTransactionId,
        amount: this.requeueAmount,
        payorId: this.requeuePayor?.payorId,
        quantity: this.requeueQuantity,
        serviceDate: this.requeueServiceDate,
        groupingKey: this.requeueGroupingKey,
        jobNumber: this.requeueJobNumber,
        poNumber: this.requeuePoNumber
      };

      this.taskHttpClient.Post<CommonResult>('/requeue-debit', payload)
        .subscribe(resp => {
          // Make sure modal is hidden before we refresh data and re-render the page
          $('.modal').each((i, elem) => $(elem).modal('hide'));
          document.querySelector('.modal-backdrop')?.remove();

          if (resp.result === 'success') {
            if (resp.validationResults?.length > 0 ?? false) {
              that.validationResultsService.setErrorMessages(resp.validationResults);
            }

            alert('Requeue success! Proceed to refresh this page.');
            window.location.reload();
          } else {
            that.validationResultsService.setErrorMessages(resp.validationResults);
          }
        }).add(() => {
          this.disableRequeue = false;
      });

      $('.modal').each((i, elem) => $(elem).modal('hide'));
      document.querySelector('.modal-backdrop')?.remove();
    }
  }

  reload() {
    sessionStorage.setItem("isShowDebits",'true');
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['invoice', this.invoiceId]));
  }


  onVoidDebitSucceeded() {
    this.reload()
  }

  isInvoiceClosed() {
    return this.invoiceStatus == InvoiceStatus.closed;
  }


  getVoidDebitMessage(): string {
    let result = "";
    if (this.invoiceStatus == InvoiceStatus.open) {
      result = "Are you sure you want to void this debit? The void will remove this debit from the invoice details."
    } else {
      result = "Are you sure you want to void this debit? Voiding this debit will create a copy of this charge with a negative amount and apply it to the current invoice."
    }
    return result;
  }


  openEditDebitModal(debit: InvoiceDetailDebitDto) {
    this.editDebit = _.cloneDeep(debit);
    $('#editdebitmodal').modal('show');
  }

  openEditDebitDocumentCompositionModal(debit: InvoiceDetailDebitDto) {
    this.billingDocumentModal.financialTransactionId = debit.financialTransactionId;
    this.billingDocumentModal.authorizationPatientId = debit.authorizationPatientId;
    $('#invoiceDebitBillingDocumentComposition').modal('show');
  }

  saveAndCloseEditDebitModal() {
    const that = this;
    this.billingHttpClient.Put<CommonResult>('/debit', that.editDebit)
      .subscribe(resp => {
        if (resp.result === 'success') {
          that.reloadDebitsTable();
          that.closeEditDebitModal();
        } else {
          alert('failed');
        }
      });
  }

  closeEditDebitModal() {
    this.editDebit = new InvoiceDetailDebitDto();
  }

  isDebitMissingRequiredInfo(debit: InvoiceDetailDebitDto): boolean {
    return debit.requiresAndMissingSpecimenId || debit.requiresAndMissingJobNumber || debit.requiresAndMissingPONumber;
  }

  reloadDebitsTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  applySelect() {
    const disabled = $('#tb-debits input.checks:checked').length === 0; // true when no boxes checked, otherwise false
    this.selectedButtonIds.forEach(id => $(id).prop('disabled', disabled));
  }

  switchChecks() {
    let checks = $('#tb-debits input.checks');
    if (checks.length === 0) {
      return;
    }
    let checkall = $('#checkall');
    for (let i = 0; i < checks.length; i++) {
      checks[i].checked = checkall[0].checked;
    }

    this.selectedButtonIds.forEach(id => $(id).prop('disabled', !checkall[0].checked));
  }

  voidSelected() {
    let select = [];
    let checks = $('#tb-debits input.checks');
    for (let j = 0; j < checks.length; j++) {
      if (checks[j].checked === true) {
        select.push(parseInt(checks[j].id));
      }
    }
    const payload = {
      invoiceId: this.invoiceId,
      financialTransactionIds: select,
    };
    this.taskHttpClient.Post<CommonResult>('/void-debits', payload)
      .subscribe(resp => {
        if (resp.result === 'success') {
          this.reload();
        } else {
          alert('failed');
        }
      });
  }

  requeueSelected() {
    this.disableRequeue = true;

    let select = [];
    let checks = $('#tb-debits input.checks');
    for (let j = 0; j < checks.length; j++) {
      if (checks[j].checked === true) {
        select.push(parseInt(checks[j].id));
      }
    }
    const payload = {
      invoiceId: this.invoiceId,
      payorId: this.requeuePayor?.payorId,
      serviceDate: this.requeueServiceDate,
      jobNumber: this.requeueJobNumber,
      poNumber: this.requeuePoNumber,
      financialTransactionIds: select,
    };
    this.taskHttpClient.Post<CommonResult>('/requeue-debits', payload)
      .subscribe(resp => {
        // Make sure modal is hidden before we refresh data and re-render the page
        $('.modal').each((i, elem) => $(elem).modal('hide'));
        document.querySelector('.modal-backdrop')?.remove();

        if (resp.result === 'success') {
          alert('Requeue success! Proceed to refresh this page.');
          window.location.reload();
        } else {
          alert('failed');
        }
      }).add(() => {
        this.disableRequeue = false;
      });

    $('.modal').each((i, elem) => $(elem).modal('hide'));
    document.querySelector('.modal-backdrop')?.remove();
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      // Disable action buttons
      this.applySelect();

      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  updatePoNumber() {
    const poNumber = prompt('Enter new PO number for selected debits.');
    if (poNumber) {
      const debitIds = [];
      const checkedBoxes = $('#tb-debits input.checks:checked');
      for (let i = 0; i < checkedBoxes.length; i++) {
        const id = parseInt(checkedBoxes[i].id, 10);
        debitIds.push(id);
      }
      this.billingHttpClient.Put<CommonResult>('/debits/po-number', {
        debitIds,
        poNumber
      }).subscribe(response => {
        if (response.result === 'success') {
          this.rerender();
        } else {
          alert('Failed to update PO number');
        }
      });
    }
  }

  updateJobNumber() {
    const jobNumber = prompt('Enter new job number for selected debits.');
    if (jobNumber) {
      const debitIds = [];
      const checkedBoxes = $('#tb-debits input.checks:checked');
      for (let i = 0; i < checkedBoxes.length; i++) {
        const id = parseInt(checkedBoxes[i].id, 10);
        debitIds.push(id);
      }
      this.billingHttpClient.Put<CommonResult>('/debits/job-number', {
        debitIds,
        jobNumber
      }).subscribe(response => {
        if (response.result === 'success') {
          this.rerender();
        } else {
          alert('Failed to update job number');
        }
      });
    }
  }
}
