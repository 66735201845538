import {EmployerEntryResultEmailDto} from "./employer-entry-result-email-dto";
import {EmployerEntryResultFaxDto} from "./employer-entry-result-fax-dto";
import {EmployerEntryResultAddressDto} from "./employer-entry-result-address-dto";

export class EmployerEntryResultsDto {
  employerDetailId: number;
  employerEntryResultEmailDtos: EmployerEntryResultEmailDto[] = [];
  employerEntryResultFaxDtos: EmployerEntryResultFaxDto[] = [];
  employerEntryResultAddressDtos: EmployerEntryResultAddressDto[] = [];
}
