import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError} from "rxjs/operators";
import {User} from "../models/authentication/user";
import {Role} from "../models/authentication/role";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSubject: BehaviorSubject<User>;
  private _user: User;
  private _hasBillingRole = false;
  private _clientId = 'billing-client';

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private oauthService: OAuthService) {
    this.oauthService.configure({
      loginUrl: environment.authUrl + '/oauth/authorize',
      redirectUri: environment.baseUrl,
      logoutUrl: environment.authUrl + '/logout',
      // postLogoutRedirectUri: environment.baseUrl,
      clientId: this._clientId,
      scope: 'user_info read write',
      oidc: false,
      showDebugInformation: true,
      requireHttps: false,
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      // silentRefreshTimeout: 5000, // For faster testing
    });
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tryLogin({
      onTokenReceived: _ => {
      }
    });
    this.userSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("user")));
    this.userSubject.subscribe(user => {
      this._user = user;
      if (user) {
        let roles = user.authorities || [];
        let authorities = roles.map(r => r.authority) || [];
        this._hasBillingRole = authorities.filter(a => a.startsWith('ROLE_billing')).length > 0
          || authorities.includes('ROLE_admin');
      }
    });
    this.oauthService.setupAutomaticSilentRefresh();
  }

  obtainAccessToken() {
    this.oauthService.initImplicitFlow();
  }

  getToken() {
    return this.oauthService.getAccessToken();
  }

  getUser() {
    let dummyUser = {
      name: "demo_billingadmin@primeoccmed.com",
      authorities: [
        {
          authority: "ROLE_billing"
        } as Role
      ]
    }
    return environment.defIsLogin ? dummyUser : this._user;
  }

  getUserSubject() {
    return this.userSubject;
  }

  getResource(resourceUrl): Observable<any> {
    let headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': 'Bearer ' + this.oauthService.getAccessToken()
    });
    return this._http.post(resourceUrl, {headers: headers})
      .pipe(
        catchError((error: any) => throwError(error.json().error || 'Server error'))
      );
  }

  // silentRefresh() {
  //   this.oauthService.silentRefresh()
  //     .then(info => console.debug('refresh ok', info))
  //     .catch(err => console.error('refresh error', err));
  // }

  isLoggedIn() {
    return environment.defIsLogin || this.oauthService.getAccessToken() !== null;
  }

  isTokenExpired() {
    return !environment.defIsLogin && !this.oauthService.hasValidAccessToken()
  }

  isAuthenticated() {
    return environment.defIsLogin || this._hasBillingRole;
  }

  hasRole(role: string) {
    if (this._user == null) return false;
    let roles = this._user.authorities || [];
    let authorities = roles.map(r => r.authority) || [];
    return authorities.includes(role);
  }

  logout() {
    sessionStorage.removeItem("user");

    let accessToken = this.oauthService.getAccessToken();
    let params = new HttpParams();
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      headers: headers,
      crossDomain: true,
      // Need this to revoke access token from remote
      // Need Authentication Server response head
      // response.setHeader("Access-Control-Allow-Origin", "http://localhost:4200");
      // response.setHeader("Access-Control-Allow-Credentials", "true");
      withCredentials: true,
    }
    params = params
      .set('client_id', this._clientId)
      .set('token', accessToken)
      .set('token_type_hint', 'access_token');
    // Need to revoke access token from authentication server
    this._http.post(environment.authUrl + '/logout', params, httpOptions)
      .subscribe((value) => {
        this.oauthService.logOut();
        location.href = environment.baseUrl;
        console.debug(value);
      }, (error) => {
        console.error(error);
      });
  }
}
