import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {EmployerSearchRow} from "../../../models/employer-list/employer-search-row";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {ClinicHttpClient} from "../../../common/clinic-http-client";

@Component({
  selector: 'employer-lookup',
  templateUrl: './employer-lookup.component.html',
  styleUrls: ['./employer-lookup.component.scss']
})
export class EmployerLookupComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  model: EmployerSearchRow[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  @Input() params: Map<string, any>;

  constructor(private clinicHttpClient: ClinicHttpClient) {
  }

  @Output() employerChange: EventEmitter<EmployerSearchRow> = new EventEmitter<EmployerSearchRow>();

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'employerName'},
        {data: 'employerAddress'},
      ],
      ajax: (dataTablesParameters: any, callback) => {
        if (that.params != null && that.params.size > 0) {
          that.params.forEach((val, key) => {
            dataTablesParameters[key] = val;
          });
        }
        that.clinicHttpClient.Post<DataTablesResponse>("/employer-search-rows", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  selectEmployer = (employer: EmployerSearchRow) => {
    this.employerChange.emit(employer);
    $('#employerLookupModal').modal('hide');
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  reloadTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}
