<h4 class="mt-3">Charge Code Displays</h4>
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>Charge Code</th>
    <th>Description</th>
    <th>Display</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <button type="button" class="btn btn-success" (click)="editDisplay(row)">
        <i class="fas fa-edit"></i>
      </button>
    </td>
    <td>{{row.chargeCode}}</td>
    <td>{{row.chargeCodeDescription}}</td>
    <td>{{row.chargeCodeDisplay}}</td>
    <td>
      <button class="btn btn-danger"
              reallyClick="Are you sure you want to delete this charge code display?"
              reallyClickAction="deleteDisplay"
              [reallyClickCaller]="this"
              [reallyClickArguments]="[row]">
        <i class="fas fa-trash-alt"></i>
      </button>
    </td>
  </tr>
  </tbody>
</table>
<div class="modal fade" id="chargeCodeDisplayModal" data-backdrop="static" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Charge Code Display</h3>
        <div *ngIf="!editChargeCodeDisplay.payorId || editChargeCodeDisplay.payorId == 0">
          <div class="row">
            <div class="col-md-4">
              <label class="pull-right text-label" for="chargeCode">Charge Code</label>
            </div>
            <div class="col-md-8">

              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-primary" type="button" data-toggle="modal"
                            data-target="#chargeCodeLookupModal">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <input type="text" class="form-control" id="chargeCode" name="chargeCode"
                         [(ngModel)]="editChargeCodeDisplay.chargeCode"
                         disabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editChargeCodeDisplay.payorId && editChargeCodeDisplay.payorId > 0">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalChargeCode">Charge Code:</label>
          </div>
          <div class="col-md-8">
            <input type="text" disabled class="form-control" id="modalChargeCode"
                   [(ngModel)]="editChargeCodeDisplay.chargeCode"/>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalDisplayChargeCode">Display Charge Code:</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modalDisplayChargeCode"
                   [(ngModel)]="editChargeCodeDisplay.chargeCodeDisplay"/>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" data-dismiss="modal" id="chargeCodeDisplayModalSave"
                (click)="saveChargeCodeDisplay()" [disabled]="!editChargeCodeDisplay.chargeCode
                || !editChargeCodeDisplay.chargeCodeDisplay">Save
        </button>
        <button class="btn btn-default" type="button" data-dismiss="modal" id="chargeCodeDisplayModalDismiss">Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<charge-code-lookup (chargeCodeChange)="chargeCodeChange($event)"></charge-code-lookup>
