import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {AdjustmentBatchSearchRow} from "../../../models/adjustment-batches/adjustment-batch-search-row";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {CommonResult} from "../../../models/common/common-result";
import {AdjustmentBatchLockDto} from '../../../models/adjustment-batch/adjustment-batch-lock-dto';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'adjustment-batches',
  templateUrl: './adjustment-batches.component.html',
  styleUrls: ['./adjustment-batches.component.scss']
})
export class AdjustmentBatchesComponent implements OnInit {

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};
  model: AdjustmentBatchSearchRow[] = [];
  lockModel: AdjustmentBatchLockDto = new AdjustmentBatchLockDto();


  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      order: [[1, 'desc']],
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "20%"
        },
      ],
      columns: [
        {data: ''},
        {data: 'adjustmentBatchId'},
        {data: 'startDate'},
        {data: 'postedDate'},
        {data: 'payor'},
        {data: 'checkNumber'},
        {data: 'total'},
        {data: 'status'},
      ],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/adjustment-batches", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data as AdjustmentBatchSearchRow[];
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-2 'B><'col-sm-3'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        {
          text: '<i class="fas fa-plus"></i><span style="margin-left: 5px;">Create Adjustment Batch</span>',
          className: "btn btn-success float-right",
          action: function (e, dt, node, config) {
            that.router.navigate(['adjustment-batch', "1", -1, -1, -1, {
              outlets: {
                linearStep: 'control-totals'
              }
            }])
          }
        }
      ]
    };
  }

  viewAdjustmentBatch = (adjustmentBatch: AdjustmentBatchSearchRow) => {
    let params = new HttpParams().set('adjustmentBatchId', adjustmentBatch.adjustmentBatchId.toString());
    this.billingHttpClient.Get<AdjustmentBatchLockDto>('/adjustment-batch-lock', {params})
      .subscribe(
        response => {
          this.lockModel = response;
          if (this.lockModel.locked){
            $('#lockmodal').modal('show');
          }else{
            this.onEdit(adjustmentBatch.adjustmentBatchId)
          }
        }
      );
  };

  onEdit(adjustmentBatchId: number) {
    this.router.navigate(['adjustment-batch', "1", adjustmentBatchId,-1,-1, {
      outlets: {
        linearStep: 'control-totals'
      }
    }]);
  }

  // Used by really click action
  copyAdjustmentBatch = (adjustmentBatch: AdjustmentBatchSearchRow) => {
    this.adjustmentBatchAction(adjustmentBatch.adjustmentBatchId, 'copy')
  }

  // Used by really click action
  reverseAdjustmentBatch = (adjustmentBatch: AdjustmentBatchSearchRow) => {
    this.adjustmentBatchAction(adjustmentBatch.adjustmentBatchId,'reverse')
  }

  private adjustmentBatchAction(adjustmentBatchId: number,type: string) {
    this.billingHttpClient.Post<CommonResult>(`/adjustment-batch/${adjustmentBatchId}/type/${type}`, {})
      .subscribe(resp => {
        if (resp.result == 'success') {
          location.reload();
        } else {
          alert('failed');
        }
      });
  }
}
