import {EmployerPayorVisitReasonListItemDto} from "./employer-payor-visit-reason-list-item-dto";
import {EmployerPayorChargeCodeListItemDto} from "./employer-payor-charge-code-list-item-dto";

export class EmployerPayorEntryResponsibilitiesDto {
  chargeCodePayorAssociationMode: string;
  visitReasonPayorAssociationMode: string;
  chargeCodeAndVisitReasonBinaryMode: string;
  employerPayorChargeCodes: EmployerPayorChargeCodeListItemDto[] = [];
  employerPayorVisitReasons: EmployerPayorVisitReasonListItemDto[] = [];
}
