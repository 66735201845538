import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CellClickedEvent, ColDef, ColumnApi, GridApi} from 'ag-grid-community';
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {EditButtonComponent} from "../../framework/edit-button/edit-button.component";
import {BranchDto} from "../../../models/common/branch-dto";

@Component({
  selector: 'app-search-branches',
  templateUrl: './search-branches.component.html',
  styleUrls: ['./search-branches.component.scss']
})
export class SearchBranchesComponent implements OnInit {

  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  columnDefs: ColDef[] = [];
  rowData: BranchDto[] = [];
  defaults = {
    suppressMovable: true,
    autoHeight: true,
  };

  constructor(private clinicHttpClient: ClinicHttpClient,
              public dialogRef: MatDialogRef<SearchBranchesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: BranchDto) {
  }

  ngOnInit(): void {
    this.clinicHttpClient.Get<BranchDto[]>('/branch-list')
      .subscribe(response => {
          this.rowData = response;
          this.columnDefs = [
            {
              headerName: '',
              cellRendererFramework: EditButtonComponent,
              resizable: false,
              onCellClicked: (params: CellClickedEvent) => {
                this.dialogRef.close(params.data);
              }
            } as ColDef,
            {
              headerName: 'Name',
              field: 'companyName',
              sortable: true,
              filter: 'agTextColumnFilter',
            } as ColDef,
          ]
          this.gridApi.setColumnDefs(this.columnDefs);
        }
      );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi?.autoSizeAllColumns();
    this.gridApi?.sizeColumnsToFit();
  } // onGridReady
}
