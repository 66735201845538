<div class="modal fade" id="testReasonLookupModal" tabindex="-1" role="dialog"
     aria-labelledby="testReasonLookupModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="testReasonLookupModalLabel">Search Visit Reasons</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th *ngIf="enableMultipleSelect"></th>
            <th></th>
            <th>Test Reason Code</th>
            <th>Test Reason Description</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td *ngIf="enableMultipleSelect">
              <div class="form-check">
                <mat-checkbox (change)="addOrRemoveTestReason($event.checked,row)" [checked]="checkTestReason(row)">
                </mat-checkbox>
              </div>
            </td>
            <td>
              <div class="button-group" role="group">
                <button class="btn btn-success mr-1" (click)="selectTestReason(row)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
            <td>{{row.testReasonCode}}</td>
            <td>{{row.testReasonDescription}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button *ngIf="enableMultipleSelect" id="chargeCodeLookupModalSave" type="button" class="btn btn-default"
                data-dismiss="modal" (click)="saveTestReason()">
          Save
        </button>
        <button id="testReasonLookupModalDismiss" type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
