import {BillingEntityFacilityDto} from "./billing-entity-facility-dto";
import {BillingEntityBranchDto} from "./billing-entity-branch-dto";

export class BillingEntityEntryDto {
  id: number;
  name: string;
  taxId: string;
  npiNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
  emailAddress: string;
  billingEnabled: boolean;
  billingEntityFacilities: BillingEntityFacilityDto[] = [];
  billingEntityBranches: BillingEntityBranchDto[] = [];
}
