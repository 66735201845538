import {Component, Input, OnInit} from "@angular/core";
import {PriceSearchHistoryDto} from "../../../../models/pending-debits-and-adjustments/price-search-hisotry-popup/price-search-history-dto";

@Component({
  selector: 'price-search-history-popup',
  templateUrl: './price-search-history-popup.component.html',
  styleUrls: ['./price-search-history-popup.component.scss']
})
export class PriceSearchHistoryPopupComponent implements OnInit {
  @Input() public history: PriceSearchHistoryDto;

  ngOnInit(): void {
  }
}
