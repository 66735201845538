import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {ChargeCodePriceRow} from 'src/app/models/charge-code-price-list/charge-code-price-row';
import {ChargeCodePriceUtility} from "../../../utilities/charge-code-price-utility";
import {ClinicHttpClient} from "../../../common/clinic-http-client";

@Component({
  selector: 'app-charge-code-price-list',
  templateUrl: './charge-code-price-list.component.html',
  styleUrls: ['./charge-code-price-list.component.scss']
})
export class ChargeCodePriceListComponent implements OnInit {

  // Must be declared as "any", not as "DataTables.Settings" for ButtonsExtension
  dtOptions: any = {};
  model: ChargeCodePriceRow[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private clinicHttpClient: ClinicHttpClient,
              private router: Router) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'chargeCode'},
        {data: 'description'},
        {data: 'price'},
        {data: 'startDateInclusive'},
        {data: 'endDateInclusive'},
        {data: 'payor'},
        {data: 'branch'},
        {data: 'billingEntity'},
        {data: 'visitReason'},
      ],
      ajax: (dataTableParams: any, callback) => {
        this.billingHttpClient.Post<DataTablesResponse>('/pricing-list', dataTableParams)
          .subscribe(a_resp => {
            this.model = a_resp.data;
            ChargeCodePriceUtility.getChargeCodePriceDetails(this.clinicHttpClient, this.model);
            callback({
              recordsTotal: a_resp.recordsTotal,
              recordsFiltered: a_resp.recordsFiltered,
              data: []
            });
          });
      },
      // Declare the use of the extension in the dom parameter
      // This is the most confusing part with using Bootstrap style for jQuery DataTables and it's undocumented so far.
      // Bootstrap extension overrides default dom
      // You have to use specially crafted dom option similar to shown below
      // You can be as creative as you want by using Bootstrap row and col-* classes in the dom option.
      dom: "<'row'<'col-sm-7'l><'col-sm-2 'B><'col-sm-3'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // Configure the buttons
      buttons: [
        {
          text: '<i class="fas fa-plus"></i><span style="margin-left: 5px;">Create New Price</span>',
          className: "btn btn-success float-right",
          action: function (e, dt, node, config) {
            that.router.navigate(['price'])
          }
        }
      ]
    };

  } // ngOnInit

  goToPrice(priceId: number) {
    this.router.navigate(['price', priceId]);
  }

}
