export class BranchEntryDto {
  branchId: number;
  branchName: string;
  facilityName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
}
