import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {BaseHttpClient} from "./base-http-client";

export function billingHttpClientCreator(http: HttpClient) {
  return new BillingHttpClient(http);
}

@Injectable()
export class BillingHttpClient extends BaseHttpClient {
  constructor(protected http: HttpClient) {
    super(http, environment.apiUrl);
  }
}

export const billingHttpClientProvider = {
  provide: BillingHttpClient,
  useFactory: billingHttpClientCreator,
  deps: [HttpClient]
};
