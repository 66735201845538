import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmployerPayorEntryResponsibilitiesDto} from "../../../../models/employer-payor-entry/employer-payor-entry-responsibilities-dto";
import {NgForm} from "@angular/forms";
import {EmployerPayorChargeCodeListItemDto} from "../../../../models/employer-payor-entry/employer-payor-charge-code-list-item-dto";
import {EmployerPayorVisitReasonListItemDto} from "../../../../models/employer-payor-entry/employer-payor-visit-reason-list-item-dto";
import {ChargeCodeLookupSearchRow} from "../../../../models/common/charge-code-lookup-search-row";
import {TestReasonLookupSearchRow} from "../../../../models/common/test-reason-lookup-search-row";

@Component({
  selector: 'employer-payor-entry-responsibilities',
  templateUrl: './employer-payor-entry-responsibilities.component.html',
  styleUrls: ['./employer-payor-entry-responsibilities.component.scss'],
})
export class EmployerPayorEntryResponsibilitiesComponent implements OnInit {

  constructor() {
  }

  @Input() model: EmployerPayorEntryResponsibilitiesDto = new EmployerPayorEntryResponsibilitiesDto();
  @Input() employerPayorId: number;
  @ViewChild('formResponsibilities') formResponsibilities: NgForm;

  chargeCodeBeingEdited: EmployerPayorChargeCodeListItemDto = new EmployerPayorChargeCodeListItemDto();
  visitReasonBeingEdited: EmployerPayorVisitReasonListItemDto = new EmployerPayorVisitReasonListItemDto();

  ngOnInit() {
  }

  get andRadioText(): string {
    return this.getRadioText('AND');
  }

  get orRadioText(): string {
    return this.getRadioText('OR');
  }

  private getRadioText(binaryType: string) {
    let result: string = binaryType + ' --- the debit ';
    if (this.model.chargeCodePayorAssociationMode == "Include") {
      result += 'MUST';
    } else if (this.model.chargeCodePayorAssociationMode == "Exclude") {
      result += 'must NOT';
    }
    result += ' contain one of the selected charge codes ' + binaryType + ' it ';
    if (this.model.visitReasonPayorAssociationMode == "Include") {
      result += 'MUST';
    } else if (this.model.visitReasonPayorAssociationMode == "Exclude") {
      result += 'must NOT';
    }
    result += ' contain one of the selected visit reasons';
    return result;
  }

  get showBinaryRadios(): boolean {
    let result: boolean = false;
    let ccStatus: boolean = this.model.chargeCodePayorAssociationMode == "Include"
      || this.model.chargeCodePayorAssociationMode == "Exclude";
    let vrStatus: boolean = this.model.visitReasonPayorAssociationMode == "Include"
      || this.model.visitReasonPayorAssociationMode == "Exclude";
    if (ccStatus && vrStatus) {
      result = true;
    }
    return result;
  }

  chargeCodeChange(chargeCodeLookupSearchRow: ChargeCodeLookupSearchRow) {
    if (this.model.employerPayorChargeCodes.findIndex(cc => cc.chargeCode == chargeCodeLookupSearchRow.code) == -1) {
      let result: EmployerPayorChargeCodeListItemDto = new EmployerPayorChargeCodeListItemDto;
      result.employerPayorId = this.employerPayorId;
      result.chargeCode = chargeCodeLookupSearchRow.code;
      result.description = chargeCodeLookupSearchRow.description;
      result.startDateInclusive = new Date('01/01/2000');
      result.endDateInclusive = new Date('12/31/9999');
      this.model.employerPayorChargeCodes.push(result);
    }
  }

  visitReasonChange(visitReasonLookupSearchRow: TestReasonLookupSearchRow) {
    if (this.model.employerPayorVisitReasons.findIndex(vr => vr.testReasonCode == visitReasonLookupSearchRow.testReasonCode) == -1) {
      var result: EmployerPayorVisitReasonListItemDto = new EmployerPayorVisitReasonListItemDto;
      result.employerPayorId = this.employerPayorId;
      result.testReasonCode = visitReasonLookupSearchRow.testReasonCode;
      result.description = visitReasonLookupSearchRow.testReasonDescription;
      result.startDateInclusive = new Date('1/1/2000');
      result.endDateInclusive = new Date('12/31/9999');
      this.model.employerPayorVisitReasons.push(result);
    }
  }

  onChargeCodeAndVisitReasonBinaryMode(mode: string) {
    this.model.chargeCodeAndVisitReasonBinaryMode = mode;
  }

  editEmployerPayorChargeCode(row: EmployerPayorChargeCodeListItemDto) {
    this.chargeCodeBeingEdited = new EmployerPayorChargeCodeListItemDto();
    this.chargeCodeBeingEdited.chargeCode = row.chargeCode;
    this.chargeCodeBeingEdited.startDateInclusive = row.startDateInclusive;
    this.chargeCodeBeingEdited.endDateInclusive = row.endDateInclusive;
    $('#editChargeCode').modal('show');
  }

  saveChargeCode() {
    let chargeCode = this.model.employerPayorChargeCodes.find(cc => cc.chargeCode == this.chargeCodeBeingEdited.chargeCode);
    chargeCode.startDateInclusive = this.chargeCodeBeingEdited.startDateInclusive;
    chargeCode.endDateInclusive = this.chargeCodeBeingEdited.endDateInclusive;
    this.closeChargeCodeModal();
  }

  closeChargeCodeModal() {
    $('#editChargeCode').modal('hide');
  }

  editEmployerPayorVisitReason(row: EmployerPayorVisitReasonListItemDto) {
    this.visitReasonBeingEdited = new EmployerPayorVisitReasonListItemDto();
    this.visitReasonBeingEdited.testReasonCode = row.testReasonCode;
    this.visitReasonBeingEdited.startDateInclusive = row.startDateInclusive;
    this.visitReasonBeingEdited.endDateInclusive = row.endDateInclusive;
    $('#editVisitReason').modal('show');
  }

  closeVisitReasonModal() {
    $('#editVisitReason').modal('hide');
  }

  saveVisitReason() {
    let visitReason = this.model.employerPayorVisitReasons.find(vr => vr.testReasonCode == this.visitReasonBeingEdited.testReasonCode);
    visitReason.startDateInclusive = this.visitReasonBeingEdited.startDateInclusive;
    visitReason.endDateInclusive = this.visitReasonBeingEdited.endDateInclusive;
    this.closeVisitReasonModal();
  }
}
