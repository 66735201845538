<button class="btn btn-success mb-2" type="button" data-toggle="modal" data-target="#mailLookupModal">
  <i class="fa">Email Deliver Log</i>
</button>
<table class="table table-bordered table-striped table-hover">
  <thead>
  <tr>
    <th></th>
    <th>Description</th>
    <th>Time</th>
    <th>User</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of model">
    <td class="fit" style="width: 30px">
      <div _class="button-container" style="text-align: center" [hidden]="!item.adjustmentBatchId">
              <span class="input-group=btn fix-span">
                <button class="grid-btn btn btn-sm btn-raised btn-success" type="button"
                        (click)="onSelectHistoryItem(item.adjustmentBatchId)"
                        [attr.disabled]="item.adjustmentBatchId == null ? 'disabled' : null">
                <i class="fa fa-fw fa-edit"></i>
                </button>
              </span>
      </div>
    <td>
      {{item.description}}
    </td>
    <td>
      {{item.time | date:'MM/dd/yyyy, h:mm:ss a'}}
    </td>
    <td>
      {{item.userName}}
    </td>
  </tr>
  </tbody>
</table>
<app-invoice-email-deliver-log #mailLookupModal [params]="mailParams"  ></app-invoice-email-deliver-log>
