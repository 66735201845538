import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {BaseHttpClient} from "./base-http-client";

export function documentHttpClientCreator(http: HttpClient) {
  return new DocumentHttpClient(http);
}

@Injectable()
export class DocumentHttpClient extends BaseHttpClient {
  constructor(protected http: HttpClient) {
    super(http, environment.documentUrl);
  }
}

export const documentHttpClientProvider = {
  provide: DocumentHttpClient,
  useFactory: documentHttpClientCreator,
  deps: [HttpClient]
};
