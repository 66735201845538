import {Component, Input, OnInit} from '@angular/core';
import {PayorEntryCustomPriceDto} from "../../../../models/payor-entry/payor-entry-custom-price-dto";
import {NavigationExtras, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";

@Component({
  selector: 'app-payor-entry-custom-pricing',
  templateUrl: './payor-entry-custom-pricing.component.html',
  styleUrls: ['./payor-entry-custom-pricing.component.scss']
})
export class PayorEntryCustomPricingComponent implements OnInit {

  @Input() payorId: number;
  @Input() payorType: string;
  @Input() payorName: string;
  model: PayorEntryCustomPriceDto[] = [];

  constructor(
    private billingHttpClient: BillingHttpClient,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.payorType != 'Employer') {
      const params = new HttpParams().set('payorId', this.payorId.toString());
      this.billingHttpClient.Get<PayorEntryCustomPriceDto[]>('/payor-custom-prices', {params})
        .subscribe(response => {
            this.model = response;
          }
        );
    }
  }

  editPrice(row: PayorEntryCustomPriceDto) {
    this.router.navigate(['price', row.chargeCodePriceId]);
  }

  addNewPrice() {
    var extras: NavigationExtras = {
      queryParams: {
        "payorId": this.payorId,
        "payorName": this.payorName,
      }
    };
    this.router.navigate(['price'], extras);
  }

}
