export const environment = {
  production: true,
  env: "PROD",
  authUrl: "https://oauth.prime-eme.com",
  baseUrl: "https://billing.prime-eme.com",
  apiUrl: "https://billingapi.prime-eme.com",
  clinicUrl: "https://clinicapi.prime-eme.com",
  documentUrl: "https://billingapi.prime-eme.com/docs",
  taskUrl: "https://billingapi.prime-eme.com/tasks",
  reviewerApi8Url: "https://billingapi.prime-eme.com/reviewer",
  reviewerApi9Url: "https://reviewerapi.prime-eme.com",
  defIsLogin: false,
};
