export class InvoiceDetailDebitDto {
  financialTransactionId: number;
  transactionDate: Date;
  patientName: string;
  authorizationPatientId: number;
  description: string;
  quantity: number;
  transactionType: string;
  amount: number;
  due: number;
  hasBeenVoided: boolean;
  hasBeenPartialPaid: boolean;
  hasBeenFullyPaid: boolean;
  paymentReversed: boolean;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  specimenId: string;
  jobNumber: string;
  poNumber: string;
  providerNPI: string;
  displayChargeCode: string;
  notes: string;
  requiresAndMissingSpecimenId: boolean;
  requiresAndMissingJobNumber: boolean;
  requiresAndMissingPONumber: boolean;
  branch: string;
}
