import {Component, OnDestroy, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {ActivatedRoute} from "@angular/router";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {GenerateStatementsHistoryPayorSearchRow} from "../../../../models/system-statement-detail/generate-statements-history-payor-search-row";

@Component({
  selector: 'app-system-statement-detail',
  templateUrl: './system-statement-detail.component.html',
  styleUrls: ['./system-statement-detail.component.scss']
})
export class SystemStatementDetailComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();
  dtOptions: DataTables.Settings = {};

  model: GenerateStatementsHistoryPayorSearchRow[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.load(+params['id']);
      });
  }

  load(id) {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columns: [
        {data: 'date'},
        {data: 'name'},
        {data: 'statementsQueued'},
        {data: 'zeroDayBalanceSum'},
        {data: 'thirtyDayBalanceSum'},
        {data: 'sixtyDayBalanceSum'},
        {data: 'ninetyDayBalanceSum'},
        {data: 'totalBalanceSum'},
        {data: 'time'},
      ],
      columnDefs: [
        {
          targets: 0,
          width: '10%',
        },
        {
          targets: 1,
          width: '10%',
        },
        {
          targets: 2,
          width: '10%',
        },
        {
          targets: 3,
          width: '10%',
        },
        {
          targets: 4,
          width: '10%',
        },
        {
          targets: 5,
          width: '10%',
        },
        {
          targets: 6,
          width: '10%',
        },
        {
          targets: 7,
          width: '10%',
        },
        {
          targets: 8,
          width: '10%',
        },
        {
          targets: 9,
          width: '10%',
        },
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.generateStatementsHistoryId = id.toString();
        that.billingHttpClient.Post<DataTablesResponse>('/system-statement-detail', dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
