import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'imageDisplay'
})
export class ImageDisplayPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(base64content, maxWidth, maxHeight, cssClass): any {
    if (base64content) {
      var html = `<img style="max-width:${maxWidth};max-height:${maxHeight}" class="${cssClass}" src="data:image/png;base64,${base64content}" />`;
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  }

}
