import {ChargeCodePriceRow} from "../charge-code-price-list/charge-code-price-row";

export class ChargeCodeEntryDto {
  id: number;
  code: string;
  description: string;
  startDate: Date;
  endDate: Date;
  requiresSpecimenId: boolean;
  allowDecimalQuantities: boolean;
  chargeCodePrices: ChargeCodePriceRow[];
}
