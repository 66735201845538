export class EmployerEntryGeneralDto {
  employerId: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  taxId: string;
  accountsPayableName: string;
  accountsPayablePhone: string;
  accountsPayableFax: string;
  accountsPayableEmail: string;
  hrMainName: string;
  hrMainPhone: string;
  injuryManagementName: string;
  injuryManagementPhone: string;
  safetyManagerName: string;
  safetyManagerPhone: string;
  responsibleManagerName: string;
  responsibleManagerTitle: string;
  responsibleManagerEmailAddress: string;
  responsibleManagerPhone: string;
  salesRepresentativeId: number;
  active: boolean;
  created: Date;
}
