export class PayorContactEntryDto {
  id: number;
  payorId: number;
  rank: number;
  name: string;
  title: string;
  email: string;
  phone: string;
  description: string;
  notes: string;
  startDateInclusive: Date;
  endDateInclusive: Date;
}
