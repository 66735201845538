import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {FailedEmailsSearchRow} from "../../../models/failed-emails/failed-emails-search-row";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {CommonResult} from "../../../models/common/common-result";

@Component({
  selector: 'failed-emails',
  templateUrl: './failed-emails.component.html',
  styleUrls: ['./failed-emails.component.scss']
})
export class FailedEmailsComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  model: FailedEmailsSearchRow[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private billingHttpClient: BillingHttpClient) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'createdDate'},
        {data: 'failureType'},
        {data: 'emailAddress'},
        {data: 'failureReason'},
        {data: 'failureStatus'},
      ],
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/failed-emails", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  reloadTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  //Used by really click
  dismissFailedEmail = (row: FailedEmailsSearchRow) => {
    const payload = {
      failedEmailId: row.failedEmailId,
    };
    this.billingHttpClient.Post<CommonResult>('/dismiss-failed-email', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          this.reloadTable();
        } else {
          alert('failed');
        }
      });
  }

  dismissAllFailedEmail = () => {
    // let failedEmailIds = this.model.map(r => r.failedEmailId) || [];
    // const payload = {
    //   failedEmailIds: failedEmailIds,
    // };
    this.billingHttpClient.Post<CommonResult>('/dismiss-all-failed-email', {})
      .subscribe(response => {
        if (response.result == 'success') {
          this.reloadTable();
        } else {
          alert('failed');
        }
      });
  }
}
