<form #formResponsibilities="ngForm">
  <div class="row mt-3">
    <div *ngIf="showBinaryRadios">
      <div class="col-md-12">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="andRadio" value="And"
                 [checked]="model.chargeCodeAndVisitReasonBinaryMode == 'And'"
                 (change)="onChargeCodeAndVisitReasonBinaryMode('And')">
          <label class="form-check-label" for="andRadio">{{andRadioText}}</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="orRadio" value="Or"
                 [checked]="model.chargeCodeAndVisitReasonBinaryMode == 'Or'"
                 (change)="onChargeCodeAndVisitReasonBinaryMode('Or')">
          <label class="form-check-label" for="orRadio">{{orRadioText}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <label class="control-label edit-label-offset">Charge Code to Payor Association Mode</label><span
          class="required"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <select class="form-control" name="ChargeCodePayorAssociationMode"
                  [(ngModel)]="model.chargeCodePayorAssociationMode">
            <option value="">(select)</option>
            <option value="All">Pay for All Charge Codes</option>
            <option value="Include">Pay for Selected Charge Codes</option>
            <option value="Exclude">Pay for Charge Codes Not Selected</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <label class="control-label edit-label-offset">Visit Reason to Payor Association Mode</label><span
          class="required"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <select class="form-control" name="VisitReasonPayorAssociationMode"
                  [(ngModel)]="model.visitReasonPayorAssociationMode">
            <option value="">(select)</option>
            <option value="All">Pay for All Visit Reasons</option>
            <option value="Include">Pay for Selected Visit Reasons</option>
            <option value="Exclude">Pay for Visit Reasons Not Selected</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6">
      <div *ngIf="model.chargeCodePayorAssociationMode == 'Include' || model.chargeCodePayorAssociationMode == 'Exclude'">
        <h4>Charge Codes</h4>
        <hr class="divider"/>
        <div class="col-md-12">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#chargeCodeLookupModal">
                  <i class="fa fa-search mr-2"></i>Add Charge Code
                </button>
              </div>
            </div>
          </div>
          <charge-code-lookup (chargeCodeChange)="chargeCodeChange($event)"></charge-code-lookup>
        </div>
        <div class="panel panel-default-dark">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th class="fit"></th>
                <th>Code</th>
                <th>Description</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of model.regionPayorChargeCodes">
                <td class="fit button-container">
                  <div>
                    <button type="button" [title]="'Edit'"
                            (click)="editRegionPayorChargeCode(row);"
                            class="grid-btn btn btn-sm btn-raised btn-success">
                      <i [ngClass]="'fa'"></i>Edit
                    </button>
                  </div>
                </td>
                <td>
                  <span>{{row.chargeCode}}</span>
                </td>
                <td>
                  <span>{{row.description}}</span>
                </td>
                <td>
                  <span>{{row.startDateInclusive | date:"M/d/yyyy":"UTC"}}</span>
                </td>
                <td>
                  <span>{{row.endDateInclusive | date: "M/d/yyyy":"UTC"}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6"
         *ngIf="model.visitReasonPayorAssociationMode == 'Include' || model.visitReasonPayorAssociationMode == 'Exclude'">
      <h4>Visit Reasons</h4>
      <hr class="divider"/>
      <div class="col-md-12">

        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#testReasonLookupModal">
                <i class="fa fa-search mr-2"></i>Add Visit Reasons
              </button>
            </div>
          </div>
        </div>
        <visit-reason-lookup (testReasonChange)="visitReasonChange($event)"></visit-reason-lookup>
      </div>
      <div class="panel panel-default-dark">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th class="fit"></th>
              <th>Test Reason</th>
              <th>Description</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of model.regionPayorVisitReasons">
              <td class="fit button-container">
                <div>
                  <button type="button" [title]="'Edit'"
                          (click)="editRegionPayorVisitReason(row);"
                          class="grid-btn btn btn-sm btn-raised btn-success">
                    <i [ngClass]="'fa'"></i>Edit
                  </button>
                </div>
              </td>
              <td>
                <span>{{row.testReasonCode}}</span>
              </td>
              <td>
                <span>{{row.description}}</span>
              </td>
              <td>
                <span>{{row.startDateInclusive | date: "M/d/yyyy":"UTC"}}</span>
              </td>
              <td>
                <span>{{row.endDateInclusive | date: "M/d/yyyy":"UTC"}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal fade" id="editChargeCode" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Edit Region-Payor Charge Code: {{chargeCodeBeingEdited.chargeCode}}
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>Start Date<span class="text-danger">*</span></mat-label>
                <input matInput [matDatepicker]="picker3" [(ngModel)]="chargeCodeBeingEdited.startDateInclusive">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>End Date<span class="text-danger">*</span></mat-label>
                <input matInput [matDatepicker]="picker4" [(ngModel)]="chargeCodeBeingEdited.endDateInclusive">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="saveChargeCode" type="button" class="btn btn-success" (click)="saveChargeCode()"
                [disabled]="!chargeCodeBeingEdited.startDateInclusive || !chargeCodeBeingEdited.endDateInclusive">
          Save
        </button>
        <button id="closeChargeCode" type="button" class="btn btn-warning" data-dismiss="modal"
                (click)="closeChargeCodeModal()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="editVisitReason" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Edit Region-Payor Visit Reason: {{visitReasonBeingEdited.testReasonCode}}
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>Start Date<span class="text-danger">*</span></mat-label>
                <input matInput [matDatepicker]="picker5" [(ngModel)]="visitReasonBeingEdited.startDateInclusive">
                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                <mat-datepicker #picker5></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>End Date<span class="text-danger">*</span></mat-label>
                <input matInput [matDatepicker]="picker6" [(ngModel)]="visitReasonBeingEdited.endDateInclusive">
                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                <mat-datepicker #picker6></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="saveVisitReason" type="button" class="btn btn-success" (click)="saveVisitReason()"
                [disabled]="!visitReasonBeingEdited.startDateInclusive || !visitReasonBeingEdited.endDateInclusive">
          Save
        </button>
        <button id="closeVisitReason" type="button" class="btn btn-warning" data-dismiss="modal"
                (click)="closeVisitReasonModal()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>
