import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import {Subject} from "rxjs";
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {ActivatedRoute} from "@angular/router";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {CommonResult} from "../../../models/common/common-result";
import {RegionPayorEntryDto} from "../../../models/region-payor-entry/region-payor-entry-dto";
import {RegionPayorEntryGeneralComponent} from "./region-payor-entry-general/region-payor-entry-general.component";
import {RegionPayorEntryResponsibilitiesComponent} from "./region-payor-entry-responsibilities/region-payor-entry-responsibilities.component";
import {CanComponentDeactivate} from "../../../services/can-deactivate-guard.service";

@Component({
  selector: 'region-payor-entry',
  templateUrl: './region-payor-entry.component.html',
  styleUrls: ['./region-payor-entry.component.scss']
})
export class RegionPayorEntryComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  id: number;
  private ngUnsubscribe = new Subject();

  constructor(
    private billingHttpClient: BillingHttpClient,
    private route: ActivatedRoute,
    private validationResultsService: ValidationResultsService) {
  }

  model: RegionPayorEntryDto = new RegionPayorEntryDto();
  @ViewChild('form') form: NgForm;
  @ViewChild('responsibilitiesComponent') responsibilitiesComponent: RegionPayorEntryResponsibilitiesComponent;
  @ViewChild('generalComponent') generalComponent: RegionPayorEntryGeneralComponent;

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
      });
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Get<RegionPayorEntryDto>('/region-payor-entry', {params})
      .subscribe(response => {
        this.model = response;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    this.billingHttpClient.Post<CommonResult>('/region-payor-entry', this.model)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded'])
          this.markAsPristine();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  saveClose() {
    this.save();
    this.cancel();
  }

  private markAsPristine() {
    this.form.form.markAsPristine();
    this.generalComponent.formGeneral.form.markAsPristine();
    this.responsibilitiesComponent.formResponsibilities.form.markAsPristine();
  }

  // noinspection JSUnusedGlobalSymbols
  canDeactivate(): Promise<boolean> | boolean {
    if (this.form.dirty || this.responsibilitiesComponent.formResponsibilities.dirty || this.generalComponent.formGeneral.dirty) {
      return window.confirm('Do you want to discard your changes?');
    }
    return true;
  }

  delete() {
    const params = new HttpParams().set('id', this.id.toString());
    this.billingHttpClient.Delete<CommonResult>('/region-payor-entry', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded'])
          this.markAsPristine();
          window.history.back();
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }

  cancel() {
    window.history.back();
  }
}
