<div class="row">
  <div class="col-md-12">
    <h1>Health insurance claim form</h1>
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">Insurance Adjuster Name</label>
    <br/>
    <input type="text" class="form-control" name="insurance-adjuster-name" [(ngModel)]="model.insuranceAdjusterName">
  </div>
  <div class="col-md-3">
    <label class="control-label">Insurance Adjuster Address1</label>
    <br/>
    <input type="text" class="form-control" name="insurance-adjuster-address1"
           [(ngModel)]="model.insuranceAdjusterAddress1">
  </div>
  <div class="col-md-3">
    <label class="control-label">Insurance Adjuster Address2</label>
    <br/>
    <input type="text" class="form-control" name="insurance-adjuster-address2"
           [(ngModel)]="model.insuranceAdjusterAddress2">
  </div>
</div>
<div class="row mt-2">
  <div class="col-md-3">
    <label class="control-label"
           [ngClass]="{'text-danger':!model.payorCity,'':model.payorCity}">Payor City</label>
    <br/>
    <input type="text" class="form-control" name="payor-city" [(ngModel)]="model.payorCity">
  </div>
  <div class="col-md-3">
    <label class="control-label"
           [ngClass]="{'text-danger':!model.payorState,'':model.payorState}">Payor State</label>
    <br/>
    <input type="text" class="form-control" name="payor-state" [(ngModel)]="model.payorState">
  </div>
  <div class="col-md-3">
    <label class="control-label" [ngClass]="{'text-danger':!model.payorPostalCode,'':model.payorPostalCode}">Payor Postal Code</label>
    <br/>
    <input type="text" class="form-control" name="payor-postal-code" [(ngModel)]="model.payorPostalCode">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">1a.Insured's I.D. Number</label>
    <br/>
    <br/>
    <input type="text" class="form-control" name="insured-id-number" [(ngModel)]="model.insuredIdNumber">
  </div>
  <div class="col-md-3">
    <label class="control-label">2.Patient Name (Last Name, FirstName, Middle Initial)</label>
    <br/>
    <br/>
    <input type="text" class="form-control" name="patient-name" [(ngModel)]="model.patientName">
  </div>
  <div class="col-md-2">
    <label class="control-label">3.Patient's Birth Date</label>
    <mat-form-field class="w-100">
      <input matInput [matDatepicker]="picker1" [(ngModel)]="model.patientBirthday">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-md-1">
    <label class="control-label" [ngClass]="{'text-danger':!model.patientGender,'':model.patientGender}">
      3.Patient's Gender</label>
    <br/>
    <select class="dropdown form-control" [(ngModel)]="model.patientGender">
      <option [ngValue]="null"></option>
      <option value="M">M</option>
      <option value="F">F</option>
      <option value="O">O</option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="control-label">4.Insured's Name (Last Name, FirstName, Middle Initial)</label>
    <br/>
    <br/>
    <input type="text" class="form-control" name="insured-name" [(ngModel)]="model.insuredName">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">5.Patient's Address (No., Stree)</label>
    <input type="text" class="form-control" name="patient-address" [(ngModel)]="model.patientAddress">
  </div>
  <div class="col-md-2">
    <label class="control-label">5.Patient's City</label>
    <br/>
    <input type="text" class="form-control" name="patient-city" [(ngModel)]="model.patientCity">
  </div>
  <div class="col-md-1">
    <label class="control-label">5.Patient's State</label>
    <input type="text" class="form-control" name="patient-state" [(ngModel)]="model.patientState">
  </div>
  <div class="col-md-3">
    <label class="control-label">5.Patient's Zip Code</label>
    <input type="text" class="form-control" name="patient-zip" [(ngModel)]="model.patientZip">
  </div>
  <div class="col-md-3">
    <label class="control-label">5.Patient's Telephone(Include Area Code)</label>
    <input type="text" class="form-control" name="patient-tel" [(ngModel)]="model.patientTel">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">7.Insured's Address (No., Stree)</label>
    <input type="text" class="form-control" name="insured-address" [(ngModel)]="model.insuredAddress">
  </div>
  <div class="col-md-3">
    <label class="control-label">7.Insured's City</label>
    <input type="text" class="form-control" name="insured-city" [(ngModel)]="model.insuredCity">
  </div>
  <div class="col-md-3">
    <label class="control-label">7.Insured's State</label>
    <input type="text" class="form-control" name="insured-state" [(ngModel)]="model.insuredState">
  </div>
  <div class="col-md-3">
    <label class="control-label">7.Insured's Zip Code</label>
    <input type="text" class="form-control" name="insured-zip" [(ngModel)]="model.insuredZip">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">11.Insured's Policy Group Or FECA Number</label>
    <br/>
    <br/>
    <input type="text" class="form-control" name="insured-policy-group" [(ngModel)]="model.insuredPolicyNumber">
  </div>
  <div class="col-md-2">
    <label class="control-label">11a.Insured's Date Of Birth</label>
    <mat-form-field class="w-100">
      <input matInput [matDatepicker]="picker2" [(ngModel)]="model.insuredDateOfBirth">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-md-1">
    <label class="control-label">11.Insured's gender</label>
    <br/>
    <select class="dropdown form-control" [(ngModel)]="model.insuredGender">
      <option [ngValue]="null"></option>
      <option value="M">M</option>
      <option value="F">F</option>
      <option value="O">O</option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="control-label">14.Date Of Current Illness, Injury, or Pregnancy (LMP)</label>
    <mat-form-field class="w-100">
      <input matInput [matDatepicker]="picker3" [(ngModel)]="model.dateOfCurrentIllness">
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <label class="control-label" [ngClass]="{'text-danger':!model.qualOfCurrent,'':model.qualOfCurrent}">14.QUAL</label>
    <br/>
    <br/>
    <input type="text" class="form-control" name="qual" [(ngModel)]="model.qualOfCurrent">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">17.Name Of Referring Provider Or Other Source</label>
    <br/>
    <input type="text" class="form-control" name="name-of-referring" [(ngModel)]="model.nameOfReferringProvider">
  </div>
  <div class="col-md-3">
    <label class="control-label">17.a</label>
    <input type="text" class="form-control" name="item-17a" [(ngModel)]="model.item17A">
  </div>
  <div class="col-md-3">
    <label class="control-label">17.b NPI</label>
    <input type="text" class="form-control" name="item-17b" [(ngModel)]="model.item17BNpi">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">21.1 Diagnosis Or Nature Of Illness Or Injury</label>
    <br/>
    <input type="text" class="form-control" name="diagnosis-a" [(ngModel)]="model.diagnosisOrNatureOfIllnessOrInjuryA">
  </div>
  <div class="col-md-3">
    <label class="control-label">21.2 Diagnosis Or Nature Of Illness Or Injury</label>
    <br/>
    <input type="text" class="form-control" name="diagnosis-b" [(ngModel)]="model.diagnosisOrNatureOfIllnessOrInjuryB">
  </div>
  <div class="col-md-3">
    <label class="control-label">21.3 Diagnosis Or Nature Of Illness Or Injury</label>
    <br/>
    <input type="text" class="form-control" name="diagnosis-c" [(ngModel)]="model.diagnosisOrNatureOfIllnessOrInjuryC">
  </div>
  <div class="col-md-3">
    <label class="control-label">21.4 Diagnosis Or Nature Of Illness Or Injury</label>
    <br/>
    <input type="text" class="form-control" name="diagnosis-d" [(ngModel)]="model.diagnosisOrNatureOfIllnessOrInjuryD">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label"
           [ngClass]="{'text-danger':!model.resubmissionCode,'':model.resubmissionCode}">22. Resubmission Code</label>
    <br/>
    <select class="dropdown form-control" [(ngModel)]="model.resubmissionCode">
      <option [ngValue]="null"></option>
      <option value="1">Original</option>
      <option value="7">Replacement</option>
      <option value="8">Void</option>
    </select>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div *ngFor="let service of model.serviceDtos; let i = index">
  <div class="row">
    <div class="col-md-12">
      <h3>Service {{i + 1}}</h3>
      <hr class="divider"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <label class="control-label">24.A From Date Of Service</label>
      <mat-form-field class="w-100">
        <input matInput [matDatepicker]="picker4" [(ngModel)]="service.fromDateOfService">
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <label class="control-label">24.A To Date Of Service</label>
      <mat-form-field class="w-100">
        <input matInput [matDatepicker]="picker5" [(ngModel)]="service.toDateOfService">
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <label class="control-label">24.D CPT/HCPCS</label>
      <br/>
      <br/>
      <input type="text" class="form-control" name="cpt-{{i + 1}}}" [(ngModel)]="service.serviceId">
    </div>
    <div class="col-md-1">
      <label class="control-label">24.E Modifier1</label>
      <br/>
      <br/>
      <input type="text" class="form-control" name="modifier1-{{i + 1}}" [(ngModel)]="service.modifier1">
    </div>
    <div class="col-md-1">
      <label class="control-label">24.E Modifier2</label>
      <br/>
      <br/>
      <input type="text" class="form-control" name="modifier2-{{i + 1}}" [(ngModel)]="service.modifier2">
    </div>
    <div class="col-md-1">
      <label class="control-label">24.E Modifier3</label>
      <br/>
      <br/>
      <input type="text" class="form-control" name="modifier3-{{i + 1}}" [(ngModel)]="service.modifier3">
    </div>
    <div class="col-md-1">
      <label class="control-label">24.E Modifier4</label>
      <br/>
      <br/>
      <input type="text" class="form-control" name="modifier4-{{i + 1}}" [(ngModel)]="service.modifier4">
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <label class="control-label">24.F Charge</label>
      <br/>
      <input type="number" class="form-control" name="charge-{{i+1}}" [(ngModel)]="service.charge">
    </div>
    <div class="col-md-3">
      <label class="control-label">24.G Days Of Units</label>
      <br/>
      <input type="number" class="form-control" name="days-of-units-{{i+1}}" [(ngModel)]="service.daysOrUnits">
    </div>
    <div class="col-md-3">
      <label class="control-label">24.J Rendering Provider Id</label>
      <br/>
      <input type="text" class="form-control" name="rendering-provider-id-{{i + 1}}"
             [(ngModel)]="service.renderingProviderId">
    </div>
    <div class="col-md-3">
      <label class="control-label">24. Line item Control Number</label><span class="required">*</span>
      <br/>
      <input type="text" class="form-control" name="line-item-control-number-{{i + 1}}"
             [(ngModel)]="service.lineItemControlNumber">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <hr class="divider"/>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">25. Federal Tax I.D. Number</label>
    <br/>
    <input type="text" class="form-control" name="federal-tax-id-number" [(ngModel)]="model.federalTaxIdNumber">
  </div>
  <div class="col-md-3">
    <label class="control-label">26 Patient's Account No.</label>
    <br/>
    <input type="text" class="form-control" name="patient-account-no" [(ngModel)]="model.patientAccountNo">
  </div>
  <div class="col-md-3">
    <label class="control-label">28 Total Charge</label>
    <br/>
    <input type="number" class="form-control" name="total-charge" [(ngModel)]="model.totalCharge">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">32. Service Facility Location Information A</label>
    <br/>
    <input type="text" class="form-control" name="service-facility-loc-a"
           [(ngModel)]="model.serviceFacilityLocationInfoA">
  </div>
  <div class="col-md-3">
    <label class="control-label">32. Service Facility Location Information B</label>
    <br/>
    <input type="text" class="form-control" name="service-facility-loc-b"
           [(ngModel)]="model.serviceFacilityLocationInfoB">
  </div>
  <div class="col-md-3">
    <label class="control-label">32. Service Facility Location Information C</label>
    <br/>
    <input type="text" class="form-control" name="service-facility-loc-c"
           [(ngModel)]="model.serviceFacilityLocationInfoC">
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="control-label">33. Billing Provider Phone(Include Area Code)</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-phone" [(ngModel)]="model.billingProviderTel">
  </div>
  <div class="col-md-3">
    <label class="control-label">33. Billing Provider Name</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-name" [(ngModel)]="model.billingProviderName">
  </div>
  <div class="col-md-3">
    <label class="control-label">33. Billing Provider Address</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-address" [(ngModel)]="model.billingProviderAddress">
  </div>
  <div class="col-md-3">
    <label class="control-label">33. Billing Provider City</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-city" [(ngModel)]="model.billingProviderCity">
  </div>
</div>
<div class="row mt-2">
  <div class="col-md-3">
    <label class="control-label">33. Billing Provider State</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-state" [(ngModel)]="model.billingProviderState">
  </div>
  <div class="col-md-3">
    <label class="control-label">33. Billing Provider Postal Code</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-postal-code" [(ngModel)]="model.billingProviderZip">
  </div>
  <div class="col-md-3">
    <label class="control-label"
           [ngClass]="{'text-danger':!model.billingProviderNpi,'':model.billingProviderNpi}">33.a Billing Provider NPI</label>
    <br/>
    <input type="text" class="form-control" name="billing-provider-npi" [(ngModel)]="model.billingProviderNpi">
  </div>
</div>
<div class="row mt-3 mb-5 mr-5">
  <div class="col-md-7"></div>
  <div class="col-md-5 text-right">
    <a class="btn btn-success mr-3" (click)="save()">
      Save
    </a>
    <a class="btn btn-success mr-3" (click)="openAuditModal()">
      Audit
    </a>
    <a class="btn btn-success mr-3" (click)="generateEDI()">
      Generate EDI
    </a>
    <a class="btn btn-success mr-3" (click)="generateFullRecord()">
      Generate Full Record
    </a>
  </div>
</div>
<app-claim-audit #claimAudit></app-claim-audit>
