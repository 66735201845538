<div>
  <button class="btn btn-success" (click)="addDefaultPrice()">
    Add Price
  </button>
  <table class="table table-striped table-bordered mt-3" style="width: 100%;">
    <thead>
    <tr>
      <th></th>
      <th>Charge Code</th>
      <th>Description</th>
      <th>Price</th>
      <th>Start Date Inclusive</th>
      <th>End Date Inclusive</th>
      <th>Payor</th>
      <th>Branch</th>
      <th>Billing Entity</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of model">
      <td style="white-space: nowrap">
        <button type="button" class="btn btn-success btn-edit" (click)="editPrice(row.id)">
          <i class="fas fa-edit"></i>
        </button>
        <button type="button" class="btn btn-danger ml-2" (click)="deletePrice(row.id)">
          <i class="fas fa-trash-alt"></i>
        </button>
      </td>
      <td>{{row.chargeCode}}</td>
      <td>{{row.description}}</td>
      <td>
        <displayable-money [model]="row.price"></displayable-money>
      </td>
      <td>{{row.startDateInclusive | date:"MM/dd/yyyy"}}</td>
      <td>{{row.endDateInclusive | date:"MM/dd/yyyy"}}</td>
      <td>{{row.payor}}</td>
      <td>{{row.branch}}</td>
      <td>{{row.billingEntity}}</td>
    </tr>
    </tbody>
  </table>
</div>
