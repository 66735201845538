<h1>{{model.payor}} Collection Call</h1>
<div class="row">
  <div class="col-md-4 current-call-column">
    <h2 class="text-center">Current Call</h2>
    <div *ngIf="model.contactName">
      <h3>contact</h3>
      <div class="row">
        <div class="col-md-3">
          <span class="pull-right"><b>Name: </b></span>
        </div>
        <div class="col-md-9">
          <span class="pull-left">{{model.contactName}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <span class="pull-right"><b>Email: </b></span>
        </div>
        <div class="col-md-9">
          <span class="pull-left">{{model.contactEmail}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <span class="pull-right"><b>Phone: </b></span>
        </div>
        <div class="col-md-9">
          <span class="pull-left">{{model.contactPhone | phonePipe}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <label>Notes</label>
      <textarea class="form-control" name="currentNotes" [(ngModel)]="model.currentNotes" rows="10" maxlength="1000"
                spellcheck="true" style="resize:none;"></textarea>
    </div>
    <h3 class="mt-2">Call Outcome: {{getCallOutcome()}}</h3>
    <button class="btn btn-lg btn-success" type="button" (click)="setCallOutcome('Answer')"
            [attr.disabled]="model.callOutcome == 'Answer' ? 'disabled' : null"><i class="fa fa-check"></i> Answer
    </button>
    <button class="btn btn-lg btn-danger ml-3" type="button" (click)="setCallOutcome('NoAnswer')"
            [attr.disabled]="model.callOutcome == 'NoAnswer' ? 'disabled' : null"><i class="fa fa-close"></i> No Answer
    </button>
    <h3 class="mt-2">Callback In:</h3>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-lg btn-primary" type="button" (click)="setCallBackDate(1)">1 Day</button>
        <button class="btn btn-lg btn-primary ml-4" type="button" (click)="setCallBackDate(5)">5 Days</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <button class="btn btn-lg btn-primary" type="button" (click)="setCallBackDate(30)">30 Days</button>
        <button class="btn btn-lg btn-primary ml-2" type="button" (click)="setCallBackDate(60)">60 Days</button>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="model.callbackDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <a class="btn btn-warning" (click)="cancel()">
          <i class="fa fa-close"></i>
          Cancel
        </a>
      </div>
      <div class="col-md-6">
        <a class="btn btn-success pull-left" (click)="saveAndClose()">
          <i class="fa fa-check-square-o"></i>
          Save and Close
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-8 past-call-column">
    <h2 class="text-center">Call Log</h2>
    <h3>Current Invoices</h3>
    <table class="table table-bordered table-condensed table-striped table-hover">
      <thead>
      <tr>
        <th>#</th>
        <th>Billing Entity</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Amount</th>
        <th>Due</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let invoice of model.currentInvoices">
        <td>
          <a routerLink="/invoice/{{invoice.invoiceId}}" href="">{{invoice.invoiceNumberDisplay}}</a>
        </td>
        <td>{{invoice.billingEntity}}</td>
        <td>{{invoice.startDate | date: "M/d/yyyy"}}</td>
        <td>{{invoice.endDate | date: "M/d/yyyy"}}</td>
        <td>
          <displayable-money [model]="invoice.amount"></displayable-money>
        </td>
        <td>
          <displayable-money [model]="invoice.due"></displayable-money>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <div *ngFor="let callLog of model.pastCalls">
        <h3>{{callLog.date | date: "M/d/yyy"}}: {{callLog.callOutcomeDisplay}}</h3>
        <p><b>Notes:</b> {{callLog.notes}}</p>
        <table class="table table-bordered table-condensed table-striped table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Billing Entity</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Amount</th>
            <th>Due At Time Of Call</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let invoice of callLog.invoices">
            <td>
              <a routerLink="/invoice/{{invoice.invoiceId}}" href="">{{invoice.invoiceNumberDisplay}}</a>
            </td>
            <td>{{invoice.billingEntity}}</td>
            <td>{{invoice.startDate | date: "M/d/yyyy"}}</td>
            <td>{{invoice.endDate | date: "M/d/yyyy"}}</td>
            <td>
              <displayable-money [model]="invoice.amount"></displayable-money>
            </td>
            <td>
              <displayable-money [model]="invoice.dueAtTimeOfCall"></displayable-money>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
