import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationResultsService {

  constructor() { }

  public successMessages: string[] = [];
  public errorMessages: string[] = [];
  public warningMessages: string[] = [];
  public infoMessages: string[] = [];
  public isPermissionError: boolean = false;

  setSuccessMessages(messages: string[]) {
    this.successMessages = messages;
    setTimeout(()=>{
      this.reset()
    }, 2000);
  }

  setErrorMessages(messages: string[]) {
    if (this.isPermissionError) {
      this.isPermissionError = false;
      return;
    }
    this.errorMessages = messages;
  }

  setWarningMessages(messages: string[]) {
    this.warningMessages = messages;
  }

  setInfoMessages(messages: string[]) {
    this.infoMessages = messages;
  }

  reset() {
    this.isPermissionError = false;
    this.successMessages = [];
    this.errorMessages = [];
    this.warningMessages = [];
    this.infoMessages = [];
  }
}
