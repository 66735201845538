<div class="row">
  <div class="col-md-9 container">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <mat-form-field class="w-100">
            <mat-label>Post Date<span class="text-danger">*</span></mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="postedDate"
                   [readonly]="!editable">
            <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!editable"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4 text-right">
        <button [attr.disabled]="!editable || hasAnyRemaining() ? 'disabled' : null" class="btn btn-success btn-lg"
                reallyClick="Are you sure you want finalize and post this batch? This cannot be undone."
                reallyClickAction="finalizeAndPostBatch"
                [reallyClickCaller]="this"
                [reallyClickArguments]="">Finalize and Post Batch
          <i class="fa fa-angle-double-right"></i>
        </button>
      </div>
    </div>
    <table class="table table-bordered table-striped table-responsive table-condensed table-hover">
      <thead>
      <tr>
        <th>Control Total</th>
        <th>Total</th>
        <th>Applied</th>
        <th>Remaining</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let controlTotal of model">
        <td>{{controlTotal.financialTransactionType}} <i class="fa fa-plus red"
                                                         *ngIf="controlTotal.remaining > 0"></i><i
          class="fa fa-minus red" *ngIf="controlTotal.remaining < 0"></i></td>
        <td>
          <displayable-money class="pull-right" [model]="controlTotal.total"></displayable-money>
        </td>
        <td>
          <displayable-money class="pull-right"
                             [model]="controlTotal.total - controlTotal.remaining"></displayable-money>
        </td>
        <td>
          <displayable-money class="pull-right" [model]="controlTotal.remaining"></displayable-money>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-9 container">
    <table class="table table-bordered table-striped table-responsive table-condensed table-hover">
      <thead>
      <tr>
        <th>Bill Entity</th>
        <th>Invoice #</th>
        <th>Payment</th>
        <th>Bill Entity Total</th>
        <th *ngIf="getKeys(entityInvoiceAdjustment.entityInvoiceMap).length > 1">Total</th>
      </tr>
      </thead>
      <tbody>
      <ng-template ngFor let-key [ngForOf]="getKeys(entityInvoiceAdjustment.entityInvoiceMap)" let-pi="index"
                   *ngIf="getKeys(entityInvoiceAdjustment.entityInvoiceMap).length > 0">
        <tr>
          <td
            [attr.rowspan]="entityInvoiceAdjustment.entityInvoiceMap.get(key).length">{{entityInvoiceAdjustment.entityMap.get(key)}}</td>
          <td>{{entityInvoiceAdjustment.entityInvoiceMap.get(key)[0]}}</td>
          <td>
            <displayable-money class="pull-right"
                               [model]="entityInvoiceAdjustment.entityPaymentMap.get(key)[0]"></displayable-money>
          </td>
          <td [attr.rowspan]="entityInvoiceAdjustment.entityInvoiceMap.get(key).length">
            <displayable-money class="pull-right"
                               [model]="entityInvoiceAdjustment.entityTotalMap.get(key)"></displayable-money>
          </td>
          <td [attr.rowspan]="entityInvoiceAdjustment.totalInvoiceNum"
              *ngIf="getKeys(entityInvoiceAdjustment.entityInvoiceMap).length > 1 && pi == 0">
            <displayable-money class="pull-right" [model]="entityInvoiceAdjustment.totalPayment"></displayable-money>
          </td>
        </tr>
        <tr *ngFor="let value of entityInvoiceAdjustment.entityInvoiceMap.get(key).slice(1); let i=index">
          <td>{{entityInvoiceAdjustment.entityInvoiceMap.get(key)[i + 1]}}</td>
          <td>
            <displayable-money class="pull-right"
                               [model]="entityInvoiceAdjustment.entityPaymentMap.get(key)[i + 1]"></displayable-money>
          </td>
        </tr>
      </ng-template>
      <tr *ngIf="getKeys(entityInvoiceAdjustment.entityInvoiceMap).length == 0">
        <td [attr.colspan]="4"><span>Try "Apply Adjustments" &gt; "Review & Finalize" step to retrive this info if Invoices exist</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-9 container">
    <table class="table table-bordered table-striped table-responsive table-condensed table-hover">
      <thead>
      <tr>
        <th>Branch Name</th>
        <th>Invoice #</th>
        <th>Payment</th>
        <th>Branch total</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let branchPayment of branchPayments">
        <tr *ngFor="let branchInvoicePayment of branchPayment.branchInvoicePaymentMap | keyvalue; let i = index">
          <td *ngIf="i == 0" [attr.rowspan]="branchPayment.branchInvoicePaymentMap.size">{{branchPayment.name}}</td>
          <td>{{branchInvoicePayment.key}}</td>
          <td>
            <displayable-money class="pull-right"
                               [model]="branchInvoicePayment.value.payment"></displayable-money>
          </td>
          <td *ngIf="i == 0" [attr.rowspan]="branchPayment.branchInvoicePaymentMap.size">
            <displayable-money class="pull-right"
                               [model]="branchPayment.payment"></displayable-money>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>

  <div *ngIf="showDeleteSection()" class="col-md-9 container mt-5">
    <!-- <div class="alert alert-danger">
      <h4>Danger zone!</h4>
      <p>
        Deleting an adjustment batch has many repercussions.
        All credits generated by this adjustment batch will be fully deleted and unrecoverable.
        All invoices related to those credits will have their amounts and balances recalculated, and their invoice statuses will be set back to an Open to be reprocessed.
      </p>
      <button class="btn btn-danger my-2" data-target="#deleteAdjustmentBatchModal" data-toggle="modal" [disabled]="deleteStatus === 'waiting'">
        Delete adjustment batch
        <i *ngIf="deleteStatus === 'waiting'" class="fa fa-spin fa-circle-notch ml-2"></i>
      </button>
    </div> -->

    <div class="modal fade" id="deleteAdjustmentBatchModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete adjustment batch?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Delete this adjustment batch? All current changes will be discarded, credits deleted, invoice balances recalculated, and invoice statuses set to Pending Payment.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteAdjustmentBatch()">Delete</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
