import {RegionPayorChargeCodeListItemDto} from "./region-payor-charge-code-list-item-dto";
import {RegionPayorVisitReasonListItemDto} from "./region-payor-visit-reason-list-item-dto";

export class RegionPayorEntryResponsibilitiesDto {
  chargeCodePayorAssociationMode: string;
  visitReasonPayorAssociationMode: string;
  chargeCodeAndVisitReasonBinaryMode: string;
  regionPayorChargeCodes: RegionPayorChargeCodeListItemDto[] = [];
  regionPayorVisitReasons: RegionPayorVisitReasonListItemDto[] = [];
}
