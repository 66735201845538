import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {GenerateStatementsHistorySearchRow} from "../../../../models/system-statements/generate-statements-history-search-row";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../../../services/authentication.service";

@Component({
  selector: 'system-statements',
  templateUrl: './system-statements.component.html',
  styleUrls: ['./system-statements.component.scss']
})
export class SystemStatementsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};

  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router,
              private _service: AuthenticationService) {
  }

  date: Date;
  model: GenerateStatementsHistorySearchRow[] = [];

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 1,
          orderable: false,
        },
      ],
      columns: [
        {data: ''},
        {data: 'date'},
        {data: 'statementsQueued'},
        {data: 'zeroDayBalanceSum'},
        {data: 'thirtyDayBalanceSum'},
        {data: 'sixtyDayBalanceSum'},
        {data: 'ninetyDayBalanceSum'},
        {data: 'totalBalanceSum'},
        {data: 'time'},
      ],
      order: [[9, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/system-statements", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  get canSave(): boolean {
    return this.date != null;
  }

  // Used by really click
  generateStatements() {
    if (this._service.hasRole('ROLE_billing_system_statement_generator')) {
      const params = new HttpParams().set('date', this.date.toISOString().substring(0, 10));
      this.billingHttpClient.Put<String>('/system-statements', params, {responseType: 'text' as 'json'})
        .subscribe(response => {
          if (response == 'success') {
            alert('Your request is being processed. Please wait a while to check the updates.' +
              ' It might take, but not limited to, more than an hour to see the results ' +
              'depending on the number of statements to be processed.');
          }
        }, error => {
          alert('error');
        });
    } else {
      alert('You are not authorized to do this operation. Please contact your supervisor.')
    }
  }

  viewDetails = (row: GenerateStatementsHistorySearchRow) => {
    this.router.navigate(['system-statement-detail', row.generateStatementsHistoryId]);
  }
}
