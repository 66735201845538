import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestReasonLookupSearchRow} from "../../../models/common/test-reason-lookup-search-row";
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {ChargeCodeLookupSearchRow} from "../../../models/common/charge-code-lookup-search-row";

@Component({
  selector: 'visit-reason-lookup',
  templateUrl: './visit-reason-lookup.component.html',
  styleUrls: ['./visit-reason-lookup.component.scss']
})
export class VisitReasonLookupComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: TestReasonLookupSearchRow[] = [];
  select: TestReasonLookupSearchRow[] = [];
  @Input() enableMultipleSelect: boolean = false;
  @Output() testReasonChange: EventEmitter<TestReasonLookupSearchRow> = new EventEmitter<TestReasonLookupSearchRow>();

  constructor(private clinicHttpClient: ClinicHttpClient) {
  }

  ngOnInit(): void {
    const that = this;
    let columns: any[];
    let order: any[];
    let columnDefs: any[];
    if (this.enableMultipleSelect) {
      columns = [
        {data: ''},
        {data: 'testReasonCode'},
        {data: 'testReasonDescription'},
      ];
      order = [[1, 'asc'], [2, 'asc']];
      columnDefs = [
        {
          targets: 0,
          orderable: false,
        },
      ];
    } else {
      columns = [
        {data: 'testReasonCode'},
        {data: 'testReasonDescription'},
      ];
      order = [[0, 'asc'], [1, 'asc']];
      columnDefs = [];
    }
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      columnDefs: columnDefs,
      columns: columns,
      searchDelay: 2000,
      order: order,
      ajax: (dataTablesParameters: any, callback) => {
        that.clinicHttpClient.Post<DataTablesResponse>("/test-reason-list", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  selectTestReason = (testReason: TestReasonLookupSearchRow) => {
    this.testReasonChange.emit(testReason);
    $('#testReasonLookupModal').modal('hide');
  };

  addOrRemoveTestReason = (checked: boolean, testReason: TestReasonLookupSearchRow) => {
    if (checked) {
      this.select.push(testReason)
    } else {
      for (var i = 0; i < this.select.length; i++) {
        if (this.select[i] == testReason) {
          this.select.splice(i, 1);
        }
      }
    }
  };

  checkTestReason = (testReason: TestReasonLookupSearchRow) => {
    return this.select.indexOf(testReason) != -1;
  };

  saveTestReason = () => {
    for (var i = 0; i < this.select.length; i++) {
      this.testReasonChange.emit(this.select[i]);
    }
    this.select.splice(0,this.select.length);
    $('#chargeCodeLookupModal').modal('hide');
  }
}
