import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {CommonResult} from "../../../models/common/common-result";
import {ValidationResultsService} from "../../../services/validation-results.service";

@Component({
  selector: 'grouping-key-distribute',
  templateUrl: './grouping-key-distribute.component.html',
  styleUrls: ['./grouping-key-distribute.component.scss']
})
export class GroupingKeyDistributeComponent implements OnInit {

  constructor(private appHttpclient: BillingHttpClient,
              private validationResultsService: ValidationResultsService) {
  }

  groupingKey: string;

  ngOnInit() {
  }

  distribute() {
    this.appHttpclient.Post<CommonResult>(`/distribute-invoices?groupingKey=${this.groupingKey}`, {})
      .subscribe(response => {
        if (response.result == 'success') {
          this.onDistributeSucceeded();
        }
      })
  }

  onDistributeSucceeded() {
    this.validationResultsService.setSuccessMessages(["Distribute Started"]);
  }
}
