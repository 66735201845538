import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {ChargeCodeLookupSearchRow} from "../../../models/common/charge-code-lookup-search-row";

declare var $: any;

@Component({
  selector: 'charge-code-lookup',
  templateUrl: './charge-code-lookup.component.html',
  styleUrls: ['./charge-code-lookup.component.scss']
})
export class ChargeCodeLookupComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: ChargeCodeLookupSearchRow[] = [];
  select: ChargeCodeLookupSearchRow[] = [];
  @Input() enableMultipleSelect: boolean = false;

  constructor(private billingHttpClient: BillingHttpClient) {
  }

  @Output() chargeCodeChange: EventEmitter<ChargeCodeLookupSearchRow> = new EventEmitter<ChargeCodeLookupSearchRow>();


  ngOnInit() {
    const that = this;
    let columns: any[];
    let order: any[];
    let columnDefs: any[];
    if (this.enableMultipleSelect) {
      columns = [
        {data: ''},
        {data: ''},
        {data: 'code'},
        {data: 'description'},
        {data: 'startDate'},
        {data: 'endDate'},
      ]
      order = [[2, 'asc'], [3, 'asc']];
      columnDefs = [
        {
          targets: 0,
          orderable: false,
        },
      ];
    } else {
      columns = [
        {data: ''},
        {data: 'code'},
        {data: 'description'},
        {data: 'startDate'},
        {data: 'endDate'},
      ]
      order = [[1, 'asc'], [2, 'asc']];
      columnDefs = [];
    }
    this.dtOptions = {
      pagingType: "simple",
      pageLength: 10000,
      serverSide: true,
      processing: true,
      columnDefs: columnDefs,
      columns: columns,
      lengthChange: false,
      searchDelay: 2000,
      order: order,
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/charge-code-list", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  selectChargeCode = (chargeCode: ChargeCodeLookupSearchRow) => {
    this.chargeCodeChange.emit(chargeCode);
    $('#chargeCodeLookupModal').modal('hide');
  };

  addOrRemoveChargeCode = (checked: boolean, chargeCode: ChargeCodeLookupSearchRow) => {
    if (checked) {
      this.select.push(chargeCode)
    } else {
      for (var i = 0; i < this.select.length; i++) {
        if (this.select[i] == chargeCode) {
          this.select.splice(i, 1);
        }
      }
    }
  };

  checkChargeCode = (chargeCode: ChargeCodeLookupSearchRow) => {
    return this.select.indexOf(chargeCode) != -1;
  };

  saveChargeCode = () => {
    for (var i = 0; i < this.select.length; i++) {
      this.chargeCodeChange.emit(this.select[i]);
    }
    this.select.splice(0,this.select.length);
    $('#chargeCodeLookupModal').modal('hide');
  }
}
