import {Injectable, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import {ControlTotalsDto} from "../models/adjustment-batch/control-totals/control-totals-dto";
import {AdjustmentBatchSubtotalDto} from "../models/adjustment-batch/control-totals/adjustment-batch-subtotal-dto";
import {SelectInvoiceDto} from "../models/adjustment-batch/select-invoices/select-invoice-dto";
import {AdjustmentBatchStatus} from "../models/adjustment-batch/adjustment-batch-status.enum";
import {PayorOption} from "../models/common/payor-option";
import {ApplyAdjustmentInvoiceDto} from "../models/adjustment-batch/apply-adjustments/apply-adjustment-invoice-dto";
import {ApplyAdjustmentControlTotal} from "../models/adjustment-batch/apply-adjustments/apply-adjustment-control-total";
import {ApplyAdjustmentDeltaDebitDto} from "../models/adjustment-batch/apply-adjustments/apply-adjustment-delta-debit-dto";
import {BillingHttpClient} from "../common/billing-http-client.service";
import {AdjustmentBatchDto} from "../models/adjustment-batch/adjustment-batch-dto";
import {HttpParams} from '@angular/common/http';
import {Observable, Subscription} from "rxjs";
import {CommonResult} from "../models/common/common-result";

@Injectable()
export class AdjustmentBatchDataService implements OnDestroy, OnDestroy {

  //Global
  adjustmentBatchId: number = null;
  //Control Total component
  controlTotalsDto: ControlTotalsDto = new ControlTotalsDto();
  payorOptions: PayorOption[] = [];
  //Select invoices component
  selectedInvoices = [] as SelectInvoiceDto[];
  //Apply Adjustments component
  applyAdjustmentInvoices = [] as ApplyAdjustmentInvoiceDto[];
  _applyAdjustmentControlTotals = [] as ApplyAdjustmentControlTotal[];

  entityInvoiceAdjustment = new EntityInvoiceAdjustment();

  branchPayments = [];

  initializedSteps: number[] = [];

  finalized: boolean = false;

  constructor(private billingHttpClient: BillingHttpClient) {
    console.log("AdjustmentBatchDataService constructor called");
  }

  get editable() {
    return this.controlTotalsDto.adjustmentBatchStatus == AdjustmentBatchStatus.open;
  }

  financialTransactionTypeOptions() {
    let options = [] as string[];
    _.forEach(this.controlTotalsDto.subtotals, (subtotal) => {
      if (subtotal.amount != null && (subtotal.amount === 0 || !isNaN(subtotal.amount))) {
        options.push(subtotal.transactionType);
      }
    });
    return options;
  }

  applyAdjustmentControlTotals() {
    let controlTotals = [] as ApplyAdjustmentControlTotal[];
    _.forEach(this.controlTotalsDto.subtotals, (subtotal) => {
      if (subtotal.amount != null && (subtotal.amount === 0 || !isNaN(subtotal.amount))) {
        let controlTotal = new ApplyAdjustmentControlTotal();
        controlTotal.financialTransactionType = subtotal.transactionType;
        controlTotal.total = subtotal.amount;
        controlTotal.remaining = subtotal.amount;
        controlTotals.push(controlTotal);
      }
    });
    let validTypes = controlTotals.map(t => t.financialTransactionType);
    let existingType = this._applyAdjustmentControlTotals.map(t => t.financialTransactionType);

    let removedTypes = (this._applyAdjustmentControlTotals.filter(t => !validTypes.includes(t.financialTransactionType))
      || []).map(t => t.financialTransactionType);
    let missingTypes = validTypes.filter(t => !existingType.includes(t));

    const that = this;
    if (removedTypes.length > 0) {
      // Remove invalid adjustments
      _.forEach(removedTypes, removedType => {
        _.forEach(that.applyAdjustmentInvoices, i => {
          i.adjustments = i.adjustments.filter(a => a.financialTransactionType !== removedType);
          _.forEach(i.debits, debit => {
            debit.adjustments = debit.adjustments.filter(a => a.financialTransactionType !== removedType);
          })
        });
      });
      // Remove invalid control total type
      this._applyAdjustmentControlTotals = this._applyAdjustmentControlTotals
        .filter(t => validTypes.includes(t.financialTransactionType));
    }
    // Add missingTypes
    _.forEach(missingTypes, missingType => {
      let total = controlTotals.find(t => t.financialTransactionType == missingType);
      that._applyAdjustmentControlTotals.push(total);
    });
    // Initial Amount & Remaining
    _.forEach(this._applyAdjustmentControlTotals, total => {
      let newTotal = controlTotals.find(t => t.financialTransactionType == total.financialTransactionType);
      total.total = newTotal.total;
      total.remaining = newTotal.remaining;
    });

    return this._applyAdjustmentControlTotals;
  }

  confirmInvoices(invoices: ApplyAdjustmentInvoiceDto[]) {
    let tempEntityTotalMap = new Map();
    let tempBillEntityMap = new Map();

    let tempBranchPaymentMap = new Map();

    this.entityInvoiceAdjustment = new EntityInvoiceAdjustment();
    _.forEach(invoices, (i) => {
      let billingEntity = i.billingEntity;
      // Avoid entity with same name but different case
      if (tempBillEntityMap.has(billingEntity.toLowerCase())) {
        billingEntity = tempBillEntityMap.get(billingEntity.toLowerCase())
      } else {
        tempBillEntityMap.set(billingEntity.toLowerCase(), billingEntity);
      }
      // let billingEntityId = value.BillingEntityId;
      if (!this.entityInvoiceAdjustment.entityMap.has(billingEntity)) {
        this.entityInvoiceAdjustment.entityMap.set(billingEntity, billingEntity);
      }
      if (!this.entityInvoiceAdjustment.entityInvoiceMap.has(billingEntity)) {
        this.entityInvoiceAdjustment.entityInvoiceMap.set(billingEntity, []);
      }
      if (!this.entityInvoiceAdjustment.entityPaymentMap.has(billingEntity)) {
        this.entityInvoiceAdjustment.entityPaymentMap.set(billingEntity, []);
      }
      this.entityInvoiceAdjustment.entityInvoiceMap.get(billingEntity).push(i.invoiceNumberDisplay);
      this.entityInvoiceAdjustment.totalInvoiceNum = this.entityInvoiceAdjustment.totalInvoiceNum + 1;

      let deltaDebits = [];
      _.forEach(i.debits, debit => {
        let deltaDebit = new ApplyAdjustmentDeltaDebitDto();
        deltaDebit.adjustments = debit.adjustments;
        // deltaDebit.FinancialTransactionType = debit.FinancialTransactionType;
        deltaDebit.financialTransactionId = debit.financialTransactionId;
        deltaDebits.push(deltaDebit);

        let branch = debit.branch;
        if (!branch) {
          branch  = 'None';
        }
        let branchPayment = this.getBranchPayment(tempBranchPaymentMap, branch);
        let debitTotal = 0;
        _.forEach(debit.adjustments, adjustment => {
          debitTotal -= adjustment.amount;
        });
        branchPayment.payment += debitTotal;
        let branchInvoicePayment = this.getBranchInvoicePayment(branchPayment.branchInvoicePaymentMap, i.invoiceNumberDisplay);
        branchInvoicePayment.payment += debitTotal;
      });
      i.deltaDebits = deltaDebits;
      let payment = 0;
      let debits = i.deltaDebits;
      _.forEach(debits, (d) => {
        let debitAdjustments = d.adjustments;
        _.forEach(debitAdjustments, da => {
          // Amount is negative
          payment -= da.amount;
        });
      });
      let invoiceAdjustments = i.adjustments;
      let invoiceTotal = 0;
      _.forEach(invoiceAdjustments, ia => {
        // Amount is negative
        payment -= ia.amount;
        invoiceTotal -= ia.amount;
      });
      let branch = i.branch;
      if (!branch) {
        branch  = 'None';
      }
      let branchPayment = this.getBranchPayment(tempBranchPaymentMap, branch);
      branchPayment.payment += invoiceTotal;
      let branchInvoicePayment = this.getBranchInvoicePayment(branchPayment.branchInvoicePaymentMap, i.invoiceNumberDisplay);
      branchInvoicePayment.payment += invoiceTotal;

      this.entityInvoiceAdjustment.entityPaymentMap.get(billingEntity).push(payment);
      if (!tempEntityTotalMap.has(billingEntity)) {
        tempEntityTotalMap.set(billingEntity, 0);
      }
      tempEntityTotalMap.set(billingEntity, tempEntityTotalMap.get(billingEntity) + payment);
    });
    tempEntityTotalMap.forEach((value, key) => {
      this.entityInvoiceAdjustment.entityTotalMap.set(key, value);
    });
    this.entityInvoiceAdjustment.totalPayment = Array.from(tempEntityTotalMap.values()).reduce((accumulator, payment) => accumulator + payment, 0);
    this.branchPayments = Array.from(tempBranchPaymentMap.values()).sort((a, b) => a.name.localeCompare(b.name));
  }

  getBranchPayment(branchPaymentMap: Map<String, BranchPayment>, branch: string) {
    let branchPayment;
    if (!branchPaymentMap.has(branch)) {
      branchPayment = new BranchPayment();
      branchPayment.name = branch;
      branchPayment.payment = 0;
      branchPayment.branchInvoicePaymentMap = new Map<String, BranchInvoicePayment>();
      branchPaymentMap.set(branch, branchPayment);
    } else {
      branchPayment = branchPaymentMap.get(branch);
    }
    return branchPayment;
  }

  getBranchInvoicePayment(branchInvoicePaymentMap: Map<String, BranchInvoicePayment>, invoice: string) {
    let branchInvoicePayment;
    if (!branchInvoicePaymentMap.has(invoice)) {
      branchInvoicePayment = new BranchInvoicePayment();
      branchInvoicePayment.invoiceNumberDisplay = invoice;
      branchInvoicePayment.payment = 0;
      branchInvoicePaymentMap.set(invoice, branchInvoicePayment);
    } else {
      branchInvoicePayment = branchInvoicePaymentMap.get(invoice);
    }
    return branchInvoicePayment;
  }

  getEntityInvoiceAdjustment() {
    return this.entityInvoiceAdjustment;
  }

  filterSubTotals() {
    let newSubtotals = [] as AdjustmentBatchSubtotalDto[];
    _.forEach(this.controlTotalsDto.subtotals, subtotal => {
      if (subtotal.amount != null && !isNaN(subtotal.amount) && subtotal.amount != 0) {
        newSubtotals.push(subtotal);
      }
    });
    this.controlTotalsDto.subtotals = newSubtotals;
  }

  removeInvoicesDebits() {
    _.forEach(this.applyAdjustmentInvoices, i => {
      i.debits = [];
    });
  }

  finalize(postedDate: Date) {
    const that = this;
    this.filterSubTotals();
    this.removeInvoicesDebits();
    let adjustmentBatchDto = {
      adjustmentBatchId: that.adjustmentBatchId < 0 ? null : that.adjustmentBatchId,
      controlTotals: that.controlTotalsDto,
      selectedInvoices: that.selectedInvoices,
      applyAdjustmentInvoices: that.applyAdjustmentInvoices,
      postedDate: postedDate
    } as AdjustmentBatchDto;
    this.billingHttpClient.Post('/adjustment-batch', adjustmentBatchDto)
      .subscribe(_ => {});
    this.finalized = true;
  }

  ngOnDestroy(): void {
    console.log("AdjustmentBatchDataService ngOnDestroy called");
    this.unlockAdjustmentBatch()
    if (this.finalized || this.controlTotalsDto.adjustmentBatchStatus == AdjustmentBatchStatus.posted) {
      return;
    }
    this.filterSubTotals();
    const that = this;
    if (this.controlTotalsDto.subtotals.length > 0 || this.controlTotalsDto.total !== undefined || this.controlTotalsDto.checkNumber !== undefined) {
      if (!this.initializedSteps.includes(2)) {
        this.selectedInvoices = null;
      }
      if (!this.initializedSteps.includes(3)) {
        this.applyAdjustmentInvoices = null;
      } else {
        this.removeInvoicesDebits();
      }

      let adjustmentBatchDto = {
        adjustmentBatchId: that.adjustmentBatchId < 0 ? null : that.adjustmentBatchId,
        controlTotals: that.controlTotalsDto,
        selectedInvoices: that.selectedInvoices,
        applyAdjustmentInvoices: that.applyAdjustmentInvoices,
      } as AdjustmentBatchDto;

      this.billingHttpClient.Post('/adjustment-batch', adjustmentBatchDto)
        .subscribe(_ => {});
    }
  }

  unlockAdjustmentBatch(){
    if (!(isNaN(this.adjustmentBatchId) || this.adjustmentBatchId <= 0)){
      let params = new HttpParams().set('adjustmentBatchId', this.adjustmentBatchId.toString());
      this.billingHttpClient.Get('/unlock-adjustment-batch', {params})
        .subscribe(_ => {});
    }
  }

  deleteAdjustmentBatch(successCallback, failureCallback) {
    if (this.adjustmentBatchId > 0) {
      this.billingHttpClient.Delete<CommonResult>(`/adjustment-batch/${this.adjustmentBatchId}`).subscribe(
        next => {
          if (next.result === 'success') {
            successCallback();
          } else {
            console.error(next);
            failureCallback();
          }
        },
        error => {
          console.error(error);
          failureCallback();
        });
    }
  }
}

export class EntityInvoiceAdjustment {
  entityInvoiceMap = new Map<string, Array<string>>();
  entityPaymentMap = new Map<string, Array<number>>();
  entityMap = new Map<string, string>();
  entityTotalMap = new Map<string, number>();
  totalPayment: number;
  totalInvoiceNum = 0;
}

export class BranchPayment {
  name: string;
  branchInvoicePaymentMap = new Map<String, BranchInvoicePayment>();
  payment: number;
}

export class BranchInvoicePayment {
  invoiceNumberDisplay: string;
  payment: number;
}
