<div class="flex-container">
  <div class="column" id="col1">
    <p class="colHeader">
      Basic Details
    </p>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Region Name</mat-label>
        <input matInput placeholder="Region Name" [(ngModel)]="model.name">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Address Line 1</mat-label>
        <input matInput required placeholder="address 1" [(ngModel)]="model.address1">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Address Line 2</mat-label>
        <input matInput placeholder="address 2" [(ngModel)]="model.address2">
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input matInput required [(ngModel)]="model.city">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select [(ngModel)]="model.state">
          <mat-option *ngFor="let state of stateList" [value]="state.value">
            {{state.display}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pad-top bold">
      <mat-form-field appearance="fill">
        <mat-label>Postal Code</mat-label>
        <input matInput required [(ngModel)]="model.postalCode">
      </mat-form-field>
    </div>
  </div>
</div>
<hr>
<div class="right-align mt-3">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-success mr-2" [attr.disabled]="!canSave ? 'disabled' : null" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" [attr.disabled]="!canSave ? 'disabled' : null" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
