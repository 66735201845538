import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {PendingFinancialTransactionDto} from "../../../models/common/pending-financial-transaction-dto";
import {CommonResult} from "../../../models/common/common-result";

@Component({
  selector: 'pending-debit-edit',
  templateUrl: './pending-debit-edit.component.html',
  styleUrls: ['./pending-debit-edit.component.scss']
})
export class PendingDebitEditComponent implements OnInit {

  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router,
              private route: ActivatedRoute) {
  }

  model: PendingFinancialTransactionDto = new PendingFinancialTransactionDto();
  canCopy = false;
  canCancel = false;
  isPayorCharge = false;
  isPatientCharge = false;
  isEmployerCharge = false;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.load(+params['id']);
    });
  }

  load(id: number) {

    this.billingHttpClient.Get<PendingFinancialTransactionDto>(`/pending-financial-transaction/${id}`)
      .subscribe(response => {
        this.model = response;
        this.canCopy = 'Void' !== this.model.financialTransactionPendingImportType;
        this.canCancel = 'Error' === this.model.financialTransactionPendingImportStatus;
        if (this.model.chargeCode == null && this.model.employerId == null && this.model.patientId == null) {
          this.isPayorCharge = true;
        } else if (this.model.patientId == null && this.model.employerId != null) {
          this.isEmployerCharge = true;
        } else {
          this.isPatientCharge = true;
        }
      });
  }

  copyDebit() {
    if (this.isPatientCharge) {
      this.navigateToChargeEntry(this.model.id)
    } else if (this.isEmployerCharge) {
      this.navigateToEmployerChargeEntry(this.model.id);
    } else if (this.isPayorCharge) {
      this.navigateToPayorChargeEntry(this.model.id);
    }
  }

  navigateToChargeEntry = (id: number) => {
    this.router.navigate(['debit-entry', id]);
  }

  navigateToEmployerChargeEntry = (id: number) => {
    this.router.navigate(['employer-debit-entry', id]);
  }

  navigateToPayorChargeEntry = (id: number) => {
    this.router.navigate(['payor-debit-entry', id]);
  }

  // Used by reallyClickAction
  cancelDebit(shouldCopy: boolean) {
    this.billingHttpClient.Put<CommonResult>(`/financial-transaction-pending-import/${this.model.id}/status`,
      {'status': 'Canceled'})
      .subscribe(response => {
        if (response.result === 'success') {
          if (shouldCopy) {
            this.copyDebit();
          } else {
            this.model.financialTransactionPendingImportStatus = 'Canceled';
          }
        } else {
          alert('failed');
        }
      });
  }

  navigateBack() {
    window.history.back();
  }
}
