import {Component, OnInit} from '@angular/core';
import {BillingHttpClient} from "../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {DataTablesResponse} from "../../../models/common/data-tables-response";
import {CollectionCallLog} from "../../../models/collection-call-log/collection-call-log";

@Component({
  selector: 'app-collection-call-log',
  templateUrl: './collection-call-log.component.html',
  styleUrls: ['./collection-call-log.component.scss']
})
export class CollectionCallLogComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  model: CollectionCallLog[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private  router: Router) {
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      stateSave: true,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
      ],
      columns: [
        {data: 'payorId'},
        {data: 'payor'},
        {data: 'payorType'},
        {data: 'callbackDate'},
        {data: 'invoiceCount'},
        {data: 'due'},
      ],
      order: [[3, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        that.billingHttpClient.Post<DataTablesResponse>("/collection-call-log", dataTablesParameters)
          .subscribe(resp => {
            that.model = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  navigateToCollectionCall = (payorId) => {
    this.router.navigate(['collection-call', payorId])
  }
}
