import {InvoiceStatus} from "../common/invoice-status.enum";
import {InvoiceRenderingType} from "./invoice-rendering-type.enum";

export class InvoiceDetailDto {
  id: number;
  invoiceNumber: number;
  invoiceNumberDisplay: string;
  payorID: number;
  payor: string;
  latestRevision: boolean;
  canCloseInvoice: boolean;
  chainOfCustody: boolean;
  authorization: boolean;
  medicalRecords: boolean;
  canPrintAsCMS1500ClaimForm: boolean;
  requiresManualSubmission: boolean;
  vendorId: string;
  invoiceRenderingType: InvoiceRenderingType;
  invoiceStatus: InvoiceStatus;
  balance: number;
  authorizationPatientId: number;
  billingEmail: string;
  payorType: string;
}
