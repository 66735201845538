<div class="row">
  <div class="col-md-12 mt-3">
    <h2>Payors Assigned to this Employer</h2>
    <hr class="divider"/>
    <div class="col-md-3" style="padding-left: 0px;">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
              <i class="fa fa-search"></i><span class="ml-2">Add payor</span>
            </button>
          </div>
        </div>
      </div>
      <payor-lookup (payorChange)="selectPayor($event)"></payor-lookup>
    </div>
    <div class="dropdown">
      <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
        Add Payor
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button class="dropdown-item" (click)="createEmployerPayor('Employer')">Employer</button>
        <button class="dropdown-item" (click)="createEmployerPayor('TPA')">TPA</button>
        <button class="dropdown-item" (click)="createEmployerPayor('WorkersComp')">Workers Comp</button>
      </div>
    </div>
    <table class="table table-bordered table-striped table-hover mt-2">
      <thead>
      <tr>
        <th class="fit"></th>
        <th>Payor Name</th>
        <th>Payor Type</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Active</th>
      </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let row of model.employerEntryPayorDtos" cdkDrag>
        <td class="fit button-container">
          <div class="row">
            <div class="col-md-3">
              <div class="row">
                <i class="fa fa-sort-up fa-stack-2x"></i>
              </div>
              <div class="row">
                <i class="fa fa-sort-down fa-stack-2x"></i>
              </div>
            </div>
            <div class="col-md-9">
              <button type="button" [title]="'Edit'" *ngIf="row.employerPayorId != null"
                      (click)="editEmployerPayor(row.employerPayorId);"
                      class="btn btn-success">
                <i class="fas fa-edit"></i>
              </button>
            </div>
          </div>
        </td>
        <td>
          <span>{{row.payorName}}</span>
        </td>
        <td>
          <span>{{row.payorType}}</span>
        </td>
        <td>
          <span>{{row.startDateInclusive | date:"MM/dd/yyyy":"UTC"}}</span>
        </td>
        <td>
          <span>{{row.endDateInclusive | date:"MM/dd/yyyy":"UTC"}}</span>
        </td>
        <td>
          <i *ngIf="row.active" class="fa fa-check" style="color:green"></i>
          <i *ngIf="!row.active" class="fa fa-times" style="color:red"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="right-align">
  <button class="btn btn-success mr-2" [attr.disabled]="!canSave ? 'disabled' : null" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
</div>
