import {Component, OnDestroy, OnInit} from '@angular/core';
import {BillingHttpClient} from '../../../common/billing-http-client.service';
import {ActivatedRoute} from '@angular/router';
import {ChargeCodeEntryDto} from "../../../models/charge-code-entry/charge-code-entry-dto";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ValidationResultsService} from "../../../services/validation-results.service";

@Component({
  selector: 'app-charge-code-entry',
  templateUrl: './charge-code-entry.component.html',
  styleUrls: ['./charge-code-entry.component.scss']
})
export class ChargeCodeEntryComponent implements OnInit, OnDestroy {

  private id: number;
  model: ChargeCodeEntryDto = new ChargeCodeEntryDto();
  private ngUnsubscribe = new Subject();

  constructor(private billingHttpClient: BillingHttpClient,
              private validationResultsService: ValidationResultsService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
        if (this.id) {
          const params = new HttpParams().set('id', this.id.toString());
          this.billingHttpClient.Get<ChargeCodeEntryDto>('/charge-code', {params})
            .subscribe(response => {
                this.model = response;
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
