import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ChargeCodeLookupSearchRow} from '../../../../models/common/charge-code-lookup-search-row';
import {TestReasonLookupSearchRow} from '../../../../models/common/test-reason-lookup-search-row';
import {FacilityPayorEntryResponsibilitiesDto} from '../../../../models/facility-payor-entry/facility-payor-entry-responsibilities-dto';
import {FacilityPayorChargeCodeListItemDto} from '../../../../models/facility-payor-entry/facility-payor-charge-code-list-item-dto';
import {FacilityPayorVisitReasonListItemDto} from '../../../../models/facility-payor-entry/facility-payor-visit-reason-list-item-dto';

@Component({
  selector: 'app-facility-payor-entry-responsibilities',
  templateUrl: './facility-payor-entry-responsibilities.component.html',
  styleUrls: ['./facility-payor-entry-responsibilities.component.scss']
})
export class FacilityPayorEntryResponsibilitiesComponent implements OnInit {

  constructor() {
  }

  @Input() model: FacilityPayorEntryResponsibilitiesDto = new FacilityPayorEntryResponsibilitiesDto();
  @Input() facilityPayorId: number;
  @ViewChild('formResponsibilities') formResponsibilities: NgForm;

  chargeCodeBeingEdited: FacilityPayorChargeCodeListItemDto = new FacilityPayorChargeCodeListItemDto();
  visitReasonBeingEdited: FacilityPayorVisitReasonListItemDto = new FacilityPayorVisitReasonListItemDto();

  ngOnInit() {
  }

  get andRadioText(): string {
    return this.getRadioText('AND');
  }

  get orRadioText(): string {
    return this.getRadioText('OR');
  }

  private getRadioText(binaryType: string) {
    let result: string = binaryType + ' --- the debit ';
    if (this.model.chargeCodePayorAssociationMode == "Include") {
      result += 'MUST';
    } else if (this.model.chargeCodePayorAssociationMode == "Exclude") {
      result += 'must NOT';
    }
    result += ' contain one of the selected charge codes ' + binaryType + ' it ';
    if (this.model.visitReasonPayorAssociationMode == "Include") {
      result += 'MUST';
    } else if (this.model.visitReasonPayorAssociationMode == "Exclude") {
      result += 'must NOT';
    }
    result += ' contain one of the selected visit reasons';
    return result;
  }

  get showBinaryRadios(): boolean {
    let result: boolean = false;
    let ccStatus: boolean = this.model.chargeCodePayorAssociationMode == "Include"
      || this.model.chargeCodePayorAssociationMode == "Exclude";
    let vrStatus: boolean = this.model.visitReasonPayorAssociationMode == "Include"
      || this.model.visitReasonPayorAssociationMode == "Exclude";
    if (ccStatus && vrStatus) {
      result = true;
    }
    return result;
  }

  chargeCodeChange(chargeCodeLookupSearchRow: ChargeCodeLookupSearchRow) {
    if (this.model.facilityPayorChargeCodes.findIndex(cc => cc.chargeCode == chargeCodeLookupSearchRow.code) == -1) {
      let result: FacilityPayorChargeCodeListItemDto = new FacilityPayorChargeCodeListItemDto;
      result.facilityPayorId = this.facilityPayorId;
      result.chargeCode = chargeCodeLookupSearchRow.code;
      result.description = chargeCodeLookupSearchRow.description;
      result.startDateInclusive = new Date('01/01/2000');
      result.endDateInclusive = new Date('12/31/9999');
      this.model.facilityPayorChargeCodes.push(result);
    }
  }

  visitReasonChange(visitReasonLookupSearchRow: TestReasonLookupSearchRow) {
    if (this.model.facilityPayorVisitReasons.findIndex(vr => vr.testReasonCode == visitReasonLookupSearchRow.testReasonCode) == -1) {
      var result: FacilityPayorVisitReasonListItemDto = new FacilityPayorVisitReasonListItemDto;
      result.facilityPayorId = this.facilityPayorId;
      result.testReasonCode = visitReasonLookupSearchRow.testReasonCode;
      result.description = visitReasonLookupSearchRow.testReasonDescription;
      result.startDateInclusive = new Date('1/1/2000');
      result.endDateInclusive = new Date('12/31/9999');
      this.model.facilityPayorVisitReasons.push(result);
    }
  }

  onChargeCodeAndVisitReasonBinaryMode(mode: string) {
    this.model.chargeCodeAndVisitReasonBinaryMode = mode;
  }

  editFacilityPayorChargeCode(row: FacilityPayorChargeCodeListItemDto) {
    this.chargeCodeBeingEdited = new FacilityPayorChargeCodeListItemDto();
    this.chargeCodeBeingEdited.chargeCode = row.chargeCode;
    this.chargeCodeBeingEdited.startDateInclusive = row.startDateInclusive;
    this.chargeCodeBeingEdited.endDateInclusive = row.endDateInclusive;
    $('#editChargeCode').modal('show');
  }

  saveChargeCode() {
    let chargeCode = this.model.facilityPayorChargeCodes.find(cc => cc.chargeCode == this.chargeCodeBeingEdited.chargeCode);
    chargeCode.startDateInclusive = this.chargeCodeBeingEdited.startDateInclusive;
    chargeCode.endDateInclusive = this.chargeCodeBeingEdited.endDateInclusive;
    this.closeChargeCodeModal();
  }

  closeChargeCodeModal() {
    $('#editChargeCode').modal('hide');
  }

  editFacilityPayorVisitReason(row: FacilityPayorVisitReasonListItemDto) {
    this.visitReasonBeingEdited = new FacilityPayorVisitReasonListItemDto();
    this.visitReasonBeingEdited.testReasonCode = row.testReasonCode;
    this.visitReasonBeingEdited.startDateInclusive = row.startDateInclusive;
    this.visitReasonBeingEdited.endDateInclusive = row.endDateInclusive;
    $('#editVisitReason').modal('show');
  }

  closeVisitReasonModal() {
    $('#editVisitReason').modal('hide');
  }

  saveVisitReason() {
    let visitReason = this.model.facilityPayorVisitReasons.find(vr => vr.testReasonCode == this.visitReasonBeingEdited.testReasonCode);
    visitReason.startDateInclusive = this.visitReasonBeingEdited.startDateInclusive;
    visitReason.endDateInclusive = this.visitReasonBeingEdited.endDateInclusive;
    this.closeVisitReasonModal();
  }


}
