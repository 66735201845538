<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
       class="table table-bordered table-striped">
  <thead>
  <tr>
    <th></th>
    <th>Service Date</th>
    <th>Posted Date</th>
    <th>Patient</th>
    <th>Description</th>
    <th>Branch</th>
    <th>Type</th>
    <th>Quantity</th>
    <th>Debits</th>
    <th>Credits</th>
    <th>Balance</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of ledgerItems">
    <td class="fit" style="width: 30px">
      <div class="button-container" style="text-align: center"
           *ngIf="isEditable(row.financialTransactionPendingImportId, row.adjustmentBatchId, row.transactionType)">
              <span class="input-group=btn fix-span">
                <button class="grid-btn btn btn-sm btn-raised btn-success" type="button"
                        (click)="onSelectLedger(row.financialTransactionPendingImportId, row.adjustmentBatchId, row.transactionType)">
                  <i class="fa fa-fw fa-edit"></i>
                </button>
              </span>
      </div>
    <td>
      <span>{{row.serviceDate | date:"MM/dd/yyyy"}}</span>
    </td>
    <td>
      <span>{{row.postedDate | date:"MM/dd/yyyy"}}</span>
    </td>
    <td>
      <span>{{row.patientName}}</span>
    </td>
    <td>
      <span>{{row.description}}</span>
    </td>
    <td>
      <span>{{row.branch}}</span>
    </td>
    <td>
      <span>{{row.transactionType}}</span>
    </td>
    <td>
      <span>{{row.quantity}}</span>
    </td>
    <td>
      <span><displayable-money [model]="row.debit"></displayable-money></span>
    </td>
    <td>
      <span><displayable-money [model]="row.credit"></displayable-money></span>
    </td>
    <td>
      <span><displayable-money [model]="row.balance"></displayable-money></span>
    </td>
  </tr>
  </tbody>
</table>
