<!--Navbar-->
<nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #012169">
  <div class="container">
    <!-- Navbar brand -->
    <a routerLink="/dashboard" class="navbar-brand">
      <img src="assets/images/prime_transparent_logo.png" height="25"/>
    </a>

    <!-- Collapse button -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav"
            aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Collapsible content -->
    <div class="collapse navbar-collapse">

      <!-- Links -->
      <div *ngIf="isAuthenticated">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/dashboard">
              <i class="fa fa-dashboard"></i>
              Dashboard
            </a>
          </li>
          <li class="nav-item active dropdown">
            <a href="#" class="nav-link dropdown-toggle" id="financials" data-toggle="dropdown" role="button"
               aria-expanded="false" aria-haspopup="true">
              <i class="fa fa-usd"></i>
              Financials
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="financials">
              <a class="dropdown-item" routerLink="/pending-debits-and-adjustments">
                <i class="fa fa-list-alt"></i> Debits (Pending)
              </a>
              <a class="dropdown-item" routerLink="/debits-and-adjustments">
                <i class="fa fa-list-alt"></i> Debits
              </a>
              <a class="dropdown-item" routerLink="/invoices">
                <i class="fa fa-file-text"></i> Invoices
              </a>
              <a class="dropdown-item" routerLink="/adjustment-batches">
                <i class="fa fa-info-circle"></i> Adjustment Batches
              </a>
              <a class="dropdown-item" routerLink="/statement">
                <i class="fa fa-clock-o"></i> Payor Statements
              </a>
              <a class="dropdown-item" routerLink="/system-statements">
                <i class="fa fa-clock-o"></i> System Statements
              </a>
            </div>
          </li>
          <li class="nav-item active dropdown">
            <a href="#" class="dropdown-toggle nav-link" id="administration" data-toggle="dropdown" role="button"
               aria-expanded="false" aria-haspopup="true">
              <i class="fa fa-cog"></i>
              Administration
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="administration">
<!--              <a class="dropdown-item" routerLink="/authorization-patient-list-for-admin">-->
<!--                <i class="fa fa-wrench"></i> Modify Authorization-->
<!--              </a>-->
<!--              <a class="dropdown-item" routerLink="/batch-processes">-->
<!--                <i class="fa fa-refresh fa-fw"></i> Batch Processes-->
<!--              </a>-->
              <a class="dropdown-item" routerLink="/billing-entities">
                <i class="fa fa-address-book fa-fw"></i> Billing Entities
              </a>
              <a class="dropdown-item" routerLink="/charge-code-list">
                <i class="fa fa-list-alt fa-fw"></i> Charge Codes
              </a>
<!--  TODO Show this when backend is ready            -->
<!--              <a class="dropdown-item" routerLink="/clinical-document-configuration">-->
<!--                <i class="fa fa-file-pdf-o fa-fw"></i> Clinical Documents-->
<!--              </a>-->
              <a class="dropdown-item" routerLink="/employer-list">
                <i class="fa fa-industry fa-fw"></i> Employer
              </a>
              <a class="dropdown-item" routerLink="/payor-list">
                <i class="fa fa-credit-card fa-fw"></i> Payors
              </a>
              <a class="dropdown-item" routerLink="/pricing-list">
                <i class="fa fa-usd fa-fw"></i> Pricing
              </a>
<!--              <a class="dropdown-item" routerLink="/user-list">-->
<!--                <i class="fa fa-users fa-fw"></i> Users-->
<!--              </a>-->
<!--              <a class="dropdown-item" routerLink="/billing-group-list">-->
<!--                <i class="fa fa-object-group fa-fw"></i> Billing Group-->
<!--              </a>-->
              <a class="dropdown-item" routerLink="/branch-list">
                <i class="fa fa-building fa-fw"></i> Branches
              </a>
<!--              <a class="dropdown-item" routerLink="/revert-checkout" *ngIf="isBillingMaster">-->
<!--                <i class="fa fa-wrench"></i> Revert Checkout-->
<!--              </a>-->
            </div>
          </li>
        </ul>
      </div>
      <ul class="navbar-nav navbar-right ml-auto">
        <li class="nav-item active dropdown">
          <a href="#" class="nav-link dropdown-toggle" id="profile" data-toggle="dropdown" role="button"
             aria-expanded="false"
             aria-haspopup="true">
            <i class="fa fa-cogs"></i>
            {{name}}
            <span class="caret"></span>
          </a>
          <div class="dropdown-menu" aria-labelledby="profile">
            <a class="dropdown-item" routerLink="/change-my-password">
              <i class="fa fa-key"></i> Change My Password
            </a>
            <a class="dropdown-item" (click)="logout()">
              <i class="fa fa-lock"></i> Log off
            </a>
<!--            <a class="dropdown-item" (click)="refresh()">-->
<!--              <i class="fa fa-lock"></i> refresh token-->
<!--            </a>-->
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<validation-results></validation-results>
