import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SearchFacilitiesComponent} from "../../../common/search-facilities/search-facilities.component";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {HttpParams} from "@angular/common/http";
import {BillingEntityFacilityDto} from "../../../../models/billing-entity-entry/billing-entity-facility-dto";
import {FacilityDto} from "../../../../models/common/facility-dto";
import {Router} from '@angular/router';

@Component({
  selector: 'app-billing-entity-entry-facilities',
  templateUrl: './billing-entity-entry-facilities.component.html',
  styleUrls: ['./billing-entity-entry-facilities.component.scss']
})
export class BillingEntityEntryFacilitiesComponent implements OnInit {

  @Input() billingEntityId: number;
  private _model: BillingEntityFacilityDto[];
  get model(): BillingEntityFacilityDto[] {
    return this._model;
  }

  @Input() set model(value: BillingEntityFacilityDto[]) {
    this._model = value;
    if (value && value.length > 0) {
      let ids = value.map(b => b.facilityId).join(',');
      const params = new HttpParams().set('ids', ids);
      this.clinicHttpClient.Get<FacilityDto[]>('/facility-list', {params})
        .subscribe(response => {
          this._model.map(b => b.facilityName = response.find(r => r.id == b.facilityId).companyName);
        });
    }
  }

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private dialog: MatDialog,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(SearchFacilitiesComponent, {
      data: new BillingEntityFacilityDto()
    });
    dialogRef.afterClosed()
      .subscribe(value => {
        if (value && value.id && this._model.filter(m => m.facilityId == value.id).length == 0) {
          this._model.push({
            billingEntityId: this.billingEntityId,
            facilityId: value.id,
            facilityName: value.companyName,
          } as BillingEntityFacilityDto);
        }
      });
  }

  removeFacility(i: number) {
    this.model.splice(i, 1);
  }

  goToFacilityPayor(facilityId: number) {
    this.router.navigate(['facility-entry', facilityId]);
  }
}
