<div class="row">
  <div class="col-md-12">
    <h1>System Statement Detail</h1>
    <hr class="divider" />
  </div>
</div>
<table id="tb-system-statement-detail" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th>Date</th>
    <th>Payor</th>
    <th>Statements Queued</th>
    <th>0 Day Balance</th>
    <th>30 Day Balance</th>
    <th>60 Day Balance</th>
    <th>90 Day Balance</th>
    <th>Total Balance</th>
    <th>Late Fees</th>
    <th>Time</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>{{row.date | date:"M/d/yyyy"}}</td>
    <td>{{row.name}}</td>
    <td>{{row.statementsQueued}}</td>
    <td><displayable-money [model]="row.zeroDayBalanceSum"></displayable-money></td>
    <td><displayable-money [model]="row.thirtyDayBalanceSum"></displayable-money></td>
    <td><displayable-money [model]="row.sixtyDayBalanceSum"></displayable-money></td>
    <td><displayable-money [model]="row.ninetyDayBalanceSum"></displayable-money></td>
    <td><displayable-money [model]="row.totalBalanceSum"></displayable-money></td>
    <td>{{row.time | date: "M/d/yyyy h:mm a"}}</td>
  </tr>
  </tbody>
</table>
