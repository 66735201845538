import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {BaseHttpClient} from "./base-http-client";

export function reviewerHttpClientCreator(http: HttpClient) {
  return new ReviewerApi8HttpClient(http);
}

@Injectable()
export class ReviewerApi8HttpClient extends BaseHttpClient {
  constructor(protected http: HttpClient) {
    super(http, environment.reviewerApi8Url);
  }
}

export const reviewerApi8HttpClientProvider = {
  provide: ReviewerApi8HttpClient,
  useFactory: reviewerHttpClientCreator,
  deps: [HttpClient]
};
