export class PendingFinancialTransactionDto {
  id: number;
  financialTransactionPendingImportType: string;
  financialTransactionPendingImportStatus: string;
  patientId: number;
  patientFirstName: string;
  patientLastName: string;
  patientIdentifier: string;
  employerId: number;
  employer: string;
  regionId: number;
  region: string;
  jobSiteId: number;
  jobSite: string;
  branchId: number;
  branch: string;
  facilityId: number;
  facility: string;
  providerId: number;
  provider: string;
  providerNPI: string;
  chargeCode: string;
  displayChargeCode: string;
  chargeCodeDescription: string;
  specimenId: string;
  serviceDate: Date;
  jobNumber: string;
  quantity: number;
  authorizationPatientId: number;
  purchaseOrderNumber: string;
  testReasonType: string;
  amount: number;
  payor: string;
  payorId: number;
  groupingKey: string;
  notes: string;
}
