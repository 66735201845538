<div class="modal fade" id="invoiceDebitBillingDocumentComposition" tabindex="-1" role="dialog" data-keyboard="false"
     data-backdrop="static" style="overflow-y:auto">
  <div class="modal-dialog modal-xl document-composition-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Billing Document Composition</h3>
      </div>
      <validation-results></validation-results>
      <div class="modal-body" style="overflow-y:scroll;">
        <div class="row">
          <div class="col-md-3">
            <label class="control-label">Document: </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <select class="dropdown form-control" name="authorizationPatientDocuments"
                    [(ngModel)]="authorizationPatientDocument"
                    (ngModelChange)="onAuthorizationPatientDocumentChanged()">
              <option [ngValue]="null">-- Select Document --</option>
              <option *ngFor="let option of authorizationPatientDocuments"
                      [ngValue]="option">{{option.documentName}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <button [disabled]="noDocumentSelected()" type="button" class="btn btn-primary"
                    (click)="downloadRawDocument()">Download Raw Document
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr class="divider"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="card border border-success">
              <div class="card-header">Document Pages</div>
              <div class="card-body" dragula="DRAGULA" [(dragulaModel)]="leftPages" id="left"
                   style="min-height:100px;max-height:600px;overflow:auto;">
                <div class="document-page-container" *ngFor="let documentPage of leftPages; let i=index">
                  <div class="vcenter text-center">
                    <div class="document-page"
                         [innerHtml]="documentPage.pageThumbnail | imageDisplay: '100%':'auto':'img-fluid'"></div>
                    <div class="document-page-number">
                      <a href="#" data-toggle="modal" data-target="#pagePreviewModal"
                         (click)="previewAuthorizationDocumentPage(documentPage)">Page {{i + 1}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card border border-info">
              <div class="card-header">Composed Document (Pages: {{rightPages.length}})</div>
              <div class="card-body">
                <div dragula="DRAGULA" [(dragulaModel)]="rightPages"
                     style="min-height:100px;max-height:600px;overflow:auto;">
                  <div class="card border border-light" *ngFor="let composedDocumentPage of rightPages; let i=index">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-2"
                             [innerHtml]="composedDocumentPage.pageThumbnail | imageDisplay: '100%':'auto':'img-fluid img-thumbnail'"></div>
                        <div class="col-md-5">
                          <b>Source Document: </b>{{composedDocumentPage.documentName}}<br/>
                          <b>Source Page: </b>{{composedDocumentPage.sourcePageNumber}}
                        </div>
                        <div class="col-md-offset-1 col-md-4">
                          <button type="button" class="btn btn-primary" data-toggle="modal"
                                  data-target="#pagePreviewModal"
                                  (click)="previewBillingComposedPage(composedDocumentPage)">Preview
                          </button>
                          <button type="button" class="btn btn-danger ml-2"
                                  reallyClick="{{getRemoveDocumentCompositionPageMessage()}}"
                                  reallyClickAction="deleteComposedDocumentPage"
                                  [reallyClickCaller]="this"
                                  [reallyClickArguments]="[i]">
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="preview" type="button" class="btn btn-primary" [disabled]="rightPages?.length == 0"
          (click)="previewRenderedComposition()">
          Preview Composition
        </button>
        <button id="save" type="button" class="btn btn-primary" (click)="saveBillingDocumentComposition()">Save
          Composition
        </button>
        <button id="dismiss" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="pagePreviewModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Page Preview</h3>
        <div class="col-md-11 vcenter"
             [innerHtml]="pageImagePreview | imageDisplay: '100%':'auto':'img-fluid img-thumbnail'"></div>
      </div>
      <div class="modal-footer">
        <button id="pagePreviewDismiss" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

