import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {InvoiceDetailClaimDto} from "../../../../models/invoice-detail/claim/invoice-detail-claim-dto";
import {HttpParams, HttpResponse} from '@angular/common/http';
import {CommonResult} from "../../../../models/common/common-result";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {forkJoin} from "rxjs";
import { jsPDF } from "jspdf";
import * as JsBarcode from 'jsbarcode';
import {ClaimAuditComponent} from './claim-audit/claim-audit.component';
import {ReviewerApi8HttpClient} from "../../../../common/reviewer-api8-http-client";
import {ReviewerApi9HttpClient} from "../../../../common/reviewer-api9-http-client";
import {DocumentHttpClient} from '../../../../common/document-http-client';
import {DatePipe} from '@angular/common';
declare var $: any;

@Component({
  selector: 'invoice-detail-claim',
  templateUrl: './invoice-detail-claim.component.html',
  styleUrls: ['./invoice-detail-claim.component.scss']
})
export class InvoiceDetailClaimComponent implements OnInit {

  model: InvoiceDetailClaimDto = new InvoiceDetailClaimDto();
  invoiceId: number;
  authorizationPatientId: number;
  invoiceNumberDisplay: string;
  @ViewChild('claimAudit') claimAudit: ClaimAuditComponent;

  constructor(public billingHttpClient: BillingHttpClient,
              public documentHttpClient: DocumentHttpClient,
              public datePipe: DatePipe,
              public reviewerApi8HttpClient: ReviewerApi8HttpClient,
              public reviewerApi9HttpClient: ReviewerApi9HttpClient,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit() {
    const params = new HttpParams().set('invoiceId', this.invoiceId.toString());
    this.billingHttpClient.Get<InvoiceDetailClaimDto>("/invoice-detail-claim", {params})
      .subscribe(response => {
      this.model = response;
      if (this.model.qualOfCurrent == null){
        this.model.qualOfCurrent='439'
      }
      if (!this.model.patientGender) {
        this.model.patientGender = "O"
      }
      if (!this.model.resubmissionCode) {
        this.model.resubmissionCode = "1"
      }
    });

    const that = this;
    $('#claimAudit').on('shown.bs.modal', function() {
      that.claimAudit.reload();
    });
  }

  generateEDI() {
    const that = this;

    let requires = "";
    if (!this.model.patientGender) {
      requires += "3. Patient's Gender\n";
    }
    if (!this.model.qualOfCurrent) {
      requires += "14. QUAL\n";
    }
    if (!this.model.payorCity) {
      requires += 'Payor City\n';
    }
    if (!this.model.payorState) {
      requires += 'Payor State\n';
    }
    if (!this.model.payorPostalCode) {
      requires += 'Payor Postal Code\n';
    }
    if (!this.model.resubmissionCode) {
      requires += '22. Resubmission Code\n';
    }
    if (!this.model.billingProviderNpi) {
      requires += '33.a Billing Provider NPI\n';
    }
    // let missingLineControl = this.model.serviceDtos
    //   .filter(s => s.lineItemControlNumber === undefined || s.lineItemControlNumber === null || s.lineItemControlNumber === "")
    //   .length > 0
    // if (missingLineControl) {
    //   requires += "24. Line item Control Number\n";
    // }

    if (!requires) {
      this.billingHttpClient.Post<Blob>(`/invoice-837P/${this.invoiceId}`, this.model,
        {responseType: 'blob' as 'json'}).toPromise()
        .then(function (result) {
          var blob = new Blob([result]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `HIPAA_837P_${that.invoiceNumberDisplay}_HIPAASpace.clp`;
          link.click();
        })
    } else {
      alert("Please fill the following required fields:\n" + requires);
    }
  }

  get canGenerateEDI() {
    return this.model.patientGender
      && this.model.qualOfCurrent
      &&this.model.payorCity
      &&this.model.payorState
      &&this.model.payorPostalCode
      &&this.model.resubmissionCode
      &&this.model.billingProviderNpi;
  }

  save() {
    this.billingHttpClient.Post<CommonResult>(`/invoice/${this.invoiceId}/invoice-detail-claim`, this.model)
      .subscribe(response => {
        if (response.result === 'success') {
          this.validationResultsService.setSuccessMessages(['Invoice claim save success']);
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      }
    );
  }

  generateFullRecord() {
    var options = {
      params:new HttpParams().set('id', this.authorizationPatientId.toString())
    };
    var options2 = {
      params:new HttpParams().set('authorizationpatientid', this.authorizationPatientId.toString())
    };
    var options3 = {
      params:new HttpParams().set('authorizationPatientId', this.authorizationPatientId.toString())
    };
    var options4 = {
      params:new HttpParams().set('id', this.authorizationPatientId.toString())
    };
    let arr = [
      this.reviewerApi8HttpClient.Get<any>("/InjuryNote/v1/getInjuryNoteById",options),
      this.reviewerApi9HttpClient.Get<any>("/authorizationpatient/getAuthorizationPatient",options2),
      this.reviewerApi9HttpClient.Get<any>("/visitInformation/query",options3),
      this.reviewerApi8HttpClient.Get<any>("/AuthProviderUser/v1/getAuthProviderUserById",options4)
    ];

    forkJoin(arr)
      .subscribe(results => {
        var injury_note_info_list = JSON.parse(results[0].data);
        var authPatient = results[1];
        var authVisitInfo = results[2];
        var provider = JSON.parse(results[3].data);
        this.generatePdf(injury_note_info_list,authPatient,authVisitInfo,provider)
      });

  }

  generatePdf(injury_note_info_list,authPatient,authVisitInfo,provider) {
    var barcode_img = document.createElement<any>("IMG");
    JsBarcode(barcode_img,'A' + this.authorizationPatientId);
    var injuryInfo = this.getInjuryInfo(injury_note_info_list);
    var d = new Date();
    var utc_print_time = [d.getMonth()+1,
        d.getDate(),
        d.getFullYear()].join('/')+' '+
      [d.getHours(),
        d.getMinutes(),
        d.getSeconds()].join(':');

    var doc = new jsPDF();
    var page_height= doc.internal.pageSize.height - 10;
    //===========table header===========
    doc.setFont("helvetica","bold");
    doc.setFontSize(25);
    doc.text("CLINIC NOTES", 15, 15);
    doc.addImage(barcode_img.src, 'JPEG', 150,5,40,20);
    doc.setFontSize(10);

    var data_starting_x1 = 70;
    var data_starting_x2 = 150;
    var dynamic_y_start = 35;

    doc.text("SSN:", 15, dynamic_y_start);
    doc.text("Company:", 120, dynamic_y_start);

    var ssnFormat = "";
    if (authPatient.ssn!=null){
      ssnFormat = (authPatient.ssn.substring(0,3)  + "-" +authPatient.ssn.substring(3,5) + "-" + authPatient.ssn.substring(5));
    }
    doc.text(ssnFormat, data_starting_x1, dynamic_y_start);
    var illness_company_spilt = doc.splitTextToSize((authPatient.companyName ?? ""), 45);
    doc.text(illness_company_spilt, data_starting_x2, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,illness_company_spilt.length * 5);

    doc.text("Name:", 15, dynamic_y_start);
    doc.text("Job Number:", 120, dynamic_y_start);
    var illness_name_spilt = doc.splitTextToSize((authPatient.name+authPatient.name ?? ""), 45);
    doc.text(illness_name_spilt, data_starting_x1, dynamic_y_start);
    doc.text(authPatient.jobNumber ?? "", data_starting_x2, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,illness_name_spilt.length * 5);

    doc.text("Date of Birth:", 15, dynamic_y_start);
    doc.text("Created By:", 120, dynamic_y_start);
    doc.text(authPatient.dateOfBirth ?? "", data_starting_x1, dynamic_y_start);
    var illness_by_spilt = doc.splitTextToSize((authPatient.createdBy ?? ""), 45);
    doc.text(illness_by_spilt, data_starting_x2, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,illness_by_spilt.length * 5);

    doc.text("Phone Number:", 15, dynamic_y_start);
    doc.text("Created:", 120, dynamic_y_start);
    doc.text(authPatient.phoneNumber ?? "", data_starting_x1, dynamic_y_start);
    doc.text(authPatient.created ?? "", data_starting_x2, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 5);

    doc.text("Authorizing Representative:", 15, dynamic_y_start);
    doc.text("Rep Phone:", 120, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.setFontSize(15);
    doc.text("Visit Information", 15, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.setFontSize(10);
    doc.text("Temp:", 15, dynamic_y_start);
    doc.text("Pulse:", 40, dynamic_y_start);
    doc.text("Systolic:", 60, dynamic_y_start);
    doc.text("Diastolic:", 85, dynamic_y_start);
    doc.text("Height:", 113, dynamic_y_start);
    doc.text("Weight:", 140, dynamic_y_start);
    doc.text("Resp:", 175, dynamic_y_start);
    // visit_info_details

    if (authVisitInfo.temperature !== null){
      doc.text(authVisitInfo.temperature + 'F', 27, dynamic_y_start);
    }else{
      doc.text('', 27, dynamic_y_start);
    }
    doc.text(String(authVisitInfo.pulse ?? ''), 51, dynamic_y_start);
    doc.text(String(authVisitInfo.systolic ?? ''), 74, dynamic_y_start);
    doc.text(String(authVisitInfo.diastolic ?? ''), 101, dynamic_y_start);
    if (authVisitInfo.height !== null){
      doc.text(authVisitInfo.height + 'in', 125, dynamic_y_start);
    }else{
      doc.text('', 125, dynamic_y_start);
    }
    if (authVisitInfo.weight !== null){
      doc.text(authVisitInfo.weight + 'lb', 153, dynamic_y_start);
    }else{
      doc.text('', 153, dynamic_y_start);
    }
    doc.text(String(authVisitInfo.respirations ?? ''), 185, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.text("Reason For Visit:", 15, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 5);
    doc.text(String(authVisitInfo.reasonForVisit ?? ''), 15, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.text("Has the patient had a tetanus shot in the last 5 years?", 15, dynamic_y_start);
    doc.text(authVisitInfo.tetanus_shot ?? 'Unknown', data_starting_x2, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.setFontSize(15);
    doc.text("Authorization Notes", 15, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.setFontSize(10);
    doc.text("Chief Complaint:", 15, dynamic_y_start);
    doc.text(injuryInfo['chief_note'], data_starting_x1, dynamic_y_start);

    //===========dynamic high for report header==============
    // var dynamic_y_start = 115;
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 10);

    doc.text("History of Present Illness:", 15, dynamic_y_start);
    var illness_his_spilt = doc.splitTextToSize(injuryInfo['illness_his'], 100);
    for (let i = 0; i < illness_his_spilt.length; i ++){
      doc.text(illness_his_spilt[i], data_starting_x1, dynamic_y_start);
      dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);
    }
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 5);
    doc.text("Past Medical History:", 15, dynamic_y_start);

    var ohter_his_split = doc.splitTextToSize(injuryInfo['other_his'], 100);
    for (let i = 0; i < ohter_his_split.length; i ++){
      doc.text(ohter_his_split[i], data_starting_x1, dynamic_y_start);
      dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);
    }
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc, 5);
    doc.text("Physical Evaluation:", 15, dynamic_y_start);

    var physical_eval_split = doc.splitTextToSize(injuryInfo['physical_evaluation'], 100);
    for (let i = 0; i < physical_eval_split.length; i ++){
      doc.text(physical_eval_split[i], data_starting_x1, dynamic_y_start);
      dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);
    }

    for (let i = 0; i < injuryInfo['treatment_name_list'].length; i ++){
      doc.text(injuryInfo['treatment_name_list'][i], data_starting_x1, dynamic_y_start);
      dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,10);
    }
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,10);

    doc.text("Assessment and Plan:", 15, dynamic_y_start);
    var split_diagnosis1 = doc.splitTextToSize(injuryInfo.diagnosis1, 30);
    let copy_dynamic_y_start1 = dynamic_y_start;
    for (let i = 0; i < split_diagnosis1.length; i ++){
      doc.text(split_diagnosis1[i], data_starting_x1, copy_dynamic_y_start1);
      copy_dynamic_y_start1 = this.drawHeight(copy_dynamic_y_start1,page_height,doc,5);
    }
    // doc.text(split_diagnosis1, data_starting_x1, dynamic_y_start);
    var split_diagnosis2 = doc.splitTextToSize(injuryInfo.diagnosis2, 50);
    let copy_dynamic_y_start2 = dynamic_y_start;
    for (let i = 0; i < split_diagnosis2.length; i ++){
      doc.text(split_diagnosis2[i], data_starting_x2, copy_dynamic_y_start2);
      copy_dynamic_y_start2 = this.drawHeight(copy_dynamic_y_start2,page_height,doc,5);
    }
    dynamic_y_start = Math.max(copy_dynamic_y_start1, copy_dynamic_y_start2);

    // doc.text(split_diagnosis2, data_starting_x2, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);

    doc.text("Return To Work:", 15, dynamic_y_start);
    doc.text(injuryInfo.return_to_work_status, data_starting_x1, dynamic_y_start);
    var return_to_work_note = doc.splitTextToSize(injuryInfo.return_to_work_note, 50);
    for (let i = 0; i < return_to_work_note.length; i ++){
      doc.text(return_to_work_note[i], data_starting_x2, dynamic_y_start);
      dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);
    }
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);

    doc.text("Next Follow-Up: ", 15, dynamic_y_start);
    doc.text(injuryInfo.next_follow_up_time, data_starting_x1, dynamic_y_start);
    var next_follow_up_note_split = doc.splitTextToSize(injuryInfo.next_follow_up_note, 50);
    for (let i = 0; i < next_follow_up_note_split.length; i ++){
      doc.text(next_follow_up_note_split[i], data_starting_x2, dynamic_y_start);
      dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);
    }
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,5);

    doc.text("Referral:", 15, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,10);

    doc.text("Discussed diagnosis and treatment plan with EMPLOYEE ONLY.", 15, dynamic_y_start);
    dynamic_y_start = this.drawHeight(dynamic_y_start,page_height,doc,10);

    doc.text("Provider Signature:", 15, dynamic_y_start);
    doc.text("Signed On:", 120, dynamic_y_start);
    doc.text("Printed On: ", 15, dynamic_y_start + 10);
    doc.text(utc_print_time + ' (Coordinated Universal Time)', data_starting_x1, dynamic_y_start + 10);

    if (provider !== null){
      doc.text(provider.firstName + " " + provider.lastName, data_starting_x1, dynamic_y_start);
      doc.text(provider.date.split('+')[0], data_starting_x2, dynamic_y_start)
    }else{
      doc.text("", data_starting_x1, dynamic_y_start);
      doc.text("", data_starting_x2, dynamic_y_start);
    }
    doc.save('Full Clinic Notes for ' + authPatient.name + '.pdf');
  }

  drawHeight(dynamic_y_start:number,page_height:number,doc:jsPDF,addHeight:number){
    dynamic_y_start += addHeight;
    if (dynamic_y_start > page_height){
      doc.addPage();
      dynamic_y_start = 15
    }
    return dynamic_y_start;
  }

  getInjuryInfo(injury_note_info_list){
    var chief_note = "";
    var illness_his = "";
    var other_his = "";
    var physical_evaluation = "";
    var treatment_name_list = [];
    var diagnosis1 = "";
    var diagnosis2 = "";
    var treatment_string = "";
    var treatment_list = [];
    var return_to_work_status = "";
    var return_to_work_note = "";
    var next_follow_up_time = "";
    var next_follow_up_note = "";
    for (var i = 0; i < injury_note_info_list.length; i++) {
      if (injury_note_info_list[i].componentTypeId == 2) {
        illness_his = injury_note_info_list[i].note ?? '';
      } else if (injury_note_info_list[i].componentTypeId == 3) {
        physical_evaluation = injury_note_info_list[i].note ?? '';
      } else if (injury_note_info_list[i].componentTypeId == 6) {
        diagnosis1 = injury_note_info_list[i].description ?? '';
        diagnosis2 = injury_note_info_list[i].note ?? '';
      } else if (injury_note_info_list[i].componentTypeId == 7) {
        treatment_name_list.push(injury_note_info_list[i].description ?? '');
        treatment_list.push(injury_note_info_list[i].note ?? '');
      } else if (injury_note_info_list[i].componentTypeId == 10) {
        other_his = injury_note_info_list[i].note ?? '';
      } else if (injury_note_info_list[i].componentTypeId == 12) {
        return_to_work_status = injury_note_info_list[i].returnToWorkTypeName ?? '';
        return_to_work_note = injury_note_info_list[i].note ?? '';
      } else if (injury_note_info_list[i].componentTypeId == 14){
        var date= new Date(
          injury_note_info_list[i].returnToClinicDate
        );
        next_follow_up_time= date.getFullYear() + "-"
          + (date.getMonth() + 1) + "-" + date.getDate();
        next_follow_up_note = injury_note_info_list[i].note ?? '';
      } else if (injury_note_info_list[i].componentTypeId == 25) {
        chief_note = injury_note_info_list[i].note ?? '';
      }
    }
    for (let i = 0 ; i < treatment_list.length; i++){
      treatment_string = treatment_string + '\n' + treatment_list[i].value
    }

    return {
      chief_note: chief_note,
      illness_his: illness_his,
      physical_evaluation: physical_evaluation,
      treatment_name_list: treatment_name_list,
      other_his: other_his,
      diagnosis1: diagnosis1,
      diagnosis2: diagnosis2,
      treatment: treatment_string,
      treatment_count: treatment_list.length,
      return_to_work_status: return_to_work_status,
      return_to_work_note: return_to_work_note,
      next_follow_up_time:next_follow_up_time,
      next_follow_up_note:next_follow_up_note
    };
  }

  openAuditModal() {
    this.claimAudit.invoiceId =  this.invoiceId;
    $('#claimAudit').modal('show');
  }


}
