<div class="modal fade" id="employerLookupModal" tabindex="-1" role="dialog" aria-labelledby="employerLookupModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="employerLookupModalLabel">Search Employers</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
               style="width: 100%">
          <thead>
          <tr>
            <th></th>
            <th>Employer</th>
            <th>Address</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of model">
            <td>
              <div class="button-group" role="group">
                <button class="btn btn-success mr-1" (click)="selectEmployer(row)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
            <td>{{row.employerName}}</td>
            <td>{{row.employerAddress}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
