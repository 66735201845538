<div class="container-fluid mt-2">
  <div class="row">
    <div class="col">
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.deliverByPostal">Deliver by Post</mat-checkbox>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Address Line 1</mat-label>
          <input matInput [(ngModel)]="model.address1">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Address Line 2</mat-label>
          <input matInput [(ngModel)]="model.address2">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>City</mat-label>
          <input matInput [(ngModel)]="model.city">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select [(ngModel)]="model.state">
            <mat-option *ngFor="let state of stateList" [value]="state.value">
              {{state.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Postal Code</mat-label>
          <input matInput [(ngModel)]="model.postalCode">
        </mat-form-field>
      </div>
    </div>
    <div class="col">
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.deliverByEmail">Deliver by Email</mat-checkbox>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="model.billingEmail">
        </mat-form-field>
      </div>
    </div>
    <div class="col">
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.deliverByFax">Deliver by Fax</mat-checkbox>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Fax Number</mat-label>
          <input matInput [(ngModel)]="model.billingFax">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div class="right-align">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-danger mr-2">
    <i class="fa fa-trash"></i>
    Delete Payor
  </button>
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
