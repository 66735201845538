import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {PayorContactEntryDto} from "../../../../models/payor-entry/payor-contact-entry-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {ValidationResultsService} from "../../../../services/validation-results.service";
import {HttpParams} from "@angular/common/http";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {CommonResult} from "../../../../models/common/common-result";
import {PayorContactsDto} from "../../../../models/payor-entry/payor-contacts-dto";

@Component({
  selector: 'app-payor-entry-contacts',
  templateUrl: './payor-entry-contacts.component.html',
  styleUrls: ['./payor-entry-contacts.component.scss']
})
export class PayorEntryContactsComponent implements OnInit {

  @Input() payorId: number;
  model: PayorContactEntryDto[] = [];

  constructor(private billingHttpClient: BillingHttpClient,
              private validationResultsService: ValidationResultsService,
              private router: Router) {
  }

  ngOnInit(): void {
    const params = new HttpParams().set('payorId', this.payorId.toString());
    this.billingHttpClient.Get<PayorContactEntryDto[]>('/payor-contacts', {params})
      .subscribe(response => {
          this.model = response;
        }
      );
  }

  addPayorContact() {
    this.router.navigate(['payor-contact-entry', '', this.payorId.toString()]);
  }

  editPayorContact(id: number) {
    this.router.navigate(['payor-contact-entry', id, this.payorId.toString()]);
  }

  drop(event: CdkDragDrop<PayorContactEntryDto[]>) {
    moveItemInArray(this.model, event.previousIndex, event.currentIndex);
  }

  deletePayorContact(id: number) {
    this.model.splice(this.model.findIndex(p => p.id == id), 1);
  }

  save() {
    // Update rank
    this.model.forEach((v, i) => {
      v.rank = i;
    });
    let payload = {
      payorId: this.payorId,
      payorContacts: this.model,
    } as PayorContactsDto;
    this.billingHttpClient.Post<CommonResult>('/payor-contacts', payload)
      .subscribe(response => {
        if (response.result == 'success') {
          this.validationResultsService.setSuccessMessages(['Save succeeded']);
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      });
  }
}
