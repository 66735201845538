import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BillingHttpClient } from "../../../common/billing-http-client.service";
import { ActivatedRoute, Router } from '@angular/router';
import { DataTablesResponse } from "../../../models/common/data-tables-response";
import { InvoiceSearchRow } from "../../../models/invoices/invoice-search-row";
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';

declare var $;


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  model: InvoiceSearchRow[] = [];
  current_draw: number;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  searchStr: string;
  isPending: boolean = false;

  constructor(private billingHttpClient: BillingHttpClient,
    private route: ActivatedRoute,
    private router: Router) {
    }

    ngOnInit(): void {
      this.route.params
      .subscribe(params => {
        this.searchStr = params.search;
        this.isPending = this.searchStr == 'PendingSubmission';
      });

    const that = this;

    const defaultColumns = [
      this.searchStr ? {
        data: "invoiceId",
        render: function (data, type, row) {
          return `
          <a type="button" class="btn btn-success btn-edit" href='/#/invoice/${data})'">
            <i class="fas fa-edit"></i>
          </a>
        `
        }
      } : { data: 'id' },
      { data: 'invoiceNumberDisplay' },
      {
        data: 'invoiceAmount',
        createdCell: function (td, cellData, rowData, row, col) {
          $(td).attr('data-order', cellData);
          if (cellData < 0) {
            $(td).css({ color: 'red' });
            $(td).html(`$(${parseInt(cellData).toString().replace('-', '')})`);
          } else {
            $(td).html(`$${parseInt(cellData)}`);
          }
        }
      },
      { data: 'employer' },
      { data: 'payor' },
      { data: 'region' },
      // {data: 'branch'},
      { data: 'jobNumber' },
      { data: 'purchaseOrder' },
      { data: 'reasonForVisit' },
      { data: 'patientName' },
      { data: 'status' },
      { data: 'authorizationPatientId' },
    ];

    const pendingColumns = [
      this.searchStr ? {
        data: "invoiceId",
        render: function (data, type, row) {
          return `
          <a type="button" class="btn btn-success btn-edit" href='/#/invoice/${data})'">
            <i class="fas fa-edit"></i>
          </a>
        `
        }
      } : { data: 'id' },
      { data: 'invoiceNumberDisplay' },
      {
        data: 'invoiceAmount',
        createdCell: function (td, cellData, rowData, row, col) {
          $(td).attr('data-order', cellData);
          if (cellData < 0) {
            $(td).css({ color: 'red' });
            $(td).html(`$(${parseInt(cellData).toString().replace('-', '')})`);
          } else {
            $(td).html(`$${parseInt(cellData)}`);
          }
        }
      },
      { data: 'employer' },
      { data: 'payor' },
      { data: 'region' },
      // {data: 'branch'},
      { data: 'jobNumber' },
      { data: 'purchaseOrder' },
      { data: 'claimNumber' },
      {
        data: 'fax',
        className: 'text-center',
        render: function (data, type, row) {
          return `
          <i class="text-info fa fa-check-square ${data ? '' : 'd-none'}"></i>
      `
        }
      },
      {
        data: 'post',
        className: 'text-center',
        render: function (data, type, row) {
          return `
        <i class="text-info fa fa-check-square ${data ? '' : 'd-none'}"></i>
    ` }
      },
      {
        data: 'email',
        className: 'text-center',
        render: function (data, type, row) {
          const show = row.triedEmail && data;
          return `
        <i class="text-danger fa fa-times ${show ? '' : 'd-none'}"></i>
    ` }
      },
    ]

    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 50,
      serverSide: !this.searchStr,
      processing: true,
      stateSave: true,
      columns: this.searchStr == 'PendingSubmission' ? pendingColumns : defaultColumns,
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        if (this.searchStr) {
          that.billingHttpClient.Post<DataTablesResponse>("/invoicesByStatus?initialFilter=" + this.searchStr, dataTablesParameters)
            .subscribe(resp => {
              callback({
                data: resp
              })
            });
        } else {
          that.billingHttpClient.Post<DataTablesResponse>("/invoices", dataTablesParameters)
            .subscribe(resp => {
              // console.log("current_draw: " + that.current_draw);
              // console.log("resp.draw: " + resp.draw);
              if (that.current_draw == null || that.current_draw < resp.draw) {
                // console.log("set model");
                that.model = resp.data;
                that.current_draw = resp.draw;
                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: []
                });
              } else {
                // console.log("ignore this resp");
              }
            });

        }
      },
    };
  }

  goToInvoice(invoiceId: number) {
    this.router.navigate(['invoice', invoiceId]);
  }

  getSearchType(): string {
    return this.searchStr || "All"
  }

  ngAfterViewInit(): void {

  }

}
