import {Component, EventEmitter, OnInit} from '@angular/core';
import {InvoiceDetailNotesDto} from '../../../../models/invoice-detail/invoice-detail-notes-dto';
import {BillingHttpClient} from '../../../../common/billing-http-client.service';
import {HttpParams} from '@angular/common/http';
import {CommonResult} from '../../../../models/common/common-result';

@Component({
  selector: 'app-invoice-detail-notes',
  templateUrl: './invoice-detail-notes.component.html',
  styleUrls: ['./invoice-detail-notes.component.scss']
})
export class InvoiceDetailNotesComponent implements OnInit {

  model: InvoiceDetailNotesDto = new InvoiceDetailNotesDto();
  invoiceId: number;
  publicDisplayValue: string;
  privateDisplayValue: string;
  showSaveError = false;

  constructor(private billingHttpClient: BillingHttpClient) {
  }

  ngOnInit() {
    const params = new HttpParams().set('invoiceId', this.invoiceId.toString());
    this.billingHttpClient.Get<InvoiceDetailNotesDto>('/invoice-detail-notes', {params}).subscribe(response => {
      this.model = response;
    });
  }

  save(text, evt) {
    const params = {
      invoiceId: this.invoiceId,
      notesPrintedOnInvoice: this.model.notesPrintedOnInvoice,
      notesInternal: this.model.notesInternal,
    };

    if (evt.name === 'publicClick') {
      params.notesPrintedOnInvoice = text;
      this.model.notesPrintedOnInvoice = text;
    } else if (evt.name === 'privateClick') {
      params.notesInternal = text;
      this.model.notesInternal = text;
    }

    this.billingHttpClient.Put<CommonResult>('/invoice-notes', params).subscribe(response => {
      if (response.result !== 'success') {
        this.showSaveError = true;
        setInterval(
          () => {
            this.showSaveError = false;
          }, 3000);
      }
    });
  }
}
