import {Component, OnInit, ViewChild} from '@angular/core';
import {RegionDto} from "../../../../models/common/region-dto";
import {PayorListSearchRow} from "../../../../models/common/payor-list-search-row";
import {EmployerSearchRow} from "../../../../models/employer-list/employer-search-row";
import {HttpParams} from "@angular/common/http";
import {DocumentHttpClient} from "../../../../common/document-http-client";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {StatementExtrasEmailDto} from '../../../../models/statement/statement-extras-email-dto';
import {BillingHttpClient} from '../../../../common/billing-http-client.service';
import {PayorContactEntryDto} from '../../../../models/payor-entry/payor-contact-entry-dto';
import {TaskHttpClient} from '../../../../common/task-http-client';
import {CommonResult} from '../../../../models/common/common-result';
import {ValidationResultsService} from '../../../../services/validation-results.service';

@Component({
  selector: 'statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {

  constructor(
    private clinicHttpClient: ClinicHttpClient,
    private documentHttpClient: DocumentHttpClient,
    private taskHttpClient: TaskHttpClient,
    private billingHttpClient: BillingHttpClient,
    private validationResultsService: ValidationResultsService) {
  }

  date: Date;
  employer: EmployerSearchRow = new EmployerSearchRow();
  region: RegionDto = null;
  payor: PayorListSearchRow = new PayorListSearchRow();
  emailModel: StatementExtrasEmailDto = new StatementExtrasEmailDto();
  model: PayorContactEntryDto[] = [];


  employerParams: Map<string, any> = new Map<string, any>();
  payorParams: Map<string, any> = new Map<string, any>();
  @ViewChild('employerLookup') employerLookup;
  @ViewChild('payorLookup') payorLookup;

  regions: RegionDto[];

  ngOnInit() {
  }

  get canSave(): boolean {
    return this.payor && this.payor.payorId && this.date != null;
  }

  setEmployer(value: EmployerSearchRow) {
    this.employer = value;
    this.payorParams.set('employerId', this.employer.employerId);
    this.getEmployerRegions();
    if (!this.payor.payorId) {
      this.payorLookup.reloadTable()
    }
  }

  clearEmployer() {
    this.employer = new EmployerSearchRow();
    if (!this.payor.payorId) {
      this.payorLookup.reloadTable();
    }
  }

  getEmployerRegions() {
    const params = new HttpParams().set('employerId', this.employer.employerId.toString());
    this.clinicHttpClient.Get<RegionDto[]>('/debit-entry-regions', {params})
      .subscribe(response => {
        this.regions = response;
      });
  }

  setPayor(value: PayorListSearchRow) {
    this.payor = value;
    this.employerParams.set('payorId', this.payor.payorId);
    this.employerLookup.reloadTable();
  }

  clearPayor() {
    this.payor = new PayorListSearchRow();
    if (!this.employer.employerId) {
      this.employerLookup.reloadTable();
    }
  }

  generateStatements(nonDetail? : boolean) {
    const params = new HttpParams()
      .set('date', this.date?.toISOString().substring(0, 10) ?? '')
      .set('payorId', this.payor.payorId.toString())
      .set('employerId', this.employer?.employerId?.toString() ?? '')
      .set('regionId', this.region?.id?.toString() ?? '')
      .set('nonDetail',nonDetail?.toString()??'false');

    this.documentHttpClient.Get<Blob>('/statements-pdf', {responseType: 'blob' as 'json', params: params})
      .subscribe(resp => {
        if (resp == null) {
          alert('There are no available statements for the search criteria.');
        } else {
          this.downloadPdf(resp, 'statements');
        }
      })
  }

  downloadPdf(blob: Blob, documentName: string) {
    let newBlob = new Blob([blob], {type: "application/pdf"})
    let data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = documentName + ".pdf";
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }


  showRequirementsListed() {
    $('#requirementsListed').modal('show');
  }

  showEmailDialog() {
    if (!this.emailModel.extrasEmail || this.model.filter(value => this.payor.payorId == value.payorId).length <= 0) {
      const params = new HttpParams().set('payorId', this.payor.payorId.toString());
      this.billingHttpClient.Get<PayorContactEntryDto[]>('/payor-contacts', {params})
        .subscribe(response => {
            this.model = response;
            this.emailModel.extrasEmail = this.model.filter(value => value.email != null).map(value => value.email).join();
          }
        );
    }
    $('#generatestatementsmodal').modal('show');
  }

  sendStatementEmail() {
    this.emailModel.payorId = this.payor.payorId;
    this.emailModel.employerId = this.employer?.employerId;
    this.emailModel.regionId = this.region?.id;
    this.emailModel.date = this.date;

    this.taskHttpClient.Post<CommonResult>(`/generate-statements`, this.emailModel).subscribe(
      response => {
        if (response.result === 'success') {
          this.validationResultsService.setSuccessMessages(['Send email success']);
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      }
    )
  }

  generateStatementsCSV(nonDetail? : boolean) {
    const params = new HttpParams()
      .set('date', this.date?.toISOString().substring(0, 10) ?? '')
      .set('payorId', this.payor.payorId.toString())
      .set('employerId', this.employer?.employerId?.toString() ?? '')
      .set('regionId', this.region?.id?.toString() ?? '')
      .set('nonDetail',nonDetail?.toString()??'false');

    this.documentHttpClient.Get<Blob>('/statements-csv', {responseType: 'blob' as 'json', params: params})
      .subscribe(resp => {
        if (resp == null) {
          alert('There are no available statements for the search criteria.');
        } else {
          this.downloadCsv(resp, 'statements');
        }
      })
  }

  downloadCsv(blob: Blob, documentName: string) {
    let fileType;
    let fileExtension;
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    fileExtension = '.xlsx';
    let newBlob = new Blob([blob], {type: fileType})
    let data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = documentName + fileExtension;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

}
