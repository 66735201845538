<app-header></app-header>
<div class="flex-fill justify-content-center px-4">
  <router-outlet></router-outlet>
  <really-click-modal></really-click-modal>
</div>
<footer id="footer"></footer>

<div class="loading-ring" *ngIf="isBusy">

  <figure class="chart" data-percent="100">
    <svg width="200" height="200">
      <circle class="outer" cx="95" cy="95" r="85" transform="rotate(-90, 95, 95)"/>
    </svg>
  </figure>

</div>
<message-modal></message-modal>
