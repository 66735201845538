<div class="container-fluid mt-2">
  <div class="row">
    <div class="col">
      <p>
        Invoice Configurations
      </p>
      <div>
        <mat-form-field>
          <mat-label>SSN Display Type</mat-label>
          <mat-select [(ngModel)]="model.ssnDisplayType">
            <mat-option value="">(select)</mat-option>
            <mat-option value="Full">Full Number</mat-option>
            <mat-option value="LastFour">Last 4 Only</mat-option>
            <mat-option value="Redact">Redacted</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Billing Frequency Type</mat-label>
          <mat-select [(ngModel)]="model.billingFrequencyType">
            <mat-option value="">(select)</mat-option>
            <mat-option value="Weekly">Bill Weekly</mat-option>
            <mat-option value="Monthly">Bill Monthly</mat-option>
            <mat-option value="EndOfJob">Bill at End of Job</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.doNotSendInvoice">Require Manual Submission</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.allowGroupingKeyInvoicesToSplitOnBillingFrequency">
          Allow Invoices with Grouping Keys to Split on Billing Freq.
        </mat-checkbox>
      </div>
      <p>
        Include Fields Configurations
      </p>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.includeSpecimenIdNumber">Include Specimen ID Number</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.includeJobNumber">Include Job Number</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.includePoNumber">Include Purchase Order Number</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.includeVendorIdNumber">Include Vendor ID Number</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.includeEmployerName">Include Employer Name</mat-checkbox>
      </div>

    </div>
    <div class="col">
      <p>
        Invoice Grouping Types
      </p>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.splitByPatient">Split by Patient Type</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.splitByJobNumber">Split by Job Number</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.splitByPo">Split by PO</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.splitByEmployerRegion">Split by Employer Region</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.splitByReasonForVisit">Split by Reason for Visit</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.nonDetailed">Non-Detailed Invoice</mat-checkbox>
      </div>
    </div>
    <div class="col">
      <p>
        Employer-Visible Documents
      </p>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.attachToInvoiceAuthorization">
          Attach Authorization To Invoice
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.attachToInvoiceChainOfCustody">
          Attach Chain of Custody To Invoice
        </mat-checkbox>
      </div>
      <p>
        Medical Testing Attachments
      </p>
      <div>
        <mat-checkbox class="bold" [(ngModel)]="model.attachToInvoiceMedicalTestingRecords">
          Attach Medical Testing Records to Invoice
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

<div class="right-align">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-danger mr-2">
    <i class="fa fa-trash"></i>
    Delete Payor
  </button>
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
