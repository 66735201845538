<div class="row">
  <div class="col-md-12 mt-3">
    <h2>Payors Assigned to this Branch</h2>
    <hr class="divider"/>
    <div class="col-md-3" style="padding-left: 0px;">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
              <i class="fa fa-search"></i><span class="ml-2">Add payor</span>
            </button>
          </div>
        </div>
      </div>
      <payor-lookup (payorChange)="selectPayor($event)"></payor-lookup>
    </div>
    <div class="dropdown">
      <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
        Add Payor
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button class="dropdown-item" (click)="createBranchPayor('Employer')">Employer</button>
        <button class="dropdown-item" (click)="createBranchPayor('TPA')">TPA</button>
        <button class="dropdown-item" (click)="createBranchPayor('WorkersComp')">Workers Comp</button>
      </div>
    </div>
    <table class="table table-bordered table-striped table-hover mt-2">
      <thead>
      <tr>
        <th class="fit"></th>
        <th>Payor Name</th>
        <th>Payor Type</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Active</th>
      </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let row of model.branchEntryPayorDtos" cdkDrag>
        <td class="fit button-container">
          <div class="row">
            <div class="col-md-3">
              <div class="row">
                <i class="fa fa-sort-up fa-stack-2x"></i>
              </div>
              <div class="row">
                <i class="fa fa-sort-down fa-stack-2x"></i>
              </div>
            </div>
            <div class="col-md-9">
              <button type="button" [title]="'Edit'" *ngIf="row.branchPayorId != null"
                      (click)="editBranchPayor(row.branchPayorId);"
                      class="btn btn-success">
                <i class="fas fa-edit"></i>
              </button>
            </div>
          </div>
        </td>
        <td>
          <span>{{row.payorName}}</span>
        </td>
        <td>
          <span>{{row.payorType}}</span>
        </td>
        <td>
          <span>{{row.startDateInclusive | date:"MM/dd/yyyy":"UTC"}}</span>
        </td>
        <td>
          <span>{{row.endDateInclusive | date:"MM/dd/yyyy":"UTC"}}</span>
        </td>
        <td>
          <i *ngIf="row.active" class="fa fa-check" style="color:green"></i>
          <i *ngIf="!row.active" class="fa fa-times" style="color:red"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div [hidden]="!(employerExceptions.length > 0)">
  <div class="row">
    <div class="col-md-12">
      <hr class="divider"/>
    </div>
  </div>
  <h3>Employer Exceptions</h3>
  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#employerLookupModal"><i class="fa fa-search"></i></button>
  <table id="tb-employer-exception-list" datatable [dtOptions]="exceptionDtOptions"
         class="table table-striped table-bordered mt-3" style="width: 100%;">
    <thead>
    <tr>
      <th></th>
      <th>Employer Name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of employerExceptions">
      <td><button type="button" class="btn btn-danger" (click)="deleteEmployerException(row.id)"><i
        class="fa fa-trash"></i></button></td>
      <td>{{row.employerName}}</td>
    </tr>
    </tbody>
  </table>
  <employer-lookup #employerLookup (employerChange)="setEmployer($event)"></employer-lookup>
</div>
<div class="right-align mt-3">
  <button class="btn btn-success mr-2" [attr.disabled]="!canSave ? 'disabled' : null" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
</div>
