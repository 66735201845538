<div class="{{modalFade}} {{modalClass}} {{backdropClass}}" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p>{{reallyClickService.message}}</p>
      </div>
      <div class="modal-footer" *ngIf="!reallyClickService.hasCustomButtons">
        <button type="button" class="btn btn-raised btn-success" (click)="accept()">Yes</button>
        <button type="button" class="btn btn-raised btn-warning" (click)="decline()">Cancel</button>
      </div>
      <div class="modal-footer" *ngIf="reallyClickService.hasCustomButtons">
        <button *ngFor="let button of reallyClickService.buttons" type="button" class="btn btn-raised btn-success" (click)="invoke(button)">{{button.text}}</button>
        <button type="button" class="btn btn-raised btn-warning" (click)="decline()">Cancel</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
