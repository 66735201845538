<h1>Distribute Invoices by Grouping Key</h1>
<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <label for="groupingKey">Grouping Key</label>
      <input id="groupingKey" type="text" class="form-control" name="groupingKey" [(ngModel)]="groupingKey">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-10"></div>
  <div class="col-md-2 text-right">
    <button class="btn btn-success" (click)="distribute()">
      <i class="fa fa-check-square-o"></i>
      Distribute
    </button>
  </div>
</div>
