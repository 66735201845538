import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postalCodePipe'
})
export class PostalCodePipePipe implements PipeTransform {

  transform(zip) {
    const value = zip.toString().trim().replace(/^\+/, '');
    if (value.match(/[^0-9]/)) {
      return zip;
    }
    let left, right, code;
    switch (value.length) {
      case 5: // ##### -> #####
        left = value;
        break;
      case 9: // ######### -> #####-####
        left = value.slice(0, 5);
        right = value.slice(5);
        break;
      default:
        return zip;
    }
    if (right) {
      code = left + '-' + right;
    }
    else {
      code = left;
    }
    return code;
  }
}
