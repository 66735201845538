import {RegionPayorEntryGeneralDto} from "./region-payor-entry-general-dto";
import {RegionPayorEntryResponsibilitiesDto} from "./region-payor-entry-responsibilities-dto";

export class RegionPayorEntryDto {
  regionPayorId: number;
  regionId: number;
  regionName: string;
  payorId: number;
  payorName: string;
  regionPayorEntryGeneralDto: RegionPayorEntryGeneralDto = new RegionPayorEntryGeneralDto();
  regionPayorEntryResponsibilitiesDto: RegionPayorEntryResponsibilitiesDto = new RegionPayorEntryResponsibilitiesDto();
}
