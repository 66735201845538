<div class="d-flex my-2 justify-content-end">
  <div class="dropdown">
    <button disabled class="btn btn-primary dropdown-toggle" type="button" id="updateSelectedMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Update Selected...
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="invoiceUpdateMenuButton">
      <a class="dropdown-item" (click)="updateJobNumber()">Update job number for selected debits</a>
      <a class="dropdown-item" (click)="updatePoNumber()">Update PO number for selected debits</a>
    </div>
  </div>
</div>

<table id="tb-debits" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
  class="table table-bordered table-striped mt-2">
  <thead>
    <tr>
      <th>
        <span id="select-all"><label for="checkall"></label><input type="checkbox" id="checkall" class="form-control"
            (change)="switchChecks()" /></span>
      </th>
      <th>Service Date</th>
      <th>Patient</th>
      <th>Description</th>
      <th>Branch</th>
      <th>Transaction Type</th>
      <th>Quantity</th>
      <th>Unit Price</th>
      <th>Balance</th>
      <th *ngIf="!_showHistory"></th>
    </tr>
  </thead>
  <tbody *ngIf="debits?.length != 0">
    <tr *ngFor="let row of debits">
      <td>
        <input id="{{row.financialTransactionId}}"
          *ngIf="!row.hasBeenFullyPaid && !row.hasBeenPartialPaid && !row.hasBeenVoided && row.transactionType != 'Void' && !isInvoiceClosed()"
          type="checkbox" class="form-control checks" (change)="applySelect()" />
      </td>
      <td>
        <span>{{row.transactionDate | date:"MM/dd/yyyy"}}</span>
      </td>
      <td>
        <span>{{row.patientName}}</span>
      </td>
      <td>
        <span>{{row.description}}</span>
        <p class="text-info void-text" *ngIf="row.hasBeenVoided">(Voided)</p>
        <p class="text-info void-text" *ngIf="row.paymentReversed">(The payment has been reversed)</p>
        <p class="text-info void-text" *ngIf="row.hasBeenFullyPaid">(This debit has been fully paid)</p>
        <p class="text-info void-text" *ngIf="row.hasBeenPartialPaid">(This debit has been partially paid)</p>
        <p class="text-danger" *ngIf="row.requiresAndMissingSpecimenId">(Missing Specimen ID)</p>
        <p class="text-danger" *ngIf="row.requiresAndMissingJobNumber">(Missing Job Number)</p>
        <p class="text-danger" *ngIf="row.requiresAndMissingPONumber">(Missing PO Number)</p>
      </td>
      <td>
        <span>{{row.branch}}</span>
      </td>
      <td>
        <span>{{row.transactionType}}</span>
      </td>
      <td>
        <span>{{row.quantity}}</span>
      </td>
      <td>
        <span>
          <displayable-money [model]="row.amount"></displayable-money>
        </span>
      </td>
      <td>
        <span>
          <displayable-money [model]="row.due"></displayable-money>
        </span>
      </td>
      <td *ngIf="!_showHistory">
        <div class="btn-group mr-1">
          <button class="btn btn-warning" (click)="showRequeueDebit(row)"
                  [disabled]="row.hasBeenFullyPaid || row.hasBeenPartialPaid || row.hasBeenVoided || row.transactionType == 'Void' || isInvoiceClosed()">
            Requeue
          </button>
          <button type="button" class="btn btn-warning dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false"
                  [disabled]="row.hasBeenFullyPaid || row.hasBeenPartialPaid || row.hasBeenVoided || row.transactionType == 'Void' || isInvoiceClosed()">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <button class="dropdown-item" type="button" data-toggle="modal" data-target="#repriceDebitsModal" (click)="repriceDebit(row)"
                    [disabled]="row.hasBeenFullyPaid || row.hasBeenPartialPaid || row.hasBeenVoided || row.transactionType == 'Void' || isInvoiceClosed()">
              Reprice
            </button>
          </div>
        </div>

        <button class="btn btn-danger mr-1" reallyClick="{{getVoidDebitMessage()}}" reallyClickAction="voidDebit"
          [disabled]="row.hasBeenVoided || row.hasBeenFullyPaid || row.hasBeenPartialPaid || isInvoiceClosed() || row.transactionType != 'Charge'"
          [reallyClickCaller]="this" [reallyClickArguments]="[row]">
          Void
        </button>
        <button
          [ngClass]="{'btn': true, 'mr-1':true, 'btn-danger':isDebitMissingRequiredInfo(row), 'btn-primary': !isDebitMissingRequiredInfo(row)}"
          (click)="openEditDebitModal(row)"><i class="fa fa-info-circle"></i></button>
        <button *ngIf="row.authorizationPatientId" class="btn btn-primary"
          (click)="openEditDebitDocumentCompositionModal(row)">Document Composition
        </button>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="debits?.length == 0">
    <tr>
      <td [colSpan]="_showHistory ? 8 : 7" class="no-data-available">No data!</td>
    </tr>
  </tbody>
</table>
<div class="modal fade" id="editdebitmodal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Edit Debit</h3>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right">Transaction Date:</label>
          </div>
          <div class="col-md-8">
            <p>{{editDebit.transactionDate | date:"MM/dd/yyyy"}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right">Patient:</label>
          </div>
          <div class="col-md-8">
            <p>{{editDebit.patientName}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right">Description:</label>
          </div>
          <div class="col-md-8">
            <p>{{editDebit.description}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalDisplayChargeCode">Display Charge Code:</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modalDisplayChargeCode"
              [(ngModel)]="editDebit.displayChargeCode" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalspecimenid">Specimen ID:<span
                *ngIf="editDebit.requiresAndMissingSpecimenId" class="required">*</span></label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modalspecimenid" [(ngModel)]="editDebit.specimenId" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modaljobnumber">Job Number:<span
                *ngIf="editDebit.requiresAndMissingJobNumber" class="required">*</span></label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modaljobnumber" [(ngModel)]="editDebit.jobNumber" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalponumber">Purchase Order #:<span
                *ngIf="editDebit.requiresAndMissingPONumber" class="required">*</span></label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modalponumber" [(ngModel)]="editDebit.poNumber" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalproviderNPI">Provider NPI:</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modalproviderNPI" [(ngModel)]="editDebit.providerNPI" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalnotes">Notes:</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="modalnotes" [(ngModel)]="editDebit.notes" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="pull-right text-label" for="modalModifier1">Modifier:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group">
              <input type="text" class="form-control" id="modalModifier1" [(ngModel)]="editDebit.modifier1" />
              <span class="input-group-addon"></span>
              <input type="text" class="form-control" id="modalModifier2" [(ngModel)]="editDebit.modifier2" />
              <span class="input-group-addon"></span>
              <input type="text" class="form-control" id="modalModifier3" [(ngModel)]="editDebit.modifier3" />
              <span class="input-group-addon"></span>
              <input type="text" class="form-control" id="modalModifier4" [(ngModel)]="editDebit.modifier4" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="saveAndCloseEditDebitModal()">
          Save
        </button>
        <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="closeEditDebitModal()">Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="requeuedebitmodal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Requeue Debit</h3>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right">Transaction Date:</label>
          </div>
          <div class="col-md-8">
            <p>{{requeueDebit.transactionDate | date:"MM/dd/yyyy"}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right">Patient:</label>
          </div>
          <div class="col-md-8">
            <p>{{requeueDebit.patientName}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="pull-right">Description:</label>
          </div>
          <div class="col-md-8">
            <p>{{requeueDebit.description}}</p>
          </div>
        </div>
        <p>Are you sure you want to requeue this debit? This will void the debit and reapply a payor and price. You can
          override those below.</p>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field style="width: 100%">
              <mat-label>Date of Service</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="requeueServiceDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="payor">Payor</label>
              </div>
              <input type="text" class="form-control" id="payor" name="payor" value="{{requeuePayor?.name}}" disabled />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-quantity">Quantity</span>
              </div>
              <input autocomplete="off" type="number" class="form-control" aria-label="Quantity"
                aria-describedby="span-quantity" name="quantity" [ngModel]="requeueQuantity" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-price">Price</span>
              </div>
              <input autocomplete="off" id="price" type="number" class="form-control" name="number" aria-label="Price"
                aria-describedby="span-price" [(ngModel)]="requeueAmount">
              <div class="input-group-append">
                <span class="input-group-text">$</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-grouping-key">Grouping Key</span>
              </div>
              <input autocomplete="off" id="groupingKey" type="text" class="form-control" aria-label="Grouping Key"
                aria-describedby="span-grouping-key" name="text" [(ngModel)]="requeueGroupingKey">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-job-number">Job Number</span>
              </div>
              <input autocomplete="off" id="jobNumber" type="text" class="form-control" aria-label="Job Number"
                aria-describedby="span-job-number" [(ngModel)]="requeueJobNumber">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-po-number">Purchase Order #</span>
              </div>
              <input autocomplete="off" id="poNumber" type="text" class="form-control" aria-label="Purchase Order #"
                aria-describedby="span-po-number" [(ngModel)]="requeuePoNumber">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="!this.parentDisableRequeue && !this.disableRequeue" class="btn btn-warning" type="button" (click)="startRequeueDebit()">
          Requeue
        </button>
        <span *ngIf="this.parentDisableRequeue || this.disableRequeue" class="btn btn-warning disabled">
          Requeue
        </span>
        <button class="btn btn-default" type="button" data-dismiss="modal" (click)="resetRequeue()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="requeueSelectedModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Requeue Invoice</h3>
        <p>Are you sure you want to requeue all selected transactions?
        </p>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field style="width: 100%">
              <mat-label>Service Date</mat-label>
              <input matInput [matDatepicker]="picker2" [(ngModel)]="requeueServiceDate">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="payor">Payor</label>
              </div>
              <input type="text" class="form-control" id="input-payor" name="payor" value="{{requeuePayor?.name}}"
                disabled />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-job-number-selected-debits">Job Number</span>
              </div>
              <input autocomplete="off" id="jobNumberSelectedDebits" type="text" class="form-control"
                aria-label="Job Number" aria-describedby="span-job-number-selected-debits"
                [(ngModel)]="requeueJobNumber">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-po-number-selected-debits">Purchase Order #</span>
              </div>
              <input autocomplete="off" id="poNumberSelectedDebits" type="text" class="form-control"
                aria-label="Purchase Order #" aria-describedby="span-po-number-selected-debits"
                [(ngModel)]="requeuePoNumber">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button *ngIf="!this.parentDisableRequeue && !this.disableRequeue" class="btn btn-warning" type="button" (click)="requeueSelected()">Requeue</button>
          <span *ngIf="this.parentDisableRequeue || this.disableRequeue" class="btn btn-warning disabled">Requeue</span>
          <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="resetRequeue()">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal fade" id="voidSelectedModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p>Are you sure you want to void all select transactions?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="voidSelected()">Confirm</button>
        <button class="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<billing-document-composition #billingDocumentModal></billing-document-composition>
