export class CollectionCallDto {
  payorId: number;
  payor: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  callOutcome: string;
  callbackDate: Date;
  dateOccurred: Date;
  offsetInMinutes: number;
  currentNotes: string;
  pastCalls: CollectionCallLogDto[] = [];
  currentInvoices: CollectionCallLogInvoiceDto[] = [];
}

export class CollectionCallLogDto {
  collectionCallId: number;
  date: Date;
  time: Date;
  notes: string;
  callOutcomeDisplay: string;
  invoices: CollectionCallLogInvoiceDto[] = [];
}

export class CollectionCallLogInvoiceDto {
  collectionCallLogInvoiceId: number;
  dueAtTimeOfCall: number;
  amount: number;
  due: number;
  invoiceId: number;
  invoiceNumberDisplay: string;
  startDate: Date;
  endDate: Date;
  billingEntity: string;
  payorId: number;
  payor: string;
  employerId: number;
  employer: string;
  regionId: number;
  region: string;
  jobNumber: string;
  purchaseOrder: string;
  testReasonType: string;
  facilityId: number;
  facility: string;
  authorizationPatientId: number;
}
