<div class="row">
  <div class="col-md-12">
    <h1>Payor Statements</h1>
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <br/>
    <div class="form-group">
      <mat-form-field class="w-100">
        <mat-label>Date<span class="text-danger">*</span></mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="payor">Payor<span class="text-danger">*</span></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <input type="text" class="form-control" id="payor" name="payor" [(ngModel)]="payor.name" disabled/>
        <div class="input-group-append">
          <button class="btn btn-warning" type="button" (click)="clearPayor()">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <payor-lookup #payorLookup [params]="payorParams" (payorChange)="setPayor($event)"></payor-lookup>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="employer">Employer</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#employerLookupModal">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <input type="text" class="form-control" id="employer" name="employer" [(ngModel)]="employer.employerName"
               disabled/>
        <div class="input-group-append">
          <button class="btn btn-warning" type="button" (click)="clearEmployer()">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <employer-lookup #employerLookup [params]="employerParams" (employerChange)="setEmployer($event)"></employer-lookup>
  </div>
  <div class="col-md-3">
    <label class="control-label">Region</label>
    <br/>
    <select class="dropdown form-control" name="region" [(ngModel)]="region"
            [attr.disabled]="!employer.employerId ? 'disabled' : null">
      <option [ngValue]="null"></option>
      <option *ngFor="let option of regions" [ngValue]="option">{{option.companyName}}</option>
    </select>
  </div>
</div>
<div class="row">
  <div class="col-md-6"></div>
  <div class="col-md-6 text-right">
    <button class="btn btn-warning mr-3" (click)="generateStatements(true)"
            [attr.disabled]="!canSave ? 'disabled' : null">
      <i class="fa fa-download"></i>
      Generate Non-Detailed Statements
    </button>
    <button class="btn btn-success mr-3" (click)="generateStatementsCSV()"
            [attr.disabled]="!canSave ? 'disabled' : null">
      <i class="fa fa-download"></i>
      Generate Statements CSV
    </button>
    <button class="btn btn-success" (click)="showRequirementsListed()"
            [attr.disabled]="!canSave ? 'disabled' : null">
<!--    <button class="btn btn-success" (click)="generateStatements()"-->
<!--       [attr.disabled]="!canSave ? 'disabled' : null">-->
      <i class="fa fa-download"></i>
      Generate Statements
    </button>
  </div>
</div>
<div class="modal fade" id="requirementsListed" tabindex="-1" role="dialog">
  <div class="modal-dialog  modal-m" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Generate Statements</h3>
        <div class="modal-footer">
          <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="generateStatements()">Download PDF
          </button>
          <button class="btn btn-success" type="button" data-dismiss="modal" (click)="showEmailDialog()">Send Email
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="modal fade" id="generatestatementsmodal" tabindex="-1" role="dialog">
      <div class="modal-dialog  modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h3>Generate Statements</h3>
<!--            <p [textContent]="submitInvoiceText"></p>-->
            <div>
              <mat-form-field appearance="fill" class="w-50">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="emailModel.extrasEmail">
              </mat-form-field>
            </div>
            <div class="form-group">
              <h4>Email Message</h4>
              <textarea name="notesInternal" rows="15"  class="form-control" [(ngModel)]="emailModel.extrasContent"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="sendStatementEmail()">Send Email
            </button>
            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
