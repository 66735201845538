import {Component} from '@angular/core';
import {ValidationResultsService} from "../../../services/validation-results.service";

@Component({
  selector: 'validation-results',
  templateUrl: './validation-results.component.html',
  styleUrls: ['./validation-results.component.scss']
})
export class ValidationResultsComponent {

  constructor(
    public validationResultsService: ValidationResultsService) {
  }

  hideSuccessMessages() {
    this.validationResultsService.successMessages = [];
  }

  hideErrorMessages() {
    this.validationResultsService.isPermissionError = true;
    this.validationResultsService.errorMessages = [];
  }

  hideWarningMessages() {
    this.validationResultsService.warningMessages = [];
  }

  hideInfoMessages() {
    this.validationResultsService.infoMessages = [];
  }
}
