<div class="row show-history-banner text-center" *ngIf="showHistory">
  <div class="col-md-12">
    <h4>*The full invoice history for invoice #{{model.invoiceNumber}} is being displayed*</h4>
  </div>
</div>
<div class="row show-requirements-banner text-center"
     *ngIf="model.chainOfCustody || model.authorization || model.medicalRecords">
  <div class="col-md-12">
    <h4>{{getRequirementsMessage()}}</h4>
  </div>
</div>
<div class="row show-requirements-banner text-center" *ngIf="model.requiresManualSubmission">
  <div class="col-md-12">
    <h4>Payor requires manual review before submission.</h4>
  </div>
</div>
<div class="row show-requirements-banner text-center"
     *ngIf="showSplitByPatientAuthorizationMessage">
  <div class="col-md-12">
    <h3>
      Invoice should be requeued after Payor is set to Split by Patient Visit.
      <button class="btn btn-link" type="button" (click)="showUnableToPrintCMS1500Modal()">Details</button>
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <h2>Invoice #{{model.invoiceNumberDisplay}}</h2>
  </div>
  <div class="col-md-3">
    <h2>Payor: {{model.payor}}</h2>
  </div>
  <div class="col-md-3">
    <div [hidden]="!model.vendorId">
      <h2>Vendor ID: {{model.vendorId}} </h2>
    </div>
  </div>
  <div class="col-md-3">
    <h2>Balance:
      <displayable-money class="pull-right" [model]="model.balance"></displayable-money>
    </h2>
  </div>
</div>
<div class="row form-check" *ngIf="model.latestRevision">
  <input type="checkbox" class="form-check-input" id="showHistoryBox" [(ngModel)]="showHistory"
         (ngModelChange)="load()"/>
  <label for="showHistoryBox" class="form-check-label">Show History</label>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-center">
    <button class="btn btn-warning mr-1"
            reallyClick="Are you sure you want to create adjustment batch?"
            reallyClickAction="applyPayment"
            [reallyClickCaller]="this"
            [reallyClickArguments]="">
      Apply Payment
    </button>

    <div class="dropdown d-inline-block">
      <button class="btn btn-primary mr-1 dropdown-toggle" type="button" id="invoiceUpdateMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Update...
      </button>
      <div class="dropdown-menu" aria-labelledby="invoiceUpdateMenuButton">
        <a class="dropdown-item" href="#"
           reallyClick="Are you sure you want to update the metadata (Payor and Contact Info) with current values?"
           reallyClickAction="startUpdateInvoiceMetadata"
           [reallyClickCaller]="this"
           [reallyClickArguments]="">
          Update metadata
        </a>
        <a class="dropdown-item" (click)="updateJobNumber()">Update job number for all debits</a>
        <a class="dropdown-item" (click)="updatePoNumber()">Update PO number for all debits</a>
      </div>
    </div>

    <div *ngIf="!showHistory" class="mr-1" style="display: inline-block">
      <div class="btn-group">
        <button class="btn btn-warning" [disabled]="isInvoiceClosed()" (click)="showRequeueInvoice()">
          Requeue All Debits
        </button>
        <button type="button" class="btn btn-warning dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="isInvoiceClosed()">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" data-toggle="modal" data-target="#repriceDebitsModal" (click)="addAllDebitsToRepriceDto()">Reprice All Debits</button>
        </div>
      </div>
    </div>

    <div class="mr-1" style="display: inline-block">
      <div class="btn-group">
        <button type="button" class="btn btn-primary" (click)="printDefault($event)">Print
          Invoice
        </button>
        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" (click)="printDefault($event)">Default Format</button>
          <button class="dropdown-item" type="button" (click)="checkIfAllowedToPrintInvoicePdfCMS1500($event)">CMS
            1500 Claim Form
          </button>
          <button class="dropdown-item" type="button" (click)="checkIfAllowedToPrintInvoicePdfCommercial1500($event)">Commercial 1500 Claim form
          </button>
          <button class="dropdown-item" type="button" (click)="printConsolidated()">Consolidated Format</button>
          <button class="dropdown-item" type="button" (click)="printNonDetailed()">Non-Detailed Format</button>
          <button class="dropdown-item" type="button" (click)="printInvoice()">Voids-Included Format</button>
          <button class="dropdown-item" *ngIf="isShowChargeOnly" type="button"
                  (click)="printChargeOnly()">Charge-Only Format</button>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mr-1" *ngIf="model.invoiceStatus ==  invoiceStatus.open && !showHistory"
            [reallyClick]="submitInvoiceText"
            reallyClickAction="showSubmitInvoice"
            [reallyClickCaller]="this"
            [reallyClickArguments]="">
      Submit Invoice
    </button>
    <button class="btn btn-primary mr-1" *ngIf="model.invoiceStatus != invoiceStatus.open" (click)="showResubmitInvoice()">
      Resubmit Invoice
    </button>
    <button class="btn btn-danger mr-1" *ngIf="model.invoiceStatus == invoiceStatus.closed && !showHistory"
            reallyClick="Are you sure you want to open this invoice?"
            reallyClickAction="openInvoice"
            [reallyClickCaller]="this"
            [reallyClickArguments]="">
      Open Invoice
    </button>
    <button class="btn btn-danger mr-1" *ngIf="model.canCloseInvoice && !showHistory"
            reallyClick="Are you sure you want to close this invoice?"
            reallyClickAction="closeInvoice"
            [reallyClickCaller]="this"
            [reallyClickArguments]="">
      Close Invoice
    </button>

    <div class="mr-1" style="display: inline-block">
      <div class="btn-group">
        <button type="button" class="btn btn-primary" (click)="printCsv()">Download CSV</button>
        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" (click)="printCsv()">Default Format</button>
          <div role="separator" class="dropdown-divider"></div>
          <button class="dropdown-item" type="button" (click)="printConsolidatedCsv()">Consolidated Format</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <ul id="child-tabs" class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" href="#summary" aria-controls="summary" role="tab" data-toggle="tab"
           aria-selected="true">Summary</a>
      </li>
      <li class="nav-item">
        <a id="a-debits" class="nav-link" href="#debits" aria-controls="debits" role="tab" data-toggle="tab"
           aria-selected="false">Debits</a>
      </li>
      <li class="nav-item">
        <a id="a-ledger" class="nav-link" href="#ledger" aria-controls="ledger" role="tab" data-toggle="tab"
           aria-selected="false">Ledger</a>
      </li>
      <li class="nav-item">
        <a id="a-debit-details" class="nav-link" href="#debitDetail" aria-controls="debitDetail" role="tab"
           data-toggle="tab"
           aria-selected="false">Debit Detail</a>
      </li>
      <li class="nav-item">
        <a id="a-notes" class="nav-link" href="#notes" aria-controls="notes" role="tab" data-toggle="tab"
           aria-selected="false">Notes</a>
      </li>
      <li class="nav-item">
        <a id="a-history" class="nav-link" href="#history" aria-controls="history" role="tab" data-toggle="tab"
           aria-selected="false">History</a>
      </li>
      <li class="nav-item" [style.display]="isShowClaim ? 'block' : 'none'">
        <a id="a-claim" class="nav-link" href="#claim" aria-controls="claim"
           role="tab" data-toggle="tab" aria-selected="false">Claim</a>
      </li>
    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane fade show active" id="summary" aria-labelledby="summary-tab">
        <invoice-detail-summary [showHistory]="showHistory" [invoiceId]="invoiceId"></invoice-detail-summary>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="debits" aria-labelledby="debits-tab" style="margin-top: 15px">
        <ng-template invoice-detail-debits-host></ng-template>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="ledger" aria-labelledby="ledger-tab" style="margin-top: 15px">
        <ng-template invoice-detail-ledger-host></ng-template>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="debitDetail" aria-labelledby="debitDetail-tab"
           style="margin-top: 15px">
        <ng-template invoice-detail-debit-details-host></ng-template>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="notes" aria-labelledby="notes-tab" style="margin-top: 15px">
        <ng-template invoice-detail-notes-host></ng-template>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="history" aria-labelledby="history-tab" style="margin-top: 15px">
        <ng-template invoice-detail-history-host></ng-template>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="claim"
           aria-labelledby="claim-tab" style="margin-top: 15px">
        <ng-template invoice-detail-claim-host></ng-template>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="repriceDebitsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Reprice Debits</h3>
        <p>
          Repricing debits looks up prices again for the selected debit(s) and applies the changes according to the price that was found.
          Then, this invoice's due amount and current balance will be recalculated.
          This does not change the status of the invoice unless the "close invoice if zero balance" option is checked. Also, this does not send out any emails to payors.
          You also have the option to customize the price date because prices for the same charge code might be different depending on when the date the charge was serviced.
          Otherwise, the price date will default to the earliest service date of all selected transactions.
        </p>
        <div class="row">
          <div class="col">
            <mat-form-field style="width: 100%">
              <mat-label>Price Date</mat-label>
              <input matInput [matDatepicker]="repriceDatePicker" [(ngModel)]="repriceDto.priceDate">
              <mat-datepicker-toggle matSuffix [for]="repriceDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #repriceDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-reprice-close-invoice-checkbox">Close invoice if zero balance</span>
              </div>
              <input autocomplete="off" id="repriceCloseInvoiceZeroBalanceCheckbox" type="checkbox" class="form-control"
                     aria-label="Close invoice if zero balance checkbox" aria-describedby="span-reprice-close-invoice-checkbox"
                     [(ngModel)]="repriceDto.closeIfZeroBalance">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="repriceDebits()"
                [disabled]="repriceDto.financialTransactionIds.length === 0 || this.disableRequeue || this.debitsComponent?.disableRequeue"
        >
          <span *ngIf="repriceDto.financialTransactionIds.length === 0" class="fa fa-circle-notch fa-spin mr-1"></span>
          Reprice
        </button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="requeueinvoicemodal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Requeue Invoice</h3>
        <p>Are you sure you want to requeue this invoice? This will void all applicable debits and reapply prices to
          them based on current configuration. You can override prices below.
          Please note that requeued charges may not reflect immediately. It will take a while to generate new charges.
        </p>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field style="width: 100%">
              <mat-label>Service Date</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="requeueServiceDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="payor">Payor</label>
              </div>
              <input type="text" class="form-control" id="payor" name="payor" value="{{requeuePayor?.name}}" disabled/>
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-job-number-all-debits">Job Number</span>
              </div>
              <input autocomplete="off" id="jobNumberAllDebits" type="text" class="form-control"
                     aria-label="Job Number" aria-describedby="span-job-number-all-debits"
                     [(ngModel)]="requeueJobNumber">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="span-po-number-all-debits">Purchase Order #</span>
              </div>
              <input autocomplete="off" id="poNumberAllDebits" type="text" class="form-control"
                     aria-label="Purchase Order #" aria-describedby="span-po-number-all-debits"
                     [(ngModel)]="requeuePoNumber">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="!this.disableRequeue && !this.debitsComponent?.disableRequeue" class="btn btn-warning" type="button" (click)="startRequeueInvoice()">Requeue
        </button>
        <span *ngIf="this.disableRequeue || this.debitsComponent?.disableRequeue" class="btn btn-warning disabled">Requeue</span>
        <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="resetRequeue()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="unableToPrintCMS1500Modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Unable to print {{claimFormType}} 1500 Claim Form</h3>
        <p>
          This invoice was not split by Patient Visit, and so cannot be printed to the {{claimFormType}} 1500 form.
          In order to print as a {{claimFormType}} 1500, configure the payor "<a
          href="#/payor-entry/{{model.payorID}}">{{model.payor}}</a>"
          to split by Patient Visit and then Requeue this invoice so that is it regenerated as one invoice per patient.
        </p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-default" type="button" data-dismiss="modal" id="unableToPrintCMS1500ModalDismiss">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="resubmitinvoicemodal" tabindex="-1" role="dialog">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Resubmit Invoice</h3>
        <p [textContent]="submitInvoiceText"></p>
        <div>
          <mat-form-field appearance="fill" class="w-50">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="emailModel.extrasEmail">
          </mat-form-field>
        </div>
        <div class="form-group">
          <h4>Email Message</h4>
          <textarea name="notesInternal" rows="15"  class="form-control" [(ngModel)]="emailModel.extrasContent"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="resubmitInvoice()">Resubmit Invoice
        </button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="submitinvoicemodal" tabindex="-1" role="dialog">
  <div class="modal-dialog  modal-m" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-body">
          <p>
            There are some required fields in the claim page. If you want to download the 837p file, please complete it
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="gotoClaim()">Go to fill required
            fields</button>
        </div>
      </div>
    </div>
  </div>
</div>
<payor-lookup (payorChange)="setPayorOverride($event)"></payor-lookup>
