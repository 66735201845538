import {Component, Input, OnInit} from '@angular/core';
import {BillingHttpClient} from '../../../../common/billing-http-client.service';
import {Router} from '@angular/router';
import {ChargeCodePriceRow} from "../../../../models/charge-code-price-list/charge-code-price-row";
import {ChargeCodePriceUtility} from "../../../../utilities/charge-code-price-utility";
import {ClinicHttpClient} from "../../../../common/clinic-http-client";
import {CommonResult} from "../../../../models/common/common-result";
import {HttpParams} from "@angular/common/http";
import {ValidationResultsService} from "../../../../services/validation-results.service";

@Component({
  selector: 'app-charge-code-entry-pricing',
  templateUrl: './charge-code-entry-pricing.component.html',
  styleUrls: ['./charge-code-entry-pricing.component.scss']
})
export class ChargeCodeEntryPricingComponent implements OnInit {

  private _model: ChargeCodePriceRow[];
  @Input() set model(value: ChargeCodePriceRow[]) {
    this._model = value;
    if (value && value.length > 0) {
      ChargeCodePriceUtility.getChargeCodePriceDetails(this.clinicHttpClient, value);
    }
  };

  get model(): ChargeCodePriceRow[] {
    return this._model;
  }

  constructor(private billingHttpClient: BillingHttpClient,
              private clinicHttpClient: ClinicHttpClient,
              private router: Router,
              private validationResultsService: ValidationResultsService) {
  }

  ngOnInit(): void {
  }

  addDefaultPrice() {
    this.router.navigate(['price']);
  }

  editPrice(id: number) {
    this.router.navigate(['price', id]);
  }

  deletePrice(id: number) {
    const params = new HttpParams().set('id', id.toString());
    this.billingHttpClient.Delete<CommonResult>('/charge-code-price', {params})
      .subscribe(response => {
        if (response.result == 'success') {
          this._model.splice(this._model.findIndex(p => p.id = id), 1);
          this.validationResultsService.setSuccessMessages(["Delete succeeded"]);
        } else {
          this.validationResultsService.setErrorMessages(response.validationResults);
        }
      })
  }
}
