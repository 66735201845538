import {BranchPayorChargeCodeListItemDto} from './branch-payor-charge-code-list-item-dto';
import {BranchPayorVisitReasonListItemDto} from './branch-payor-visit-reason-list-item-dto';

export class BranchPayorEntryResponsibilitiesDto {
  chargeCodePayorAssociationMode: string;
  visitReasonPayorAssociationMode: string;
  chargeCodeAndVisitReasonBinaryMode: string;
  branchPayorChargeCodes: BranchPayorChargeCodeListItemDto[] = [];
  branchPayorVisitReasons: BranchPayorVisitReasonListItemDto[] = [];
}
