<h1>Debits (Pending)</h1>
<div class="row">
  <div class="col-md-3">
    <button type="button" class="btn btn-success" (click)="navigateToChargeEntry()">
      <i class="fas fa-plus"></i><span style="margin-left: 5px;">Create Patient Debit</span>
    </button>
  </div>
  <div class="col-md-3">
    <button type="button" class="btn btn-success" (click)="navigateToEmployerChargeEntry()">
      <i class="fas fa-plus"></i><span style="margin-left: 5px;">Create Employer Debit</span>
    </button>
  </div>
  <div class="col-md-3">
    <button type="button" class="btn btn-success" (click)="navigateToPayorChargeEntry()">
      <i class="fas fa-plus"></i><span style="margin-left: 5px;">Create Payor Debit</span>
    </button>
  </div>
  <div class="col-md-3">
    
  </div>
</div>
<div class="row mt-5">
</div>

<div class="row">
  <div class="input-group col-md-2 ml-auto mr-3">
    <input id="input-search" type="text" class="form-control" placeholder="search" [(ngModel)]="searchStr" aria-label="Search" aria-describedby="Search">
    <div class="input-group-append">
      <button class="btn btn-success" type="button" (click)="search()"><i class="fas fa-search"></i></button>
    </div>
  </div>
</div>

<table id="tb-pending-debits-and-adjustments" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered"
       style="width: 100%">
  <thead>
  <tr>
    <th></th>
    <th>ID</th>
    <th>Patient</th>
    <th>Employer</th>
    <th>Region</th>
    <th>Job Site</th>
    <th>Job #</th>
    <th>Facility</th>
    <th>Branch</th>
    <th>Provider</th>
    <th>Service Date</th>
    <th>Type</th>
    <th>Charge Code</th>
    <th>Qty.</th>
    <th>P.O.</th>
    <th>Status</th>
    <th>AP #</th>
    <th>Grouping Key</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model">
    <td>
      <div style="white-space: nowrap">
        <button class="btn btn-success mr-1" (click)="viewDebit(row)">
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn btn-success mr-1" (click)="viewHistory(row)">
          <i class="fas fa-info-circle"></i>
        </button>
      </div>
    </td>
    <td>{{row.financialTransactionPendingImportId}}</td>
    <td>{{row.patient}}</td>
    <td>{{row.employer}}</td>
    <td>{{row.region}}</td>
    <td>{{row.jobSite}}</td>
    <td>{{row.jobNumber}}</td>
    <td>{{row.facility}}</td>
    <td>{{row.branch}}</td>
    <td>{{row.provider}}</td>
    <td>{{row.serviceDate | date:"MM/dd/yyyy"}}</td>
    <td>{{row.type}}</td>
    <td>{{row.chargeCode}}</td>
    <td>{{row.quantity}}</td>
    <td>{{row.purchaseOrder}}</td>
    <td>{{row.status}}</td>
    <td>{{row.authorizationPatientId}}</td>
    <td>{{row.groupingKey}}</td>
  </tr>
  </tbody>
</table>
<price-search-history-popup [history]="priceHistory"></price-search-history-popup>
