<div class="row" style="position: relative">
  <div class="col-md-12">
    <label class="control-label">Control Total Type</label>
    <br/>
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <select class="dropdown form-control input-sm" name="controlTotalType" [(ngModel)]="controlTotalType"
                  (ngModelChange)="controlTotalTypeChange()" [attr.disabled]="!editable ? 'disabled' : null">
            <option [ngValue]="null">-Select Type-</option>
            <option *ngFor="let option of financialTransactionTypeOptions"
                    [ngValue]="option">{{option}}</option>
          </select>
          <span class="input-group-btn ml-3">
            <button [attr.disabled]="!editable ? 'disabled' : null" *ngIf="controlTotalType != null" type="button"
                    class="btn btn-success btn-sm"
                    (click)="applyControlTotalToAllInvoices()">Smart Apply {{controlTotalType}}
              <i class="fa fa-angle-double-right" aria-hidden="true"></i></button>
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="control-totals-container">
    <h4 class="float-right"><a>Control Totals</a></h4>
    <table
      class="float-right table table-bordered table-striped table-responsive table-condensed table-hover control-totals-table">
      <thead>
      <tr>
        <th>Type</th>
        <th>Total</th>
        <th>Remaining</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let controlTotal of controlTotals">
        <td>{{controlTotal.financialTransactionType}}</td>
        <td>
          <displayable-money [model]="controlTotal.total"></displayable-money>
        </td>
        <td>
          <displayable-money [model]="controlTotal.remaining"></displayable-money>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <hr class="divider"/>
  </div>
</div>
<div class="d-flex justify-content-center">
  <mat-progress-spinner mode="indeterminate" *ngIf="model.length == 0">
  </mat-progress-spinner>
</div>
<div class="row">
  <div class="col-md-12">
    <apply-invoices-adjustments #invoices
                                [model]="model"
                                [disabled]="!editable"
                                [financialTransactionTypeOptions]="financialTransactionTypeOptions"
                                [controlTotalType]="controlTotalType"
                                (updateControlTotal)="updateControlTotal($event)"
                                (smartApplyUpdate)="handleSmartApplyUpdate($event)"></apply-invoices-adjustments>
  </div>
</div>
