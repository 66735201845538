import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { ControlTotalUpdate, SmartApplyAdjustmentUpdate, SmartApplyType } from '../apply-adjustments.component';
import { AdjustmentBatchDataService } from "../../../../../services/adjustment-batch.data.service";
import { AdjustmentCreatorComponent } from "./adjustment-creator/adjustment-creator.component";
import { ApplyAdjustmentInvoiceDto } from "../../../../../models/adjustment-batch/apply-adjustments/apply-adjustment-invoice-dto";
import { AdjustmentDto } from "../../../../../models/adjustment-batch/apply-adjustments/apply-invoices-adjustments/adjustments/adjustment-dto";
import { ApplyAdjustmentDebitDto } from "../../../../../models/adjustment-batch/apply-adjustments/apply-adjustment-debit-dto";

@Component({
  selector: 'apply-invoices-adjustments',
  templateUrl: './apply-invoices-adjustments.component.html',
  styleUrls: ['./apply-invoices-adjustments.component.scss'],
})
export class ApplyInvoicesAdjustmentsComponent implements OnInit, OnDestroy {

  @Input() model: ApplyAdjustmentInvoiceDto[];
  @Input() disabled: boolean;
  @Input() financialTransactionTypeOptions: string[];
  @Input() controlTotalType: string;
  @Output() updateControlTotal: EventEmitter<ControlTotalUpdate> = new EventEmitter<ControlTotalUpdate>();
  @Output() smartApplyUpdate: EventEmitter<SmartApplyAdjustmentUpdate> = new EventEmitter<SmartApplyAdjustmentUpdate>();
  @ViewChildren("adjustmentCreator") adjustmentCreators: QueryList<AdjustmentCreatorComponent>;
  allCollapsed: boolean = false;
  collapsedMap = new Map();

  constructor(private adjustmentBatchDataService: AdjustmentBatchDataService) {
  }

  ngOnInit() {
    this.modelUpdatedCallback();
  }

  modelUpdatedCallback() {
    console.log(this.model)
    if (!this.disabled) {
      _.forEach(this.model, (invoice) => {
        invoice.debits.sort((a, b) => {
          if (a.patientName < b.patientName) {
            return -1;
          }
          if (a.patientName > b.patientName) {
            return 1;
          }
          if (a.description < b.description) {
            return -1;
          }
          if (a.description > b.description) {
            return 1;
          }
          return 0;
        })
        this.updateInvoiceBalance(invoice);
        _.forEach(invoice.debits, (debit) => {
          this.updateDebitBalance(debit);
        });
        this.collapsedMap.set(invoice.invoiceId, false);
      });
    } else {
      _.forEach(this.model, (invoice) => {
        this.collapsedMap.set(invoice.invoiceId, false);
      });
    }
  }

  ngOnDestroy(): void {
    this.adjustmentBatchDataService.confirmInvoices(this.model);
  }

  toggleCollapse(invoiceId: number) {
    this.collapsedMap.set(invoiceId, !this.collapsedMap.get(invoiceId));
  }

  toggleAllCollapse() {
    this.allCollapsed = !this.allCollapsed;
    this.collapsedMap.forEach((_, key, map) => {
      map.set(key, this.allCollapsed);
    });
  }

  addInvoiceAdjustment(adjustment: AdjustmentDto, invoice: ApplyAdjustmentInvoiceDto) {
    this.addAdjustment(adjustment, invoice);
    this.updateInvoiceBalance(invoice);
    this.updateControlTotals(adjustment.financialTransactionType, adjustment.amount);
  }

  addDebitAdjustment(adjustment: AdjustmentDto, invoice: ApplyAdjustmentInvoiceDto, debit: ApplyAdjustmentDebitDto) {
    this.addAdjustment(adjustment, debit);
    this.updateInvoiceBalance(invoice);
    this.updateDebitBalance(debit);
    this.updateControlTotals(adjustment.financialTransactionType, adjustment.amount);
  }


  addAdjustment(adjustment: AdjustmentDto, owner: any) {
    const existingAdjustment = _.find(owner.adjustments, (value) => {
      return value.financialTransactionType == adjustment.financialTransactionType;
    });
    if (existingAdjustment != null) {
      // Adjustments should have the opposite sign of the charge/credit amount
      existingAdjustment.amount += adjustment.amount;
      if (existingAdjustment.amount == 0) {
        this.removeAdjustment(owner, existingAdjustment);
      }
    } else {
      owner.adjustments.push(adjustment);
    }
    owner.adjustments = _.orderBy(owner.adjustments, "financialTransactionType");
  }

  updateInvoiceBalance(invoice: ApplyAdjustmentInvoiceDto) {
    let originalBalance = invoice.balance;
    invoice.updatedBalance = originalBalance;
    let allInvoiceDebitAdjustments = _.flatten(_.map(invoice.debits, (value) => {
      return value.adjustments || [];
    }));
    let allInvoiceAdjustments = _.union(invoice.adjustments || [], allInvoiceDebitAdjustments);
    let sum = _.sumBy(allInvoiceAdjustments, (value) => {
      return value.amount;
    });
    // the sum is negative values
    invoice.updatedBalance = originalBalance + sum;
  }

  updateDebitBalance(debit: ApplyAdjustmentDebitDto) {
    let originalBalance = debit.balance;
    let sum = _.sumBy(debit.adjustments, (value) => {
      return value.amount;
    });
    debit.updatedBalance = originalBalance + sum;
  }

  removeInvoiceAdjustment(adjustment: AdjustmentDto, invoice: ApplyAdjustmentInvoiceDto,) {
    this.removeAdjustment(adjustment, invoice);
    this.updateInvoiceBalance(invoice);
    this.updateControlTotals(adjustment.financialTransactionType, -adjustment.amount);
  }

  removeDebitAdjustment(adjustment: AdjustmentDto, invoice: ApplyAdjustmentInvoiceDto, debit: ApplyAdjustmentDebitDto) {
    this.removeAdjustment(adjustment, debit);
    this.updateInvoiceBalance(invoice);
    this.updateDebitBalance(debit);
    this.updateControlTotals(adjustment.financialTransactionType, -adjustment.amount);
  }

  removeAdjustment(adjustment: AdjustmentDto, owner: any) {
    _.remove(owner.adjustments, (value) => {
      return value.financialTransactionType == adjustment.financialTransactionType;
    });
  }

  updateControlTotals(financialTransactionType: string, value: number) {
    this.updateControlTotal.emit({
      financialTransactionType: financialTransactionType,
      value: value
    })
  }

  updateAdjustmentCreatorSelections(option: string) {
    this.adjustmentCreators.forEach((creator, _, __) => {
      creator.setFinancialTransactionTypeOption(option);
    });
  }

  smartApplyAdjustmentToInvoiceDebits(option: string, invoice: ApplyAdjustmentInvoiceDto) {
    let update: SmartApplyAdjustmentUpdate = {
      debit: null,
      invoice: invoice,
      option: option,
      type: SmartApplyType.invoiceDebits
    };
    this.smartApplyUpdate.emit(update);
  }

  smartApplyAdjustmentToInvoice(option: string, invoice: ApplyAdjustmentInvoiceDto) {
    let update: SmartApplyAdjustmentUpdate = {
      debit: null,
      invoice: invoice,
      option: option,
      type: SmartApplyType.invoice
    };
    this.smartApplyUpdate.emit(update);
  }

  smartApplyAdjustmentToDebit(option: string, invoice: ApplyAdjustmentInvoiceDto, debit: ApplyAdjustmentDebitDto) {
    let update: SmartApplyAdjustmentUpdate = {
      debit: debit,
      invoice: invoice,
      option: option,
      type: SmartApplyType.debit
    };
    this.smartApplyUpdate.emit(update);
  }
}
