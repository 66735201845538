<div class="mt-2">
  <mat-form-field appearance="fill" class="w-30">
    <mat-label>Code</mat-label>
    <input matInput [(ngModel)]="model.code">
  </mat-form-field>
</div>

<div>
  <mat-form-field appearance="fill" class="w-30">
    <mat-label>Description</mat-label>
    <input matInput [(ngModel)]="model.description">
  </mat-form-field>
</div>

<div class="form-group mt-2">
  <mat-form-field class="w-30">
    <mat-label>Start Date<span class="text-danger">*</span></mat-label>
    <input matInput [matDatepicker]="picker" name="startDate" [(ngModel)]="model.startDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>

<div class="form-group mt-2">
  <mat-form-field class="w-30">
    <mat-label>End Date<span class="text-danger">*</span></mat-label>
    <input matInput [matDatepicker]="picker1" name="endDate" [(ngModel)]="model.endDate">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
</div>

<div>
  <mat-checkbox class="bold" [(ngModel)]="model.requiresSpecimenId">Requires Specimen ID</mat-checkbox>
</div>
<div>
  <mat-checkbox class="bold" [(ngModel)]="model.allowDecimalQuantities">Allow Decimal Quantities</mat-checkbox>
</div>
<div class="right-align">
  <button class="btn btn-warning" (click)="cancel()">
    Return
  </button>
  <button class="btn btn-danger ml-2" (click)="delete()" [disabled]="!model.id">
    Delete
  </button>
  <button class="btn btn-success ml-2" (click)="saveClose()">
    Save and Close
  </button>
</div>
