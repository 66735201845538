import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClinicHttpClient} from "../../../common/clinic-http-client";
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {BranchEntryDto} from "../../../models/branch-entry/branch-entry-dto";
import {NgForm} from '@angular/forms';
import {takeUntil} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ValidationResultsService} from "../../../services/validation-results.service";
import {CommonResult} from "../../../models/common/common-result";
import {State, StateList} from "../../../utilities/state-list";

@Component({
  selector: 'branch-entry',
  templateUrl: './branch-entry.component.html',
  styleUrls: ['./branch-entry.component.scss']
})
export class BranchEntryComponent implements OnInit, OnDestroy {

  id: number;
  private ngUnsubscribe = new Subject();

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.id = +params['id'];
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
