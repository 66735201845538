<div class="container-fluid mt-2">
  <div>
    <mat-checkbox class="bold" [(ngModel)]="model.receivesStatements">Receives Statements</mat-checkbox>
  </div>
  <div>
    <mat-checkbox class="bold" [(ngModel)]="model.includeEmployerName">Include Employer Name</mat-checkbox>
  </div>
</div>

<div class="right-align">
  <button class="btn btn-warning mr-2" (click)="cancel()">
    <i class="fa fa-times"></i>
    Cancel
    <span class="spinner"><i class="icon-spin icon-refresh"></i></span>
  </button>
  <button class="btn btn-danger mr-2">
    <i class="fa fa-trash"></i>
    Delete Payor
  </button>
  <button class="btn btn-success mr-2" (click)="save()">
    <i class="fa fa-check-square-o"></i>
    Save
  </button>
  <button class="btn btn-success mr-2" (click)="saveClose()">
    <i class="fa fa-check-square-o"></i>
    Save and Close
  </button>
</div>
