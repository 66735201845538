import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InvoiceDetailLedgerItemDto} from "../../../../models/invoice-detail/ledger/invoice-detail-ledger-item-dto";
import {BillingHttpClient} from "../../../../common/billing-http-client.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {DataTableDirective} from "angular-datatables";
import {DataTablesResponse} from "../../../../models/common/data-tables-response";

@Component({
  selector: 'invoice-detail-ledger',
  templateUrl: './invoice-detail-ledger.component.html',
  styleUrls: ['./invoice-detail-ledger.component.scss']
})
export class InvoiceDetailLedgerComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  ledgerItems: InvoiceDetailLedgerItemDto[];
  invoiceId: number;
  _showHistory: boolean = false;
  set showHistory(value: boolean) {
    if (this._showHistory !== value) {
      this._showHistory = value;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }

  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private billingHttpClient: BillingHttpClient,
              private router: Router) {
  }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 100,
      serverSide: true,
      processing: true,
      searching: false,
      autoWidth: false,
      searchDelay: 2000,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          searchable: false,
          width: '3%',
        },
        {
          targets: 1,
          width: '11%',
          data: 'serviceDate',
        },
        {
          targets: 2,
          width: '11%',
          data: 'postedDate',
        },
        {
          targets: 3,
          width: '11%',
          data: 'patientName',
        },
        {
          targets: 4,
          width: '11%',
          data: 'description',
        },
        {
          targets: 5,
          width: '11%',
          data: 'branch',
        },
        {
          targets: 6,
          width: '11%',
          data: 'transactionType',
        },
        {
          targets: 7,
          width: '11%',
          data: 'quantity',
        },
        {
          targets: 8,
          width: '10%',
          data: 'debit',
        },
        {
          targets: 9,
          width: '10%',
          data: 'credit',
        },
        {
          targets: 10,
          width: '11%',
          data: 'due',
        },
      ],
      order: [[2, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.invoiceId = this.invoiceId.toString();
        dataTablesParameters.showHistory = String(this._showHistory);
        that.billingHttpClient.Post<DataTablesResponse>("/invoice-detail-ledger", dataTablesParameters)
          .subscribe(resp => {
            that.ledgerItems = resp.data as InvoiceDetailLedgerItemDto[];
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  onSelectLedger(financialTransactionPendingImportId: any, adjustmentBatchId: any, transactionType: any): void {
    if (financialTransactionPendingImportId != null && (transactionType == "Charge" || transactionType == "Void")) {
      this.router.navigate(['pending-debit-edit', financialTransactionPendingImportId])
    } else {
      this.router.navigate(['adjustment-batch', "1", adjustmentBatchId,-1,-1])
    }
  }

  isEditable(financialTransactionPendingImportID: any, adjustmentBatchID: any, transactionType: any): boolean {
    let result = false;

    if ((financialTransactionPendingImportID !== null && (transactionType == "Charge" || transactionType == "Void"))
      || (adjustmentBatchID != null && (transactionType != "Charge" && transactionType != "Void"))) {
      result = true;
    }
    return result;
  }


}
