<div class="row">
  <div class="col-md-12">
    <h1>Patient Debit Entry</h1>
    <hr class="divider"/>
  </div>
</div>
<form #form="ngForm">
  <div class="row">
    <div class="col-md-12">
      <h4>Patient</h4>
      <div>
        <table class="table">
          <thead>
          <tr>
            <th></th>
            <th>Authorization ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>ID (SSN)</th>
            <th>DOB</th>
            <th>Phone Number</th>
            <th>Company</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#patientLookupModal"><i
                class="fa fa-search"></i></button>
            </td>
            <td>{{patient.authorizationId}}</td>
            <td>{{patient.firstName}}</td>
            <td>{{patient.lastName}}</td>
            <td>{{patient.identifierValue}}</td>
            <td>{{patient.dateOfBirth | date: 'M/d/yyyy'}}</td>
            <td>{{patient.contactPhone}}</td>
            <td>{{patient.companyName}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <patient-lookup (patientChange)="patientChange($event)"></patient-lookup>
      <hr class="divider"/>
    </div>
  </div>
  <div *ngIf="patient.patientId">
    <div class="row">
      <div class="col-md-3">
        <label class="control-label">Debit Type</label><span class="text-danger">*</span>
        <br/>
        <select class="dropdown form-control" name="debitType" [(ngModel)]="transactionType">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of transactionTypes" [ngValue]="option">{{option}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr class="divider"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class="control-label">Employer</label> <span class="text-danger">*</span>
        <br/>
        <select class="dropdown form-control" name="employer" [(ngModel)]="employer" (ngModelChange)="updateEmployer()">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of employers" [ngValue]="option">{{option.companyName}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Region</label>
        <br/>
        <select class="dropdown form-control" name="region" [(ngModel)]="region" (ngModelChange)="updateRegion()"
                [attr.disabled]="regions.length == 0 ? 'disabled' : null">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of regions" [ngValue]="option">{{option.companyName}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Job Site</label>
        <br/>
        <select class="dropdown form-control" name="jobsite" [(ngModel)]="jobSite"
                [attr.disabled]="jobSites.length == 0 ? 'disabled' : null">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of jobSites" [ngValue]="option">{{option.companyName}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Job Number</label>
        <br/>
        <select class="dropdown form-control" name="jobNumber" [(ngModel)]="jobNumber"
                [attr.disabled]="jobNumbers.length == 0 ? 'disabled' : null">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of jobNumbers" [ngValue]="option">{{option}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr class="divider"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class="control-label">Reason For Visit</label>
        <br/>
        <select class="dropdown form-control" name="testReasonType" [(ngModel)]="testReasonType">
          <option [ngValue]="null"></option>
          <option value="ANNUAL">Annual</option>
          <option value="DRGSCRN">Drug Screen</option>
          <option value="EKG">EKG</option>
          <option value="FITDUTY">Fit for Duty</option>
          <option value="FOLLOWUP">Follow Up</option>
          <option value="ILLNESS">Illness</option>
          <option value="INJURY">Injury</option>
          <option value="PSTACCDNT">Post-Accident</option>
          <option value="PREACCESS">Pre-Access</option>
          <option value="PREEMP">Pre-Employment</option>
          <option value="QUEST">Questionnaire</option>
          <option value="RANDOM">Random</option>
          <option value="RSNSUSPCN">Reasonable Suspicion</option>
          <option value="RETRNWRK">Return to Work</option>
          <option value="VACCINE">Vaccination</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Facility</label><span class="text-danger">*</span>
        <br/>
        <select class="dropdown form-control" name="facility" [(ngModel)]="facility" [attr.disabled]="branch"
                (ngModelChange)="updateFacility()">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of facilities" [ngValue]="option">{{option.companyName}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Branch</label>
        <br/>
        <select class="dropdown form-control" name="branch" [(ngModel)]="branch" [attr.disabled]="facility">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of branches" [ngValue]="option">{{option.companyName}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Provider</label>
        <br/>
        <select class="dropdown form-control" name="provider" [(ngModel)]="provider"
                [attr.disabled]="providers.length == 0 ? 'disabled' : null">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of providers" [ngValue]="option">{{option.name}} {{option.credentials}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr class="divider"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="chargeCode">Charge Code</label><span class="text-danger">*</span>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#chargeCodeLookupModal">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <input type="text" class="form-control" id="chargeCode" name="chargeCode" [(ngModel)]="chargeCode.code"
                   disabled/>
          </div>
        </div>
        <charge-code-lookup (chargeCodeChange)="chargeCodeChange($event)"></charge-code-lookup>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="displayChargeCode">Display Charge Code</label>
          <input id="displayChargeCode" type="text" class="form-control" name="displayChargeCode"
                 [(ngModel)]="displayChargeCode">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="quantity">Quantity</label><span class="text-danger">*</span>
          <input id="quantity" type="text" class="form-control" name="quantity" [ngModel]="quantity" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="purchaseOrder">Purchase Order</label>
          <input id="purchaseOrder" type="text" class="form-control" name="purchaseOrder" [(ngModel)]="purchaseOrder">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr class="divider"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="specimenID">Specimen ID</label>
          <input id="specimenID" type="text" class="form-control" name="specimenID" [(ngModel)]="specimenId">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="groupingKey">Grouping Key</label>
          <input id="groupingKey" type="text" class="form-control" name="groupingKey" [(ngModel)]="groupingKey"
                 value="groupingKey">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Grouping Key List</label>
          <br/>
          <select class="dropdown form-control" name="groupingKeyList" [(ngModel)]="groupingKeyModel"
                  (ngModelChange)="onSelectGroupingKey($event)">
            <option [ngValue]="null"></option>
            <option *ngFor="let option of groupingKeys" [ngValue]="option">{{option.name}} ({{option.billingGroupId}})
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="notes">Notes</label>
          <input id="notes" type="text" class="form-control" name="notes" [(ngModel)]="notes">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <mat-form-field class="w-100">
            <mat-label>Service Date<span class="text-danger">*</span></mat-label>
            <input matInput [matDatepicker]="picker" name="serviceDate" [(ngModel)]="serviceDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-danger" *ngIf="!overridingPayorPrice" (click)="showOverridePayorPrice()">Override
          Payor/Price
        </button>
      </div>
    </div>
    <div *ngIf="overridingPayorPrice">
      <div class="row">
        <div class="col-md-12">
          <hr class="divider"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-danger" (click)="hideOverridePayorPrice()">Stop Overriding Payor/Price</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="payor">Payor</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#payorLookupModal">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <input type="text" class="form-control" id="payor" name="payor" [(ngModel)]="payor.name" disabled/>
              <div class="input-group-append">
                <button class="btn btn-warning" type="button" (click)="clearPayor()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
            </div>
          </div>
          <payor-lookup (payorChange)="setPayorOverride($event)"></payor-lookup>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="price">Price</label>
            <input id="price" type="text" class="form-control" name="amount" [(ngModel)]="amount">
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7"></div>
      <div class="col-md-1 text-right">
        <button class="btn btn-warning" (click)="cancel()">
          <i class="fa fa-close"></i>
          Cancel
        </button>
      </div>
      <div class="col-md-2 text-right">
        <button class="btn btn-success" (click)="saveAndNew()" [attr.disabled]="!canSave ? 'disabled' : null">
          <i class="fa fa-check-square-o"></i>
          Save and New
        </button>
      </div>
      <div class="col-md-2 text-right">
        <button class="btn btn-success" (click)="saveAndClose()" [attr.disabled]="!canSave ? 'disabled' : null">
          <i class="fa fa-check-square-o"></i>
          Save and Close
        </button>
      </div>
    </div>
  </div>
</form>
